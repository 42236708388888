import React, { useEffect, useState } from "react";
import { InsuranceSummary } from "../../components/InsuranceSummary/InsuranceSummary";
import { ExpandView } from "../../components/ExpandView";
import { useDispatch, useSelector } from "react-redux";
import {
  getPersonalAccidentInsuranceDetail,
  getMotobikeInsuranceDetail,
  getCarInsuranceDetail,
} from "../../stores/insurance/insurance.action";
import ZaloPaySDK from "../../utils/zalopay";
import { SafeAreaView } from "../../components/SafeAreaView";
import Utils from "../../utils/utils";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { TYPE_INSURANCE, PATH } from "../../utils/const";
import { findPriceAccidentInsurance, getStringDuration } from "../../utils/helper";
import images from "../../utils/images";
import ModalExternalWebsite from "../../components/Modal/ModalExternalWebsite";
import colors from "../../utils/colors";
import trackingId from "../../utils/tracking";
import "./DetailScreen.scss";

// BHTN bán theo tháng, BHXM bán theo năm
// BHXM Save Money bán theo năm => benefits[0].rate[0].premiumRule.premium_value
// BHTN Save Money có yearly, monthly. Phase 1 ZaloPay bán monthly. => Tính giá findPriceAccidentInsurance

const DetailScreen = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const type = useLocation().state?.type || TYPE_INSURANCE.ACCIDENT;
  const data = useLocation().state?.data;
  const isAccidentInsurance = type === TYPE_INSURANCE.ACCIDENT;
  const { code } = useParams();
  const {
    gettingPersonalAccidentInsuranceDetail,
    personalAccidentInsuranceDetail,
    getPersonalAccidentInsuranceDetailErrMsg,
    gettingMotobikeInsuranceDetail,
    motobikeInsuranceDetail,
    getMotobikeInsuranceDetailErrMsg,
    gettingCarInsuranceDetail,
    carInsuranceDetail,
    getCarInsuranceDetailErrMsg,
  } = useSelector((state) => state.insurance);

  useEffect(() => {
    ZaloPaySDK.setTitle("Chi tiết bảo hiểm");
  }, []);

  console.log({ planId: code, langId: "vi" })
  useEffect(() => {
    if (isAccidentInsurance) {
      dispatch(
        getPersonalAccidentInsuranceDetail({ planId: code, langId: "vi" })
      );
    } else if(type === TYPE_INSURANCE.MOTOBIKE) {
      dispatch(getMotobikeInsuranceDetail({ productId: code, langId: "vi" }));
    } else {
      dispatch(getCarInsuranceDetail({ productId: code, langId: "vi", data }));
    }
  }, [dispatch, code, type, data, isAccidentInsurance]);

  useEffect(() => {
    if (
      gettingPersonalAccidentInsuranceDetail ||
      gettingMotobikeInsuranceDetail ||
      gettingCarInsuranceDetail
    ) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [gettingPersonalAccidentInsuranceDetail, gettingMotobikeInsuranceDetail, gettingCarInsuranceDetail]);

  useEffect(() => {
    if (getPersonalAccidentInsuranceDetailErrMsg) {
      ZaloPaySDK.showDialog({
        message: getPersonalAccidentInsuranceDetailErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
    if (getMotobikeInsuranceDetailErrMsg) {
      ZaloPaySDK.showDialog({
        message: getMotobikeInsuranceDetailErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
    if (getCarInsuranceDetailErrMsg) {
      ZaloPaySDK.showDialog({
        message: getCarInsuranceDetailErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
  }, [
    getPersonalAccidentInsuranceDetailErrMsg,
    getMotobikeInsuranceDetailErrMsg,
    getCarInsuranceDetailErrMsg
  ]);

  const insuranceDetail = isAccidentInsurance
    ? personalAccidentInsuranceDetail
    : type === TYPE_INSURANCE.MOTOBIKE ? motobikeInsuranceDetail
    : carInsuranceDetail;

  const { billingTypes } = insuranceDetail || {};
  const billingType = billingTypes && billingTypes.length && billingTypes[0].billing_type

  const onNextPress = (priceAccidentInsurance) => {
  Utils.trackingBeforeRedirect(
    () => ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.BUY),
    () =>
      history.push({
        pathname: `${PATH}/customer-info`,
        state: {
          insuranceDetail,
          type,
          logo: insuranceDetail.image,
          title: insuranceDetail.translation.name,
          companyName: insuranceDetail.company.translation.name,
          totalAllSumAssured: Utils.formatMoneyVND(
            insuranceDetail.benefits[0][
              isAccidentInsurance ? "sumAssured" : "sumInsured"
            ]
          ),
          amount: isAccidentInsurance
            ? priceAccidentInsurance
            : type === TYPE_INSURANCE.MOTOBIKE ? insuranceDetail.benefits[0].rate[0].premiumRule.premium_value
            : insuranceDetail.dataFeeCar.premiums,
          timeText: getStringDuration(billingType),
        },
      })
    );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.SEE_PDF);
    setIsOpen(true);
  };

  const goToQAScreen = () => {
    Utils.trackingBeforeRedirect(
      () => ZaloPaySDK.tracking(trackingId.SUCCESSFUL_CONTRACT.FAQ),
      () => history.push(`${PATH}/qa`, { productCode: insuranceDetail.productCode })
    );
  };

  if (insuranceDetail) {
    const priceAccidentInsurance =
      isAccidentInsurance &&
      findPriceAccidentInsurance(
        insuranceDetail.benefits,
        insuranceDetail.billingTypes,
        insuranceDetail.discountRate
      );
    return (
      <SafeAreaView>
        <div className="padding-bottom-90">
          <InsuranceSummary
            logo={insuranceDetail.image}
            title={insuranceDetail.translation.name}
            companyName={insuranceDetail.company.translation.name}
            totalAllSumAssured={Utils.formatMoneyVND(
              insuranceDetail.benefits[0][
                isAccidentInsurance ? "sumAssured" : "sumInsured"
              ]
            )}
            amount={
              isAccidentInsurance
                ? priceAccidentInsurance
                : type === TYPE_INSURANCE.MOTOBIKE ? insuranceDetail.benefits[0].rate[0].premiumRule.premium_value
                : insuranceDetail.dataFeeCar.premiums
            }
            // timeText={`1 ${isAccidentInsurance ? "tháng" : "năm"}`}
            timeText={getStringDuration(billingType)}
          />
          <ExpandView
            expandable={false}
            className="margin-top-16 custom-innerHTML"
            title="Điểm nổi bật"
          >
            <div className="padding-h-16 margin-bottom-14">
              {Utils.htmlParse(insuranceDetail.translation.sellingPoint)
                .slice(0, 3)
                .map((el, index) => {
                  return (
                    <div className="benefit-item flex" key={index}>
                      <div className="radio-active">
                        <img alt="" src={images.radio_active} />
                      </div>
                      <div className="content">{el}</div>
                    </div>
                  );
                })}
            </div>
          </ExpandView>
          <ExpandView
            className="margin-top-12 custom-innerHTML"
            title="Đối tượng áp dụng"
            onTitleClick={() =>
              ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.SEE_ELIGIBILITY)
            }
          >
            <div className={"padding-h-16"}>
              <div
                className="font font-size-14 line-height-18"
                dangerouslySetInnerHTML={{
                  __html: insuranceDetail.insuredDeclaredValue,
                }}
              />
            </div>
          </ExpandView>
          <ExpandView
            className="margin-top-12 custom-innerHTML"
            title="Phạm vi bảo hiểm"
            onTitleClick={() =>
              ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.SEE_COVERAGE)
            }
          >
            <div className="padding-h-16 padding-bottom-16">
              <div
                className="font font-size-14 line-height-18"
                dangerouslySetInnerHTML={{
                  __html: insuranceDetail.advanceCash,
                }}
              />
            </div>
          </ExpandView>
          <ExpandView
            className="margin-top-12 custom-innerHTML"
            title="Quyền lợi bảo hiểm"
            onTitleClick={() =>
              ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.SEE_BENEFIT)
            }
          >
            <div className="padding-h-16 padding-bottom-16">
              <div
                className="font font-size-14 line-height-18"
                dangerouslySetInnerHTML={{
                  __html: insuranceDetail.translation.benefitsDescription,
                }}
              />
            </div>
          </ExpandView>
          <ExpandView
            className="margin-top-12 custom-innerHTML"
            title="Thủ tục bồi thường"
            onTitleClick={() =>
              ZaloPaySDK.tracking(trackingId.POLICY_DETAIL.SEE_CLAIM_PROCEDURE)
            }
          >
            <div className="padding-h-16 padding-bottom-16">
              <div
                className="font font-size-14 line-height-18"
                dangerouslySetInnerHTML={{
                  __html: insuranceDetail.translation.claimDocs,
                }}
              />
            </div>
          </ExpandView>
          <div className="bg-white flex-v-center flex-space-between margin-top-12 padding-v-12 padding-h-16">
            <div className="left-item flex-v-center">
              <img src={images.icon_systems_glyph_info} alt="" />
              <div className="color-black font-size-14 margin-left-12">
                Thông tin từ nhà cung cấp
              </div>
            </div>
            <div className="rigth-item flex-v-center" onClick={openModal}>
              <div className="color-blue font-size-14">Xem thêm</div>
              <img
                className="margin-left-8 rotate-counter-clockwise-90"
                src={images.icon_arrow_top}
                alt="icon_systems"
              />
            </div>
          </div>
          <div
            className="bg-white padding-v-12 padding-h-16 margin-bottom-16 margin-top-12"
            onClick={goToQAScreen}
          >
            <div className="flex-v-center flex-space-between">
              <div className="flex-v-center">
                <div className="qa-icon-left">
                  <img
                    src={images.icon_systems_glyph_info_tutorial}
                    alt="icon_systems"
                  />
                </div>
                <div className="qa-title color-black font-size-14 margin-left-12">
                  Câu hỏi thường gặp
                </div>
              </div>
              <div className="qa-icon-right flex-v-center">
                <div className="color-blue font-size-14">Xem thêm</div>
                <img
                  src={images.icon_arrow_top}
                  className="margin-left-8 rotate-counter-clockwise-90"
                  alt="icon_systems"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className="margin-top-12 padding-16 flex-center flex-space-between flex-row box-sizing-border bg-white pos-fixed w-100"
          style={{ bottom: 0 }}
        >
          <div className="font font-size-18 line-height-22 font-weight-bold">
            {Utils.formatMoneyVND(
              isAccidentInsurance
                ? priceAccidentInsurance
                : type === TYPE_INSURANCE.MOTOBIKE ? insuranceDetail.benefits[0].rate[0].premiumRule.premium_value
                : insuranceDetail.dataFeeCar.premiums
            )}
            đ
          </div>
          <button
            onClick={() => onNextPress(priceAccidentInsurance)}
            className="border-radius-4 bg-blue padding-v-12"
            style={{ width: "60%" }}
          >
            <div className="font font-size-16 line-height-20 font-weight-bold color-white">
              Tiếp tục
            </div>
          </button>
        </div>
        <ModalExternalWebsite
          isOpen={isOpen}
          closeModal={closeModal}
          url={insuranceDetail.translation.descriptionDetail}
          modalStyles={modalStyles}
          iframeStyles={iframeStyles}
          titleModal="Thông tin từ nhà cung cấp"
        />
      </SafeAreaView>
    );
  }
  return <></>;
};

export default DetailScreen;

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(36, 39, 43, 0.5)",
    zIndex: 3,
  },
  content: {
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: 0,
    padding: 0,
    width: "100%",
    border: "none",
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    background: colors.background,
    height: "90%",
  },
};

const iframeStyles = {
  height: "100%",
  width: "100%",
  border: "none",
};
