import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import Modal from 'react-modal';
import './Select.scss'
import Icon from 'components/Icon';
import EmptyList from 'components/EmptyList';
import Button from 'components/Button';
import images from 'utils/images';
import colors from 'utils/colors';
import Text from 'components/Text';
import Util from 'utils/utils';
import Input from 'components/Input/Input';

Modal.setAppElement('#root');

const PickerItem = (props) => {
    // console.log('props:',props)
  
    const onCheckItem = (code, name, touchableLabel) => {
      if (touchableLabel) {
        props.onCheckItem(code, touchableLabel);
      } else {
        props.onCheckItem(code, name);
      }
    };
  
    const {touchableLabel, item, selectedValue, textStyle = {}} = props;
    const isSelected = (item.code === selectedValue.code);
    const selectedStyle = isSelected ? {color: colors.main} : {};
  
    return (
      <div onClick={() => onCheckItem(item.code, item.nameWithType, touchableLabel)}>
        <div className={'select-input-item'}>
          <div className={'select-input-item-label'} style={{...textStyle, ...selectedStyle}}>{item.nameWithType}</div>
          {isSelected
            ? <Icon
              name='check'
              size={18}
              color={colors.main}
              style={{fontSize: 14, color: colors.main, marginLeft: 'auto', display: 'flex', alignItems: 'center'}}
            />
            : null
          }
        </div>
      </div>
    )
};

const Select = forwardRef(({style, className, title, error, placeholder, disabled, selectedValue, data, onValueChange, iconClear, onClearValueEvent = () => {}, require, heightModal = '20vh', isSearch = true}, ref) => {
    const [dataCurrent, setDataCurrent] = useState(data);
    const [name, setName] = useState('');
    const [filterText, setFilterText] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);

    useEffect(() => {
        setDataCurrent(data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);
    
    useEffect(() => {
        let {name} = selectedValue;
        setName(name ? name : '');
        return () => {
          document.body.style.overflow = 'unset';
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedValue, data]);

    const afterOpenModal = () => {
        document.body.style.overflow = 'hidden';
    };
    const openModal = () => {
        resetData();
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
        document.body.style.overflow = 'unset';
    };

    const resetData = () => {
        if (data) {
            setFilterText('');
            setDataCurrent(data);
        }
    };

    const onFilterChange = (value) => {
        const PATTERN = Util.cleanUnicodeAndUpperCase(value).toLowerCase().trim();
        let result = data.filter(item => {
          let slug = item.code.toLowerCase().trim();
          let nameWithType = Util.cleanUnicodeAndUpperCase(item.nameWithType).toLowerCase().trim();
          // console.log('str: ',PATTERN , slug, nameWithType);
          return slug.includes(PATTERN)
            || nameWithType.includes(PATTERN);
        });
        // console.log('result: ',result);
        setDataCurrent(result);
        setFilterText(value);
      };

    const onCheckItem = (code, name) => {
        closeModal();
        setName(name);
        onValueChange({code, name});
    };

    const onClearValue = () => {
        ref.current.closeModal();
        onClearValueEvent();
        return onValueChange && onValueChange({});
    };
    
    const labelStyle = () => {
        if (error) {
          return {color: colors.red}
        } else {
          return {color: colors.gray}
        }
    };
    
    useImperativeHandle(ref, () => ({
        openModal() {
          return openModal()
        },
        closeModal() {
            return closeModal()
        }
    }));

    return (
        <div className={`select-input ${className}`} style={{...style}}>
            <Button disabled={disabled} onPress={openModal} className={'select-input-picker'}
                    style={disabled ? {opacity: 0.35} : {}} backgroundColor={disabled ? colors.white : null}>
                <div>
                    <div className={'select-input-placeholder'}
                        style={name ? {fontSize: 11, ...labelStyle()} : {...labelStyle()}}>{placeholder}
                        {require && <span style={{color: 'red', marginLeft: 5}}>*</span>}
                    </div>
                    <Text numberOfLines={1} style={{textAlign: 'start'}}>{name}</Text>
                </div>
                {!name && <Icon name='arrowdown' size={16} color={colors.disable}/>}
            </Button>
            {name && iconClear && <div style={{position: 'relative', right: 0, left: '-8px', top: '15px'}} onClick={onClearValue}>
                <img src={images.icon_clear_text} style={{width: 18, height: 18}} alt="icon clear"/>
            </div>}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={modalStyles(heightModal)}
                contentLabel="Example Modal"
            >
                <div className={'select-input-container'}>
                    <div className={'select-input-title'}>
                        <div style={{display: "flex", justifyContent: 'center'}}>
                            <div className={'select-input-title-text'}>{title}</div>
                        </div>
                        <Button style={{position: 'absolute', top: 0, right: 0, backgroundColor: 'transparent'}}
                                onPress={closeModal}>
                            <div className={'select-input-title-text'} style={{color: colors.main, padding: '10px 16px 0px'}}>
                                <img src={images.icon_close} alt="icon clear"/>
                            </div>
                        </Button>
                    </div>

                    {
                        isSearch && <div className={'select-input-wrap-input'}>
                            <div className={'select-input-input-container'}>
                                <div className={'select-input-input-icon'}>
                                    <img src={images.icon_search} alt="icon clear"/>
                                </div>
                                <Input style={{width: '100%', marginLeft: 8, height: 40}}
                                    autoFocus={true}
                                    placeholder={'Tìm kiếm'}
                                    value={filterText}
                                    iconClear={true}
                                    onChangeText={onFilterChange}
                                />
                            </div>
                        </div>
                    }
                </div>
                <div className={'select-input-content'}>
                    {dataCurrent.length > 0
                        ? <div className="select-input-content-list">
                            {dataCurrent.map((item, index) => {
                                return <PickerItem 
                                    key={index} index={index}
                                    length={dataCurrent.length}
                                    selectedValue={selectedValue}
                                    item={item}
                                    onCheckItem={(code, name) => onCheckItem(code, name)}
                                />
                            })}
                        </div>
                        : <div style={{padding: 10}}>
                            <EmptyList
                                subTitle={`Rất xin lỗi, không có kết quả nào phù hợp. Bạn vui lòng nhập lại!`}/>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
});

const modalStyles = (height) => {
    return {
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(36, 39, 43, 0.5)',
          zIndex: 3,
        },
        content: {
          top: 'auto',
          left: 'auto',
          right: 'auto',
          bottom: 0,
          padding: 0,
          width: '100%',
          border: 'none',
          borderRadius: 0,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
          height: height
        }
    }
};

export default Select;