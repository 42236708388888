import {put, takeLatest} from 'redux-saga/effects';
import { AppApiInstance } from '../../api/app-api';
import {types} from './address.type';
import {ERROR_MESSAGES} from "../../utils/const";
import ZaloPaySDK from "../../utils/zalopay";

function* getCities() {
  try {
    const dataParse = yield AppApiInstance.getCities();
    if(dataParse && dataParse.data && dataParse.data.getCities){
      yield put({ type: types.GET_CITIES_SUCCESS, cities: dataParse.data.getCities});
    }else {
      ZaloPaySDK.showDialog({message: ERROR_MESSAGES.ADDRESS_FAIL, buttons: [{text: 'Đóng'}]});
    }
  } catch (err) {
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES.ADDRESS_FAIL, buttons: [{text: 'Đóng'}]});
  }
}

function* getDistricts({city, callback}) {
  try {
    const dataParse = yield AppApiInstance.getDistricts({city});
    if(dataParse && dataParse.data && dataParse.data.getDistricts){
      yield put({ type: types.GET_ADDRESS_SUCCESS});
      yield callback(dataParse.data.getDistricts.sort((a,b)=> a.name - b.name));
    }else {
      ZaloPaySDK.showDialog({message: ERROR_MESSAGES.ADDRESS_FAIL, buttons: [{text: 'Đóng'}]});
    }
  } catch (err) {
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES.ADDRESS_FAIL, buttons: [{text: 'Đóng'}]});
  }
}

function* getWards({city, district, callback}) {
  try {
    const dataParse = yield AppApiInstance.getWards({city, district});
    if(dataParse && dataParse.data && dataParse.data.getWards){
      yield put({ type: types.GET_ADDRESS_SUCCESS});
      yield callback(dataParse.data.getWards);
    }else {
      ZaloPaySDK.showDialog({message: ERROR_MESSAGES.ADDRESS_FAIL, buttons: [{text: 'Đóng'}]});
    }
  } catch (err) {
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES.ADDRESS_FAIL, buttons: [{text: 'Đóng'}]});
  }
}


export function* addressWatcher() {
  yield takeLatest(types.GETTING_CITIES, getCities);
  yield takeLatest(types.GETTING_DISTRICTS, getDistricts);
  yield takeLatest(types.GETTING_WARDS, getWards);
}