export default {
  INSURANCE_LISTING: {
    LOAD_PAGE_SUCCESSFULLY: "01.4A00.000",
    TWO_WHEELER_INSURANCE: "01.4A00.001",
    ACCIDENT_INSURANCE: "01.4A00.002",
    SEE_POLICY_DETAIL: "01.4A00.003",
    BUY: "01.4A00.004",
    MANAGE_CONTRACT: "01.4A00.005",

    CAR_INSURANCE: "01.4A00.006"
  },
  POLICY_DETAIL: {
    LOAD_PAGE_SUCCESSFULLY: "01.4A01.000",
    SEE_ELIGIBILITY: "01.4A01.001",
    SEE_COVERAGE: "01.4A01.002",
    SEE_BENEFIT: "01.4A01.003",
    SEE_CLAIM_PROCEDURE: "01.4A01.004",
    SEE_PDF: "01.4A01.005",
    BUY: "01.4A01.006",
  },
  CONTRACT_INFO: {
    LOAD_PAGE_SUCCESSFULLY: "01.4A02.000",
    USE_HOLDER_INFO: "01.4A02.001",
    PAYMENT: "01.4A02.002",
  },
  REVIEW_CONTRACT: {
    LOAD_PAGE_SUCCESSFULLY: "01.4A03.000",
    TICK_ON_CHECKBOX: "01.4A03.001",
    PAYMENT: "01.4A03.002",
  },
  CONTRACT_MANAGEMENT: {
    LOAD_PAGE_SUCCESSFULLY: "01.4A04.000",
    FILTER: "01.4A04.001",
    SEE_CONTRACT_DETAIL: "01.4A04.002",
    HOME: "01.4A04.003",
  },
  SUCCESSFUL_CONTRACT: {
    LOAD_PAGE_SUCCESSFULLY: "01.4A04.000",
    SEE_POLICY_DETAIL: "01.4A05.001",
    FAQ: "01.4A05.002",
    CLOSE: "01.4A05.002",
  },
};
