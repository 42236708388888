export const APPID = 1020;
export const APPID_CAR = 3507;

export const ZPI_AGREEMENT_PAY = process.env.REACT_APP_ZPI_AGREEMENT_PAY

export const PATH = "/insurance" || process.env.PUBLIC_URL;

export const NODE_ENV = {
	DEVELOPMENT: 'development',
	PRODUCTION: 'production'
}

export const API_KEY = process.env.REACT_APP_API_KEY || '';

export const ERROR_MESSAGES = {
	NORMAL: 'Có lỗi xảy ra, xin vui lòng thử lại sau!',
	LOGIN_FAIL: 'Có lỗi xảy ra, không lấy được thông tin người dùng!',
	ADDRESS_FAIL: 'Có lỗi xảy ra, không lấy được thông tin địa chỉ!',
}

export const TYPE_INSURANCE = {
	ACCIDENT: "accident",
	MOTOBIKE: "motobike",
	CAR: "car"
}

export const TYPE_CONTRACT = {
	PAID: "paid",
	UNPAID: "unpaid",
}

export const FILTER_CONTRACT = {
	ALL: "all",
	ACCIDENT: "accident",
	MOTOBIKE: "motobike",
	CAR: "car"
}

export const BILLING_TYPE = {
    yearly: 'Yearly',
    semiAnnually: 'Semi-annually',
    quarterly: 'Quarterly',
	'30days': '30days',
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily'
};

export const PRODUCT_TYPE = {
	human: 'human',
	car: 'car',
	personal_accident: 'personal_accident',
	hospital_cash: 'hospital_cash',
	motorbike_liability: 'motorbike_liability',
	travel: 'travel',
	home: 'home',
	device: 'device',
	critical_illness: 'critical_illness',
	cyber: 'cyber',
  };

export const UIC_PRODUCT_CODES = ['ZUI-PA'];

export const VNI_PRODUCT_CODES = ['VNI-O50-MB', 'VNI-U50-MB'];

export const HDI_PRODUCT_CODES = ['HDI-O50-MB', 'HDI-U50-MB', 'HDI-Car01'];

export const ABIC_PRODUCT_CODES = ['ABIC-MTO50', 'ABIC-Car01'];

export const BSH_PRODUCT_CODES = ['BSH-MTO50'];

export const USE_PURPOSE_CAR = {
	roadster: 'Xe không kinh doanh vận tải',
	car: 'Xe kinh doanh vận tải',
	// truck: 'Xe chở hàng (Xe tải)',
};
