import React, { useEffect, useState } from 'react';
import DatePickerMobile from 'react-mobile-datepicker';
import Modal from 'react-modal';
import moment from 'moment';
import colors from "../../utils/colors";
import Button from "../Button";
import images from '../../utils/images';

//to bind modal to root app
Modal.setAppElement('#root');

const dateConfig = {
  'date': {
    format: 'DD',
    caption: 'Day',
    step: 1
  },
  'month': {
    format: 'MM',
    caption: 'Mon',
    step: 1
  },
  'year': {
    format: 'YYYY',
    caption: 'Year',
    step: 1
  },
};

const dateConfigYear = {
  'year': {
    format: 'YYYY',
    caption: 'Year',
    step: 1
  },
};

const minDateDefault = new Date(1900, 1, 1);
const maxDateDefault = new Date();

const DatePicker = ({ config = 'day', date, defaultDate, minDate = minDateDefault, maxDate = maxDateDefault, confirmText = 'Chọn', cancelText = 'Hủy', error, placeholder, disabled, title = '', onChange, require }) => {
  const [dateTime, setDateTime] = useState(date ? date : defaultDate);
  const [dateTemp, setDateTemp] = useState(dateTime);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  useEffect(() => {
    if (!date && defaultDate) {
      setDateTime(defaultDate);
      setDateTemp(defaultDate);
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [defaultDate, date]);

  const onOpenModal = () => {
    setModalIsOpen(true);
  };
  const onCloseModal = () => {
    // console.log('onClose: ',dateTime,);
    document.body.style.overflow = 'unset';

    setModalIsOpen(false);
    setDateTemp(dateTime);
  };

  const afterOpenModal = () => {
    document.body.style.overflow = 'hidden';
  };

  const onChangeDate = (value) => {
    // console.log('onchange: ',value);
    setDateTemp(value);
  }

  const onSelectDate = () => {
    document.body.style.overflow = 'unset';
    setDateTime(dateTemp);
    onChange(dateTemp);
    setModalIsOpen(false);
  }

  const labelStyle = () => {
    if (error) {
      return { color: colors.red }
    } else {
      return { color: colors.gray }
    }
  }

  const Header = () => {
    return (
      <div style={styles.header}>
        <Button style={{ backgroundColor: colors.background }}
          onPress={onCloseModal}>
          <div style={{ ...styles.titleText, ...{ color: colors.main, padding: '10px 16px' } }}>{cancelText}</div>
        </Button>

        <div>
          <div style={styles.titleText}>{title}</div>
        </div>

        <Button style={{ backgroundColor: colors.background }}
          onPress={onSelectDate}>
          <div style={{ ...styles.titleText, ...{ color: colors.main, padding: '10px 16px' } }}>{confirmText}</div>
        </Button>
      </div>
    )
  }
  console.log(minDate)
  return (
    <div className="App" style={styles.datePickerStyle}>
      <Button disabled={disabled} onPress={onOpenModal} style={styles.picker}>
        {date
          ? <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <div style={{
              ...styles.placeholder, ...labelStyle(),
              marginBottom: 2,
              fontSize: 11
            }}>
              {placeholder}
              {require && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
            </div>
            {config === 'year'
              ? <div>{moment(dateTime).format('YYYY')}</div>
              : <div>{moment(dateTime).format('DD/MM/YYYY')}</div>
            }
          </div>
          : <div style={{ ...styles.placeholder, ...labelStyle() }}>
            {placeholder}
            {require && <span style={{ color: 'red', marginLeft: 5 }}>*</span>}
          </div>
        }
        {!disabled
          ? <img src={images.icon_calendar} alt="icon calendar" />
          : null
        }
      </Button>

      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={onCloseModal}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <DatePickerMobile
          value={dateTemp}
          min={minDate}
          max={maxDate}
          dateConfig={config === 'year' ? dateConfigYear : dateConfig}
          theme={'ios'}
          isPopup={false}
          customHeader={<Header />}
          showFooter={false}
          onChange={onChangeDate}
          onSelect={onOpenModal}
          onCancel={onCloseModal} />
      </Modal>
    </div>
  );
}

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(36, 39, 43, 0.5)',
    zIndex: 3,
  },
  content: {
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 0,
    padding: 0,
    width: '100%',
    border: 'none',
    backgroundColor: 'transparent',
    overflow: 'inherit',
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  }
};

const styles = {
  datePickerStyle: {
    display: 'flex',
    backgroundColor: colors.white,
    border: '1px solid',
    borderColor: colors.border,
    borderRadius: 4,
    boxSizing: 'border-box',
    overflow: 'hidden',
    width: '100%',
    height: 48,

  },
  header: {
    backgroundColor: colors.background,
    position: 'absolute',
    width: '100%',
    left: 0,
    display: 'flex',
    justifyContent: 'space-between',
  },
  picker: {
    backgroundColor: colors.transparent,
    outline: 'none',
    padding: '0px 6px 0px 12px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    color: colors.text_main,
    fontSize: 14,
  },
  titleText: {
    textAlign: 'center',
    fontSize: 14,
    color: colors.text_main
  },
};
export default DatePicker