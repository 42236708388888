import { types } from "./insurance.type";

const personalAccidentInsuranceDetailInitialState = {
  gettingPersonalAccidentInsuranceDetail: false,
  personalAccidentInsuranceDetail: null,
  getPersonalAccidentInsuranceDetailErrMsg: "",
};

const motobikeInsuranceDetailInitialState = {
  gettingMotobikeInsuranceDetail: false,
  motobikeInsuranceDetail: null,
  getMotobikeInsuranceDetailErrMsg: "",
};

const carInsuranceDetailInitialState = {
  gettingCarInsuranceDetail: false,
  carInsuranceDetail: null,
  getCarInsuranceDetailErrMsg: "",
};

const insuranceAllProductInitialState = {
  gettingInsuranceAllProduct: false,
  insuranceAllProduct: null,
  getInsuranceAllProductErrMsg: "",
};

const insuranceCarProductInitialState = {
  gettingInsuranceCarProduct: false,
  insuranceCarProduct: null,
  getInsuranceCarProductErrMsg: "",
};

const paramProductInitialState = {
  param: null
}

const initState = {
  ...insuranceAllProductInitialState,
  ...insuranceCarProductInitialState,
  ...carInsuranceDetailInitialState,
  ...personalAccidentInsuranceDetailInitialState,
  ...motobikeInsuranceDetailInitialState,
  ...paramProductInitialState
};

export const insuranceReducer = (state = initState, action) => {
  switch (action.type) {
    // PERSONAL_ACCIDENT_INSURANCE_DETAIL
    case types.GETTING_PERSONAL_ACCIDENT_INSURANCE_DETAIL:
      return {
        ...state,
        ...motobikeInsuranceDetailInitialState,
        gettingPersonalAccidentInsuranceDetail: true,
        personalAccidentInsuranceDetail: null,
        getPersonalAccidentInsuranceDetailErrMsg: "",
      };
    case types.GET_PERSONAL_ACCIDENT_INSURANCE_DETAIL_SUCCESS:
      return {
        ...state,
        gettingPersonalAccidentInsuranceDetail: false,
        personalAccidentInsuranceDetail: action.personalAccidentInsuranceDetail,
        getPersonalAccidentInsuranceDetailErrMsg: "",
      };
    case types.GET_PERSONAL_ACCIDENT_INSURANCE_DETAIL_FAIL:
      return {
        ...state,
        gettingPersonalAccidentInsuranceDetail: false,
        personalAccidentInsuranceDetail: null,
        getPersonalAccidentInsuranceDetailErrMsg: action.errMsg,
      };
    // MOTOBIKE_INSURANCE_DETAIL
    case types.GETTING_MOTOBIKE_INSURANCE_DETAIL:
      return {
        ...state,
        ...personalAccidentInsuranceDetailInitialState,
        gettingMotobikeInsuranceDetail: true,
        motobikeInsuranceDetail: null,
        getMotobikeInsuranceDetailErrMsg: "",
      };
    case types.GET_MOTOBIKE_INSURANCE_DETAIL_SUCCESS:
      return {
        ...state,
        gettingMotobikeInsuranceDetail: false,
        motobikeInsuranceDetail: action.motobikeInsuranceDetail,
        getMotobikeInsuranceDetailErrMsg: "",
      };
    case types.GET_MOTOBIKE_INSURANCE_DETAIL_FAIL:
      return {
        ...state,
        gettingPersonalAccidentInsuranceDetail: false,
        personalAccidentInsuranceDetail: null,
        getPersonalAccidentInsuranceDetailErrMsg: action.errMsg,
      };
    // LIST PERSONAL_ACCIDENT_INSURANCE
    case types.GETTING_INSURANCE_ALL_PRODUCT:
      return {
        ...state,
        gettingInsuranceAllProduct: true,
        insuranceAllProduct: null,
        getInsuranceAllProductErrMsg: "",
      };
    case types.GET_INSURANCE_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        gettingInsuranceAllProduct: false,
        insuranceAllProduct: action.insuranceAllProduct,
        getInsuranceAllProductErrMsg: "",
      };
    case types.GET_INSURANCE_ALL_PRODUCT_FAIL:
      return {
        ...state,
        gettingInsuranceAllProduct: false,
        insuranceAllProduct: null,
        getInsuranceAllProductErrMsg: action.errMsg,
      };
    // LIST CAR_INSURANCE
    case types.GETTING_INSURANCE_CAR_PRODUCT:
      return {
        ...state,
        gettingInsuranceCarProduct: true,
        insuranceCarProduct: null,
        getInsuranceCarProductErrMsg: "",
      };
    case types.GET_INSURANCE_CAR_PRODUCT_SUCCESS:
      return {
        ...state,
        gettingInsuranceCarProduct: false,
        insuranceCarProduct: action.insuranceCarProduct,
        getInsuranceCarProductErrMsg: "",
      };
    case types.GET_INSURANCE_CAR_PRODUCT_FAIL:
      return {
        ...state,
        gettingInsuranceCarProduct: false,
        insuranceCarProduct: null,
        getInsuranceCarProductErrMsg: action.errMsg,
      };
    // CAR_INSURANCE_DETAIL
    case types.GETTING_CAR_INSURANCE_DETAIL:
      return {
        ...state,
        gettingCarInsuranceDetail: true,
        carInsuranceDetail: null,
        getCarInsuranceDetailErrMsg: "",
      };
    case types.GET_CAR_INSURANCE_DETAIL_SUCCESS:
      return {
        ...state,
        gettingCarInsuranceDetail: false,
        carInsuranceDetail: action.carInsuranceDetail,
        getCarInsuranceDetailErrMsg: "",
      };
    case types.GET_CAR_INSURANCE_DETAIL_FAIL:
      return {
        ...state,
        gettingCarInsuranceDetail: false,
        carInsuranceDetail: null,
        getCarInsuranceDetailErrMsg: action.errMsg,
      };
    // PARAM PRODUCT
    case "PARAM_TYPE_PRODUCT":
      return {
        ...state,
        param: action.payload
      }
    default:
      return state;
  }
};
