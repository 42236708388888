import { types } from "./payment.type";

export const createOrder = (type, insuranceDetail, customerInfo, vehicleInfo, recipient) => {
  return {
    type: types.CREATING_ORDER,
    payload: {
      type,
      insuranceDetail,
      customerInfo,
      vehicleInfo,
      recipient,
    },
  };
};

export const payOrder = (contractId, productType) => {
  return {
    type: types.CREATING_PAY_ORDER,
    payload: {
      contractId,
      productType
    }
  };
}

export const clearCheckPayment = () => {
  return {
    type: types.CLEAR_CHECK_PAYMENT,
  };
}

export const clearPaymentInfo = () => {
  return {
    type: types.CLEAR_PAYMENT_INFO,
  };
}

export const clearErrMess = () => {
  return {
    type: types.CLEAR_MESS_ERROR,
  };
}