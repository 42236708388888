import moment from 'moment-timezone';
import EMOJI_REGEX from "./lib/Emoji";

export default class Utils {

  static invalidInfo = (key) => {
    switch (key) {
      case "fullName":
        return "Vui lòng kiểm tra họ và tên";
      case "identity":
        return "Vui lòng kiểm tra số CMND / CCCD";
      case "birthDay":
        return "Vui lòng kiểm tra sinh nhật";
      case "dateEffect":
        return "Vui lòng kiểm tra ngày hiệu lực";
      case "phone":
        return "Vui lòng kiểm tra số điện thoại";
      case "email":
        return "Vui lòng kiểm tra email";
      case "address":
        return "Vui lòng kiểm tra địa chỉ";
      case "licensePlates":
        return "Vui lòng kiểm tra biển số xe";
      case "brand":
        return "Hãng xe";
      case "name":
        return "Loại xe";
      case "year":
        return "Vui lòng kiểm tra năm sản xuất";
      case "chassisNo":
        return "Vui lòng kiểm tra số khung";
      case "engineNo":
        return "Vui lòng kiểm tra số máy";
      case "carBrand":
        return "Vui lòng chọn hãng xe";
      case "carModel":
        return "Vui lòng chọn hiệu xe";
      default:
        return "";
    }
  };

  static toTitleCase = (str = "") => {
    return str
      .split(/\s+/)
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1).toLowerCase())
      .join(" ");
  };

  static validateName = (name = "") => {
    // at least 2 words
    return /.+?(?:[\s'].+?){1,}/.test(name.trim());
  };

  static validateEmptyText = (text = "") => {
    let value = text.trim();
    if (!value || value.length === 0) {
      return false;
    }
    return true;
  };

  static validateAddress = (address) => {
    let value = address.trim();
    if (!value || value.length === 0) {
      return false;
    }
    return true;
  };

  static cleanUnicodeAndUpperCase = (string) => {
    let str = string;
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/^-+|-+$/g, "");
    return str.toUpperCase();
  }

  static validatePhone = (phone) => {
    let regex = /((09|03|07|08|05)+([0-9]{8})\b)/g;
    return regex.test(phone);
  };

  static validateIdentity = (identity) => {
    let regex = /[0-9]/g;
    return regex.test(identity) && ([9, 12].includes(identity.length));
  };

  static convertPhone = (phone) => {
    let digit = phone.substring(0, 2);
    if (digit === '84') {
      return `0${phone.slice(2,)}`;
    } else {
      return phone
    }
  };

  static convertLicensePlates = (licensePlates) => {
    let licensePlatesFormatted = licensePlates;
    if (licensePlates.length === 4) {
      // Tự động thêm dấu - Example 93M1-
      licensePlatesFormatted = `${licensePlates}-`;
    }
    if (licensePlates.length === 10 && !licensePlates.includes(".")) {
      // Tự động thêm dấu . Example 93M1-224.72
      licensePlatesFormatted =
        licensePlates.indexOf(".") !== -1
          ? licensePlates
          : `${licensePlates.substring(0, 8)}.${licensePlates.substring(8, 10)}`;
    }
    if(licensePlates.length === 10 && licensePlates.includes(".")) {
      licensePlatesFormatted = licensePlates.replace(".", "");
    }
    return licensePlatesFormatted.toUpperCase();
  };

  static _convertLicensePlates = (licensePlates) => {
    if (licensePlates && licensePlates.length === 8) {
      return `${licensePlates.substring(0, 4)}-${licensePlates.substring(4, 8)}`;
    }
    if (licensePlates && licensePlates.length === 9) {
      return `${licensePlates.substring(0, 4)}-${licensePlates.substring(4, 7)}.${licensePlates.substring(7, 9)}`
    }
  };

  static validateLicensePlates = (licensePlates) => {
    if (licensePlates && licensePlates.length === 9) {
      // 98M1-3234
      const format = [
        /^([0-9]{2}[A-Z][0-9]-[0-9]{4})$/i,
        /^([0-9]{2}[A-Z][A-Z]-[0-9]{4})$/i
      ];
      return format.some((regex) => regex.test(licensePlates));
    }
    if (licensePlates && licensePlates.length === 11) {
      //98M1-323.40
      const format = [
        /^([0-9]{2}[A-Z][0-9]-[0-9]{3}[.][0-9]{2})$/i,
        /^([0-9]{2}[A-Z][A-Z]-[0-9]{3}[.][0-9]{2})$/i
      ];
      return format.some((regex) => regex.test(licensePlates));
    }
  };

  static validateLicensePlatesRemoveCharSpecial = (licensePlates) => {
    if (licensePlates && licensePlates.length === 8) {
      const format = [
        /^([0-9]{2}[A-Z][0-9][0-9]{4})$/i,
        /^([0-9]{2}[A-Z][A-Z][0-9]{4})$/i
      ];
      return format.some((regex) => regex.test(licensePlates));
    }
    if (licensePlates && licensePlates.length === 9) {
      const format = [
        /^([0-9]{2}[A-Z][0-9][0-9]{5})$/i,
        /^([0-9]{2}[A-Z][A-Z][0-9]{5})$/i
      ];
      return format.some((regex) => regex.test(licensePlates));
    }
  };

  static checkLicensePlateMotoO50 = (licensePlates) => {
    const format = [
      /^(\d){2}[A-Z]{1}(\d){1}(\d){3}(\d){2}$/,
      /^(\d){2}[A-Z]{1}(\d){1}(\d){4}$/,
      /^(\d){2}[A-Z]{2}-(\d){3}(\d){2}$/,
      /^(\d){2}[A-Z]{2}-(\d){4}$/,
    ];
    const isValid = format.some((regex) => regex.test(licensePlates));
    return isValid
  }

  static checkLicensePlateMotoU50 = (licensePlates) => {
    const format = [
      /^(\d){2}[A-Z]{2}(\d){3}(\d){2}$/,
      /^(\d){2}[A-Z]{2}(\d){4}$/,
      /^(\d){2}MĐ(\d){1}(\d){3}(\d){2}$/,
      /^(\d){2}MĐ(\d){1}(\d){4}$/,
    ];
    const isValid = format.some((regex) => regex.test(licensePlates));
    return isValid
  }

  static convertLicensePlatesCar = (licensePlates) => {
    let licensePlatesFormatted = licensePlates;
    if (licensePlates.length === 3) {
      licensePlatesFormatted = `${licensePlates}-`;
    }
    if (licensePlates.length === 9 && !licensePlates.includes(".")) {
      licensePlatesFormatted =
        licensePlates.indexOf(".") !== -1
          ? licensePlates
          : `${licensePlates.substring(0, 7)}.${licensePlates.substring(7, 9)}`;
    }
    if(licensePlates.length === 9 && licensePlates.includes(".")) {
      licensePlatesFormatted = licensePlates.replace(".", "");
    }
    return licensePlatesFormatted.toUpperCase();
  };

  static _convertLicensePlatesCar = (licensePlates) => {
    if(licensePlates && licensePlates.length === 7){
      return `${licensePlates.substring(0, 3)}-${licensePlates.substring(3, 7)}`;
    }
    if (licensePlates && licensePlates.length === 8) {
      return `${licensePlates.substring(0, 3)}-${licensePlates.substring(3, 6)}.${licensePlates.substring(6, 8)}`;
    }
  }

  static validateLicensePlatesCar = (licensePlates) => {
    if (licensePlates && licensePlates.length === 8) {
      return /^([0-9]{2}[A-Z]-[0-9]{4})$/i.test(licensePlates);
    }
    if (licensePlates && licensePlates.length === 10) {
      return /^([0-9]{2}[A-Z]-[0-9]{3}[.][0-9]{2})$/i.test(licensePlates);
    }

  };

  static validateLicensePlatesCarRemoveCharSpecial = (licensePlates) => {
    if (licensePlates && licensePlates.length === 7) {
      return /^([0-9]{2}[A-Z][0-9]{4})$/i.test(licensePlates);
    }
    if (licensePlates && licensePlates.length === 8) {
      return /^([0-9]{2}[A-Z]{1}[0-9]{5})$/i.test(licensePlates);
    }
  };

  static validateCharSpecial = (licensePlates) => {
    const format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return format.test(licensePlates)
  }

  static validateBrand = (brand) => {
    return brand.length >= 3 && brand.length <= 50;
  };

  static validateChassisNo = (chassisNo) => {
    return chassisNo.length >= 3 && chassisNo.length <= 50;
  };

  static validateEngineNo = (engineNo) => {
    return engineNo.length >= 3 && engineNo.length <= 50;
  };

  static validateBirthDay = (date) => {
    let diff = moment().diff(moment(date, 'YYYY'), 'years');
    // console.log('old: ', diff);
    if (!date) {
      return false
    } else if (diff > 70) { // case nay chua dung
      return false
    }
    return true
  }

  static validateId = (id) => {
    let value = id.trim();
    let isNumberOrText = value.match(/^[A-Za-z0-9_.]+$/g);
    // console.log('test: ',isNumberOrText, value.length);
    if (isNumberOrText && value.length >= 8 && value.length <= 12) {
      return true;
    }
    return false;
  };

  static validateEmail = (email) => {
    return /^([a-z0-9]{1,})[a-z0-9_.]{0,}@[a-z0-9]{1,}(\.[a-z0-9]{1,}){1,}$/i.test(email);
  };

  static validateEmailNoCheck = (email) => {
    if (email) {
      return /^([a-z0-9]{1,})[a-z0-9_.]{0,}@[a-z0-9]{1,}(\.[a-z0-9]{1,}){1,}$/i.test(email);
    } else {
      return true;
    }
  }

  static checkIsAdults = (year) => {
    let diff = moment().diff(moment(year, 'YYYY'), 'years');
    // console.log('old: ', diff);
    return diff >= 18;
  }

  static upperFirstLetter(words) {
    return words.split(" ").map((word) => word.slice(0, 1).toUpperCase() + word.slice(1, word.length)).join(" ")
  };

  static formatMoneyVND = (p) => {
    return this.formatMoney(p, 0, "");
  }

  static formatMoney = (number, places, symbol, thousand, decimal) => {
    places = !isNaN(places = Math.abs(places)) ? places : 2;
    symbol = symbol !== undefined ? symbol : "$";
    thousand = thousand || ".";
    decimal = decimal || ",";

    let negative = number < 0 ? "-" : "";
    let i = parseInt(number = Math.abs(+number || 0).toFixed(places), 10) + "";
    let j = i.length;
    j = j > 3 ? j % 3 : 0;
    return negative + (j ? i.substr(0, j) + thousand : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousand) + (places ? decimal + Math.abs(number - i).toFixed(places).slice(2) : "") + symbol;
  };

  static removeEmoji(text) {
    // emoji regex from the emoji-regex library
    const regex = EMOJI_REGEX;
    if (text && text !== '') {
      return text.replace(regex, '')
    }
    return ''
  }

  static cleanCharText = (string) => {
    let str = string;
    str = str.replace(/[^\d]/g, "");
    return str;
  }

  static numToWords = (number) => {
    var digits = number.toString().length;

    if (digits > 9) {
      number /= (1000000000);
      return number.toFixed(1).replace(/\.?0+$/, '') + ' tỷ';
    }
    if (digits > 6) {
      number /= (1000000);
      return number.toFixed(1).replace(/\.?0+$/, '') + ' triệu';
    }
    return number.toString();
  }

  static htmlParse = (htmlText) => {
    const result = [];
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(htmlText, "text/html");
    const listSelector = htmlDoc.querySelectorAll("li");
    listSelector.forEach((item) => result.push(item.innerText));
    return result;
  };

  static trackingBeforeRedirect = (actionTracking, actionRedirect) => {
    actionTracking();
    const TIMEOUT = 0;
    setTimeout(() => {
      actionRedirect();
    }, TIMEOUT);
  }
}
