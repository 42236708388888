import React from 'react';
import colors from '../utils/colors';

const Button = ({onPress, style, className, backgroundColor, children, disabled = false}) => {
    const defaultBackgroundColor = (style && style.backgroundColor) ? style.backgroundColor : colors.white;
    const bgColor = () =>{
        if(backgroundColor){
            return backgroundColor;
        }else if(disabled){
            return colors.button_disable;
        }else return defaultBackgroundColor;
    };

    return (
        <button className={className} disabled={disabled} onClick={onPress} style={{background: 'none', border: 'none', outline: 'none', padding: 0, ...style, backgroundColor: bgColor(), }}>
            {children}
        </button>
    )
}

export default Button;