export const dataCar = {
    "data": {
      "getCarInsuranceAllProduct": {
        "products": [
          {
            "productCode": "HDI-O50-MB",
            "advanceCash": "<div><span style=\"font-family: Arial;\">Bảo hiểm HD sẽ chi trả cho khách hàng trong những trường hợp sau:</span></div><div><span style=\"font-family: Arial;\">-Thiệt hại ngoài hợp đồng về thân thể, tính mạng và tài sản đối với bên thứ ba do xe cơ giới gây ra.</span></div><div><span style=\"font-family: Arial;\">-Thiệt hại về thân thể và tính mạng của hành khách theo hợp đồng vận chuyển hành khách do xe cơ giới gây ra.</span></div>",
            "benefits": [
              {
                "id": "162",
                "code": null,
                "sumAssured": null,
                "__typename": "HumanBenefits"
              }
            ],
            "benefitsDescription": "<ol><li><span style=\"font-size: 14px; font-family: Arial;\">Mức trách nhiệm bảo hiểm đối với thiệt hại về người: 150 triệu đồng/1 người/1 vụ tai nạn.</span></li><li><span style=\"font-size: 14px; font-family: Arial;\">Mức trách nhiệm bảo hiểm đối với thiệt hại về tài sản: 50 triệu đồng/1 vụ tai nạn.</span></li></ol>",
            "billingType": null,
            "claimDocs": "<ul><li><span style=\"font-family: Arial; color: rgb(0, 0, 0); font-size: 0.875rem;\">Gọi ngay đến số hotline của Bảo hiểm HD (được in trên giấy chứng nhận bảo hiểm) để được hỗ trợ và hướng dẫn.</span><br></li><li><span style=\"font-family: Arial;\">Thông báo ngay cho cơ quan công an hoặc chính quyền địa phương trong trường hợp tai nạn nghiêm trọng.</span></li></ul>",
            "company": {
              "code": null,
              "description": "Trải qua hơn 30 năm hình thành và phát triển, Tập đoàn Sovico đã cung cấp những sản phẩm - dịch vụ tài chính, ngân hàng, hàng không, bất động sản và công nghiệp theo tiêu chuẩn và chất lượng quốc tế. Với mục tiêu hoàn thiện hệ sinh thái sản phẩm, dịch vụ đa dạng nhằm đem đến cho khách hàng những giá trị và sự tiện lợi, ngày 19 tháng 05 năm 2020, Công ty TNHH Bảo hiểm HD (“Bảo hiểm HD”) được thành lập với vốn điều lệ 1.800 tỷ đồng. Bảo hiểm HD nằm trong hệ sinh thái của Tập đoàn Sovico, được thừa hưởng tiềm lực tài chính và uy tín vững mạnh của Tập đoàn, đồng thời ứng dụng công nghệ số toàn diện để cam kết đem lại những sản phẩm, dịch vụ bảo hiểm phi nhân thọ đa dạng, hữu ích, với trải nghiệm thuận tiện và linh hoạt đáp ứng tối ưu nhu cầu bảo hiểm của khách hàng. Chúng tôi cung cấp đầy đủ các danh mục sản phẩm bảo hiểm phi nhân thọ trong tất cả các lĩnh vực kinh doanh và đối tượng khách hàng với mong muốn trở thành người bạn đồng hành, là chỗ dựa đáng tin cậy của mọi khách hàng trên hành trình hướng tới tương lai chung vững mạnh và thịnh vượng.",
              "id": 46,
              "image": "http://localhost:3000/assets/null",
              "name": "Công ty TNHH Bảo hiểm HD",
              "__typename": "Company"
            },
            "description": "",
            "descriptionDetail": "https://www.hdinsurance.com.vn/san-pham-ca-nhan/bao-hiem-tnds-xe-may.html",
            "discountRate": 0,
            "effectiveDate": 1,
            "insuredDeclaredValue": "<ul><li><span style=\"font-family: Arial;\">Chủ xe máy tham gia giao thông trên lãnh thổ Nước Cộng hòa Xã hội Chủ nghĩa Việt Nam</span><br></li></ul>",
            "name": "Bảo hiểm bắt buộc TNDS của chủ xe máy - Trên 50cc",
            "planId": "75c63724-7306-4411-a60b-49bead2a6e44",
            "productId": "f315fee9-95f8-490d-8e84-e8ce00040f60",
            "sellingPoint": "<ul><li><span style=\"font-size: 14px; font-family: Arial;\">Chi trả cho bên thứ ba lên đến&nbsp;150 triệu đồng/1 người/1 vụ tai nạn đối với thiệt hại về người, lên đến 50 triệu đồng/ vụ đối với thiệt hại về tài sản,</span></li><li><span style=\"font-size: 14px; font-family: Arial;\">Thủ tục mua và bồi thường đơn giản,</span></li><li><span style=\"font-size: 14px; font-family: Arial;\">Nhận giấy chứng nhận điện tử nhanh chóng.&nbsp;</span></li></ul>",
            "totalAllPremium": 66000,
            "totalAllSumAssured": 150000000,
            "type": 5,
            "zeroDepClaims": "<div><span style=\"font-family: Arial;\"><span style=\"font-size: 14px;\">1. Tôi xác nhận và hoàn toàn chịu trách nhiệm về tính chính xác, trung thực, đầy đủ của tất cả các thông tin cung cấp nêu trên và sẽ thông báo cho HDI khi có bất kỳ thông tin nào thay đổi.</span><br></span></div><div><span style=\"font-family: Arial;\"><span style=\"font-size: 14px;\">2. Tôi chấp nhận, đọc hiểu rõ tất cả các quy định của đại lý SaveMoney và HDI đối với hoạt động mua bảo hiểm và và&nbsp;</span><span style=\"color: rgb(0, 0, 255); font-size: 14px;\"><a href=\"https://www.hdinsurance.com.vn/san-pham-ca-nhan/bao-hiem-tnds-xe-may.html\" target=\"_blank\">quy tắc bảo hiểm</a></span><span style=\"font-size: 14px;\">&nbsp;đi kèm sản phẩm này của HDI.</span></span></div><div><span style=\"font-size: 14px; font-family: Arial;\">3. Đơn bảo hiểm sẽ là cơ sở của hợp đồng giữa tôi và Bảo Hiểm HDI, tôi sẽ chấp nhận tất cả các điều kiện bảo hiểm quy định tại hợp đồng bảo hiểm và nộp phí bảo hiểm đúng theo quy định.</span></div>",
            "__typename": "Product"
          },
          {
            "productCode": "VNI-O50-MB",
            "advanceCash": "<div>Trách nhiệm của bạn đối với:</div><ul><li>Thiệt hại ngoài hợp đồng về sức khỏe, tính mạng và tài sản đối với bên thứ ba do xe cơ giới gây ra.</li><li>Thiệt hại về sức khoẻ, tính mạng của hành khách do xe cơ giới gây ra.</li></ul>",
            "benefits": [
              {
                "id": "162",
                "code": null,
                "sumAssured": null,
                "__typename": "HumanBenefits"
              }
            ],
            "benefitsDescription": "<ul><li>Mức trách nhiệm bảo hiểm đối với thiệt hại về người: 150 triệu đồng/1 người/1 vụ tai nạn.</li><li>Mức trách nhiệm bảo hiểm đối với thiệt hại về tài sản: 50 triệu đồng/1 vụ tai nạn</li></ul>",
            "billingType": null,
            "claimDocs": "<ul><li>Gọi ngay đến số hotline của VNI (được in trên giấy chứng nhận bảo hiểm) để được hỗ trợ và hướng dẫn</li><li>Thông báo ngay cho cơ quan công an hoặc chính quyền địa phương trong trường hợp tai nạn nghiêm trọng&nbsp;&nbsp;</li></ul>",
            "company": {
              "code": null,
              "description": "Về hoạt động kinh doanh,VNI đã và đang là một trong các thương hiệu bảo hiểm phi nhân thọ có uy tín trên thị trường bảo hiểm phi nhân thọ tại Việt Nam. Các sản phẩm, dịch vụ bảo hiểm do VNI cung cấp góp phần hỗ trợ khách hàngổn định hoạt động kinh doanh, hướng tới mục tiêu phát triển bền vững, dài hạn.  Hiện nay mạng lưới của VNI có mặt tại hầu khắp các tỉnh, thành trên toàn quốc, với 41 Công ty bảo hiểm thành viên, hàng trăm phòng kinh doanh khu vực, hệ thống đại lý và hơn 1.200 CBNV, mở rộng hợp tác với nhiều đối tác, ngân hàng lớn, trạm đăng kiểm, bệnh viện, showroom – Garage ô tô trên khắp cả nước...giúp khách hàng tham gia bảo hiểm mọi lúc, mọi nơi. Ngoài ra, để mở rộng năng lực bảo hiểm và đảm bảo an toàn tài chính, VNI đã hợp tác với nhiều nhà môi giới tái bảo hiểm hàng đầu thế giới như Munich Re, CCR Re....thu xếp tái bảo hiểm nhanh chóng và kịp thời.",
              "id": 44,
              "image": "http://localhost:3000/assets/upload/company/vnilogo@300x-100202012290630592021111902595720211227054123.jpg",
              "name": "Tổng Công ty cổ phần Bảo hiểm Hàng Không (VNI)",
              "__typename": "Company"
            },
            "description": "",
            "descriptionDetail": "",
            "discountRate": 0,
            "effectiveDate": 1,
            "insuredDeclaredValue": "<ul><li>Chủ xe mô tô 2 bánh/ xe máy trên 50cc</li></ul>",
            "name": "Bảo hiểm bắt buộc TNDS của chủ xe mô tô - xe máy (Trên 50cc)",
            "planId": "c1bdf678-24fb-42f7-b695-80c6f754da87",
            "productId": "cb81a0f9-29fa-4b03-bd06-960e133b1de1",
            "sellingPoint": "<ul><li>Chi trả cho bên thứ ba lên đến&nbsp;150 triệu đồng/1 người/1 vụ tai nạn đối với thiệt hại về người, lên đến 50 triệu đồng/ vụ đối với thiệt hại về tài sản.<br></li><li>Thủ tục mua và bồi thường đơn giản</li><li>Nhận giấy chứng nhận điện tử nhanh chóng&nbsp; &nbsp; &nbsp;&nbsp;</li></ul><div><br></div>",
            "totalAllPremium": 66000,
            "totalAllSumAssured": 150000000,
            "type": 5,
            "zeroDepClaims": "<div>1. Tôi xác nhận và hoàn toàn chịu trách nhiệm về tính chính xác, trung thực, đầy đủ của tất cả các thông tin cung cấp nêu trên và sẽ thông báo cho VNI khi có bất kỳ thông tin nào thay đổi.<br></div><div>2. Tôi chấp nhận, đọc hiểu rõ tất cả các quy định của đại lý SaveMoney và VNI đối với hoạt động mua bảo hiểm và và&nbsp;<span style=\"color: rgb(0, 0, 255);\"><a href=\"https://bhhk.com.vn/bao-hiem-bat-buoc-tnds-cua-chu-xe-co-gioi-va-bao-hiem-tu-nguyen-tnds-cua-chu-xe-co-gioi-46.html\" target=\"_blank\">quy tắc bảo hiểm</a></span>&nbsp;đi kèm sản phẩm này của VNI.</div><div>3. Đơn bảo hiểm sẽ là cơ sở của hợp đồng giữa tôi và Bảo Hiểm VNI, tôi sẽ chấp nhận tất cả các điều kiện bảo hiểm quy định tại hợp đồng bảo hiểm và nộp phí bảo hiểm đúng theo quy định.</div><div><br></div>",
            "__typename": "Product"
          },
          {
            "productCode": "TNDS0001",
            "advanceCash": "<ul><li style=\"line-height: 1.4;\"><span class=\"fontstyle4\">Trách nhiệm của bạn đối với các Thiệt hại về sức khỏe, tính mạng và tài sản của người khác&nbsp;(bên thứ ba) do xe máy của bạn gây ra.</span></li><li style=\"line-height: 1.4;\"><span class=\"fontstyle4\" style=\"font-size: 0.875rem; color: rgb(0, 0, 0);\">Thiệt hại về tính mạng và thương tật thân thể của người lái xe và người ngồi trên xe do tai&nbsp;nạn gây ra khi sử dụng xe</span><span style=\"font-size: 0.875rem; color: rgb(0, 0, 0);\">&nbsp;&nbsp;</span></li></ul>",
            "benefits": [
              {
                "id": "137",
                "code": null,
                "sumAssured": null,
                "__typename": "HumanBenefits"
              },
              {
                "id": "139",
                "code": null,
                "sumAssured": null,
                "__typename": "HumanBenefits"
              }
            ],
            "benefitsDescription": "<ul><li style=\"line-height: 1.4;\"><span class=\"fontstyle0\">Chi trả cho Bên thứ ba tối đa như sau:</span></li><li style=\"margin-left: 30px; line-height: 1.4; list-style-type: circle;\"><span class=\"fontstyle4\">150.000.000 đồng/1 người/1 vụ tai nạn đối với thiệt hại về người.</span></li><li style=\"margin-left: 30px; line-height: 1.4; list-style-type: circle;\">50.000.000 đồng/1 vụ tai nạn đối với thiệt hại về tài sản.</li><li style=\"line-height: 1.4;\"><span style=\"color: rgb(0, 0, 0); font-size: 0.875rem;\">Chi trả cho người ngồi trên xe:</span></li><li style=\"margin-left: 30px; line-height: 1.4; list-style-type: circle;\"><span class=\"fontstyle4\">10.000.000 đồng/1 người đối với trường hợp Tử vong do tai nạn;</span></li><li style=\"margin-left: 30px; line-height: 1.4; list-style-type: circle;\"><span class=\"fontstyle4\">Chi trả theo Bảng tỷ lệ thương tật đối với các Thương tật thân thể (thương tật bộ phận&nbsp;vĩnh viễn, thương tật tạm thời) do tai nạn, tối đa đến 10.000.000 đồng/người/vụ.</span>&nbsp;&nbsp;</li></ul>",
            "billingType": null,
            "claimDocs": "<ul><li style=\"line-height: 1.4;\"><span class=\"fontstyle2\">Gọi ngay đến số hotline của PTI 1900545475 để được hỗ trợ và&nbsp;hướng dẫn</span></li><li style=\"line-height: 1.4;\"><span class=\"fontstyle2\">Thông báo ngay cho cơ quan công an hoặc chính quyền địa phương trong trường hợp tai nạn&nbsp;nghiêm trọng</span>&nbsp;&nbsp;</li></ul>",
            "company": {
              "code": null,
              "description": "PTI đem đến cho khách hàng những sản phẩm dịch vụ thiết thực, có uy tín trên thị trường",
              "id": 10,
              "image": "http://localhost:3000/assets/upload/company/pti-logo-min20210525082110.png",
              "name": "Tổng Công ty Cổ phần Bảo hiểm Bưu Điện (PTI)",
              "__typename": "Company"
            },
            "description": "Bảo hiểm bắt buộc trách nhiệm dân sự và bảo hiểm tự nguyện của chủ xe mô tô – xe máy",
            "descriptionDetail": "https://drive.google.com/file/d/1Ersq38OIeT2FEiluFXQSiclc13fjkm39/preview",
            "discountRate": 0,
            "effectiveDate": 2,
            "insuredDeclaredValue": "<ul><li style=\"line-height: 1.4;\"><span class=\"fontstyle2\">Chủ xe máy, chủ xe mô tô; hoặc</span></li><li style=\"line-height: 1.4;\"><span class=\"fontstyle2\">Người đang trực tiếp quản lý xe máy, xe mô tô (xe mượn, xe của gia đình …)</span>&nbsp;&nbsp;</li></ul>",
            "name": "Bảo hiểm bắt buộc trách nhiệm dân sự và bảo hiểm tự nguyện của chủ xe mô tô - xe máy",
            "planId": "021c2d5f-9238-4d08-b8eb-9163e0294a92",
            "productId": "3c3e79ea-3871-43ca-9015-7373f23b0859",
            "sellingPoint": "<ul><li style=\"line-height: 1.4;\">Chi trả cho bên thứ ba lên đến 150 triệu/vụ</li><li style=\"line-height: 1.4;\">Chi trả cho người ngồi trên xe lên đến 10 triệu/vụ</li><li style=\"line-height: 1.4;\">Thủ tục mua và bồi thường đơn giản</li><li style=\"line-height: 1.4;\">Giấy chứng nhận bảo hiểm được giao trong 03 ngày&nbsp; &nbsp; &nbsp;&nbsp;</li></ul>",
            "totalAllPremium": 85000,
            "totalAllSumAssured": 150000000,
            "type": 5,
            "zeroDepClaims": "<div style=\"line-height: 1.4;\"><div>1. Tôi chấp nhận, đọc hiểu rõ tất cả các quy định của đại lý SaveMoney và PTI đối với hoạt động mua bảo hiểm và các <a href=\"https://etpasaigon.vn/article/policies\" style=\"\">quy định của PTI</a>&nbsp;về bảo hiểm đã mua;<br></div><div>2. Đơn bảo hiểm là cơ sở của Hợp đồng giữa tôi và bảo hiểm và tôi sẽ chấp thuận tất cả các điều khoản quy định trong Hợp đồng bảo hiểm và các Quy định đối với loại bảo hiểm đề nghị mua của PTI;</div><div>3. Tôi xác nhận và hoàn toàn chịu trách nhiệm về tính chính xác, trung thực, đầy đủ của tất cả các thông tin cung cấp nêu trên.</div><div><br></div><div><ul style=\"margin-top:0in\" type=\"disc\"></ul></div></div>",
            "__typename": "Product"
          }
        ],
        "__typename": "HumanInsuranceFeeResponse"
      }
    }
}