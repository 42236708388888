import React from "react";

const InsuranceInfo = (props) => {
  const { contract } = props;
  return (
    <div className="bg-white padding-top-16 margin-bottom-16">
      <div className="margin-h-16 font-size-16 font-weight-bold margin-bottom-8 color-black">
        Thông tin nhận GCNBH
      </div>
      <div className="row">
        <div className="row-left">Họ và tên</div>
        <div className="row-right">{contract.recipient.name}</div>
      </div>

      <div className="row">
        <div className="row-left">Số điện thoại</div>
        <div className="row-right">{contract.recipient.phone}</div>
      </div>
      <div className="row">
        <div className="row-left">Địa chỉ</div>
        <div className="row-right">
          {contract.recipient.address}, {contract.recipient.wardNameWithType},{" "}
          {contract.recipient.districtNameWithType}, {contract.recipient.cityProvinceNameWithType}
        </div>
      </div>
    </div>
  );
};

export default React.memo(InsuranceInfo);
