import React from 'react';
import colors from "../utils/colors";
import images from '../utils/images';

const EmptyList = ({subTitle}) => {
    return (
        <div style={styles.container}>
            <img alt="" src={images.searchNullIcon} style={{width: 100, height: 100}}/>
            <div style={styles.subTitle}>{subTitle}</div>
        </div>
    )
}
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        padding: '16px 12px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        backgroundColor: 'transparent'
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: 1.29,
        textAlign: 'center',
        color: colors.black,
        margin: '8px 0',
    },
    subTitle: {
        fontSize: 14,
        lineHeight: 1.29,
        textAlign: 'center',
        color: colors.black
    }
}
export default React.memo(EmptyList);