import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import Modal from 'react-modal';
import './AddressInput.scss'
import Button from "../../../../components/Button";
import Icon from "../../../../components/Icon";
import colors from "../../../../utils/colors";
import images from "../../../../utils/images";
import Text from "../../../../components/Text";
import Util from "../../../../utils/utils";
import EmptyList from "../../../../components/EmptyList";
import Input from "../../../../components/Input/Input";

//to bind modal to root app
Modal.setAppElement('#root');

const PickerItem = (props) => {
  // console.log('props:',props)

  const onCheckItem = (code, name, touchableLabel) => {
    if (touchableLabel) {
      props.onCheckItem(code, touchableLabel);
    } else {
      props.onCheckItem(code, name);
    }
  };

  const {touchableLabel, item, selectedValue, textStyle = {}} = props;
  const isSelected = (item.code === selectedValue.code);
  const selectedStyle = isSelected ? {color: colors.main} : {};

  return (
    <div onClick={() => onCheckItem(item.code, item.nameWithType, touchableLabel)}>
      <div className={'address-input-item'}>
        <div className={'address-input-item-label'} style={{...textStyle, ...selectedStyle}}>{item.nameWithType}</div>
        {isSelected
          ? <Icon
            name='check'
            size={18}
            color={colors.main}
            style={{fontSize: 14, color: colors.main, marginLeft: 'auto', display: 'flex', alignItems: 'center'}}
          />
          : null
        }
      </div>
    </div>
  )
}


const AddressInput = forwardRef(({style, className, title, error, placeholder, disabled, selectedValue, data, onValueChange, require}, ref) => {

  const [dataCurrent, setDataCurrent] = useState(data);
  const [name, setName] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  // console.log('data:',filterText, dataCurrent, data);

  useEffect(() => {
    setDataCurrent(data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    let {name} = selectedValue;
    setName(name ? name : '');
    return () => {
      document.body.style.overflow = 'unset';
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, data]);

  // const getItemSelected = (items) => {
  //   let code = 0, name = '';
  //   items.forEach(child => {
  //     if (child.code === selectedValue) {
  //       code = child.code;
  //       name = child.nameWithType;
  //     }
  //   });
  //   return {code, name}
  // };

  const afterOpenModal = () => {
    document.body.style.overflow = 'hidden';
  };
  const openModal = () => {
    resetData();
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'unset';
  };

  const resetData = () => {
    if (data) {
      setFilterText('');
      setDataCurrent(data);
    }
  };

  const onFilterChange = (value) => {
    const PATTERN = Util.cleanUnicodeAndUpperCase(value).toLowerCase().trim();
    let result = data.filter(item => {
      let slug = item.slug.toLowerCase().trim();
      let nameWithType = Util.cleanUnicodeAndUpperCase(item.nameWithType).toLowerCase().trim();
      // console.log('str: ',PATTERN , slug, nameWithType);
      return slug.includes(PATTERN)
        || nameWithType.includes(PATTERN);
    });
    // console.log('result: ',result);
    setDataCurrent(result);
    setFilterText(value);
  };

  const onCheckItem = (code, name) => {
    closeModal();
    setName(name);
    onValueChange({code, name});
  };

  const labelStyle = () => {
    if (error) {
      return {color: colors.red}
    } else {
      return {color: colors.gray}
    }
  };

  useImperativeHandle(ref, () => ({
    openModal() {
      return openModal()
    }
  }));

  return (
    <div className={`address-input ${className}`} style={{...style}}>
      <Button disabled={disabled} onPress={openModal} className={'address-input-picker'}
              style={disabled ? {opacity: 0.35} : {}} backgroundColor={disabled ? colors.white : null}>
        <div>
          <div className={'address-input-placeholder'}
               style={name ? {fontSize: 11, ...labelStyle()} : {...labelStyle()}}>
                {placeholder}
                {require && <span style={{color: 'red', marginLeft: 5}}>*</span>}
          </div>
          <Text numberOfLines={1} style={{textAlign: 'start'}}>{name}</Text>
        </div>
        <Icon name='arrowdown' size={16} color={colors.disable}/>
      </Button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={modalStyles}
        contentLabel="Example Modal"
      >
        <div className={'address-input-container'}>
          <div className={'address-input-title'}>
            <div style={{display: "flex", justifyContent: 'center'}}>
              <div className={'address-input-title-text'}>{title}</div>
            </div>
            <Button style={{position: 'absolute', top: 0, right: 0, backgroundColor: 'transparent'}}
                    onPress={closeModal}>
              <div className={'address-input-title-text'} style={{color: colors.main, padding: '10px 16px 0px'}}>
                <img src={images.icon_close} alt="icon clear"/>
              </div>
            </Button>
          </div>

          <div className={'address-input-wrap-input'}>
            <div className={'address-input-input-container'}>
              <div className={'address-input-input-icon'}>
                <img src={images.icon_search} alt="icon clear"/>
              </div>
              <Input style={{width: '100%', marginLeft: 8, height: 40}}
                     autoFocus={true}
                     placeholder={'Tìm kiếm'}
                     value={filterText}
                     iconClear={true}
                     onChangeText={onFilterChange}
              />
            </div>
          </div>

          <div className={'address-input-content'}>
            {dataCurrent.length > 0
              ? <div className="address-input-content-list">
                {dataCurrent.map((item, index) => {
                  return <PickerItem key={index} index={index}
                                     length={dataCurrent.length}
                                     selectedValue={selectedValue}
                                     item={item}
                                     onCheckItem={(code, name) => onCheckItem(code, name)}
                  />
                })}
              </div>
              : <div style={{padding: 10}}>
                <EmptyList
                  subTitle={`Rất xin lỗi, không có kết quả nào phù hợp. Bạn vui lòng nhận lại địa chỉ khác nhé!`}/>
              </div>
            }
          </div>
        </div>
      </Modal>
    </div>
  )
});

const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(36, 39, 43, 0.5)',
    zIndex: 3,
  },
  content: {
    top: 'auto',
    left: 'auto',
    right: 'auto',
    bottom: 0,
    padding: 0,
    width: '100%',
    border: 'none',
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  }
};

export default AddressInput