import {types} from './address.type';

export const getCities = () => {
  return {
    type: types.GETTING_CITIES,
  }
};
export const getDistricts = ({city}, callback) => {
  return {
    type: types.GETTING_DISTRICTS,
    city,
    callback
  }
};
export const getWards = ({city, district}, callback) => {
  return {
    type: types.GETTING_WARDS,
    city, district,
    callback
  }
};
