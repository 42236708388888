import React, {useState} from 'react';
import './float-input-styles.css';
import colors from "../../utils/colors";
import images from '../../utils/images';
import Util from "../../utils/utils";

const FloatInput = React.forwardRef(({
                                         style, value, label, type, id,
                                         emoji = false, iconClear = true, onChangeText, onSubmitEditing, textTransform,
                                         onEndEditing, disabled, error, onChangeEvent = () => {}, onKeyDownEvent = () => {}, onClearValueEvent = () => {}, require
                                     }, ref) => {
    const [isFocus, setIsFocus] = useState(false);

    const onChange = (e) => {
        let value = e.target.value;
        if(!emoji){
            value = Util.removeEmoji(value);
        }
        if (type === 'tel') {
            value = Util.cleanCharText(value);
        }
        if (value.toLowerCase().includes('script')) {
            value = Util.cleanCharText(value);
        }
        onChangeEvent(value);
        return onChangeText && onChangeText(value);
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            return onSubmitEditing && onSubmitEditing();
        } else {
            let value = e.target.value;
            return onChangeText && onChangeText(value);
        }
    };

    const onKeyDown = (e) => {
        onKeyDownEvent(e);
    }

    const onBlur = (e) => {
        // console.log('onBlur',e)
        let value = e.target.value;
        setIsFocus(false);
        return onEndEditing && onEndEditing(value);
    }

    const onFocus = (e) => {
        setIsFocus(true);
    };

    const onClearValue = () => {
        ref.current.focus();
        onClearValueEvent();
        return onChangeText && onChangeText('');
    };

    const labelStyle = () => {
        if (disabled) {
            return {color: colors.disable}
        } else if (error) {
            return {color: colors.red}
        } else if (isFocus) {
            return {color: colors.main}
        } else {
            return {color: colors.disable}
        }
    }
    const textTransformStyle = () => {
        if (textTransform) {
            return {textTransform: textTransform}
        } else {
            return {textTransform: 'unset'}
        }
    }

    return (
      <div style={styles.container}>
          <div className="form-group" style={{...styles.wrap, ...style}}>
              <input ref={ref} id={id} required
                     className="form-control"
                     autoComplete="off"
                     style={{...styles.input, ...textTransformStyle()}}
                     type={type === 'tel' ? 'tel' : type === 'number' ? 'number' : 'text'}
                     value={value}
                     disabled={disabled}
                     onKeyUp={onKeyPress}
                     onKeyDown={onKeyDown}
                     onBlur={onBlur}
                     onFocus={onFocus}
                     onChange={onChange}/>
                <label className="form-control-placeholder" htmlFor={id}
                        style={{...styles.label, ...labelStyle()}}>{label}
                        {require && <span style={{color: 'red', marginLeft: 5}}>*</span>}
                </label>
          </div>

          {value && iconClear && !disabled
            ? <div onClick={onClearValue} style={styles.iconClear}>
                <img src={images.icon_clear_text} style={{width: 18, height: 18}} alt="icon clear"/>
            </div>
            : null
          }
      </div>
    )
});

export default FloatInput;

const styles = {

    container: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: `1px solid ${colors.border}`,
        borderRadius: 4,
        padding: '8px 0px',
        overflow: 'hidden',
        marginBottom: 16,
        position: 'relative',
    },
    wrap: {
        width: '100%',
        padding: '5px 12px',
        boxSizing: 'border-box',
    },
    label: {
        color: colors.disable,
        marginLeft: 2,
        fontSize: 14,
    },
    input: {
        color: colors.black,
        fontSize: 14,
        width: '100%',
        padding: 0,
        backgroundColor: colors.transparent,
    },
    iconClear: {
        backgroundColor: colors.transparent,
        width: 15,
        height: 15,
        marginRight: 12,
        marginLeft: 10
    },
};
