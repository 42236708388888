import React, { useEffect, useState } from "react";
import { Ticket } from "../../components/Ticket/Ticket";
import "./ManagementScreen.scss";
import images from "../../utils/images";
import Util from "../../utils/utils";
import ZaloPaySDK from "../../utils/zalopay";
import { SafeAreaView } from "../../components/SafeAreaView";
import { useDispatch, useSelector } from "react-redux";
import { getContracts } from "../../stores/contract/contract.action";
import moment from "moment";
import { contractStatusAdapter, getStringDuration } from "../../utils/helper";
import { useHistory, useLocation } from "react-router-dom";
import {
  PATH,
  TYPE_CONTRACT,
  FILTER_CONTRACT,
  APPID,
  ZPI_AGREEMENT_PAY,
  PRODUCT_TYPE,
  APPID_CAR,
} from "../../utils/const";
import ModalFilter, { listFilter } from "./components/ModalFilter";
import trackingId from "../../utils/tracking";
import EmptyList from "../../components/EmptyList";
import { groupBy } from "../../utils/helper";
import {
  clearAgreementpay, createAgreementpay,
} from "../../stores/agreementpay/agreementpay.action";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ManagementScreen = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.appParams);
  const history = useHistory();
  const tab = useQuery().get('payment') || TYPE_CONTRACT.PAID;
  const { param } = useSelector((state) => state.insurance);
  const [filter, setFilter] = useState(param || FILTER_CONTRACT.ACCIDENT);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const { contracts, gettingContracts, getContractsErrMsg } = useSelector(
    (state) => state.contract
  );
  const {
    creatingAgreementpay,
    agreementpayResponse,
    createAgreementpayErrMsg,
  } = useSelector((state) => state.agreementpay);

  useEffect(() => {
    if(param && contracts) {
      if(param === "motobike") {
        setFilter(FILTER_CONTRACT.MOTOBIKE);
      }else if(param === "car") {
        setFilter(FILTER_CONTRACT.CAR);
      }else if(param === "accident") {
        setFilter(FILTER_CONTRACT.ACCIDENT);
      }
    }
  }, [param, contracts])
  
  useEffect(() => {
    const unListen = history.listen((newLocation, action) => {
      if (action === "POP") {
        if(param) {
          history.push(`${PATH}/home?product_type=${param}`);
        }else {
          history.push(`${PATH}/home`);
        }
        // history.push(`${PATH}/home`);
      }
    });
    return () => {
      setTimeout(() => {
        unListen();
      }, 500);
    };
  }, [history]);

  useEffect(() => {
    ZaloPaySDK.setTitle("Quản lý hợp đồng");
    ZaloPaySDK.getAppUserInfoChangeTab(APPID, () => {});
  }, []);

  useEffect(() => {
    if (agreementpayResponse) {
      dispatch(clearAgreementpay());
      if (ZaloPaySDK.isZaloPay()) {
        ZaloPaySDK.launchDeeplink(
          agreementpayResponse.requestBinding.deepLink,
          () => { }
        );
      } else {
        const appID = param ? ["motobike", "accident"].includes(param) ? APPID : APPID_CAR : APPID;
        console.log("screen management - appID: ", appID)
        const queryParam = {
          appid: appID,
          binding_token: agreementpayResponse.requestBinding.bindingToken,
        };
        ZaloPaySDK.navigateTo(
          ZPI_AGREEMENT_PAY + new URLSearchParams(queryParam)
        );
      }
      if(param) {
        history.push(`${PATH}/home?product_type=${param}`);
      }else {
        history.push(`${PATH}/home`);
      }
      // history.push(`${PATH}/home`);
    }
  }, [agreementpayResponse, dispatch, history, userInfo.mUid]);

  useEffect(() => {
    dispatch(getContracts(userInfo.mUid, tab, 'vi'));
  }, [tab, dispatch, userInfo.mUid]);

  useEffect(() => {
    if (gettingContracts || creatingAgreementpay) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [gettingContracts, creatingAgreementpay]);

  useEffect(() => {
    if (getContractsErrMsg) {
      ZaloPaySDK.showDialog({
        message: getContractsErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
    if (createAgreementpayErrMsg) {
      ZaloPaySDK.showDialog({
        message: createAgreementpayErrMsg,
        buttons: [{ text: "Đóng" }],
      });
    }
  }, [getContractsErrMsg, createAgreementpayErrMsg]);

  const renderActions = (contract, latestEndorsementApproved) => {
    const isMotobike = contract.productType === PRODUCT_TYPE.motorbike_liability;
    const isHaveRenew =
      moment(
        latestEndorsementApproved
          ? latestEndorsementApproved.IMExpiryDate
          : contract.insuredMemberDetail[0].expired_date
      ).diff(moment(), "days") > 2 && contract.status === "approved";
    // ONLY FOR PRODUCT PA
      const isRenewed = contract.bindingStatus === "Confirmed" ||
      contract.bindingStatus === "Disabled";

    if (isMotobike || (!isRenewed && !isHaveRenew)) {
      return (
        <div className="status padding-top-0">
          <div className="title color-black">
            <span
              className={
                contract.status === "approved" ? "color-green" : "color-orange"
              }
            >
              {contractStatusAdapter(
                contract.status,
                contract.insuredMemberDetail[0].effective_date,
                latestEndorsementApproved
                  ? latestEndorsementApproved.IMExpiryDate
                  : contract.insuredMemberDetail[0].expired_date
              )}
            </span>
          </div>
          <div onClick={() => viewContractDetail(contract)}>
            <img className="info" src={images.info} alt="" />
          </div>
        </div>
      );
    } else {
      if (!isRenewed) {
        return (
          <>
            <div className="row action">
              <div className="flex-v-center">
                <div className="button-renew">
                  <button
                    className="renew-button"
                    onClick={() => {
                      dispatch(createAgreementpay(-1, contract.quotationCode));
                    }}
                  >
                    <img src={images.icon_systems_outline_auto_renew} alt="" />
                    Đăng ký gia hạn tự động
                  </button>
                </div>
              </div>
              <div onClick={() => viewContractDetail(contract)}>
                <img className="info" src={images.info} alt="" />
              </div>
            </div>
            <div className="status">
              <div className="title color-black">
                <span
                  className={
                    contract.status === "approved"
                      ? "color-green"
                      : "color-orange"
                  }
                >
                  {contractStatusAdapter(
                    contract.status,
                    contract.insuredMemberDetail[0].effective_date,
                    latestEndorsementApproved
                      ? latestEndorsementApproved.IMExpiryDate
                      : contract.insuredMemberDetail[0].expired_date
                  )}
                </span>
              </div>
            </div>
          </>
        )
      } else {
        return (
          <>
            <div className="row action">
              <div className="flex-v-center">
                <div className="content">Đã đăng ký gia hạn tự động</div>
              </div>
              <div onClick={() => viewContractDetail(contract)}>
                <img className="info" src={images.info} alt="" />
              </div>
            </div>
            <div className="status">
              <div className="title color-black">
                <span
                  className={
                    contract.status === "approved"
                      ? "color-green"
                      : "color-orange"
                  }
                >
                  {contractStatusAdapter(
                    contract.status,
                    contract.insuredMemberDetail[0].effective_date,
                    latestEndorsementApproved
                      ? latestEndorsementApproved.IMExpiryDate
                      : contract.insuredMemberDetail[0].expired_date
                  )}
                </span>
              </div>
            </div>
          </>
        );
      }
    }
  };

  const viewContractDetail = (contract) => {
    Util.trackingBeforeRedirect(
      () =>
        ZaloPaySDK.tracking(trackingId.CONTRACT_MANAGEMENT.SEE_CONTRACT_DETAIL),
      () =>
        history.push({
          pathname: `${PATH}/${contract.status === "new" ? "contract-review" : "contract-detail"
            }/${contract.quotationCode}`,
          state: {
            fromPaymentPage: false,
          },
        })
    );
  };

  const onChangeFilter = (value) => {
    ZaloPaySDK.showLoading();
    setTimeout(() => {
      setFilter(value);
      switch (value) {
        case FILTER_CONTRACT.ACCIDENT:
        case FILTER_CONTRACT.MOTOBIKE:
          ZaloPaySDK.getAppUserInfoChangeTab(APPID, () => {});
          break;
        case FILTER_CONTRACT.CAR:
          ZaloPaySDK.getAppUserInfoChangeTab(APPID_CAR, () => {});
          break;
        default:
          break;
      }
      ZaloPaySDK.hideLoading();
    }, 1000);
    setIsOpenModal(false);
  };

  const closeModal = () => {
    setIsOpenModal(false);
  };

  const openModal = () => {
    ZaloPaySDK.tracking(trackingId.CONTRACT_MANAGEMENT.FILTER);
    setIsOpenModal(true);
  };
  const contractFiltered = (contracts || []).filter((i) => {
    //  status = new : chưa thanh toán, status != new : đã thanh toán
    const filterType =
      tab === TYPE_CONTRACT.PAID ? i.status !== "new" : i.status === "new";
    switch (filter) {
      // BHTN accident không có plateNumber
      // case FILTER_CONTRACT.ACCIDENT:
      //   return filterType && !i.insuredMemberDetail[0].plateNumber;
      // case FILTER_CONTRACT.MOTOBIKE:
      //   return filterType && i.insuredMemberDetail[0].plateNumber;
      // default:
      //   return filterType;
      case FILTER_CONTRACT.ACCIDENT:
        return filterType && i.productType === PRODUCT_TYPE.personal_accident;
      case FILTER_CONTRACT.MOTOBIKE:
        return filterType && i.productType === PRODUCT_TYPE.motorbike_liability;
      case FILTER_CONTRACT.CAR:
        return filterType && i.productType === PRODUCT_TYPE.car;
      default:
        return filterType;
    }
  });
  const contractsRender = groupBy(contractFiltered, "createdDate");

  return (
    <SafeAreaView>
      <div className="management-contract bg">
        <div className="flex bg-white header-management-page">
          <div
            className={`management-tab ${TYPE_CONTRACT.PAID === tab ? "border-bottom-blue-2" : ""
              }`}
            onClick={() => history.push(`./management?payment=${TYPE_CONTRACT.PAID}`)}
          >
            <div
              className={`font-size-14 margin-top-4 item-bold ${TYPE_CONTRACT.PAID === tab ? "color-blue" : "color-gray"
                }`}
            >
              Đã thanh toán
            </div>
          </div>
          <div
            className={`management-tab ${TYPE_CONTRACT.UNPAID === tab ? "border-bottom-blue-2" : ""
              }`}
            onClick={() => history.push(`./management?payment=${TYPE_CONTRACT.UNPAID}`)}
          >
            <div
              className={`font-size-14 margin-top-4 item-bold ${TYPE_CONTRACT.UNPAID === tab ? "color-blue" : "color-gray"
                }`}
            >
              Chưa thanh toán
            </div>
          </div>
          {
            <div className="management-filter" onClick={openModal}>
              <div className="icon-filter bg">
                {FILTER_CONTRACT.ALL === filter ? (
                  <img src={images.icon_systems_outline_filter} alt="" />
                ) : (
                  <img src={images.icon_systems_outline_filter_active} alt="" />
                )}
              </div>
            </div>
          }
        </div>
        <div className="management-content">
          <div style={{
            textAlign: "center",
            color: "#006dff",
            fontSize: "18px",
            paddingBottom: 15
          }}>
            <b>{listFilter.find(f => f.value === filter).label}</b>
          </div>
          {Object.keys(contractsRender).map((el, index) => {
            return (
              <div key={index} className="margin-bottom-16">
                {contractsRender[el].length && (
                  <div className="unit">Tháng {el}</div>
                )}
                {contractsRender[el].map((item) => {
                  const isMotobike = item.insuredMemberDetail[0].plateNumber;
                  const isCar = item.productType === PRODUCT_TYPE.car;
                  const latestEndorsementApproved = item.endorsement
                    .find(
                      (endorsement) =>
                        endorsement.status === "approved" ||
                        endorsement.status === "waiting_for_approve"
                    );
                  const productName = (item.productTranslations
                    && item.productTranslations.length
                    && item.productTranslations[0].name) || '';
                  return (
                    <div className="wrap-ticket" key={item.quotationCode}>
                      <Ticket
                        to={{
                          pathname: `${PATH}/${item.status === "new"
                              ? "contract-review"
                              : "contract-detail"
                            }/${item.quotationCode}`,
                          state: {
                            fromPaymentPage: false,
                          },
                        }}
                      >
                        <>
                          <div className="contract-code bg-white">
                            <div className="code">
                              <div className="title">Mã hợp đồng</div>
                              <div className="value color-blue">
                                {item.quotationCode}
                              </div>
                            </div>
                            <div className="contract-price">
                              <div className="value">
                                {Util.formatMoneyVND(item.premium * (1 - (item.discountRate || 0) / 100))}đ
                              </div>
                              <div className="detail color-gray product-name">
                                {`${productName} ${getStringDuration(item.billingType)}`}
                              </div>
                            </div>
                          </div>
                          <div className="customer">
                            <div className="customer-detail flex-v-center">
                              <div className="icon margin-right-12">
                                <img
                                  alt=""
                                  src={
                                    isCar ? images.icon_car :
                                    isMotobike
                                      ? images.icon_bike
                                      : images.icon_accident
                                  }
                                />
                              </div>
                              <div>
                                <div className="name">{item.holderName}</div>
                                <div className="birthday">
                                  {isMotobike &&
                                    `Biển số xe ${item.insuredMemberDetail[0].plateNumber}`}
                                </div>
                              </div>
                            </div>
                            <div className="logo">
                              <img
                                alt=""
                                src={item.productLogo || item.companyLogo}
                              />
                            </div>
                          </div>
                          <div className="valid-date">
                            <div className="validity-date">
                              <div className="title">Ngày hiệu lực</div>
                              <div className="date">
                                {moment(
                                  item.insuredMemberDetail[0].effective_date
                                ).format("DD/MM/YYYY")}
                              </div>
                            </div>
                            <div className="expire-date bg-white">
                              <div className="title">Ngày hết hạn</div>
                              <div className="date">
                                {latestEndorsementApproved
                                  ? moment(
                                    latestEndorsementApproved.IMExpiryDate
                                  ).format("DD/MM/YYYY")
                                  : moment(
                                    item.insuredMemberDetail[0].expired_date
                                  ).format("DD/MM/YYYY")}
                              </div>
                            </div>
                          </div>
                        </>
                        {renderActions(item, latestEndorsementApproved)}
                      </Ticket>
                    </div>
                  );
                })}
              </div>
            );
          })}
          {contractFiltered.length === 0 && (
            <EmptyList subTitle="Bạn chưa có gói bảo hiểm nào" />
          )}
        </div>
        <ModalFilter
          isOpen={isOpenModal}
          filterValue={filter}
          onChange={onChangeFilter}
          closeModal={closeModal}
        />
      </div>
    </SafeAreaView>
  );
};

export default ManagementScreen;
