import moment from "moment";
import { BILLING_TYPE } from '../utils/const';

export const billingTypeAdapter = (billingType) => {
  switch (billingType) {
    case BILLING_TYPE.yearly:
      return "năm";
    case BILLING_TYPE.monthly:
      return "tháng";
    case BILLING_TYPE['30days']:
      return "30 ngày";
    default:
      return "năm";
  }
};

export const getStringDuration = (billingType) => {
  switch (billingType) {
    case BILLING_TYPE.yearly:
      return "1 năm";
    case BILLING_TYPE.monthly:
      return "1 tháng";
    case BILLING_TYPE['30days']:
      return "30 ngày";
    default:
      return "1 năm";
  }
};

export function getDurationUnitBillingType(billingType) {
  switch (billingType) {
    case BILLING_TYPE.yearly:
      return {
        duration: 1,
        unit: 'years'
      };
    case BILLING_TYPE.semiAnnually:
      return {
        duration: 6,
        unit: 'months'
      };
    case BILLING_TYPE.quarterly:
      return {
        duration: 3,
        unit: 'months'
      };
    case BILLING_TYPE.monthly:
      return {
        duration: 1,
        unit: 'months'
      };
    case BILLING_TYPE.weekly:
      return {
        duration: 1,
        unit: 'weeks'
      };
    case BILLING_TYPE.daily:
      return {
        duration: 1,
        unit: 'days'
      };
    case BILLING_TYPE['30days']:
      return {
        duration: 30,
        unit: 'days'
      };
    default:
      return {
        duration: 0,
        unit: 'days'
      };
  }
}

export const scrollToRefObject = (ref) => {
  const wrapper = document.getElementById("wrapper-scroll");
  if (wrapper && ref && ref.current) {
    wrapper.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  }
};


export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

export const contractStatusAdapter = (status, effectiveDate, expiredDate) => {
  // effectiveDate , expiredDate : string "YYYY-MM-DD HH:mm:ss"
  switch (status) {
    case "new":
      return "Chờ thanh toán";
    case "waiting_for_approve":
      return "Đang xử lý";
    case "approved":
      if (moment(expiredDate, "YYYY-MM-DD HH:mm:ss") < moment()) {
        return "Hết hạn";
      } else if (
        moment(expiredDate, "YYYY-MM-DD HH:mm:ss") > moment() &&
        moment(effectiveDate, "YYYY-MM-DD HH:mm:ss") < moment()
      ) {
        return "Có hiệu lực";
      } else {
        return "Đã xác nhận";
      }
    case "rejected":
      return "Thất bại/Đã hoàn tiền";
    case "cancelled":
      return "Đã hủy";
    default:
      return "";
  }
};

export const findPriceAccidentInsurance = (
  benefits,
  billingTypes,
  discountRate
) => {
  const id = billingTypes.find((i) => i.billing_type === "Monthly" || "Yearly").id || "";

  const benefitRate = benefits[0].rate.find((i) => i.billing_type_id === id);
  let price = 0;
  if (benefitRate.fixed_premium) {
    price = benefitRate.fixed_premium;
  } else {
    //! Price not calculate with premium rate of benefit
    price = benefits[0].sumAssured;
  }
  return (price * (100 - discountRate || 0)) / 100;
};

export const sortAlphaBeta = (a, b) => {
  if(a.productCode.includes("ABIC")) {
    return -1
  }
  if (a.productCode < b.productCode) {
    return -1;
  }
  if (a.productCode > b.productCode) {
    return 1;
  }
  return 0;
}

export const createAccidentAdapter = (insuranceDetail, customerInfo, mUid) => {
  const externalData = { userId: mUid };
  // phải trả lời các câu hỏi : logic Save Money.
  const uwQuestionAnswers = insuranceDetail.uwQuestions.map(() => {
    return { answer: "no" };
  });
  delete insuranceDetail.uwQuestions;
  // phase 1 bảo hiểm tai nạn bán theo tháng.
  const billingType = insuranceDetail.billingTypes[0].billing_type;
  insuranceDetail.paymentPlan = billingType;
  // ngày hết hạn tính theo ngày hiệu lực, nhờ trừ đi 1 giây ( logic Save Money )
  const effectiveDate = moment(customerInfo.dateEffect, "DD/MM/YYYY").format();
  const dob = customerInfo.dobEffect ? moment(customerInfo.dobEffect, "DD/MM/YYYY").format('YYYY-MM-DD') : '1990-01-01';
  const { duration, unit } = getDurationUnitBillingType(billingType);
  const expiryDate = moment(effectiveDate)
    .add(duration, unit)
    .subtract(1, "second")
    .format();
  // gender hard code male. Cái bảo hiểm tai nạn bên đó ko có gender other
  const policyHolder = {
    externalData: JSON.stringify(externalData),
    fullName: customerInfo.fullName,
    dob,
    gender: "male",
    nationalId: customerInfo.identity || "nationalId",
    phoneNumber: customerInfo.phone,
    email: customerInfo.email,
    city: customerInfo.city.code,
    district: customerInfo.district.code,
    ward: customerInfo.ward.code,
    address: customerInfo.address,
  };
  const insuredMember = JSON.parse(JSON.stringify(policyHolder));
  delete insuredMember.externalData;
  insuredMember.relationship = "yourself";
  insuredMember.uwQuestionAnswers = uwQuestionAnswers;
  const selectedBenefitCodes = insuranceDetail.benefits.map(
    (i) => i.benefitCode
  );
  if (insuranceDetail.effectiveDate) {
    insuranceDetail.effectiveDate = insuranceDetail.effectiveDate.toString();
  }
  return {
    policyHolder,
    insuredMember: [insuredMember],
    insuredMemberDetail: {
      product: formatProductInsurance(insuranceDetail),
      annualPremium: findPriceAccidentInsurance(
        insuranceDetail.benefits,
        insuranceDetail.billingTypes,
        insuranceDetail.discountRate
      ).toString(),
      effectiveDate,
      expiryDate,
    },
    selectedBenefitCodes,
  };
};

const formatProductInsurance = (insuranceDetail) => {
  insuranceDetail.benefits = insuranceDetail.benefits.map(benefit => {
    const rate = benefit.rate.map(element => {
      delete element.__typename;
      return element;
    })

    const childBenefits = benefit.childBenefits.map(element => {
      delete element.__typename;
      return element;
    })
    benefit.rate = rate;
    benefit.childBenefits = childBenefits;
    delete benefit.__typename;
    return benefit;
  });
  insuranceDetail.billingTypes = insuranceDetail.billingTypes.map(billing => {
    delete billing.__typename;
    return billing;
  })
  insuranceDetail.paymentTerms = insuranceDetail.paymentTerms.map(paymentTerm => {
    delete paymentTerm.__typename;
    return paymentTerm;
  })
  delete insuranceDetail.company.translation.__typename;
  delete insuranceDetail.company.__typename;
  delete insuranceDetail.eligibility.__typename;
  delete insuranceDetail.plan.__typename;
  delete insuranceDetail.translation.__typename;
  delete insuranceDetail.__typename;
  if (insuranceDetail?.plan?.geographicalCoverages?.__typename) delete insuranceDetail.plan.geographicalCoverages.__typename;
  return insuranceDetail;
}

export const createMotobikeAdapter = (
  insuranceDetail,
  customerInfo,
  vehicleInfo,
  recipientInsurance,
  mUid
) => {
  const externalData = { userId: mUid };
  // ngày hết hạn tính theo ngày hiệu lực, nhờ trừ đi 1 giây ( logic Save Money )
  const effectiveDate = moment(customerInfo.dateEffect, "DD/MM/YYYY").format();
  const expiryDate = moment(effectiveDate)
    .add(1, "years")
    .subtract(1, "second")
    .format();
  // benefits : {benefitCode, sumInsured}
  // premiums : số tiền thanh toán : Tổng các rate trong requiredBenefit
  let benefits = [],
    premiums = 0;
  insuranceDetail.benefits.forEach((element) => {
    if (element.requiredBenefit) {
      benefits.push({
        benefitCode: element.benefitCode,
        sumInsured: element.sumInsured,
        premiums: element.rate[0].premiumRule.premium_value + (element.rate[0].vat / 100 * element.rate[0].premiumRule.premium_value)
      });
      premiums += element.rate[0].premiumRule.premium_value + (element.rate[0].vat / 100 * element.rate[0].premiumRule.premium_value);
    }
  });
  const product = {
    tenantId: insuranceDetail.tenantId,
    branchId: insuranceDetail.branch_id,
    agentId: "",
    productCode: insuranceDetail.productCode,
    productName: insuranceDetail.translation.name,
    currency: "VND",
    discountRate: parseInt(insuranceDetail.discountRate),
    premiums,
    status: "new",
    effectiveDate,
    expiryDate,
    benefits,
    type: "motorbike_liability",
  };
  // gender hard code other
  const carOwner = {
    fullName: vehicleInfo.carOwner,
    phoneNumber: customerInfo.phone,
    email: customerInfo.email,
    city: customerInfo.city.code,
    district: customerInfo.district.code,
    ward: customerInfo.ward.code,
    address: customerInfo.address,
    dob: "1990-01-01",
    gender: "other",
    nationalId: "nationalId",
  };
  const contractOwner = JSON.parse(JSON.stringify(carOwner));
  contractOwner.externalData = JSON.stringify(externalData);
  contractOwner.fullName = customerInfo.fullName;
  // thông tin xe. Cái nào ko có hardcode theo file doc của Save Money
  const car = {
    firstRegistrationYear: 2020,
    licensePlate: vehicleInfo.licensePlates,
    left_side_image: {},
    right_side_image: {},
    usePurpose: "non_commercial",
    model: "DX1",
    seat: 2,
    brand: "HX2",
    chassisNumber: vehicleInfo.chassisNo || "00000000",
    engineNumber: vehicleInfo.engineNo || "00000000",
    carInfo: [],
    carValueForReference: 10000000,
    currency: "VND",
  };
  const recipient = {
    name: recipientInsurance.fullNameRecipient || customerInfo.fullName,
    phone: recipientInsurance.phoneRecipient || customerInfo.phone,
    address: {
      ward_code:
        recipientInsurance.wardRecipient.code || customerInfo.ward.code,
      region_code:
        recipientInsurance.cityRecipient.code || customerInfo.city.code,
      district_code:
        recipientInsurance.districtRecipient.code || customerInfo.district.code,
      street_address:
        recipientInsurance.addressRecipient || customerInfo.address,
    },
    type_delivery: "1 ",
  };
  return {
    product,
    carOwner,
    contractOwner,
    car,
    carDriver: {},
    recipient,
  };
};

export const createCarAdapter = (
  insuranceDetail,
  customerInfo,
  vehicleInfo,
  recipientInsurance,
  mUid
) => {

  const externalData = { userId: mUid };
  const effectiveDate = moment(customerInfo.dateEffect, "DD/MM/YYYY HH:mm").format();
  const expiryDate = moment(effectiveDate)
    .add(1, "years")
    .format();

  let benefits = [], premiums = 0;
  insuranceDetail.benefits.forEach((element) => {
    benefits.push({
      benefitCode: element.benefitCode,
      sumInsured: element.sumInsured,
      premiums: element.premium
    });
    premiums += element.premium;
  });

  const product = {
    tenantId: insuranceDetail.tenantId,
    branchId: insuranceDetail.branch_id,
    agentId: "",
    productCode: insuranceDetail.productCode,
    productName: insuranceDetail.translation.name,
    currency: "VND",
    discountRate: parseInt(insuranceDetail.discountRate),
    premiums,
    status: "new",
    effectiveDate,
    expiryDate,
    benefits,
    type: "car",
  }

  const carOwner = {
    fullName: vehicleInfo.carOwner,
    phoneNumber: customerInfo.phone,
    email: customerInfo.email,
    city: customerInfo.city.code,
    district: customerInfo.district.code,
    ward: customerInfo.ward.code,
    address: customerInfo.address,
    dob: "1990-01-01",
    gender: "other",
    nationalId: "nationalId",
  };

  const contractOwner = JSON.parse(JSON.stringify(carOwner));
  contractOwner.externalData = JSON.stringify(externalData);
  contractOwner.fullName = customerInfo.fullName;

  const car = {
    firstRegistrationYear: 2020,
    licensePlate: vehicleInfo.licensePlates,
    left_side_image: {},
    right_side_image: {},
    model: vehicleInfo.carModel.code === "Khác" ? "KHAC" : vehicleInfo.carModel.code,
    brand: vehicleInfo.carBrand.code === "Khác" ? "KHAC" : vehicleInfo.carBrand.code,
    chassisNumber: vehicleInfo.chassisNo || null,
    engineNumber: vehicleInfo.engineNo || null,
    seat: insuranceDetail.seat,
    usePurpose: insuranceDetail.usePurpose,
    carInfo: insuranceDetail.carInfo,
    goodsCap: insuranceDetail.goodsCap,
    typePurpose: insuranceDetail.carInfo.includes("16") ? 3 : insuranceDetail.usePurpose === "commercial" ? 2 : 1,
    carValueForReference: 10000000,
    currency: "VND",
  };

  const recipient = {
    name: recipientInsurance.fullNameRecipient || customerInfo.fullName,
    phone: recipientInsurance.phoneRecipient || customerInfo.phone,
    address: {
      ward_code:
        recipientInsurance.wardRecipient.code || customerInfo.ward.code,
      region_code:
        recipientInsurance.cityRecipient.code || customerInfo.city.code,
      district_code:
        recipientInsurance.districtRecipient.code || customerInfo.district.code,
      street_address:
        recipientInsurance.addressRecipient || customerInfo.address,
    },
    type_delivery: "1 ",
  };

  return {
    product,
    carOwner,
    contractOwner,
    car,
    carDriver: {},
    recipient,
  }
}
