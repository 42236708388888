import React from "react";
import moment from "moment";
import Util from "../../../utils/utils";
import { contractStatusAdapter } from "../../../utils/helper";
import images from "../../../utils/images";
import { ABIC_PRODUCT_CODES } from "utils/const";

const InsuranceInfo = (props) => {
  const {
    contract,
    viewCoverageBenefit,
    viewDetailBinding,
    latestEndorsementApproved,
  } = props;
  const isBindingSuccess =
    contract.bindingStatus === "Confirmed" ||
    contract.bindingStatus === "Disabled";

  const productName = (contract.productTranslations
    && contract.productTranslations.length
    && contract.productTranslations[0].name) || '';

  return (
    <div className="bg-white padding-top-16 margin-bottom-16 padding-bottom-8">
      <div className="margin-h-16 font-size-16 font-weight-bold margin-bottom-8 color-black">
        {productName}
      </div>
      <div className="row">
        <div
          className="row-left"
          style={{
            width: 40,
            height: 40,
            backgroundImage: `url(${contract.productLogo})`,
            backgroundSize: ABIC_PRODUCT_CODES.includes(contract.productCode) ? "contain" : 60,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="row-right item-bold">
          {Util.formatMoneyVND(contract.premium * (1 - (contract.discountRate || 0) / 100))}đ
        </div>
      </div>
      <div className="row">
        <div className="row-left">Mã hợp đồng</div>
        <div className="row-right">
          {contract.endorsement.map((el) => {
            if (el.status === "approved") {
              return <div key={el.code}>{el.partnerEndorsementCode}</div>;
            }
            return null;
          })}
          {contract.parnerPolicyCode ? (
            <div>{contract.parnerPolicyCode}</div>
          ) : (
            <div>{contract.quotationCode}</div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="row-left">Ngày hiệu lực</div>
        <div className="row-right">
          {moment(contract.insuredMemberDetail[0].effective_date).format(
            "DD/MM/YYYY"
          )}
        </div>
      </div>
      <div className="row">
        <div className="row-left">Ngày kết thúc</div>
        <div className="row-right">
          {latestEndorsementApproved
            ? moment(latestEndorsementApproved.IMExpiryDate).format(
              "DD/MM/YYYY"
            )
            : moment(contract.insuredMemberDetail[0].expired_date).format(
              "DD/MM/YYYY"
            )}
        </div>
      </div>
      {contract.endorsement.length > 0 ? (
        <div className="row">
          <div className="row-left">Mã thanh toán tự động</div>
          <div className="row-right">{contract.quotationCode}</div>
        </div>
      ) : null}

      <div className="row">
        <div className="row-left">Nhà cung cấp</div>
        <div className="row-right">
          {
            contract.companyTranslations.find((item) => item.langId === "vi")
              .name
          }
        </div>
      </div>
      <div className="row">
        <div className="row-left">Trạng thái</div>
        <div
          className={`row-right ${contract.status === "approved" ? "color-green" : "color-orange"
            } `}
        >
          {contractStatusAdapter(
            contract.status,
            contract.insuredMemberDetail[0].effective_date,
            latestEndorsementApproved
              ? latestEndorsementApproved.IMExpiryDate
              : contract.insuredMemberDetail[0].expired_date
          )}
        </div>
      </div>
      <div className="row">
        <div className="row-left">Quyền lợi & Thủ tục bồi thường</div>
        <div className="row-right color-blue" onClick={viewCoverageBenefit}>
          Xem chi tiết
        </div>
      </div>
      {isBindingSuccess && (
        <div className="row">
          <div className="row-left">Đã đăng ký gia hạn tự động</div>
          <div className="row-right color-blue" onClick={viewDetailBinding}>
            Xem chi tiết
          </div>
        </div>
      )}
      {contract.status === "approved" ||
        contract.status === "waiting_for_approve" ? (
        <div className="row">
          <div className="systems-new-feature">
            <div className="icon margin-right-8">
              <img src={images.icon_systems_glyph_new_feature} alt="" />
            </div>
            <div className="font-size-12">
              Vui lòng kiểm tra email & SMS để xem thông tin chi tiết hợp đồng
              thành công
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(InsuranceInfo);
