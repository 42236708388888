import React from "react";

const MotobikeInfo = (props) => {
  const { licensePlate, carOwner } = props;
  return (
    <div className="bg-white padding-top-16 margin-bottom-16">
      <div className="margin-h-16 font-size-16 font-weight-bold margin-bottom-8 color-black">
        Thông tin xe
      </div>
      <div className="row">
        <div className="row-left">Họ và tên chủ xe</div>
        <div className="row-right">{carOwner}</div>
      </div>
      <div className="row">
        <div className="row-left">Biển số xe</div>
        <div className="row-right">{licensePlate}</div>
      </div>
    </div>
  );
};

export default React.memo(MotobikeInfo);
