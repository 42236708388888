import { types } from "./customer.type";

const initState = {
  customerInfo: {
    dateEffect: "",
    address: "",
    identity: "",
    dobEffect: "",
    email: "",
    fullName: "",
    phone: "",
    city: "",
    district: "",
    ward: "",
    licensePlates: "",
    carOwner: "",
  },
};

export const customerReducer = (state = initState, action) => {
  switch (action.type) {
    case types.UPDATE_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: action.customerInfo,
      };

    default:
      return state;
  }
};
