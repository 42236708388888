import { ApiCodes } from './error-code';
import ZaloPaySDK from "../utils/zalopay";

let JWT_TOKEN = ''; 
class ApiManager {
  
  async _prepareParams(params) {
    try {
        const userInfo = ZaloPaySDK.getUserInfo();
        const { muid, maccesstoken } = userInfo;
        
        return {
          m_uid: muid,
          m_access_token: maccesstoken,
          ...params
        };
    } catch (error) {
      ZaloPaySDK.writeLog("error", "OFFLINE_STORES - _prepareParams error:", error);
      return Promise.reject({ code: ApiCodes.GET_USER_INFO_FAIL });
    }
  }
  
  // async tokenUser (url, body, method) {
  //   let headers = {
  //     'Content-Type': 'application/json',
  //   };
  //   var rawResponse = await fetch(url, {
  //     method: method,
  //     headers: headers,
  //     body: JSON.stringify(body)
  //   });
  //   const content = await rawResponse.json();
  //   ZaloPaySDK.writeLog("info", "INSURANCE - tokenUser resp:", content);
    
    
  //   content.token = '12';

  //   JWT_TOKEN = content.token;

  //   // ! Remove
  //   console.log('base-api tokenUser');
  //   console.log(JWT_TOKEN);
    
  //   return content
  // };

  // async request(endpoint, method = 'POST', data) {
  //   const headers = {
  //     'Content-Type': 'application/json',
  //     'Authorization': 'bearer ' + JWT_TOKEN,
  //   };
    
  //   const url = `${process.env.REACT_APP_ZPI_EVN}${endpoint}`;

  //   return fetch(url, {
  //     method: method, 
  //     headers: headers,
  //     body: JSON.stringify(data)
  //   })
  //     .then(resp => {
  //       return resp.json();
  //     })
  //     .then(resp => {
  //       ZaloPaySDK.writeLog("info", "INSURANCE - fetch", JSON.stringify(url), "resp: ", resp);
  //       return ({ ...resp })
  //     })
  //     .catch(error => {
  //       ZaloPaySDK.writeLog("error", "INSURANCE - fetch error: " + error);
  //         Promise.reject({ ...error, code: parseInt(error.code) })
  //       }
  //     );
  // }
}
export default ApiManager;
