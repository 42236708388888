import React from "react";

const ContractOwnerInfo = (props) => {
  const {
    fullName,
    phone,
    email,
    address,
    ward,
    district,
    city,
  } = props;
  return (
    <div className="bg-white padding-top-16 margin-bottom-16">
      <div className="margin-h-16 font-size-16 font-weight-bold margin-bottom-8 color-black">
        Thông tin chủ hợp đồng
      </div>
      <div className="row">
        <div className="row-left">Họ và tên</div>
        <div className="row-right">{fullName}</div>
      </div>
      <div className="row">
        <div className="row-left">Số điện thoại</div>
        <div className="row-right">{phone}</div>
      </div>
      <div className="row">
        <div className="row-left">Email</div>
        <div className="row-right">{email}</div>
      </div>
      <div className="row">
        <div className="row-left">Địa chỉ thường trú</div>
        <div className="row-right">
          {address}, {ward}, {district}, {city}
        </div>
      </div>
    </div>
  );
};

export default React.memo(ContractOwnerInfo);
