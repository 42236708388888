import {types} from './address.type';

const initState = {
  cities: [],
  // gettingAddress: false,
  // getAddressErrMsg: '',
};

export const addressReducer = (state = initState, action) => {
  switch (action.type) {
    case types.GET_CITIES_SUCCESS:
      return {
        ...state,
        cities: action.cities,
      };
    default:
      return state;
  }
};
