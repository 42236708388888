import React, { useEffect, useState } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";
import DetailScreen from "./screens/DetailScreen/DetailScreen";
import MainScreen from "./screens";
import CustomerInfoScreen from "./screens/CustomerInfoScreen/CustomerInfoScreen";
import ContractDetailScreen from "./screens/ContractDetailScreen/ContractDetailScreen";
import ContractReviewScreen from "./screens/ContractReviewScreen/ContractReviewScreen";
import QAScreen from "./screens/QAScreen/QAScreen";
import QADetailScreen from "./screens/QADetailScreen/QADetailScreen";
import { useDispatch } from "react-redux";
import { getTokenUser } from "./stores/appparams/appparams.action";
import { PATH } from "./utils/const";

import "./index.scss";

const history = createBrowserHistory();

function App() {
  const dispatch = useDispatch();
  const [isLogin, setIsLogin] = useState(false);
  const {search} = window.location;

  useEffect(() => {
    dispatch(getTokenUser((isSuccess) => setIsLogin(isSuccess)));
  }, [dispatch]);

  const routes = [
    {
      path: `${PATH}/detail/:code`,
      component: () => <DetailScreen />,
    },
    {
      path: `${PATH}/customer-info`,
      component: () => <CustomerInfoScreen />,
    },
    {
      path: `${PATH}/contract-detail/:quotationCode`,
      component: () => <ContractDetailScreen />,
    },
    {
      path: `${PATH}/contract-review/:quotationCode`,
      component: () => <ContractReviewScreen />,
    },
    {
      path: `${PATH}/qa`,
      component: () => <QAScreen />,
    },
    {
      path: `${PATH}/qa-detail`,
      component: () => <QADetailScreen />,
    },
    {
      path: `${PATH}/`,
      component: () => <MainScreen />,
    },
  ];

  return (
    <div>
      {isLogin ? (
        <Router basename={process.env.PUBLIC_URL} history={history}>
          <Switch>
            <Route exact path={"/"}>
              <Redirect to={{
                pathname: `${PATH}`,
                search,
              }} />
            </Route>
            {routes.map((item) => (
              <Route
                key={item.path}
                path={item.path}
                children={item.component}
              />
            ))}
          </Switch>
        </Router>
      ) : null}
    </div>
  );
}

export default App;
