import React from 'react';
import colors from '../utils/colors';
import Button from './Button';
import images from '../utils/images';

export const Checkbox = ({style, size = 24, onClick, isChecked}) => {
    let checkboxSrc = isChecked ? images.icon_checkbox_active : images.icon_checkbox_inactive;
    return (
        <Button style={ { width: size, height: size, backgroundColor: colors.transparent, ...style } } onPress={() => onClick(!isChecked)}>
            <img alt={''} src={checkboxSrc} style={{width: 24, height: 24}} />
        </Button>
    )
}