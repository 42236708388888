import React from "react";
import { ExpandView } from "../../../components/ExpandView";
import Modal from "react-modal";
import Button from "../../../components/Button";
import colors from "../../../utils/colors";
import images from "../../../utils/images";

const ModalCoverageBenefit = (props) => {
  const { isOpen, afterOpenModal, closeModal, contract } = props;
  const product = contract.productTranslations.find((i) => i.langId === "vi");
  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="address-input-title">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="address-input-title-text">Thông tin bảo hiểm</div>
        </div>
        <Button
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "transparent",
          }}
          onPress={closeModal}
        >
          <div style={{ color: colors.main, padding: "10px 16px 0px" }}>
            <img src={images.icon_close} alt="icon clear" />
          </div>
        </Button>
      </div>
      <div
        className="padding-10"
        style={{ backgroundColor: colors.background }}
      >
        <ExpandView title="Phạm vi bảo hiểm">
          <div className="padding-h-16 padding-bottom-16">
            <div
              className={"font font-size-14 line-height-18"}
              dangerouslySetInnerHTML={{
                __html: contract.advanceCash,
              }}
            />
          </div>
        </ExpandView>
        <ExpandView className={"margin-top-12"} title={"Quyền lợi bảo hiểm"}>
          <div className={"padding-h-16 padding-bottom-16"}>
            <div
              className={"font font-size-14 line-height-18"}
              dangerouslySetInnerHTML={{
                __html: product.benefitsDescription,
              }}
            />
          </div>
        </ExpandView>
        <ExpandView className={"margin-top-12"} title={"Thủ tục bồi thường"}>
          <div className={"padding-h-16 padding-bottom-16"}>
            <div
              className={"font font-size-14 line-height-18"}
              dangerouslySetInnerHTML={{
                __html: product.claimDocs,
              }}
            />
          </div>
        </ExpandView>
      </div>
    </Modal>
  );
};

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(36, 39, 43, 0.5)",
    zIndex: 3,
  },
  content: {
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: 0,
    padding: 0,
    width: "100%",
    border: "none",
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    maxHeight: '100%',
  },
};

export default React.memo(ModalCoverageBenefit);
