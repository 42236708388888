import {put, takeLatest} from 'redux-saga/effects';
import {AppApiInstance} from '../../api/app-api';
import {types} from './appparams.type';
import {store} from './../configStore';
import {ERROR_MESSAGES} from "../../utils/const";
import ZaloPaySDK from "../../utils/zalopay";

function* getTokenUser({cb}) {
  const {userInfo} = store.getState().appParams;
  try {
    const data = yield AppApiInstance.verifyUser({muid: userInfo.mUid, maccesstoken: userInfo.mAccessToken});
    if (data && data.returncode === 1) {
      yield put({type: types.GET_TOKEN_SUCCESS, token: 'token'});
      cb(true);
    } else {
      ZaloPaySDK.showDialog({message: ERROR_MESSAGES.LOGIN_FAIL, buttons: [{text: 'Đóng'}]});
      cb(false);
    }
  } catch (err) {
    ZaloPaySDK.showDialog({message: ERROR_MESSAGES.LOGIN_FAIL, buttons: [{text: 'Đóng'}]});
    cb(false);
  }
}

export function* appParamsWatcher() {
  yield takeLatest(types.GETTING_TOKEN, getTokenUser);
}