const colors = {
    main: '#006dff',
    light_main: '#e6f0ff',
    black: '#142b43',
    gray: '#8995a1',
    disable: '#8995a1',
    button_disable: '#dbdfe2',
    white: '#ffffff',
    border: '#e4e6e5',
    background: '#f6f7f8',
    transparent: 'transparent',
    red: '#ff5630',
    red_8: '#fb303408',
    text_main: '#24272B',
    text_sub: '#ACB3BA',
    text_title: '#727f8c',
}

export default colors;