import React, {useState, useEffect} from 'react';
import './input-styles.css';
import colors from "../../utils/colors";
import iconClearSVG from "./close-text.svg";

const Input = React.forwardRef(({style, autoFocus=false, value, type, placeholder, iconClear, onChangeText, onSubmitEditing, onEndEditing, focusChange, disabled, onClick}, ref) => {
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value])

    const onChange = (e) => {
        let value = e.target.value;
        // console.log('value: ', value);
        setInputValue(value);
        return onChangeText && onChangeText(value);
    }

    const onKeyPress = (e) => {
        if (e.key === 'Enter') {
            return onSubmitEditing && onSubmitEditing();
        }
    }

    const onBlur = (e) => {
        let value = e.target.value;
        focusChange && focusChange(false);
        return onEndEditing && onEndEditing(value);
    }
    const onFocus = (e) => {
        focusChange && focusChange(true);
    };

    const onClearValue = () => {
        setInputValue('');
        return onChangeText && onChangeText('');
    };
    // console.log('Input-disable: ',disabled);
    return (
        <div style={{...styles.container, ...style}}>
            <input ref={ref}
                   style={{...styles.label,}}
                   type={type}
                   autoFocus={autoFocus}
                   value={inputValue}
                   placeholder={placeholder}
                   disabled={disabled}
                   onClick={onClick}
                   onKeyPress={onKeyPress}
                   onBlur={onBlur}
                   onFocus={onFocus}
                   onChange={onChange}/>
            {inputValue && iconClear
                ? <div onClick={onClearValue} style={styles.iconClear}>
                    <img src={iconClearSVG} alt="icon clear"/>
                </div>
                : null
            } 
         </div>

    )
});

const styles = {
    container: {
        display: 'flex',
        backgroundColor: colors.transparent,
        alignItems: 'center',
        position: 'relative',
    },
    label: {
        backgroundColor: colors.transparent,
        width: '100%',
        fontWeight: "normal",
        color: colors.black,
        marginRight: 10,
        fontSize: 13,
        border: '0px solid',
    },
    iconClear: {
        backgroundColor: colors.transparent,
        width: 18,
        height: 18,
        marginRight: 10,
        marginLeft: 10
    },
}

export default Input;