import { put, takeLatest } from "redux-saga/effects";
import { AppApiInstance } from "../../api/app-api";
import { types } from "./agreementpay.type";
import { ERROR_MESSAGES } from "../../utils/const";
import { store } from "../configStore";

function* createAgreementpay({ payload }) {
  try {
    const { term, quotationCode } = payload;
    const { userInfo } = store.getState().appParams;
    const response = yield AppApiInstance.agreementPay(
      userInfo.mUid,
      quotationCode,
      "binding",
      term
    );
    if (response.data) {
      const agreementpayResponse = response.data;
      yield put({
        type: types.CREATE_AGREEMENTPAY_SUCCESS,
        agreementpayResponse,
      });
    } else {
      yield put({
        type: types.CREATE_AGREEMENTPAY_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (e) {
    yield put({
      type: types.CREATE_AGREEMENTPAY_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* unBindingAgreementpay({ payload }) {
  try {
    const { term, quotationCode } = payload;
    const { userInfo } = store.getState().appParams;
    const response = yield AppApiInstance.agreementPay(
      userInfo.mUid,
      quotationCode,
      "unbinding",
      term
    );
    if (response.data) {
      yield put({
        type: types.UNBINDING_AGREEMENTPAY_SUCCESS,
      });
    } else {
      yield put({
        type: types.UNBINDING_AGREEMENTPAY_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (e) {
    yield put({
      type: types.UNBINDING_AGREEMENTPAY_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

export function* agreementpayWatcher() {
  yield takeLatest(types.CREATING_AGREEMENTPAY, createAgreementpay);
  yield takeLatest(types.UNBINDING_AGREEMENTPAY, unBindingAgreementpay);
}
