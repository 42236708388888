import { put, takeLatest } from "redux-saga/effects";
import { AppApiInstance } from "../../api/app-api";
import { types } from "./payment.type";
import { ERROR_MESSAGES, TYPE_INSURANCE, APPID, APPID_CAR, PRODUCT_TYPE } from "../../utils/const";
import { createAccidentAdapter, createCarAdapter, createMotobikeAdapter } from "../../utils/helper";
import { store } from "./../configStore";

function* createOrder({ payload }) {
  // tạo hợp đồng, create policy
  try {
    const {
      type,
      insuranceDetail,
      customerInfo,
      vehicleInfo,
      recipient,
    } = payload;
    let responseCreateOrder,
      insurance = insuranceDetail,
      createOrderData = null;
    const { userInfo } = store.getState().appParams;
    if (type === TYPE_INSURANCE.ACCIDENT) {
      // bảo hiểm tai nạn
      if (insuranceDetail.planId) {
        // trường hợp từ trang chủ ( list bảo hiểm ) cần get detail bảo hiểm để post lên lại
        const resInsuredMemberDetail = yield AppApiInstance.getPersonalAccidentInsuranceDetail(
          { planIds: insuranceDetail.planId, langId: "vi" }
        );
        insurance = resInsuredMemberDetail.data
          .getHealthInsuranceDetail[0];
      }
      createOrderData = createAccidentAdapter(
        insurance,
        customerInfo,
        userInfo.mUid
      );
      const { data } = yield AppApiInstance.createAccidentInsurance(
        createOrderData
      );
      if (data) {
        responseCreateOrder = data.createPolicyByType;
      }
    } else if(type === TYPE_INSURANCE.MOTOBIKE) {
      // bảo hiểm xe máy
      if (insuranceDetail.productId) {
        // trường hợp từ trang chủ ( list bảo hiểm ) cần get detail bảo hiểm để post lên lại
        const motobikeInsuranceDetail = yield AppApiInstance.getMotobikeInsuranceDetail(
          { productId: insuranceDetail.productId, langId: "vi" }
        );

        insurance = motobikeInsuranceDetail.data
          .getMotobikeInsuranceDetail;
      }
      createOrderData = createMotobikeAdapter(
        insurance,
        customerInfo,
        vehicleInfo,
        recipient,
        userInfo.mUid
      );
      responseCreateOrder = yield AppApiInstance.createMotobikeInsurance(
        createOrderData
      );
      if (responseCreateOrder.data) {
        responseCreateOrder = responseCreateOrder.data
          .createPolicy;
      }
    } else {
      // bảo hiểm xe ô tô
      if (insuranceDetail.productId) {
        // trường hợp từ trang chủ ( list bảo hiểm )
        const carInsuranceDetail = yield AppApiInstance.getMotobikeInsuranceDetail(
          { productId: insuranceDetail.productId, langId: "vi" }
        );
        insurance = {
          branch_id: carInsuranceDetail.data.getMotobikeInsuranceDetail.branch_id,
          translation: carInsuranceDetail.data.getMotobikeInsuranceDetail.translation,
          ...insuranceDetail
        }
      }else {
        insurance = {
          ...insuranceDetail,
          benefits: insuranceDetail.dataFeeCar.products[0].benefits
        }
      }
      createOrderData = createCarAdapter(
        insurance,
        customerInfo,
        vehicleInfo,
        recipient,
        userInfo.mUid
      );

      responseCreateOrder = yield AppApiInstance.createCarInsurance(
        createOrderData
      );
      if (responseCreateOrder.data) {
        responseCreateOrder = responseCreateOrder.data
          .createPolicy;
      }
    }
    if (responseCreateOrder) {
      yield put({
        type: types.CREATE_ORDER_SUCCESS,
        responseCreateOrder,
        createOrderData,
        insurance,
      });
    } else {
      yield put({
        type: types.CREATE_ORDER_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (err) {
    const { vehicleInfo, type } = payload;
    const errMsg = (err.message.includes(vehicleInfo.licensePlates) && type === TYPE_INSURANCE.CAR) ? `Biển số xe ${vehicleInfo.licensePlates} đã được sử dụng` : ERROR_MESSAGES.NORMAL
    yield put({ type: types.CREATE_ORDER_FAIL, errMsg: errMsg });
  }
}

function* createPayOrder({ payload }) {
  try {
    const { contractId, productType } = payload;
    const appID = [PRODUCT_TYPE.motorbike_liability, PRODUCT_TYPE.personal_accident].includes(productType) ? APPID : APPID_CAR;
    const dataParse = yield AppApiInstance.payOrder(contractId);
    if (dataParse && dataParse.data) {
      yield put({
        type: types.CREATE_PAY_ORDER_SUCCESS,
        paymentInfo: {
          order_url: dataParse.data.createOrder.orderUrl,
          zptranstoken: dataParse.data.createOrder.zpTransToken,
          message: dataParse.data.createOrder.message,
          appid: appID,
        },
      });
    } else {
      yield put({
        type: types.CREATE_PAY_ORDER_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (err) {
    yield put({
      type: types.CREATE_PAY_ORDER_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

export function* paymentWatcher() {
  yield takeLatest(types.CREATING_ORDER, createOrder);
  yield takeLatest(types.CREATING_PAY_ORDER, createPayOrder);
}
