import React, { useState } from "react";
import { Checkbox } from "../Checkbox";
import "./ConfirmTerm.scss";
import ModalExternalWebsite from "../../components/Modal/ModalExternalWebsite";
import colors from "../../utils/colors";

export const ConfirmTerm = ({ onCheckboxClick, agreeTerm, content }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [url, setUrl] = useState("");
  const clickHandler = (e) => {
    const el = e.target;
    e.preventDefault();
    if (el.tagName === "A") {
      setUrl(el.href);
      setIsOpen(true);
    }
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <div style={{ paddingBottom: 76 }}>
      <div className="flex flex-row margin-top-12">
        <Checkbox
          style={{ marginRight: 12, marginTop: 2 }}
          onClick={onCheckboxClick}
          isChecked={agreeTerm}
        />
        <div>
          <div
            className="confirm-text"
            dangerouslySetInnerHTML={{
              __html: content,
            }}
            onClick={clickHandler}
          />
        </div>
      </div>
      <ModalExternalWebsite
        isOpen={isOpen}
        closeModal={closeModal}
        url={url}
        modalStyles={modalStyles}
        iframeStyles={iframeStyles}
        titleModal="Điều khoản"
        isPdf={true}
      />
    </div>
  );
};

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(36, 39, 43, 0.5)",
    zIndex: 3,
  },
  content: {
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: 0,
    padding: 0,
    width: "100%",
    border: "none",
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    background: colors.background,
  },
};

const iframeStyles = {
  height: 300,
  width: 1,
  minWidth: "100%",
  border: "none",
};
