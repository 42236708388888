import React from "react";
import Modal from "react-modal";
import "./modal-renew.scss";
import images from "../../utils/images";

const modalStyles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(36, 39, 43, 0.5)",
    zIndex: 3,
  },
  content: {
    top: "auto",
    left: "auto",
    right: "auto",
    bottom: 0,
    padding: 0,
    width: "100%",
    border: "none",
    borderRadius: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    background: "#ffffff",
  },
};

const ModalUnBinding = (props) => {
  const { isOpen, afterOpenModal, closeModal, unbinding } = props;

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="cancel-autodebit">
        <div className="img-cancel-autodebit">
          <img src={images.cancel_autodebit} alt="" />
        </div>
        <div className="title-cancel-autodebit">
          Huỷ thanh toán tự động cho hoá đơn này ?
        </div>
        <div className="content-cancel-autodebit">
          Nếu huỷ, bạn sẽ phải thực hiện lại đăng ký khi muốn tiếp tục sử dụng
          dịch vụ Thanh toán tự động của ZaloPay
        </div>
        <div className="action-renew">
          <button className="left-button" onClick={closeModal}>
            Bỏ qua
          </button>
          <button className="right-button" onClick={unbinding}>
            Huỷ tự động
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default React.memo(ModalUnBinding);
