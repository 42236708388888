import { types } from './appparams.type';

export const updateUserInfo = (userInfo) => {
    return {
        type: types.UPDATE_USER_INFO,
        userInfo,
    }
};

export const getTokenUser = (cb = () => {}) => {
    return {
        type: types.GETTING_TOKEN,
        cb,
    }
}