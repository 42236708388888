import { put, takeLatest, select } from "redux-saga/effects";
import { AppApiInstance } from "../../api/app-api";
import { ERROR_MESSAGES } from "../../utils/const";
import { types } from "./contract.type";
import moment from "moment";
import ZaloPaySDK from "../../utils/zalopay";
import trackingId from "../../utils/tracking";

function* getContracts({ payload }) {
  try {
    const { data } = yield AppApiInstance.getContractsInfo(payload);
    if (data && data.getPolicyDetails) {
      ZaloPaySDK.tracking(
        trackingId.CONTRACT_MANAGEMENT.LOAD_PAGE_SUCCESSFULLY
      );
      const contractsRaw = data.getPolicyDetails
        // Các HĐ Chưa thanh toán sau 23:59:59 sẽ bị ẩn khoải danh sách trong Quản lý hợp đồng
        // chưa thanh toán status = new
        .filter((i) => {
          if (
            i.status === "new" &&
            moment(i.quotationCreatedDate) <
              moment().subtract(1, "day").endOf("date")
          ) {
            return false;
          }
          return true;
        })
        // sort theo ngày tạo (quotationCreatedDate)
        .sort((a, b) => {
          return moment
            .unix(moment(b.quotationCreatedDate))
            .diff(moment.unix(moment(a.quotationCreatedDate)));
        });
      const contracts = contractsRaw.map((item) => {
        item.createdDate = moment(item.quotationCreatedDate).format("MM/YYYY");
        return item;
      });
      yield put({
        type: types.GET_CONTRACT_LIST_SUCCESS,
        contracts,
      });
    } else {
      yield put({
        type: types.GET_CONTRACT_LIST_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_CONTRACT_LIST_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

function* getContractDetail({ payload }) {
  try {
    const mUid = yield select(({ appParams }) => appParams.userInfo.mUid);
    const dataParse = yield AppApiInstance.getContractDetail({  
      quotationCode: payload.quotationCode,
      mUid: mUid || ''
    });

    if (
      dataParse.data &&
      dataParse.data.getPolicyByQuotationCode
    ) {
      const contractDetail = dataParse.data.getPolicyByQuotationCode;
      contractDetail.createdDate = moment(
        contractDetail.quotationCreatedDate
      ).format("MM/YYYY");
      contractDetail.status = payload.status ? payload.status : contractDetail.status;
      yield put({
        type: types.GET_CONTRACT_DETAIL_SUCCESS,
        contractDetail,
      });
    } else {
      yield put({
        type: types.GET_CONTRACT_DETAIL_FAIL,
        errMsg: ERROR_MESSAGES.NORMAL,
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_CONTRACT_DETAIL_FAIL,
      errMsg: ERROR_MESSAGES.NORMAL,
    });
  }
}

export function* contractWatcher() {
  yield takeLatest(types.GETTING_CONTRACT_LIST, getContracts);
  yield takeLatest(types.GETTING_CONTRACT_DETAIL, getContractDetail);
}
