import React from 'react';

export const SafeAreaView = ({ style, children, onScroll, id }) => {
    return (
        <div id={id} style={{ ...styles.container, ...style }} onScroll={onScroll}>
            {children}
        </div>
    )
}

const styles = {
    container: {
        position: 'relative',
        width: '100%',
        top: 0,
        left: 0,
        // overflow: 'auto'
    },
}