import React from "react";
import Vemaybay from './../fonts/vemaybay';
import Travel from './../fonts/travel-zlp'

const Icon = ({ style = {}, color = "#000", size = "12", font = 'vemaybay', name = '' }) => {
  const getIconPath = (font, name) => {
    let selectingFont = null;
    switch (font) {
      case 'travel':
        selectingFont = Travel;
        break;
      default:
        selectingFont = Vemaybay;
        break;
    }

    let icon = selectingFont.icons.filter(icon => icon.properties.name === name);
    if (icon && icon.length >= 1) {
      return icon[0].icon.paths;
    }
    return [];
  }
  return (
    <div style={{ position: 'relative', width: size, height: size, }}>
      {getIconPath(font, name).map((path, index) => {
        return (
          <div key={index} style={{position: 'absolute', top: 0, width: size, height: size, ...style }}>
            <svg
              width={size}
              height={size}
              style={size < 15 ? {overflow:'visible', position: 'absolute', top: 0, left: 0} : {overflow:'visible'}}
              viewBox={'0 0 1024 1024'}
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <path
                fill={color}
                d={path}
              />
            </svg>
          </div>
        )
      })}
    </div>
  )
};


export default Icon;
