import React from "react";
import Util from "../../utils/utils";
import "./InsuranceSummary.scss";

export const InsuranceSummary = ({
  logo = "",
  title = "",
  companyName = "",
  totalAllSumAssured = "",
  timeText = "",
  amount = 0,
}) => {
  return (
    <div className="insurance-summary bg margin-top-40 margin-h-16 flex">
      <div className="bg-white flex pos-relative w-100 insurance-summary-wrapper">
        <div className="logo flex-center bg-white">
          <img alt="" src={logo} className="fit-contain" />
        </div>
        <div className="title-insurance color-black">{title}</div>
        <div className="company-name color-gray">{companyName}</div>
        <div className="maximum-limit">
          <div className="title color-black">Số tiền bảo hiểm:</div>
          <div className="value color-black">{totalAllSumAssured}đ</div>
        </div>
        <div className="price-time">
          <div className="title color-gray">Giá tiền:</div>
          <div className="value color-blue">{Util.formatMoneyVND(amount)}đ</div>
        </div>
        <div className="price-time">
          <div className="title color-gray">Thời hạn:</div>
          <div className="value color-black">{timeText}</div>
        </div>
      </div>
    </div>
  );
};
