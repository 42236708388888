import React, { useEffect } from "react";
import ZaloPaySDK from "../../utils/zalopay";
import { SafeAreaView } from "../../components/SafeAreaView";
import { useLocation } from "react-router-dom";
import "./QADetail.scss";

const QADetailScreen = () => {
  useEffect(() => {
    ZaloPaySDK.setTitle("Câu hỏi thường gặp");
  }, []);
  const { QADetail } = useLocation().state;
  return (
    <SafeAreaView>
      <div className="margin-16 qa-detail">
        {QADetail.question.map((item, index) => (
          <div key={index}>
            <div style={{textAlign: "justify"}} className="question item-bold margin-top-12 margin-bottom-12 font-size-16 color-black">
              {item.question}
            </div>
            <div className="padding-12 font-size-14 bg-white border-radius-4">
              {item.answer.listStyleNone.map((i, index) => (
                <div key={index}>
                  <div style={{textAlign: "justify"}}>
                    {i.content}
                  </div>
                  <ul className={`${(i.listStyleDisc || i.listStyleDecimal) ? "padding-left-14" : "padding-left-0"} margin-top-4 margin-bottom-2`}>
                    {i.listStyleDisc &&
                      i.listStyleDisc.map((el, index) => (
                        <li key={index} className="list-style-disc">
                          {el}
                        </li>
                      ))}
                    {i.listStyleDecimal &&
                      i.listStyleDecimal.map((el, index) => (
                        <li key={index} className="list-style-decimal">
                          {el}
                        </li>
                      ))}
                    {i.listStyleCustom &&
                      i.listStyleCustom.map((el, index) => (
                        <li key={index} className="list-style-none">
                          <div dangerouslySetInnerHTML={{
                            __html: el
                          }}></div>
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </SafeAreaView>
  );
};

export default QADetailScreen;
