import { types } from "./agreementpay.type";

export const createAgreementpay = (term, quotationCode) => {
  return {
    type: types.CREATING_AGREEMENTPAY,
    payload: {
      term,
      quotationCode,
    },
  };
};

export const unBindingAgreementpayAction = (term, quotationCode) => {
  return {
    type: types.UNBINDING_AGREEMENTPAY,
    payload: {
      term,
      quotationCode,
    },
  };
};

export const clearAgreementpay = () => {
  return {
    type: types.CLEAR_AGREEMENTPAY,
  };
};
