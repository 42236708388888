import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getLocalStorage } from "helpers/localStorage";
import gql from 'graphql-tag';

const authLink = setContext((_, { headers }) => ({
  headers: {
    ...headers,
    "x-access-token": getLocalStorage("jwt") ? `Bearer ${getLocalStorage("jwt")}` : "",
  },
}));

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_ENDPOINT_GRAPHQL,
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const query = async (gqlQuery, variables = {}) => {
  try {
    const data = await client.query({
      query: gql`${gqlQuery}`,
      variables,
      fetchPolicy: 'no-cache',
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const mutate = async (mutation, variables = {}, signal) => {
  try {
    const data = await client.mutate({
      mutation: gql`${mutation}`,
      variables,
      context: {
        fetchOptions: {
          signal,
        },
      },
      fetchPolicy: 'no-cache',
    });
    return data;
  } catch (error) {
    throw error;
  }
};
