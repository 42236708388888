import React from "react";

const InsuranceInfo = (props) => {
  const {
    title,
    image,
    insuranceName,
    effectiveDate,
    expiryDate,
    companyName,
    quotationCode,
  } = props;

  return (
    <div className="bg-white padding-top-16 margin-bottom-16 padding-bottom-8">
      <div className="margin-h-16 font-size-16 font-weight-bold margin-bottom-8 color-black">
        {title}
      </div>
      <div className="row">
        <div className="row-left">
          <img
            alt=""
            src={image}
            className="fit-contain"
            style={{ minWidth: 40, maxWidth: 40, height: 40 }}
          />
        </div>
        <div className="row-right item-bold">{insuranceName}</div>
      </div>
      <div className="row">
        <div className="row-left">Mã hợp đồng</div>
        <div className="row-right item-bold">{quotationCode}</div>
      </div>
      <div className="row">
        <div className="row-left">Ngày hiệu lực</div>
        <div className="row-right item-bold">{effectiveDate}</div>
      </div>
      <div className="row">
        <div className="row-left">Ngày kết thúc</div>
        <div className="row-right item-bold">{expiryDate}</div>
      </div>
      <div className="row">
        <div className="row-left">Nhà cung cấp</div>
        <div className="row-right item-bold">{companyName}</div>
      </div>
    </div>
  );
};

export default React.memo(InsuranceInfo);
