import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { PATH, TYPE_INSURANCE, USE_PURPOSE_CAR } from "utils/const";
import images from "utils/images";
import { Checkbox } from "components/Checkbox";
import FloatInput from "components/FloatInput/FloatInput";
import Select from "./component/Select";
import Button from "components/Button";
import colors from "utils/colors";
import { useDispatch, useSelector } from "react-redux";
import { getInsuranceCarProduct } from "stores/insurance/insurance.action";
import ZaloPaySDK from "utils/zalopay";
import Util from "utils/utils";
import { billingTypeAdapter, sortAlphaBeta } from "utils/helper";
import trackingId from "../../utils/tracking";
import { getStringDuration } from "utils/helper";
import "./CarProduct.scss";

const ICON = {
    roadster: images.icon_mdi_car_convertible,
    car: images.icon_mdi_car_estate,
    truck: images.icon_mdi_truck
};

const dataAmountInsurances = [{
    code: "10",
    name: "10.000.000 VNĐ/người/vụ",
    nameWithType: "10.000.000 VNĐ/người/vụ"
}]

const CarProduct = () => {
    const [stateCar, setStateCar] = useState({
        seat: 5,
        goodsCap: 5,
        isPickupCar: false,
        usePurpose: 1,
    });
    const [stateAmountInsurances, setStateAmountInsurances] = useState({});
    const [isSearch, setIsSearch] = useState(false);

    const [isSeatCar, setIsSeatCar] = useState(false);

    const refSeat = useRef();
    const refAmountInsurances = useRef();

    const {
        gettingInsuranceCarProduct,
        insuranceCarProduct,
        getInsuranceCarProductErrMsg,
    } = useSelector((state) => state.insurance);
    const history = useHistory();

    const dispatch = useDispatch();

    const { seat, goodsCap, isPickupCar, usePurpose } = stateCar;

    const getUsePurpose = () => {
        switch (stateCar.usePurpose) {
          case 1:
            return 'non_commercial';
          case 2:
          case 3:
            return 'commercial';
          default:
            return 'non_commercial';
        }
    };

    const onChangeUsePurpose = (value) => {
        setStateCar({
          ...stateCar,
          usePurpose: value,
          seat: 5,
          goodsCap: 5,
        });
        setIsSeatCar(false);
        setIsSearch(false);
    };

    const onClickCheckbox = (value) => {
        setStateCar({
            ...stateCar,
            isPickupCar: value,
            seat: stateCar.seat
        });
        setIsSearch(false);
        if(value) {
            setIsSeatCar(stateCar.seat && (stateCar.seat > 5 || stateCar.seat < 2));
        }else {
            setIsSeatCar(stateCar.seat && (stateCar.seat > 50 || stateCar.seat < 2));
        }
    }

    const onSeatChange = (value) => {
        if(value) {
            if((isPickupCar || usePurpose === 3)){
                let isCheck = [2,3,4,5].includes(+value);
                setIsSeatCar(!isCheck);
            }else{
                setIsSeatCar(!(+value <= 50 && +value >= 2));
            }
        }else{
            setIsSeatCar(false);
        }
        setStateCar({
            ...stateCar,
            seat: value ? +value : "",
        });
        setIsSearch(false);
    }

    const onChangeGoodsCap = (value) => {
        setStateCar({
          ...stateCar,
          goodsCap: value ? parseFloat(value) : "",
        });
        setIsSearch(false);
    };

    const onChangeAmountInsurances = (value) => {
        setStateAmountInsurances(value);
        setIsSearch(false);
    }

    const searchCarInfo = {
        1: isPickupCar ? ['19'] : ['1'],
        2: isPickupCar ? ['19'] : ['1'],
        3: ['16'],
    };
    const searchCarInsurances = () => {
        const data = {
            ...stateCar,
            usePurpose: getUsePurpose(),
            goodsCap: usePurpose === 3 ? goodsCap : 0,
            carInfo: searchCarInfo[usePurpose || 1],
            isPickupCar,
            stateAmountInsurances
        };
        dispatch(getInsuranceCarProduct({data}));
        setIsSearch(true);
    }

    useEffect(() => {
        if (gettingInsuranceCarProduct) {
          ZaloPaySDK.showLoading();
        } else {
          ZaloPaySDK.hideLoading();
        }
    }, [gettingInsuranceCarProduct]);

    useEffect(() => {
        if (getInsuranceCarProductErrMsg) {
          ZaloPaySDK.showDialog({
            message: getInsuranceCarProductErrMsg,
            buttons: [{ text: "Đóng" }],
          });
        }
    }, [getInsuranceCarProductErrMsg]);

    const viewDetailInsurance = (insuranceDetail) => {
        Util.trackingBeforeRedirect(
            () => ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.SEE_POLICY_DETAIL),
            () =>
                history.push(
                    `${PATH}/detail/${insuranceDetail.productId}`,
                    { 
                        type: "car",
                        data: {
                            ...stateCar,
                            usePurpose: getUsePurpose(),
                            goodsCap: usePurpose === 3 ? goodsCap : 0,
                            carInfo: searchCarInfo[usePurpose || 1],
                            isPickupCar,
                            stateAmountInsurances,
                            id: insuranceDetail.id
                        }
                    }
                )
        );
    };

    const buyInsurance = (insuranceDetail) => {
        Util.trackingBeforeRedirect(
          () => ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.BUY),
          () =>
            history.push({
              pathname: `${PATH}/customer-info`,
              state: {
                insuranceDetail,
                type: 'car',
                logo: insuranceDetail.company.image,
                title: insuranceDetail.name,
                companyName: insuranceDetail.company.name,
                totalAllSumAssured: Util.formatMoneyVND(
                    insuranceDetail.benefits[0].sumInsured || insuranceDetail.premium
                ),
                amount:
                  (insuranceDetail.premium *
                    (100 - insuranceDetail.discountRate || 0)) /
                  100,
                timeText: getStringDuration(
                  insuranceDetail.billingType
                ).toLowerCase(),
              },
            })
        );
    };

    return (
        <>
            <div style={{marginBottom: 36}} className="card-product">
                <div className="item-card-product">
                    <h5 className="font font-size-14">Chọn gói Bảo hiểm TNDS Ôtô với:</h5>
                    <h6 className="font font-size-11">Mục đích sử dụng xe</h6>
                    <div className="row-car">
                        {Object.keys(USE_PURPOSE_CAR).map((key, index) => (
                            <div key={index} 
                                className={[
                                    'col-car',
                                    usePurpose === index + 1 ? 'active-col-car' : '',
                                ].join(' ')}
                                onClick={() => onChangeUsePurpose(index + 1)}
                            >
                                <img src={ICON[key]} alt="" />
                                <p className="font font-size-14">{USE_PURPOSE_CAR[key]}</p>
                            </div>
                        ))}
                    </div>
                    {
                        usePurpose && (
                            usePurpose !== 3 ? <>
                                <div className="checkbox-info-car-pickup">
                                    <Checkbox
                                        onClick={onClickCheckbox}
                                        isChecked={isPickupCar}
                                    />
                                    <span className="font font-size-14">Xe vừa chở người vừa chở hàng (pickup, minivan)</span>
                                </div>
                                <div className="input-info-car-seats">
                                    <div className="title font font-size-14">Số chỗ ngồi theo đăng ký (2 - {isPickupCar ? '5' : '50'} chỗ)</div>
                                    <div className="w-100">
                                        <FloatInput
                                            ref={refSeat}
                                            label={isSeatCar ? "Vượt quá giới hạn chỗ ngồi" : "Số chỗ ngồi" }
                                            type="number"
                                            iconClear={true}
                                            value={seat}
                                            onChangeText={onSeatChange}
                                            error={isSeatCar}
                                        />
                                    </div>
                                </div>
                            </> : <>
                                <div className="input-info-car-seats">
                                    <div className="title font font-size-14">Số chỗ ngồi theo đăng ký (2 - 5 chỗ)</div>
                                    <div className="w-100">
                                        <FloatInput
                                            ref={refSeat}
                                            label={isSeatCar ? "Vượt quá giới hạn chỗ ngồi" : "Số chỗ ngồi" }
                                            type="number"
                                            iconClear={true}
                                            value={seat}
                                            onChangeText={onSeatChange}
                                            error={isSeatCar}
                                        />
                                    </div>
                                </div>
                                <div className="input-info-car-seats">
                                    <div className="title font font-size-14">Tải trọng (tấn)</div>
                                    <div className="w-100">
                                        <FloatInput
                                            ref={refSeat}
                                            label={"Tải trọng (tấn)" }
                                            type="number"
                                            iconClear={true}
                                            value={goodsCap}
                                            onChangeText={onChangeGoodsCap}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }
                    <div className="select-info-car-amount-insurances">
                        <div className="title font font-size-14">Chọn Số Tiền Bảo Hiểm Tai nạn lái xe và người ngồi trên xe</div>
                        <Select
                            ref={refAmountInsurances}
                            style={{marginBottom: 16}}
                            title={'Số tiền bảo hiểm tai nạn'}
                            placeholder={'Chọn số tiền'}
                            data={dataAmountInsurances}
                            selectedValue={stateAmountInsurances}
                            disabled={!dataAmountInsurances}
                            onValueChange={onChangeAmountInsurances}
                            iconClear={true}
                            isSearch={false}
                        >
                        </Select>
                    </div>
                    <div className="button-car-search">
                        <Button
                            onPress={() => searchCarInsurances()}
                            className="search-insurance-button"
                            disabled={!seat || !goodsCap || isSeatCar}
                            style={{
                                backgroundColor: (!seat || !goodsCap || isSeatCar) ? "#dbdfe2" : "#004a9c",
                                cursor: (!seat || !goodsCap || isSeatCar) ? "no-drop" : ""
                            }}
                        >
                            Tìm kiếm
                        </Button>
                    </div>
                </div>
            </div>
            {
                (insuranceCarProduct && isSearch) && insuranceCarProduct['car'].sort((a, b) => sortAlphaBeta(a, b)).map((insuranceDetail, index) => {
                    return <div className="card-product" key={index}>
                        <div className="swiper-slide-item box-shadow">
                            <div className="header-item">
                                <div className="header-logo">
                                    <img alt="" src={insuranceDetail.company.image} />
                                </div>
                                <div className="header-name">
                                    <div className="title color-black">
                                        {insuranceDetail.name}
                                    </div>
                                    <div className="name color-gray">
                                        {insuranceDetail.company.name}
                                    </div>
                                </div>
                            </div>
                            <div className="maximum-limit">
                                <div className="title color-black">Số tiền bảo hiểm:</div>
                                <div className="value color-black">
                                    {Util.formatMoneyVND(insuranceDetail.benefits[0].sumInsured || insuranceDetail.premium)}đ
                                </div>
                            </div>
                            <div className="benefit">
                                <div className="title">Quyền lợi nổi bật</div>
                                <div className="list-benefit">
                                    {
                                        Util.htmlParse(insuranceDetail.sellingPoint)
                                            .slice(0, 3)
                                            .map((el, index) => {
                                                return (
                                                <div className="benefit-item flex" key={index}>
                                                    <div className="radio-active">
                                                    <img alt="" src={images.radio_active} />
                                                    </div>
                                                    <div className="content">{el}</div>
                                                </div>
                                                );
                                            })
                                    }
                                </div>
                                <div
                                    className="detail color-blue"
                                    onClick={() => {
                                        viewDetailInsurance(insuranceDetail);
                                    }}
                                >
                                    Xem chi tiết <img alt="" src={images.arrow_right} />
                                </div>
                            </div>
                            <div className="buy-insurance">
                                <div className="price">
                                    <span className="money color-black">
                                        {Util.formatMoneyVND(
                                            ((insuranceDetail.totalAllPremium || insuranceDetail.premium) *
                                            (100 - insuranceDetail.discountRate || 0)) /
                                            100
                                        )}
                                        đ
                                    </span>
                                    <span className="unit">
                                        {" "}
                                        /{billingTypeAdapter(insuranceDetail.billingType)}
                                    </span>
                                </div>
                                <Button
                                    className="buy-insurance-button"
                                    onPress={() => buyInsurance(insuranceDetail)}
                                    style={{ backgroundColor: colors.main }}
                                >
                                    Chọn mua
                                </Button>
                            </div>
                        </div>
                    </div>
                })
            }
        </>
    )
};

export default CarProduct;