import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import images from "../../utils/images";
import Util from "../../utils/utils";
import Button from "../../components/Button";
import colors from "../../utils/colors";
import { billingTypeAdapter, getStringDuration, sortAlphaBeta } from "../../utils/helper";
import { useHistory } from "react-router-dom";
import { TYPE_INSURANCE, PATH } from "../../utils/const";
import trackingId from "../../utils/tracking";
import ZaloPaySDK from "../../utils/zalopay";

import "./Swiper.scss";
import { CarProduct } from "components/CarProduct";

export default (props) => {
  const { insurances, type, changeTab } = props;
  const history = useHistory();
  const [controlledSwiper, setControlledSwiper] = useState(null);

  useEffect(() => {
    const index = Object.keys(insurances).findIndex((item) => item === type);
    controlledSwiper &&  controlledSwiper.slideTo(index);
  }, [controlledSwiper, insurances, type]);

  const buyInsurance = (insuranceDetail) => {
    Util.trackingBeforeRedirect(
      () => ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.BUY),
      () =>
        history.push({
          pathname: `${PATH}/customer-info`,
          state: {
            insuranceDetail,
            type,
            logo: insuranceDetail.company.image,
            title: insuranceDetail.name,
            companyName: insuranceDetail.company.name,
            totalAllSumAssured: Util.formatMoneyVND(
              insuranceDetail.totalAllSumAssured
            ),
            amount:
              (insuranceDetail.totalAllPremium *
                (100 - insuranceDetail.discountRate || 0)) /
              100,
            timeText: getStringDuration(
              insuranceDetail.billingType
            ).toLowerCase(),
          },
        })
    );
  };

  const viewDetailInsurance = (insuranceDetail) => {
    Util.trackingBeforeRedirect(
      () => ZaloPaySDK.tracking(trackingId.INSURANCE_LISTING.SEE_POLICY_DETAIL),
      () =>
        history.push(
          `${PATH}/detail/${
            type === TYPE_INSURANCE.ACCIDENT
              ? insuranceDetail.planId
              : insuranceDetail.productId
          }`,
          { type }
        )
    );
  };

  return (
    <Swiper
      spaceBetween={12}
      centeredSlides={true}
      onSlideChange={(swiper) =>
          {
            changeTab && changeTab(swiper.slides[swiper.activeIndex].dataset.type)
          }
      }
      onSwiper={setControlledSwiper}
      allowTouchMove={false}
    >
      {
        Object.keys(insurances).map((productType) => (<SwiperSlide
          key={productType}
          className="swiper-slide-homepage"
          data-type={productType}
        >
          {
            productType === "car" ? <CarProduct /> : <>
              {insurances[productType]?.sort((a, b) => sortAlphaBeta(a, b)).map((insuranceDetail, index) =>
                <div className="card-product" key={index}>
                  <div className="swiper-slide-item box-shadow">
                    <div className="header-item">
                      <div className="header-logo">
                        <img alt="" src={insuranceDetail.company.image} />
                      </div>
                      <div className="header-name">
                        <div className="title color-black">
                          {insuranceDetail.name}
                        </div>
                        <div className="name color-gray">
                          {insuranceDetail.company.name}
                        </div>
                      </div>
                    </div>
                    <div className="maximum-limit">
                      <div className="title color-black">Số tiền bảo hiểm:</div>
                      <div className="value color-black">
                        {Util.formatMoneyVND(insuranceDetail.totalAllSumAssured)}đ
                      </div>
                    </div>
                    <div className="benefit">
                      <div className="title">Quyền lợi nổi bật</div>
                      <div className="list-benefit">
                        {Util.htmlParse(insuranceDetail.sellingPoint)
                          .slice(0, 3)
                          .map((el, index) => {
                            return (
                              <div className="benefit-item flex" key={index}>
                                <div className="radio-active">
                                  <img alt="" src={images.radio_active} />
                                </div>
                                <div className="content">{el}</div>
                              </div>
                            );
                          })}
                      </div>
                      <div
                        className="detail color-blue"
                        onClick={() => {
                          viewDetailInsurance(insuranceDetail);
                        }}
                      >
                        Chi tiết quyền lợi <img alt="" src={images.arrow_right} />
                      </div>
                    </div>
                    <div className="buy-insurance">
                      <div className="price">
                        <span className="money color-black">
                          {Util.formatMoneyVND(
                            (insuranceDetail.totalAllPremium *
                              (100 - insuranceDetail.discountRate || 0)) /
                              100
                          )}
                          đ
                        </span>
                        <span className="unit">
                          {" "}
                          /{billingTypeAdapter(insuranceDetail.billingType)}
                        </span>
                      </div>
                      <Button
                        className="buy-insurance-button"
                        onPress={() => buyInsurance(insuranceDetail)}
                        style={{ backgroundColor: colors.main }}
                      >
                        Chọn mua
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          }
        </SwiperSlide>))
      }
    </Swiper>
  );
};
