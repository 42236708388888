import React, { useState } from 'react';
import images from '../../utils/images';
import './index.css';

export const ExpandView = ({title = '', expandable = true, children, className, style, onTitleClick = () => {} }) => {
    const [titleClassName, setTitleClassName] = useState('');
    const [expanded, setExpanded] = useState(!expandable);

    const onTitlePress = () => {
        if (expanded) {
            setTitleClassName('expand-dropup')
        } else {
            setTitleClassName('expand-dropdown')
        }
        setExpanded(!expanded);
        var content = document.getElementById(`expand-content-${title}`);
        if (content?.style?.maxHeight){
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = `${content.scrollHeight}px`;
        } 
        onTitleClick();
    }

    return (
        <div className={`bg-white ${className}`} style={style}>
            <button onClick={expandable ? onTitlePress : () => {}} className="padding-16 flex-space-between flex-row w-100">
                <div className="font font-size-16 line-height-20 font-weight-bold">{title}</div>
                {expandable && <img alt="" className={titleClassName} src={ images.icon_arrow_top } width={24} height={24}/>}
            </button>
            <div id={`expand-content-${title}`} className={expanded ? 'expand expand-content-active' : 'expand expand-content'} >
                {children}
            </div>
        </div>
    )
}