import React, { useEffect, useRef } from "react";
import Util from "../../utils/utils";
import "./CustomerInfoScreen.scss";
import Button from "../../components/Button";
import ContactInput from "./components/ContactInput";
import colors from "../../utils/colors";
import ZaloPaySDK from "../../utils/zalopay";
import { useDispatch, useSelector } from "react-redux";
import { SafeAreaView } from "../../components/SafeAreaView";
import { clearErrMess, createOrder } from "../../stores/payment/payment.action";
import { ERROR_MESSAGES, UIC_PRODUCT_CODES, VNI_PRODUCT_CODES } from "../../utils/const";
import { useHistory, useLocation } from "react-router-dom";
import { InsuranceSummary } from "../../components/InsuranceSummary/InsuranceSummary";
import { getCities } from "../../stores/address/address.action";
import MotobikeInput from "./components/MotobikeInput";
import MotobikeInputRecipient from "./components/MotobikeInputRecipient";
import { TYPE_INSURANCE, PATH } from "../../utils/const";
import { clearCheckPayment } from "../../stores/payment/payment.action";
import trackingId from "../../utils/tracking";
import CarInput from "./components/CarInput";
import CarInputRecipient from "./components/CarInputRecipient";
import Utils from "../../utils/utils";

const CustomerInfoScreen = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    responseCreateOrder,
    insurance,
    createOrderData,
    creatingOrder,
    createOrderErrMsg,
    hasPayment,
  } = useSelector((state) => state.payment);
  const {
    insuranceDetail,
    type,
    logo,
    title,
    companyName,
    totalAllSumAssured,
    amount,
    timeText,
  } = useLocation().state;

  const contactRef = useRef();
  const vehicleRef = useRef();
  const recipientRef = useRef();

  useEffect(() => {
    ZaloPaySDK.setTitle("Thông tin khách hàng");
    ZaloPaySDK.tracking(trackingId.CONTRACT_INFO.LOAD_PAGE_SUCCESSFULLY);
    ZaloPaySDK.tracking(trackingId.CONTRACT_INFO.USE_HOLDER_INFO);
  }, []);

  useEffect(() => {
    if (creatingOrder) {
      ZaloPaySDK.showLoading();
    } else {
      ZaloPaySDK.hideLoading();
    }
  }, [creatingOrder]);

  useEffect(() => {
    if (createOrderErrMsg) {
      const errMsg = type === TYPE_INSURANCE.CAR ? createOrderErrMsg : ERROR_MESSAGES.NORMAL
      ZaloPaySDK.showDialog({
        message: errMsg,
        buttons: [{ text: "Đóng" }],
      });
      return () => {
        dispatch(clearErrMess());
      }
    }
  }, [createOrderErrMsg]);

  useEffect(() => {
    if (responseCreateOrder && createOrderData && insurance && hasPayment) {
      dispatch(clearCheckPayment());
      history.push({
        pathname: `${PATH}/contract-review/${responseCreateOrder.quotation_code}`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [responseCreateOrder, createOrderData, hasPayment]);

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  const onNextStepPress = () => {
    ZaloPaySDK.tracking(trackingId.CONTRACT_INFO.PAYMENT);
    const contract = getCustomerInfo(contactRef);
    if (!UIC_PRODUCT_CODES.includes(insuranceDetail.productCode)) {
      delete contract.dobEffect;
      delete contract.identity;
    }
    const isValidContract = contactValid(contactRef);
    if (type === TYPE_INSURANCE.MOTOBIKE || type === TYPE_INSURANCE.CAR) {
      const vehicleInfo = getVehicleInfo(vehicleRef);
      const isValidVehicleInfo = validVehicleInfo(vehicleRef);
      const recipient = getInsuranceRecipient(recipientRef);
      const isValidRecipient = validRecipient(recipientRef);
      if (isValidContract && isValidVehicleInfo && isValidRecipient) {
        const key = type === TYPE_INSURANCE.CAR ? "_convertLicensePlatesCar" : "_convertLicensePlates";
        vehicleInfo.licensePlates = Utils[key](vehicleInfo.licensePlates);
        dispatch(
          createOrder(type, insuranceDetail, contract, vehicleInfo, recipient)
        );
      }
    } else {
      if (isValidContract) {
        dispatch(createOrder(type, insuranceDetail, contract));
      }
    }
  };

  return (
    <div>
      <SafeAreaView id="wrapper-scroll" style={styles}>
        <div style={{ paddingBottom: 10 }}>
          {insuranceDetail && (
            <div className="margin-bottom-16">
              <InsuranceSummary
                logo={logo}
                title={title}
                companyName={companyName}
                totalAllSumAssured={totalAllSumAssured}
                amount={amount}
                timeText={timeText}
              />
            </div>
          )}

          <div id="contact" className="form">
            {type === TYPE_INSURANCE.MOTOBIKE ? (
              <MotobikeInput ref={vehicleRef} productCode={insuranceDetail.productCode} />
            ) : type === TYPE_INSURANCE.CAR ? <CarInput ref={vehicleRef} productCode={insuranceDetail.productCode} /> : null}
            <ContactInput
              ref={contactRef}
              minDateEffect={insuranceDetail.effectiveDate}
              productCode={insuranceDetail.productCode}
              minAge={insuranceDetail.eligibility && insuranceDetail.eligibility.min_age}
              maxAge={insuranceDetail.eligibility && insuranceDetail.eligibility.max_age}
              insuranceDetail={insuranceDetail}
            />
            {type === TYPE_INSURANCE.MOTOBIKE ? (
              <MotobikeInputRecipient ref={recipientRef} />
            ) : type === TYPE_INSURANCE.CAR ? <CarInputRecipient ref={recipientRef} /> : null}
          </div>
        </div>
      </SafeAreaView>
      <div className="button-fixed-bottom">
        <div className="price">{Util.formatMoneyVND(amount)}đ</div>
        <Button
          className="btn-main"
          onPress={onNextStepPress}
          style={{ backgroundColor: colors.main }}
        >
          Tiếp tục
        </Button>
      </div>
    </div>
  );
};

const styles = {
  height: "calc(100vh - env(safe-area-inset-bottom) - 76px)",
  bottom: 76,
  position: "fixed",
  top: 0,
  overflowY: 'auto'
};

export default CustomerInfoScreen;

const contactValid = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const getCustomerInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getCustomerInfo();
  }
};

const validVehicleInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const getVehicleInfo = (ref) => {
  if (ref && ref.current) {
    return ref.current.getVehicleInfo();
  }
};

const validRecipient = (ref) => {
  if (ref && ref.current) {
    return ref.current.validateInfo();
  }
};

const getInsuranceRecipient = (ref) => {
  if (ref && ref.current) {
    return ref.current.getInsuranceRecipient();
  }
  return null;
};
