import { all } from 'redux-saga/effects'
import { appParamsWatcher } from './appparams/appparams.saga'
import { insuranceWatcher } from './insurance/insurance.saga'
import { paymentWatcher } from './payment/payment.saga'
import { contractWatcher } from './contract/contract.saga'
import { addressWatcher } from './address/address.saga'
import { agreementpayWatcher } from './agreementpay/agreementpay.saga'

export default function* rootSaga() {
  yield all([
    appParamsWatcher(),
    insuranceWatcher(),
    paymentWatcher(),
    contractWatcher(),
    addressWatcher(),
    agreementpayWatcher(),
  ])
}