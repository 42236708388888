import {types} from './appparams.type';

const initState = {
    userInfo: {
        mUid: '',
        mAccessToken: '',
        zalopay_userid: '',
        displayName: '',
        avatarUrl: '', 
    },
    environment: 'web',
};

export const appParamsReducer = (state = initState, action) => {
    switch (action.type) {
        case types.UPDATE_USER_INFO:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    mUid: action.userInfo.muid,
                    mAccessToken: action.userInfo.maccesstoken,
                    zalopay_userid: action.userInfo.userid,
                    displayName: action.userInfo.displayname,
                    avatarUrl: action.userInfo.avatarurl
                },
                environment: action.userInfo.environment || 'web'
            };

        case types.GET_TOKEN_SUCCESS:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    token: action.token,
                },
            };
        default:
            return state;
    }
};