import React from 'react';
import './Ticket.css';
import { Link } from 'react-router-dom';

export const Ticket = ({children, style, to, onPress}) => {
    if (children && children.length === 2) {
        return (
            <div className={'ticket'} style={{...style}} >
                <Link to={to} style={{width: '100%'}} onClick={onPress}>
                <div className={'ticket-section-info'}>
                    {children[0]}
                </div>
                <div className="ticket-divider-notch">
                    <div className="ticket-notch bg"></div>
                    <div className="ticket-divider"></div>
                    <div className="ticket-notch bg ticket-notch-right"></div>
                </div>
                </Link>
                <div className={'ticket-section-action'}>
                    {children[1]}
                </div>
            </div>
        )
    }
    return <></>
}