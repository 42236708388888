import { query, mutate } from '../graphql/apollo';
import { API_KEY } from '../utils/const';
import BaseApi from './base-api';

class AppApi extends BaseApi {

  getPersonalAccidentInsuranceAllProduct({
    langId,
    dob,
    insuranceAmount,
    type,
  }) {
    const data = {
      langId,
      dob,
      insuranceAmount,
      type,
      apiKey: API_KEY,
    };

    const outputSchema = `
    query getPersonalAccidentInsuranceAllProduct(
      $langId: String!
      $dob: String!
      $insuranceAmount: Int
      $type: Int
      $apiKey: String!){
      getPersonalAccidentInsuranceAllProduct(
        langId: $langId,
        dob: $dob,
        insuranceAmount: $insuranceAmount,
        type: $type,
        apiKey: $apiKey
      ){
        companies{
          code
          image
          name
        }
        products{
          productCode
          advanceCash
          benefits{
            id
            code
            sumAssured {
              id
            }
          }
          benefitsDescription
          billingType
          claimDocs
          company{
            id
            code
            name
            description
            image
          }
          description
          descriptionDetail
          discountRate
          effectiveDate
          insuredDeclaredValue
          name
          planId
          planDescription
          planName
          productId
          sellingPoint
          totalAllPremium
          totalAllSumAssured
          type
          zeroDepClaims
          eligibility {
            min_age
            max_age
          }
        }
      }
    }`
    return query(outputSchema, data);
  }

  getMotobikeInsuranceAllProduct({ langId, type }) {
    const data = {
      langId,
      type,
      apiKey: API_KEY,
    };
    const outputSchema = `
    query getMotobikeInsuranceAllProduct($langId: String!, $type: Int, $apiKey: String!) {
      getMotobikeInsuranceAllProduct(
        langId: $langId
        type: $type
        apiKey: $apiKey
      )  {
        products {
          productCode
          advanceCash
          benefits {
            id
            code
            sumAssured {
              id
            }
          }
          benefitsDescription
          billingType
          claimDocs
          company{
            code
            description
            id
            image
            name
          }
          description
          descriptionDetail
          discountRate
          effectiveDate
          insuredDeclaredValue
          name
          planId
          productId
          sellingPoint
          totalAllPremium
          totalAllSumAssured
          type
          zeroDepClaims
        }
      }
    }
    `
    return query(outputSchema, data)
  }

  getCarInsuranceAllProduct({
    langId,
    type,
    seat,
    goodsCap,
    carInfo,
    usePurpose,
  }) {
    const data = {
      langId, type,
      apiKey: API_KEY,
      car: {
        usePurpose,
        brand: "HX1",
        model: "HX1-DX7",
        firstRegistrationYear: 2017, seat,
        licensePlate: "72A-004.88",
        currency: "VND",
        carInfo
      }
    };
    if (goodsCap) {
      data.car.goodsCap = goodsCap
      // data.car.seat = 0
    }
    const outputSchema = `
    query getBasicCarInsuranceFeeOfAllProducts($langId: String!, $type: String!, $apiKey: String!, $car: CarInfo!) {
      getBasicCarInsuranceFeeOfAllProducts(langId: $langId, type: $type, apiKey: $apiKey, car: $car) {
        products {
          id
          productId
          productCode
          company {
            id
            name
            address
            phoneNumber
            image
          }
          discountRate
          name
          effectiveDate
          policyTerm
          description
          type
          image
          premium
          insuredDeclaredValue
          cashlessGarage
          listBenefit
          advanceCash
          zeroDepClaims
          zeroDepreciation
          addons
          includedAddons
          sellingPoint
          claimDocs
          benefitsDescription
          descriptionDetail
          termCondition
          idv
          tenantId
          benefits {
            id
            parentId
            benefitCode
            sumInsured
            premium
            currency
            name
            description
          }
        }
      }
    }
    `
    return query(outputSchema, data);
  }

  getCarInsuranceFee({
    langId,
    type,
    seat,
    goodsCap,
    carInfo,
    usePurpose,
    productIds
  }) {
    const data = {
      langId, type,
      productIds,
      car: {
        usePurpose,
        brand: "HX1",
        model: "HX1-DX7",
        firstRegistrationYear: 2017, seat,
        licensePlate: "72A-004.88",
        currency: "VND",
        carInfo
      }
    };
    if (goodsCap) {
      data.car.goodsCap = goodsCap
      // data.car.seat = 0
    }
    const outputSchema = `
    query getCarInsuranceFee($productIds: [Int]!, $langId: String!, $type: String, $car: CarInfo!) {
      getCarInsuranceFee(productIds: $productIds, langId: $langId, type: $type, car: $car) {
        premiums
        car{
          carValueForReference
        }
        products{
          productCode
          premium
          listBenefit
          benefits{
            benefitCode
            premium
            sumInsured
          }
        }
      }
    }
    `
    return query(outputSchema, data);
  }

  getContractsInfo(data) {
    const variables = {
      filter: JSON.stringify({
        externalData: { userId: data.userId },
        status: data.contractType === 'unpaid' ? 'new' : ['waiting_for_approve', 'cancelled', 'approved'].join(),
        langId: data.langId || 'vi'
      })
    };
    const outputSchema = `query getPolicyDetails($filter: JSON!) {
      getPolicyDetails(
        filter: $filter
      ) {
        id
        advanceCash
        approvedDate
        billingType
        bindingStatus
        bindingTerm
        cashlessGarage
        code
        companyLogo
        companyTranslations {
          langId
          name
        }
        quotationCode
        discountRate
        effective_date
        endorsement {
          code
          status
          IMExpiryDate
        }
        expired_date
        holderAddress
        holderCityProvince
        holderCityProvinceNameWithType
        holderCityProvinceType
        holderDistrict
        holderDistrictNameWithType
        holderDistrictType
        holderDob
        holderEmail
        holderName
        holderNationalId
        holderPhoneNumber
        holderWard
        holderWardNameWithType
        holderWardType
        id
        insuredDeclaredValue
        insuredMemberDetail {
          address
          benefit {
            benefitName
            sumAssured
          }
          brand
          certificate_link
          chassisNumber
          cityProvince
          cityProvinceNameWithType
          cityProvinceType
          district
          districtNameWithType
          districtType
          dob
          effective_date
          email
          engineNumber
          expired_date
          firstRegistrationYear
          manufactorYear
          model
          name
          nationalId
          phone
          planName
          plateNumber
          premium
          productName
          purposeOfVehicle
          refCarValue
          relationship
          releaseYear
          seatNumber
          vehicleOwnerName
          ward
          wardNameWithType
          wardType
        }
        nextBillingDate
        numberOfIM
        parnerPolicyCode
        premium
        productCode
        productLogo
        productName
        productType
        productTranslations {
          benefitsDescription
          claimDocs
          description
          descriptionDetail
          langId
          name
          sellingPoint
          termCondition
        }
        quotationCode
        quotationCreatedDate
        recipient {
          address
          cityProvince
          cityProvinceNameWithType
          cityProvinceType
          district
          districtNameWithType
          districtType
          email
          name
          phone
          typeDelivery
          ward
          wardNameWithType
          wardType
        }
        status
        zeroDepClaims
        zeroDepreciation
      }
    }`;
    return query(outputSchema, variables);
  }

  createAccidentInsurance(data) {
    const variables = {
      ...data,
      apiKey: API_KEY,
    }

    const outputSchema = `mutation createPolicyByType(
      $policyHolder: PolicyHolderInput
      $insuredMember: [InsuredMemberInput]
      $insuredMemberDetail: IsuredMemberDetail
      $selectedBenefitCodes: [String]
      $apiKey: String
    ) {
      createPolicyByType(
        policyHolder: $policyHolder
        insuredMember: $insuredMember
        insuredMemberDetail: $insuredMemberDetail
        selectedBenefitCodes: $selectedBenefitCodes
        apiKey: $apiKey
      ) {
        id
        quotation_code
      }
    }
    `;
    return mutate(outputSchema, variables);
  }

  createMotobikeInsurance(data) {
    const outputSchema = `mutation createMotobikeInsurance(
      $product: ProductInput
      $carOwner: OwnerInput
      $contractOwner: OwnerInput
      $car: CarInput
      $carDriver: CarDriverInput
      $recipient: RecipientInput
      $apiKey: String
    ) {
      createPolicy(
        product: $product
        carOwner: $carOwner
        contractOwner: $contractOwner
        car: $car
        carDriver: $carDriver
        recipient: $recipient
        apiKey: $apiKey
      ) {
        id
        quotation_code
        phone
      }
    }`;
    return mutate(outputSchema, data);
  }

  createCarInsurance(data) {
    const outputSchema = `mutation createCarInsurance(
      $product: ProductInput
      $carOwner: OwnerInput
      $contractOwner: OwnerInput
      $car: CarInput
      $carDriver: CarDriverInput
      $recipient: RecipientInput
      $apiKey: String
    ) {
      createPolicy(
        product: $product
        carOwner: $carOwner
        contractOwner: $contractOwner
        car: $car
        carDriver: $carDriver
        recipient: $recipient
        apiKey: $apiKey
      ) {
        id
        quotation_code
        phone
      }
    }`;
    return mutate(outputSchema, data);
  }

  getPersonalAccidentInsuranceDetail({ planIds, langId }) {
    const data = {
      planIds: [planIds],
      langId,
    };
    const outputSchema = `query getHealthInsuranceDetail(
      $planIds: [String]!
      $langId: String!
    ) {
      getHealthInsuranceDetail(planIds: $planIds, langId: $langId) {
        advanceCash
        benefits {
          apply_unit
          apply_type
          benefitCode
          childBenefits {
            code
            name
            sumAssured
          }
          createdAt
          currency
          data
          description
          id
          isActive
          isDelete
          level
          max_sum_assured
          min_sum_assured
          parentId
          rate {
            billing_type_id
            rate
            fixed_premium
            max_age
            min_age
          }
          rate_type
          requiredBenefit
          sumAssured
          type
          updatedAt
        }
        billingTypes {
          id
          plan_id
          billing_type
        }
        paymentTerms {
          id
          plan_id
          payment_term
        }
        branch_id
        cashlessGarage
        company {
          IsShowPreviousInsurer
          code
          id
          image
          isActive
          isDelete
          translation {
            address
            companyId
            createdAt
            description
            id
            langId
            phoneNumber
            updatedAt
          }
          type
        }
        companyId
        createdAt
        discountRate
        effectiveDate
        eligibility {
          id
          max_age
          max_insured_member
          min_age
          min_insured_member
          min_premiums
          product_id
          tenant_id
          updated_at
        }
        id
        image
        insuredDeclaredValue
        isActive
        isDelete
        listBenefit
        plan {
          created_by
          created_at
          description
          excludes {
            worldwide
          }
          geographicalCoverages {
            countries
            countries
            worldwide
          }
          id
          language
          name
          product_plan_id
          tenant_id
          updated_at
        }
        productCode
        translation {
          benefitsDescription
          claimDocs
          createdAt
          description
          descriptionDetail
          id
          langId
          name
          productId
          sellingPoint
          termCondition
          updatedAt
        }
        type
        updatedAt
        uwQuestions {
          id
          product_id
          question
          sequence
          tenant_id
          type
        }
        zeroDepClaims
        zeroDepreciation
      }
    }`;
    return query(outputSchema, data)
  }

  getMotobikeInsuranceDetail({ productId, langId }) {
    const data = {
      productId: productId,
      langId: langId,
    }
    const outputSchema = `query getMotobikeInsuranceDetail(
      $productId: String!
      $langId: String!
    ) {
      getMotobikeInsuranceDetail(productId: $productId, langId: $langId) {
        advanceCash
        benefits {
          apply_type
          apply_unit
          benefitCode
          sumInsured
          childBenefits {
            code
            name
            sumInsured
          }
          currency
          data
          description
          id
          isActive
          isDelete
          level
          max_sum_assured
          min_sum_assured
          name
          parentId
          requiredBenefit
          rate {
            id
            benefit_id
            premiumRule {
              id
              premium_value
            }
            vat
          }
        }
        branch_id
        cashlessGarage
        company {
          IsShowPreviousInsurer
          code
          id
          image
          isActive
          isDelete
          translation {
            address
            companyId
            createdAt
            description
            id
            langId
            name
            phoneNumber
            updatedAt
          }
          type
        }
        companyId
        createdAt
        discountRate
        effectiveDate
        garage {
          id
          tax_code
        }
        id
        image
        insuredDeclaredValue
        isActive
        isDelete
        listBenefit
        plan {
          created_at
          created_by
          description
          geographicalCoverages {
            continents
            countries
            worldwide
          }
          id
          language
          name
          product_plan_id
          tenant_id
          updated_at
        }
        productCode
        tenantId
        translation {
          benefitsDescription
          claimDocs
          createdAt
          description
          descriptionDetail
          id
          langId
          name
          productId
          sellingPoint
          termCondition
          updatedAt
        }
        type
        updatedAt
        zeroDepClaims
        zeroDepreciation
      }
    }`;
    return query(outputSchema, data);
  }

  async verifyUser({ muid, maccesstoken }) {
    console.log('verify user');
    const data = { muid, maccesstoken };
    const outputSchema = `query verifyUser($muid: String!, $maccesstoken: String!) {
      verifyUser(muid: $muid, maccesstoken: $maccesstoken) {
        returncode
        returnmessage
      }
    }`;

    const response = await query(outputSchema, data);
    if (response.error) {
      return {
        returncode: -1,
      }
    }

    return response.data.verifyUser;
  }


  getCities() {
    const data = {
      countryCode: "VN",
    };

    const outputSchema = `query getCities($countryCode: String!) {
      getCities( countryCode: $countryCode ) {
        code
        name
        slug
        type
        nameWithType
        path
        pathWithType
        parentCode
      }
    }`;
    return query(outputSchema, data);
  }

  getDistricts({ city }) {
    const data = {
      countryCode: "VN",
      cityCode: city,
    };
    const outputSchema = `query getDistricts(
      $countryCode: String!
      $cityCode: String!
    ) {
      getDistricts( countryCode: $countryCode, cityCode: $cityCode ) {
        code
        name
        slug
        type
        nameWithType
        path
        pathWithType
        parentCode
      }
    }`
    return query(outputSchema, data);
  }
  getWards({ city, district }) {
    const data = {
      countryCode: "VN",
      cityCode: city,
      districtCode: district,
    };
    const outputSchema = `query getWards(
      $countryCode: String!
      $cityCode: String!
      $districtCode: String!
    ) {
      getWards( countryCode: $countryCode, cityCode: $cityCode, districtCode: $districtCode) {
        code
        name
        slug
        type
        nameWithType
        path
        pathWithType
        parentCode
      }
    }`;
    return query(outputSchema, data);
  }

  payOrder(contractId) {
    const data = {
      policyId: contractId
    }
    const outputSchema = `query createOrder($policyId: String!){
      createOrder(policyId:$policyId){
      orderUrl
    zpTransToken
    message
      }
    }`
    return query(outputSchema, data);
  }

  // agreementPay(user_id, quotation_code, binding_type, binding_term) {
  //   const data = {
  //     user_id,
  //     quotation_code,
  //     binding_type,
  //     binding_term,
  //   };
  //   return this.request("/insurance/create-agreement", "POST", data);
  // }

  async agreementPay(userId, quotationCode, bindingType, bindingTerm) {
    const data = {
      userId,
      quotationCode,
      bindingType,
      bindingTerm,
    };

    const outputSchema = `query requestBinding(
      $userId: String!,
      $quotationCode: String!,
      $bindingType: BindingType!,
      $bindingTerm: Int,
      $maxAmount: Float
    ) {
      requestBinding(
        userId: $userId,
        quotationCode: $quotationCode,
        bindingType: $bindingType,
        bindingTerm: $bindingTerm,
        maxAmount: $maxAmount
      ) {
        success
        message
        bindingToken
        bindingUrl
        deepLink
        bindingQrLink
        shortLink
      }
    }`

    const res = await query(outputSchema, data);
    if (res.error) {
      throw new Error();
    }

    return res;
  }

  getContractDetail({ mUid, quotationCode, langId = 'vi' }) {
    const variables = {
      externalData: { userId: mUid, langId },
      quotationCode,
    }
    const outputSchema = `query getPolicyByQuotationCode($externalData: JSON!, $quotationCode: String!) {
      getPolicyByQuotationCode(
        externalData: $externalData,
        quotationCode: $quotationCode
      ) {
        id
        advanceCash
        approvedDate
        billingType
        bindingStatus
        bindingTerm
        cashlessGarage
        code
        companyLogo
        companyTranslations {
          langId
          name
        }
        quotationCode
        discountRate
        effective_date
        endorsement {
          code
          status
          IMExpiryDate
          partnerEndorsementCode
        }
        expired_date
        holderAddress
        holderCityProvince
        holderCityProvinceNameWithType
        holderCityProvinceType
        holderDistrict
        holderDistrictNameWithType
        holderDistrictType
        holderDob
        holderEmail
        holderName
        holderNationalId
        holderPhoneNumber
        holderWard
        holderWardNameWithType
        holderWardType
        id
        insuredDeclaredValue
        insuredMemberDetail {
          address
          benefit {
            benefitName
            sumAssured
          }
          brand
          certificate_link
          chassisNumber
          cityProvince
          cityProvinceNameWithType
          cityProvinceType
          district
          districtNameWithType
          districtType
          dob
          effective_date
          email
          engineNumber
          expired_date
          firstRegistrationYear
          manufactorYear
          model
          name
          nationalId
          phone
          planName
          plateNumber
          premium
          productName
          purposeOfVehicle
          refCarValue
          relationship
          releaseYear
          seatNumber
          vehicleOwnerName
          ward
          wardNameWithType
          wardType
        }
        nextBillingDate
        numberOfIM
        parnerPolicyCode
        premium
        productCode
        productLogo
        productName
        productType
        productTranslations {
          benefitsDescription
          claimDocs
          description
          descriptionDetail
          langId
          name
          sellingPoint
          termCondition
        }
        quotationCode
        quotationCreatedDate
        recipient {
          address
          cityProvince
          cityProvinceNameWithType
          cityProvinceType
          district
          districtNameWithType
          districtType
          email
          name
          phone
          typeDelivery
          ward
          wardNameWithType
          wardType
        }
        status
        zeroDepClaims
        zeroDepreciation
      }
    }`;
    return query(outputSchema, variables);
  }
}

const AppApiInstance = new AppApi();

export { AppApiInstance };
