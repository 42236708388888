import React from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import images from '../../utils/images';
import './Footer.scss';
import {PATH, TYPE_CONTRACT} from "../../utils/const";
import ZaloPaySDK from "../../utils/zalopay";
import trackingId from '../../utils/tracking';
import utils from '../../utils/utils';

export const Footer = withRouter(() => {

    const { param } = useSelector((state) => state.insurance);
    const history = useHistory();
    const goToScreen = (path) => {
      const url = (param && path === "home") ? `${PATH}/${path}?product_type=${param}` : `${PATH}/${path}`
      utils.trackingBeforeRedirect(
        () => history.push(url),
        () =>
          ZaloPaySDK.tracking(
            path === "home"
              ? trackingId.CONTRACT_MANAGEMENT.HOME
              : trackingId.INSURANCE_LISTING.MANAGE_CONTRACT
          )
      );
    };

    return (
        <div className='border-top-1 pos-fixed flex row bg-white footer-home-page'>
            <div onClick={()=>{goToScreen("home")}} className={`${window.location.pathname === `${PATH}/home` ? 'color-blue disabled' : 'color-gray'} padding-v-4 flex-center flex-column w-50`}>
                <img alt='' src={window.location.pathname === `${PATH}/home` ? images.icon_home_active : images.icon_home_inactive} />
                <div className='font font-size-11 line-height-12'>Trang chủ</div>
            </div>
            <div onClick={()=>{goToScreen(`management?payment=${TYPE_CONTRACT.PAID}`)}} className={`${window.location.pathname.includes(`${PATH}/management`) ? 'color-blue disabled' : 'color-gray'} padding-v-4 flex-center flex-column w-50`}>
                <img alt='' src={window.location.pathname.includes(`${PATH}/management`) ? images.icon_contract_active : images.icon_contract_inactive} />
                <div className='font font-size-11 line-height-12'>Quản lý hợp đồng</div>
            </div>
        </div>
    )
})