import React, { useEffect } from "react";
import Modal from "react-modal";
import Button from "../../components/Button";
import colors from "../../utils/colors";
import images from "../../utils/images";
import ZaloPaySDK from "../../utils/zalopay";

const ModalExternalWebsite = (props) => {
  const {
    isOpen,
    afterOpenModal,
    closeModal,
    url,
    modalStyles,
    iframeStyles,
    titleModal,
    isPdf=false
  } = props;

  useEffect(() => {
    if (isOpen) {
      ZaloPaySDK.showLoading();
      setTimeout(() => {
        document.querySelector("iframe").onload = function () {
          ZaloPaySDK.hideLoading();
        };
      }, 2000);
      // hide loading after 5s
      setTimeout(() => {
        ZaloPaySDK.hideLoading();
      }, 5000);
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={modalStyles}
      contentLabel="Example Modal"
    >
      <div className="address-input-title">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <div className="address-input-title-text">{titleModal}</div>
        </div>
        <Button
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            backgroundColor: "transparent",
          }}
          onPress={closeModal}
        >
          <div style={{ color: colors.main, padding: "10px 16px 0px" }}>
            <img src={images.icon_close} alt="icon clear" />
          </div>
        </Button>
      </div>
      <div style={{ height: "100%" }}>
        <iframe
          allowtransparency="true"
          allowFullScreen
          src={isPdf ? `https://docs.google.com/viewer?embedded=true&url=${url}` : url}
          title="External Website"
          style={iframeStyles}
        />
      </div>
    </Modal>
  );
};

export default React.memo(ModalExternalWebsite);
