import { types } from "./contract.type";

export const getContracts = (userId, contractType, langId) => {
  return {
    type: types.GETTING_CONTRACT_LIST,
    payload: { userId, contractType, langId },
  };
};

export const getContractDetail = (quotationCode, status) => {
  return {
    type: types.GETTING_CONTRACT_DETAIL,
    payload: { quotationCode, status },
  };
};
