import React from 'react'
import './text.css';

const Text = ({style, numberOfLines, children, className}) => {
    return (
        <div className={`${className} react-text`} style={{...style, ...{'--data-lines': numberOfLines}}}>
            {children}
        </div>
    )
}

export default Text;