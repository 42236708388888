export const carData = [
  {
    "brand": "Khác",
    "model": "Khác",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B40-2D",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B40-2D (20-20)",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B50 E2-17",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B50AE2",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B50E2",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B55 E3",
    "seats": "55 chỗ",
    "minSeat": "55",
    "maxSeat": "55",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B60",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B60 E2",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH B60 E3",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH K34 E2",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "AH K34 E3",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA B65E2",
    "seats": "65 chỗ",
    "minSeat": "65",
    "maxSeat": "65",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA B80AE2",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA B80E2",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K37 UNIVERSE",
    "seats": "37 chỗ",
    "minSeat": "37",
    "maxSeat": "37",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K42 UNIVERSE",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K46 E2 ST",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K46 SC E2",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K46 UNIVERSE",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K46-2F",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K46A E2 ST",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K47 UNIVERSE",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K47 UNIVERSE-26",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K47 UNIVERSE-26\/VL-K45",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K52AE2",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "CA K52E2",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "DHZ K52E2",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "EQ K52",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "FC TK",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "3-2 AUTO",
    "model": "FC TK",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "3-2 AUTO",
    "model": "FC TT",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "3-2 AUTO",
    "model": "FT-TK",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "3-2 AUTO",
    "model": "FTD.TK",
    "seats": "3.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.11",
    "maxTonnage": "3.11"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC B40 2D E3",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC B40 7.5",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC B40-2D 7.0",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC B40-2D 7.5",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC B40-2D 7.5 (20-20)",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC B40E2",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC K29 LONG",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC K29 LONG 7.5",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC K29E2",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HC-K26",
    "seats": "26 chỗ",
    "minSeat": "26",
    "maxSeat": "26",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HD",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HN TN",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HN TT FF",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HN-TN",
    "seats": "3.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.24",
    "maxTonnage": "3.24"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HN.TK.FF",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HN.TK.FF",
    "seats": "6.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.13",
    "maxTonnage": "6.13"
  },
  {
    "brand": "3-2 AUTO",
    "model": "HYUNDAI D6AV",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "3-2 AUTO",
    "model": "K43.2F HN",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "KM - TT",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "3-2 AUTO",
    "model": "KM TT",
    "seats": "14.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.77",
    "maxTonnage": "14.77"
  },
  {
    "brand": "3-2 AUTO",
    "model": "KM-TT",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "3-2 AUTO",
    "model": "KM-TT",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "3-2 AUTO",
    "model": "MITSU",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "3-2 AUTO",
    "model": "MITSU-TH",
    "seats": "3.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.42",
    "maxTonnage": "3.42"
  },
  {
    "brand": "3-2 AUTO",
    "model": "TO CT2001",
    "seats": "7 chỗ",
    "minSeat": "7",
    "maxSeat": "7",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "TO CT2002",
    "seats": "7 chỗ",
    "minSeat": "7",
    "maxSeat": "7",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "3-2 AUTO",
    "model": "UNIVERSE EXPRESS",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "UNIVERSE K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "UNIVERSE K29A",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "UNIVERSE K47 HN",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "UNIVERSE K47 W336",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "UNIVERSE K47.26",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "3-2 AUTO",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "3250TD1",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950KM1",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950KM1",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950KM1",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950TĐ1",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950TĐ2",
    "seats": "2.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.52",
    "maxTonnage": "2.52"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950TĐ2",
    "seats": "3.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.55",
    "maxTonnage": "3.55"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950TĐ2",
    "seats": "4.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.23",
    "maxTonnage": "4.23"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950TĐ2",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950TĐ2",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "4950TĐ2",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC5TD.4x4",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC7000TD1",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC7500KM1",
    "seats": "6.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.66",
    "maxTonnage": "6.66"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC7500KM1",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC7500KM1",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC8TD1",
    "seats": "4.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.06",
    "maxTonnage": "4.06"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC8TD1",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC8TD1",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "AC8TD1",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "FC TK",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "FTD.TK",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "HN TT FF",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "HN.TK.FF",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "ANTHAI CONECO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "BALLOONCA",
    "model": "Legend",
    "seats": "2.7-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "Legend",
    "seats": "3.2-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "MDX",
    "seats": "3.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "MDX",
    "seats": "3.7L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "MDX",
    "seats": "SH-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "RDX",
    "seats": "SH-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "ZDX",
    "seats": "SH-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "DB9",
    "seats": "VOLANTE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "A1",
    "seats": "1.4-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "A1",
    "seats": "2.0T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "A1",
    "seats": "2.0T-S-LINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "BALLOONCA",
    "model": "A1",
    "seats": "SPORTBACK-S-LINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHENGSHIDA",
    "model": "A3",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHENGSIDA",
    "model": "A3",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A3",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A4",
    "seats": "1.8-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A4",
    "seats": "1.8T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A4",
    "seats": "2.0-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A4",
    "seats": "2.0T-QUATTRO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A5",
    "seats": "2.0-T-QUATTRO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A5",
    "seats": "2.0T-S-LINE-QUATTRO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A5",
    "seats": "S-LINE-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A5",
    "seats": "SPORTBACK-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHUFENG",
    "model": "A6",
    "seats": "1.8-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A6",
    "seats": "2.0-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A6",
    "seats": "2.0T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A6",
    "seats": "3.0T-QUATTRO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A6",
    "seats": "3.2-FSI-QUATTRO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A6",
    "seats": "S-LINE-2.0T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A7",
    "seats": "3.0-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A8",
    "seats": "L-3.0-QUATTRO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A8",
    "seats": "L-4.2-QUATTRO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A8",
    "seats": "L-V6-3.0-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "A8",
    "seats": "L-V8-4.0L-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "Q2",
    "seats": "1.4-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "Q3",
    "seats": "2.0-QUATTRO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "Q5",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "Q5",
    "seats": "3.0-TDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "COMTRANCO",
    "model": "Q7",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CONECO",
    "model": "Q7",
    "seats": "3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CONECO",
    "model": "Q7",
    "seats": "3.0-TDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CONECO",
    "model": "Q7",
    "seats": "3.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CONECO",
    "model": "Q7",
    "seats": "4.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CONECO",
    "model": "Q7",
    "seats": "S-LINE-3.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CONECO",
    "model": "Q8",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CONECO",
    "model": "TT",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CONECO",
    "model": "TT",
    "seats": "2.0-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "Chery",
    "model": "TT",
    "seats": "S-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "Chery",
    "model": "TT",
    "seats": "S-2.0-TFSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "Chery",
    "model": "BJ40L",
    "seats": "Plus",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIDUONG",
    "model": "Beijing X7",
    "seats": "1.5 Elite",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIDUONG",
    "model": "Beijing X7",
    "seats": "1.5 Premium",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIDUONG",
    "model": "Beijing X7",
    "seats": "1.5MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIDUONG",
    "model": "Changhe Q7",
    "seats": "1.5 L CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIDUONG",
    "model": "X55",
    "seats": "1.4L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIDUONG",
    "model": "1.25A",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "DAIDUONG",
    "model": "1.25A\/TK",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "DAIDUONG",
    "model": "2.2A",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "DAMCO",
    "model": "3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "DAMCO",
    "model": "3.5A",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DAMCO",
    "model": "3A",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "DAMCO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "DAMCO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "DAMCO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "DAMCO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DAMCO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "DAMCO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "DAMCO",
    "model": "1 Series",
    "seats": "116I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "1 Series",
    "seats": "118I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "2 Series",
    "seats": "218I-GRAN-TOURER",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "318I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "318I-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "320I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "320I-GT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "320I-IEDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "323I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "325I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "325I-CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "328I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "328I-GT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "330CI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "330I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "330I-M-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "330I-SPORT-LINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMCO",
    "model": "3 Series",
    "seats": "335I-CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "4 Series",
    "seats": "420I-CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "4 Series",
    "seats": "420I-GRAN-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "4 Series",
    "seats": "428I-CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "4 Series",
    "seats": "428I-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "4 Series",
    "seats": "428I-GRAN-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "5 Series",
    "seats": "520I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "5 Series",
    "seats": "523I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "5 Series",
    "seats": "525I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "5 Series",
    "seats": "528I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "5 Series",
    "seats": "528I-GT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "5 Series",
    "seats": "530I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAMSAN",
    "model": "5 Series",
    "seats": "530I-LUXURY-LINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "5 Series",
    "seats": "535I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "5 Series",
    "seats": "535I-GT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "5 Series",
    "seats": "550I-GT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "6 Series",
    "seats": "640I-GRAN-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "7 Series",
    "seats": "730D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "7 Series",
    "seats": "730LI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "7 Series",
    "seats": "740LI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "7 Series",
    "seats": "750LI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "7 Series",
    "seats": "M760LI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "M3",
    "seats": "CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "M6",
    "seats": "5.0-V10",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "X1",
    "seats": "SDRIVE18I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "X1",
    "seats": "XDRIVE28I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "X2",
    "seats": "SDRIVE18I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "X2",
    "seats": "SDRIVE20I-M-SPORT-X",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "X2",
    "seats": "XDRIVE20I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "X3",
    "seats": "2.0D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAYUN",
    "model": "X3",
    "seats": "3.0I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X3",
    "seats": "XDRIVE20D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X3",
    "seats": "XDRIVE20I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X3",
    "seats": "XDRIVE28I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X3",
    "seats": "XDRIVE30I-M-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X3",
    "seats": "XDRIVE30I-XLINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X4",
    "seats": "XDRIVE20I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X4",
    "seats": "XDRIVE28I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X5",
    "seats": "3.0I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X5",
    "seats": "3.0SI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DETECH",
    "model": "X5",
    "seats": "4.8I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X5",
    "seats": "XDRIVE30D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X5",
    "seats": "XDRIVE35I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X5",
    "seats": "XDRIVE40D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X5",
    "seats": "XDRIVE40I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X5",
    "seats": "XDRIVE40I-XLINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X6",
    "seats": "XDRIVE30D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X6",
    "seats": "XDRIVE35I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X6",
    "seats": "XDRIVE50I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X7",
    "seats": "XDRIVE40I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "X7",
    "seats": "XDRIVE40I-M-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "Z4",
    "seats": "3.0SI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "i8",
    "seats": "1.5L-HYBRID",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "F0",
    "seats": "1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "Bentayga",
    "seats": "W12",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGVANG",
    "model": "Continental",
    "seats": "GT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "EICHER",
    "model": "Continental",
    "seats": "GT-SPEED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "EICHER",
    "model": "Continental",
    "seats": "GTC-W12",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "EICHER",
    "model": "Continental",
    "seats": "GTC-W12-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "EICHER",
    "model": "Flying Spur",
    "seats": "6.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "EICHER",
    "model": "Flying Spur",
    "seats": "CONTINENTAL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "EICHER",
    "model": "Flying Spur",
    "seats": "SPEED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "EICHER",
    "model": "Flying Spur",
    "seats": "V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "EICHER",
    "model": "Flying Spur",
    "seats": "V8-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FEELING",
    "model": "Flying Spur",
    "seats": "W12",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FEELING",
    "model": "Mulsanne",
    "seats": "6.75-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FEELING",
    "model": "Mulsanne",
    "seats": "SPEED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FEELING",
    "model": "HP\/YC6K1034",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "FEELING",
    "model": "KM\/YC6K1034",
    "seats": "14.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.52",
    "maxTonnage": "14.52"
  },
  {
    "brand": "FEELING",
    "model": "KM\/YC6K1034",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "FEELING",
    "model": "KM\/YC6K1034",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "FEELING",
    "model": "PMS\/YC6K",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "FEELING",
    "model": "QTH\/YC6K1034",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "FEELING",
    "model": "SQR1400D6T8",
    "seats": "17.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.7",
    "maxTonnage": "17.7"
  },
  {
    "brand": "FUSIN",
    "model": "SQR1400D6T8",
    "seats": "18.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.2",
    "maxTonnage": "18.2"
  },
  {
    "brand": "FUSIN",
    "model": "SQR1400D6T8",
    "seats": "18.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.86",
    "maxTonnage": "18.86"
  },
  {
    "brand": "FUSIN",
    "model": "SQR1400D6T8",
    "seats": "19.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.24",
    "maxTonnage": "19.24"
  },
  {
    "brand": "FUSIN",
    "model": "SQR1400D6T8",
    "seats": "19.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.98",
    "maxTonnage": "19.98"
  },
  {
    "brand": "FUSIN",
    "model": "SQR1400D6T8",
    "seats": "20.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20",
    "maxTonnage": "20"
  },
  {
    "brand": "FUSIN",
    "model": "SQR1400D6T8",
    "seats": "21.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.35",
    "maxTonnage": "21.35"
  },
  {
    "brand": "FUSIN",
    "model": "SQR1400D6T8",
    "seats": "21.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.7",
    "maxTonnage": "21.7"
  },
  {
    "brand": "FUSIN",
    "model": "SQR1400D6T8",
    "seats": "21.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.8",
    "maxTonnage": "21.8"
  },
  {
    "brand": "FUSIN",
    "model": "SQR4250D6ZT4",
    "seats": "11.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.55",
    "maxTonnage": "11.55"
  },
  {
    "brand": "FUSIN",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "FUSIN",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "FUSIN",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "FUSIN",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FUSIN",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "FUSIN",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "FUSIN",
    "model": "VL\/YC6K1034",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "FUSIN",
    "model": "VN\/YC6K1034",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "FUSIN",
    "model": "VN\/YC6K1034",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "FUSIN",
    "model": "YC6K1034",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "FUSIN",
    "model": "ĐL\/YC6K1034",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "FUSIN",
    "model": "ĐL\/YC6K1034",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "FUSIN",
    "model": "ĐN\/YC6K 1034",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "FUSIN",
    "model": "CKGT\/P11C",
    "seats": "15.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.36",
    "maxTonnage": "15.36"
  },
  {
    "brand": "FUSIN",
    "model": "CP\/P11C",
    "seats": "16.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.65",
    "maxTonnage": "16.65"
  },
  {
    "brand": "FUSIN",
    "model": "DONGLINH\/J08E",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "FUSIN",
    "model": "DONGLINH\/P11C",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "FUSIN",
    "model": "DONGLINH\/P11C",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "FUSIN",
    "model": "DT\/YC6MK300",
    "seats": "17.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.6",
    "maxTonnage": "17.6"
  },
  {
    "brand": "FUSIN",
    "model": "DT\/YC6MK300",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "FUSIN",
    "model": "HIEPPHAT\/YC6MK300",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "FUSIN",
    "model": "HN1160C16C8M4J\/VIMID",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "FUSIN",
    "model": "HN1310B38D5M4J\/VIMID",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "FUSIN",
    "model": "HN1310G6D3HJ\/TMBG",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "FUSIN",
    "model": "HN1310P29D6M3\/SAOVIET",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "FUSIN",
    "model": "HN1310P29D6M3\/TTCM",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "FUSIN",
    "model": "HN1310X34D6M5J\/VIMID",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "FUSIN",
    "model": "HN1310Z29D4M3J\/TTCM",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "FUSIN",
    "model": "HN1311P29D6M3J\/DONGLINH",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "FUSIN",
    "model": "HN1311P29D6M5\/DNC",
    "seats": "17.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.85",
    "maxTonnage": "17.85"
  },
  {
    "brand": "FUSIN",
    "model": "HN1311P29D6M5\/HT",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "FUSIN",
    "model": "HN1311P29D6M5\/VL",
    "seats": "15.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.77",
    "maxTonnage": "15.77"
  },
  {
    "brand": "FUSIN",
    "model": "HN1360P35C7M3J\/DONGLINH",
    "seats": "22.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22",
    "maxTonnage": "22"
  },
  {
    "brand": "Geely",
    "model": "HN3230P36D1MJ-YMC",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "Geely",
    "model": "HN3250GJ-YMC",
    "seats": "7.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.32",
    "maxTonnage": "7.32"
  },
  {
    "brand": "Geely",
    "model": "HN3250GJ-YMC",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "Goldhofer",
    "model": "HN3250GJ-YMC",
    "seats": "8.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.76",
    "maxTonnage": "8.76"
  },
  {
    "brand": "HAINCO",
    "model": "HN3250GJ-YMC",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "HAINCO",
    "model": "HN3250GJ\/MK",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HAINCO",
    "model": "HN3250P34C6M-YMC",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HAINCO",
    "model": "HN3250P35D4MJ-YMC",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HAINCO",
    "model": "HN3250P36D1MJ-YMC",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HAINCO",
    "model": "HP\/YC6MK300",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "HAINCO",
    "model": "HT\/P11C",
    "seats": "12.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.31",
    "maxTonnage": "12.31"
  },
  {
    "brand": "HAINCO",
    "model": "HT\/WP10",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "HAINCO",
    "model": "PHUMINH\/J08E",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "HAINCO",
    "model": "PHUMINH\/J08E",
    "seats": "9.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.49",
    "maxTonnage": "9.49"
  },
  {
    "brand": "HEIBAO",
    "model": "SAOBAC\/WP12",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HEIBAO",
    "model": "SAOBAC\/WP12",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HEIBAO",
    "model": "SAOVIET-TMB15",
    "seats": "17.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.82",
    "maxTonnage": "17.82"
  },
  {
    "brand": "HEIBAO",
    "model": "SAOVIET\/WP10",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "HEIBAO",
    "model": "SAOVIET\/WP10",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "HEIBAO",
    "model": "SAOVIET\/WP10",
    "seats": "18.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.2",
    "maxTonnage": "18.2"
  },
  {
    "brand": "HEIBAO",
    "model": "SAOVIET\/WP10",
    "seats": "22.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22",
    "maxTonnage": "22"
  },
  {
    "brand": "HEIBAO",
    "model": "TN\/P11C",
    "seats": "17.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.75",
    "maxTonnage": "17.75"
  },
  {
    "brand": "HEIBAO",
    "model": "TTCM\/C300",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "HEIBAO",
    "model": "TTCM\/C300",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HEIBAO",
    "model": "TTCM\/C300",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "HEIBAO",
    "model": "TTCM\/C300",
    "seats": "8.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.86",
    "maxTonnage": "8.86"
  },
  {
    "brand": "HONGYAN",
    "model": "TTCM\/J08E",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HONGYAN",
    "model": "TTCM\/J08E",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/L375",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/L375",
    "seats": "11.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.55",
    "maxTonnage": "11.55"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/L375",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/L375",
    "seats": "16.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.6",
    "maxTonnage": "16.6"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/L375",
    "seats": "16.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.7",
    "maxTonnage": "16.7"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/L375",
    "seats": "17.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.1",
    "maxTonnage": "17.1"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/P11C",
    "seats": "17.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.75",
    "maxTonnage": "17.75"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/P11C",
    "seats": "17.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.85",
    "maxTonnage": "17.85"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/P11C",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/P11C",
    "seats": "19.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19",
    "maxTonnage": "19"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/WP10",
    "seats": "18.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.8",
    "maxTonnage": "18.8"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/WP12",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "HONOR",
    "model": "TTCM\/WP12",
    "seats": "15.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.78",
    "maxTonnage": "15.78"
  },
  {
    "brand": "HONOR",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "HONOR",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "HONOR",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "HONOR",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HONOR",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "HONOR",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "HONOR",
    "model": "VIETDANG\/WP12",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "HONOR",
    "model": "VIETDANG\/WP12",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "HONOR",
    "model": "VL\/WP10",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HONOR",
    "model": "VL\/WP12",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "HONOR",
    "model": "VL\/WP12",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HONOR",
    "model": "WP10.380E32",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "HONOR",
    "model": "Ô tô xi téc (chở nhựa đường nóng lỏng)",
    "seats": "17.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.78",
    "maxTonnage": "17.78"
  },
  {
    "brand": "HONOR",
    "model": "SC1011G",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "HONOR",
    "model": "SC1016C",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N",
    "seats": "0.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.71",
    "maxTonnage": "0.71"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N",
    "seats": "0.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.76",
    "maxTonnage": "0.76"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N4",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N4",
    "seats": "0.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.86",
    "maxTonnage": "0.86"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DB4N4",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DBN",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DBN",
    "seats": "0.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.64",
    "maxTonnage": "0.64"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DBN",
    "seats": "0.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.74",
    "maxTonnage": "0.74"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DBN",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "HUAZHONG",
    "model": "SC1022DBN",
    "seats": "0.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.82",
    "maxTonnage": "0.82"
  },
  {
    "brand": "Hummer",
    "model": "SC1023D",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "Hummer",
    "model": "SC1023DR",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "HÀO QUANG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "HÀO QUANG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "HÀO QUANG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "HÀO QUANG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HÀO QUANG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "HÀO QUANG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "HÀO QUANG",
    "model": "ALP\/YC6A240",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HÀO QUANG",
    "model": "ALP\/YC6L310",
    "seats": "17.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.05",
    "maxTonnage": "17.05"
  },
  {
    "brand": "HÀO QUANG",
    "model": "ALP\/YC6L310",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "HÀO QUANG",
    "model": "CK327\/CL375",
    "seats": "9.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.75",
    "maxTonnage": "9.75"
  },
  {
    "brand": "HÀO QUANG",
    "model": "CK327\/L315",
    "seats": "17.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.75",
    "maxTonnage": "17.75"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/L315",
    "seats": "18.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.6",
    "maxTonnage": "18.6"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6A240",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6A240",
    "seats": "9.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.63",
    "maxTonnage": "9.63"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6A240",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6J220",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6J220",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6J220",
    "seats": "9.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.89",
    "maxTonnage": "9.89"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6L310",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6M375",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6MK375",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6MK400",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CK327\/YC6MK400",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CKGT\/YC6L310",
    "seats": "15.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.62",
    "maxTonnage": "15.62"
  },
  {
    "brand": "HỒNG HÀ",
    "model": "CONECO\/YC6L310",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "IFA",
    "model": "DATECHCO\/YC6A240",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "IFA",
    "model": "GMC-MPB01",
    "seats": "12.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.19",
    "maxTonnage": "12.19"
  },
  {
    "brand": "IFA",
    "model": "GMC-YC6M375",
    "seats": "16.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.75",
    "maxTonnage": "16.75"
  },
  {
    "brand": "IFA",
    "model": "GMC-YC6M375-33\/MPB",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "IFA",
    "model": "HUH\/YC6L310",
    "seats": "17.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.35",
    "maxTonnage": "17.35"
  },
  {
    "brand": "IFA",
    "model": "HUH\/YC6L310",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "IFA",
    "model": "KIENMINH\/YC6L310",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "IFA",
    "model": "KIENMINH\/YC6L310",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "IFA",
    "model": "KIENMINH\/YC6L310",
    "seats": "17.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.85",
    "maxTonnage": "17.85"
  },
  {
    "brand": "IFA",
    "model": "LZ1250M5DBT\/HIEPPHAT",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "IFA",
    "model": "LZ1250PDT\/ALP",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "IFA",
    "model": "LZ1250PDT\/ALP",
    "seats": "12.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.35",
    "maxTonnage": "12.35"
  },
  {
    "brand": "IFA",
    "model": "LZ1250PDT\/ALP",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "IFA",
    "model": "LZ1250PDT\/ALP",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "IFA",
    "model": "LZ1250PDT\/ALP",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "IFA",
    "model": "LZ1250PDT\/AS",
    "seats": "11.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.85",
    "maxTonnage": "11.85"
  },
  {
    "brand": "IFA",
    "model": "LZ1250PDT\/CDMB",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "JIEFANG",
    "model": "LZ1250PDT\/CDMB",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "JIEFANG",
    "model": "LZ1250PDT\/CDMB",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "JIEFANG",
    "model": "LZ1250PDT\/CK327",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "JIEFANG",
    "model": "LZ1250PDT\/CK327",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "JIEFANG",
    "model": "LZ1250PDT\/CKGT",
    "seats": "12.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.73",
    "maxTonnage": "12.73"
  },
  {
    "brand": "JIEFANG",
    "model": "LZ1250PDT\/HUH",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "JIEFANG",
    "model": "LZ1250PDT\/LEXIM",
    "seats": "9.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.75",
    "maxTonnage": "9.75"
  },
  {
    "brand": "JIEFANG",
    "model": "LZ1250PDT\/TTCM",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1250PDT\/TTCM",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1310H7FBT\/ALP",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1310H7FBT\/HIEPPHAT",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/ALP",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/ALP",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/CK327",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/HUH",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/HUH",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/IMAE",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/SGCD",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/TTCM",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/TTCM",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/TTCM",
    "seats": "16.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.3",
    "maxTonnage": "16.3"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/TTCM",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1311QELT\/TUP",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1340PELT\/ALP",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1340PELT\/ALP",
    "seats": "19.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.39",
    "maxTonnage": "19.39"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1340PELT\/ALP",
    "seats": "22.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.45",
    "maxTonnage": "22.45"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1340PELT\/HUH",
    "seats": "19.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.98",
    "maxTonnage": "19.98"
  },
  {
    "brand": "JINBEI",
    "model": "LZ1340PELT\/TTCM",
    "seats": "22.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.45",
    "maxTonnage": "22.45"
  },
  {
    "brand": "JINBEI",
    "model": "LZ3250PDGT\/ALP",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "JINBEI",
    "model": "LZ4251M3\/ALP",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "JINBEI",
    "model": "LZ4251M7DA\/ALP",
    "seats": "14.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.08",
    "maxTonnage": "14.08"
  },
  {
    "brand": "JINBEI",
    "model": "LZ4251QDCA\/ALP",
    "seats": "13.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.86",
    "maxTonnage": "13.86"
  },
  {
    "brand": "JINBEI",
    "model": "LZ4252M7DA\/ALP",
    "seats": "14.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.06",
    "maxTonnage": "14.06"
  },
  {
    "brand": "JINBEI",
    "model": "LZ4255H7DB",
    "seats": "Tải",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JINBEI",
    "model": "SAOBAC\/L315",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "JINBEI",
    "model": "SAOBAC\/YC6M375",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "JINBEI",
    "model": "SGCD\/YC6L310",
    "seats": "17.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.65",
    "maxTonnage": "17.65"
  },
  {
    "brand": "JINBEI",
    "model": "SGCD\/YC6L310",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JINBEI",
    "model": "TB\/YC6L310",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "JINBEI",
    "model": "TTCM\/YC6A240",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "JINBEI",
    "model": "TTCM\/YC6A240",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "JINBEI",
    "model": "TTCM\/YC6A240",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "JINBEI",
    "model": "TTCM\/YC6L310",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "JINBEI",
    "model": "TTCM\/YC6L310",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6L340",
    "seats": "19.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.39",
    "maxTonnage": "19.39"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6L340",
    "seats": "22.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.45",
    "maxTonnage": "22.45"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6M375",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6M375",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6M375",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6M375",
    "seats": "17.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.1",
    "maxTonnage": "17.1"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6MK375",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6MK400",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "JIULONG",
    "model": "TTCM\/YC6MK400",
    "seats": "16.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.6",
    "maxTonnage": "16.6"
  },
  {
    "brand": "JIULONG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "JIULONG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "JIULONG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "JIULONG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "JIULONG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "JIULONG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "JIULONG",
    "model": "Chengshida",
    "seats": "So mi Ro mooc",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JIULONG",
    "model": "CHENGSIDA",
    "seats": "So mi ro mooc",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JIULONG",
    "model": "2D1",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "JIULONG",
    "model": "2D2",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "JMC",
    "model": "3 Tấn",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JMC",
    "model": "3A",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JMC",
    "model": "3B",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JMC",
    "model": "3D1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JMC",
    "model": "3D2",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JMC",
    "model": "3D3A",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JMC",
    "model": "3T 4x4",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JMC",
    "model": "3TDA 4X4",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JMC",
    "model": "4 x 4",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JPM",
    "model": "4 x 4\/4100",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JPM",
    "model": "CT0.98D1",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JPM",
    "model": "CT0.98D1\/4x4",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JPM",
    "model": "CT0.98D2",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JPM",
    "model": "CT0.98D3",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JPM",
    "model": "CT0.98T1",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JPM",
    "model": "CT0.98T1\/KM",
    "seats": "0.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.83",
    "maxTonnage": "0.83"
  },
  {
    "brand": "JPM",
    "model": "CT0.98T2",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JPM",
    "model": "CT0.98T3",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JPM",
    "model": "CT0.98T3\/KM",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "JPM",
    "model": "CT0.98T3\/KM1",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "JPM",
    "model": "CT0.98TL1",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JPM",
    "model": "CT0.98TL1\/KM",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "JPM",
    "model": "CT1.25D1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JPM",
    "model": "CT1.25D2",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JPM",
    "model": "CT1.25T1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JPM",
    "model": "CT1.25T1\/KM",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "JPM",
    "model": "CT1.2TD1",
    "seats": "1.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.21",
    "maxTonnage": "1.21"
  },
  {
    "brand": "JPM",
    "model": "CT1.2TD1\/CTD",
    "seats": "0.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.96",
    "maxTonnage": "0.96"
  },
  {
    "brand": "KESDA",
    "model": "CT1.2TD1\/TTI",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KESDA",
    "model": "CT1.4TL1",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "KESDA",
    "model": "CT1.50D1",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "KESDA",
    "model": "CT1.50TD1",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "KESDA",
    "model": "CT1.50TL1",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "KESDA",
    "model": "CT1.50TL1\/KM",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "KESDA",
    "model": "CT1.85T1",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "KESDA",
    "model": "CT13TM1",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "KESDA",
    "model": "CT15TM1",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "KESDA",
    "model": "CT2.00D1\/4x4",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "KESDA",
    "model": "CT2.00D2\/4x4",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "KESDA",
    "model": "CT2.00T1",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "KRAZ",
    "model": "CT2.00T1\/KM",
    "seats": "1.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.71",
    "maxTonnage": "1.71"
  },
  {
    "brand": "KRAZ",
    "model": "CT2.00TD1\/4x4",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "KRAZ",
    "model": "CT2.50TL1",
    "seats": "2.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.22",
    "maxTonnage": "2.22"
  },
  {
    "brand": "KRAZ",
    "model": "CT2.50TL1",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "KRAZ",
    "model": "CT2.50TL1\/KM",
    "seats": "2.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.26",
    "maxTonnage": "2.26"
  },
  {
    "brand": "KRAZ",
    "model": "CT2.50TL1\/MB",
    "seats": "2.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.27",
    "maxTonnage": "2.27"
  },
  {
    "brand": "King Long",
    "model": "CT2.5T1",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "LIFAN",
    "model": "CT2.5T1\/KM",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "LIFAN",
    "model": "CT2.5TD1",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "LIFAN",
    "model": "CT2D3",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "LIFAN",
    "model": "CT2D4",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "LIFAN",
    "model": "CT3.25D1",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "LIFAN",
    "model": "CT3.25D1\/4x4",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "LIFAN",
    "model": "CT3.25D2",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "LIFAN",
    "model": "CT3.25D2\/4x4",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "LIFAN",
    "model": "CT3.45D1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "LIFAN",
    "model": "CT3.45D1\/4x4",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "LIFAN",
    "model": "CT3.45T1",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "Liebherr",
    "model": "CT3.45T1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "Lifan",
    "model": "CT3.45T1\/KM",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "MG",
    "model": "CT3.45T1\/VL",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48D1",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48D1\/4x4",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48TD1",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48TD1\/4x4",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48TD1\/4x4",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48TL1\/4x4",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48TL1\/4x4",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48TL2\/4x4",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.48TL2\/4x4",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.50T1",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.50T1\/KM",
    "seats": "3.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.17",
    "maxTonnage": "3.17"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.5TD1",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "MITABUS",
    "model": "CT3.95TD1",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT3.98TD1",
    "seats": "3.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.98",
    "maxTonnage": "3.98"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT3.9TD1\/4x4",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT3.9TD1\/4x4",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.00D1",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.00D1\/4x4",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.00D2",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.00D2\/4x4",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.25D2\/4x4",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.50D1",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.50D2",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.50D2\/4x4",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.50D3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.6TD1",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.95D1",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.95D1\/4x4",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.95T1",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "NGT HK29LB-D",
    "model": "CT4.95T1",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "Proton",
    "model": "CT4.95T1",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "QINGQI",
    "model": "CT4.95T1",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "QINGQI",
    "model": "CT4.95T1\/DUCNGUYEN",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "QINGQI",
    "model": "CT4.95T1\/DUCNGUYEN",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "QINGQI",
    "model": "CT4.95T1\/KM",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "QINGQI",
    "model": "CT4.95T1\/KM1",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "QINGQI",
    "model": "CT4.95T1\/TUP",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "QINGQI",
    "model": "CT4.95T1\/VL",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "QINGQI",
    "model": "CT5.00D1",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "QINGQI",
    "model": "CT5.00D1\/4x4",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "QINGQI",
    "model": "CT5.50TD1",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "QINGQI",
    "model": "CT5.50TD1\/4x4",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "QINGQI",
    "model": "CT6.00TL1\/4x4",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "QINGQI",
    "model": "CT6.00TL1\/4x4",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "QINGQI",
    "model": "CT6.20D1",
    "seats": "5.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.88",
    "maxTonnage": "5.88"
  },
  {
    "brand": "QINJI",
    "model": "CT6.20D1",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "QINJI",
    "model": "CT6.20D1\/4X4",
    "seats": "5.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.88",
    "maxTonnage": "5.88"
  },
  {
    "brand": "QINJI",
    "model": "CT6.20D1\/4x4",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "QINJI",
    "model": "CT6.20D2\/4x4",
    "seats": "6.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.02",
    "maxTonnage": "6.02"
  },
  {
    "brand": "QINJI",
    "model": "CT6.20D2\/4x4",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "QINJI",
    "model": "CT6.20D3\/4x4",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "QINJI",
    "model": "CT6.25TL1",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "QINJI",
    "model": "CT6.25TL1\/KM",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.50TL1",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.50TL1",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.50TL1",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.50TL2",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.50TL3\/KM",
    "seats": "5.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.32",
    "maxTonnage": "5.32"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.50TL3\/KM",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.5TL1\/4x4",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.5TL1\/4x4",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.5TL1\/4x4",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.5TL1\/4x4",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT6.70TL1\/KM",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT7.00D1",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT7.20TL1\/KM",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT750T1",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT750T1\/KM",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT750TL2",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "QUANG KHOA",
    "model": "CT750TL2\/KM",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "RAM",
    "model": "CT750TM1",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "ROWOR",
    "model": "CT7D1",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "ROWOR",
    "model": "CT7TM1",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "ROWOR",
    "model": "CT7TM2",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "ROWOR",
    "model": "CT8.00TL1\/4x4",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ROWOR",
    "model": "CT8.00TL1\/4x4",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "ROWOR",
    "model": "CT8D1",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "ROWOR",
    "model": "CT8D1.4x4",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "ROWOR",
    "model": "CT8D2",
    "seats": "7.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.89",
    "maxTonnage": "7.89"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CT8TM1.4x4",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CT990D1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CT9TM1",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "SAIGONBUS",
    "model": "TĐ3\/4x2",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "SAIGONBUS",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "SAIGONBUS",
    "model": "VT\/C300 20",
    "seats": "16.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.65",
    "maxTonnage": "16.65"
  },
  {
    "brand": "SAIGONBUS",
    "model": "VT\/C300 20",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "SAIGONBUS",
    "model": "VT\/YC6A240",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "SAIGONBUS",
    "model": "VT\/YC6A240",
    "seats": "9.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.17",
    "maxTonnage": "9.17"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CK327-PB",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CK327\/WD615",
    "seats": "12.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.67",
    "maxTonnage": "12.67"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CK327\/WD615",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CK327\/WD615",
    "seats": "16.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.45",
    "maxTonnage": "16.45"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CK327\/WD615",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CK327\/WD615",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "SAIGONBUS",
    "model": "CKGT\/WD615",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "SATRANCO",
    "model": "CKGT\/WD615",
    "seats": "13.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.02",
    "maxTonnage": "13.02"
  },
  {
    "brand": "SATRANCO",
    "model": "CKGT\/WD615",
    "seats": "13.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.92",
    "maxTonnage": "13.92"
  },
  {
    "brand": "SATRANCO",
    "model": "CKGT\/WD615",
    "seats": "14.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.27",
    "maxTonnage": "14.27"
  },
  {
    "brand": "SATRANCO",
    "model": "CKGT\/WD615",
    "seats": "14.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.37",
    "maxTonnage": "14.37"
  },
  {
    "brand": "SATRANCO",
    "model": "CKGT\/WD615",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "SATRANCO",
    "model": "CKGT\/WD615",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "SATRANCO",
    "model": "CKGT\/WD615",
    "seats": "17.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.21",
    "maxTonnage": "17.21"
  },
  {
    "brand": "SATRANCO",
    "model": "CKGT\/WD615",
    "seats": "17.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.65",
    "maxTonnage": "17.65"
  },
  {
    "brand": "SHENYE",
    "model": "CL.331HP",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "SHENYE",
    "model": "CL.366HP",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "SHENYE",
    "model": "CL\/MC07",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "SHENYE",
    "model": "CL\/MC11",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "SHENYE",
    "model": "CL\/WD615",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "SHENYE",
    "model": "CL\/YC6J220",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "SHENYE",
    "model": "CONECO\/WD615",
    "seats": "14.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.43",
    "maxTonnage": "14.43"
  },
  {
    "brand": "SHENYE",
    "model": "CONECO\/YC4E160",
    "seats": "3.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.78",
    "maxTonnage": "3.78"
  },
  {
    "brand": "SHENYE",
    "model": "CONECO\/YC4E160",
    "seats": "4.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.96",
    "maxTonnage": "4.96"
  },
  {
    "brand": "SHENYE",
    "model": "CONECO\/YC4E160",
    "seats": "6.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.88",
    "maxTonnage": "6.88"
  },
  {
    "brand": "SHENYE",
    "model": "CONECO\/YC4E160",
    "seats": "6.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.92",
    "maxTonnage": "6.92"
  },
  {
    "brand": "SHENYE",
    "model": "CONECO\/YC4E160",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "SHENYE",
    "model": "CONECO\/YC4E160",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "SINOTRUK",
    "model": "CONECO\/YC4E160",
    "seats": "8.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.14",
    "maxTonnage": "8.14"
  },
  {
    "brand": "SINOTRUK",
    "model": "CONECO\/YC4E160",
    "seats": "8.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.51",
    "maxTonnage": "8.51"
  },
  {
    "brand": "SINOTRUK",
    "model": "CONECO\/YC4E160",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "SINOTRUK",
    "model": "CONECO\/YC4E160",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "SINOTRUK",
    "model": "CONECO\/YC4E160",
    "seats": "9.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.15",
    "maxTonnage": "9.15"
  },
  {
    "brand": "SINOTRUK",
    "model": "CONECO\/YN38PE",
    "seats": "4.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.21",
    "maxTonnage": "4.21"
  },
  {
    "brand": "SINOTRUK",
    "model": "CONECO\/YN38PE",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "SINOTRUK",
    "model": "CONECO\/YN38PE",
    "seats": "4.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.97",
    "maxTonnage": "4.97"
  },
  {
    "brand": "SITOM",
    "model": "CONECO\/YN38PE",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "SITOM",
    "model": "CONECO\/YN38PE",
    "seats": "5.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.48",
    "maxTonnage": "5.48"
  },
  {
    "brand": "SITOM",
    "model": "CONECO\/YN38PE",
    "seats": "5.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.59",
    "maxTonnage": "5.59"
  },
  {
    "brand": "SITOM",
    "model": "CONECO\/YN38PE",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "SITOM",
    "model": "CONECO\/YN38PE",
    "seats": "6.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.12",
    "maxTonnage": "6.12"
  },
  {
    "brand": "SITOM",
    "model": "CONECO\/YN38PE",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "SITOM",
    "model": "FVM34T\/TTCM",
    "seats": "13.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.66",
    "maxTonnage": "13.66"
  },
  {
    "brand": "SITOM",
    "model": "FVM34W\/AV",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "SITOM",
    "model": "FVM34W\/TT",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "SITOM",
    "model": "FVR34S\/QTH",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "SITOM",
    "model": "GMC-YC6M375-33\/MPB",
    "seats": "11.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.56",
    "maxTonnage": "11.56"
  },
  {
    "brand": "SONGHONG",
    "model": "HH\/WD615",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "SONGHONG",
    "model": "HH\/WD615",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "SONGHONG",
    "model": "HH\/WD615",
    "seats": "15.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.9",
    "maxTonnage": "15.9"
  },
  {
    "brand": "SONGHONG",
    "model": "HH\/WD615",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "SONGHONG",
    "model": "HH\/WD615",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "SONGHONG",
    "model": "HOWO ZZ1317N4667V",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "SONGHONG",
    "model": "HOWO ZZ1317N4667V",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "SONGHONG",
    "model": "HOWO ZZ1387N30B1V",
    "seats": "16.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.96",
    "maxTonnage": "16.96"
  },
  {
    "brand": "SONGHONG",
    "model": "HOWO ZZ3357M3241",
    "seats": "9.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.45",
    "maxTonnage": "9.45"
  },
  {
    "brand": "SONGHONG",
    "model": "HOWO ZZ5317CLXN4667W",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "SONGHONG",
    "model": "HUANGHE ZZ3164H4015A",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "SONGHONG",
    "model": "HUAZHONG-KM",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "SONGHONG",
    "model": "HUH\/W615",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "SONGHONG",
    "model": "HZ-YC6J210",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "SONGHONG",
    "model": "HZ\/MC07",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "SONGHONG",
    "model": "HZ\/WD615",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "SONGHONG",
    "model": "HZ\/WD615",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "SONGHONG",
    "model": "HZ\/WD615",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "SONGHONG",
    "model": "HZ\/WD615",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "HZ\/WD615",
    "seats": "15.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.37",
    "maxTonnage": "15.37"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "HZ\/WD615",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "HZ\/WD615",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "HZ\/WD615",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "HZ\/WD615",
    "seats": "16.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.35",
    "maxTonnage": "16.35"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "KM\/WD615",
    "seats": "12.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.55",
    "maxTonnage": "12.55"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "NLR55E CAB",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "NPR85K CAB",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "NQR75M\/TINPHAT",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "QKR55H\/LEE",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "SAOBAC\/WD615",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "SONGHUAJIANG",
    "model": "SAOBAC\/WD615",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "Saturn",
    "model": "SGCD\/WD615",
    "seats": "14.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.43",
    "maxTonnage": "14.43"
  },
  {
    "brand": "Scion",
    "model": "ST290132D-V",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "TANDA",
    "model": "ST336172D-V",
    "seats": "17.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.05",
    "maxTonnage": "17.05"
  },
  {
    "brand": "TANDA",
    "model": "TG\/WD615",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST10590D",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST105C",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST105C",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST105C",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST105C",
    "seats": "8.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.36",
    "maxTonnage": "8.36"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST105C",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST11895D",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST290135D",
    "seats": "13.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.23",
    "maxTonnage": "13.23"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336172D",
    "seats": "16.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.95",
    "maxTonnage": "16.95"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336180T",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336180T",
    "seats": "15.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.9",
    "maxTonnage": "15.9"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336180T",
    "seats": "17.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.7",
    "maxTonnage": "17.7"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336180T",
    "seats": "17.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.91",
    "maxTonnage": "17.91"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336220T",
    "seats": "17.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.22",
    "maxTonnage": "17.22"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336220T",
    "seats": "17.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.7",
    "maxTonnage": "17.7"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336220T",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336220T",
    "seats": "19.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.24",
    "maxTonnage": "19.24"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336220T",
    "seats": "21.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.45",
    "maxTonnage": "21.45"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST336220T",
    "seats": "21.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.9",
    "maxTonnage": "21.9"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST371100D",
    "seats": "10.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.22",
    "maxTonnage": "10.22"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST371110D",
    "seats": "10.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.9",
    "maxTonnage": "10.9"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST371120D",
    "seats": "11.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.86",
    "maxTonnage": "11.86"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST371120D",
    "seats": "12.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.23",
    "maxTonnage": "12.23"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST371145D",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST371145D",
    "seats": "14.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.68",
    "maxTonnage": "14.68"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST371160D",
    "seats": "15.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.75",
    "maxTonnage": "15.75"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST371160D",
    "seats": "15.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.86",
    "maxTonnage": "15.86"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST7535T",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST75CL",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST75CL",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST75CL",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST75CL",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST8160MB",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST8160MB",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST8160MB",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST8160MB",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "TANDA",
    "model": "TMT\/ST8160MB",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/ST8160MB",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/ST8160MB",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/ST8160TK",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/ST8160TL",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/ST8165D",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/ST8565D",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/ST96C",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/ST96C",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/WD615",
    "seats": "14.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.36",
    "maxTonnage": "14.36"
  },
  {
    "brand": "THANHCONG",
    "model": "TMT\/WD615",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "THANHCONG",
    "model": "TNT\/WD615",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/D12",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/D12",
    "seats": "14.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.21",
    "maxTonnage": "14.21"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/D12",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/MC07",
    "seats": "22.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.3",
    "maxTonnage": "22.3"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "11.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.95",
    "maxTonnage": "11.95"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "14.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.67",
    "maxTonnage": "14.67"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "15.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.24",
    "maxTonnage": "15.24"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "15.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.27",
    "maxTonnage": "15.27"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "15.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.38",
    "maxTonnage": "15.38"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "THANHCONG",
    "model": "TTCM\/WD615",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "TRACOMECO",
    "model": "TTCM\/WD615",
    "seats": "15.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.74",
    "maxTonnage": "15.74"
  },
  {
    "brand": "TRACOMECO",
    "model": "TTCM\/WD615",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "TRACOMECO",
    "model": "TTCM\/WD615",
    "seats": "15.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.9",
    "maxTonnage": "15.9"
  },
  {
    "brand": "TRACOMECO",
    "model": "TTCM\/WD615",
    "seats": "15.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.95",
    "maxTonnage": "15.95"
  },
  {
    "brand": "TRACOMECO",
    "model": "TTCM\/WD615",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "TRACOMECO",
    "model": "TTCM\/WD615",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "TRACOMECO",
    "model": "TTCM\/WD615",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "TRUNG QUOC",
    "model": "TTCM\/WD615",
    "seats": "16.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.3",
    "maxTonnage": "16.3"
  },
  {
    "brand": "TRUNG QUOC",
    "model": "TTCM\/WD615",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "TRUNG QUOC",
    "model": "TTCM\/WD615",
    "seats": "16.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.55",
    "maxTonnage": "16.55"
  },
  {
    "brand": "TRUNG QUOC",
    "model": "TTCM\/WD615",
    "seats": "16.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.75",
    "maxTonnage": "16.75"
  },
  {
    "brand": "TRUNG QUOC",
    "model": "TTCM\/WD615",
    "seats": "16.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.8",
    "maxTonnage": "16.8"
  },
  {
    "brand": "TRUONG HAI",
    "model": "TTCM\/WD615",
    "seats": "16.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.9",
    "maxTonnage": "16.9"
  },
  {
    "brand": "TRUONG HAI",
    "model": "TTCM\/WD615",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "TRUONG HAI",
    "model": "TTCM\/WD615",
    "seats": "17.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.3",
    "maxTonnage": "17.3"
  },
  {
    "brand": "TRUONG HAI",
    "model": "TTCM\/WD615",
    "seats": "17.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.65",
    "maxTonnage": "17.65"
  },
  {
    "brand": "TRUONG HAI",
    "model": "TTCM\/WD615",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "TRUONG HAI",
    "model": "TTCM\/YC4E160",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "TRUONG SON",
    "model": "TTCM\/YC6A260",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "TRUONG SON",
    "model": "TTCM\/YC6A260",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "TRUONG SON",
    "model": "TTCM\/YC6J220",
    "seats": "9.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.35",
    "maxTonnage": "9.35"
  },
  {
    "brand": "TRUONG SON",
    "model": "TTCM\/YC6J220",
    "seats": "9.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.9",
    "maxTonnage": "9.9"
  },
  {
    "brand": "TRUONG SON",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "TRUONG SON",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "TRUONG SON",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "TRUONG SON",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "TRUONG SON",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "TRUONG SON",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "TRUONG SON",
    "model": "VIMID\/WD615",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "Tadano",
    "model": "VIMID\/WD615",
    "seats": "13.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.18",
    "maxTonnage": "13.18"
  },
  {
    "brand": "Tadano",
    "model": "VIMID\/WD615",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "Tadano",
    "model": "VIMID\/WD615",
    "seats": "17.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.6",
    "maxTonnage": "17.6"
  },
  {
    "brand": "Tadano",
    "model": "VL-XMTC",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "Tadano",
    "model": "VL\/WD615",
    "seats": "11.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.22",
    "maxTonnage": "11.22"
  },
  {
    "brand": "Tesla",
    "model": "VL\/WD615",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "Tesla",
    "model": "VL\/WD615",
    "seats": "14.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.52",
    "maxTonnage": "14.52"
  },
  {
    "brand": "Tobe",
    "model": "VL\/WD615",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "UD TRUCK",
    "model": "VL\/WD615",
    "seats": "14.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.76",
    "maxTonnage": "14.76"
  },
  {
    "brand": "VIETTRUNG",
    "model": "VL\/WD615",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "VIETTRUNG",
    "model": "VL\/WD615",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "VIETTRUNG",
    "model": "VT\/WD615",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "VIETTRUNG",
    "model": "VT\/WD615",
    "seats": "15.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.88",
    "maxTonnage": "15.88"
  },
  {
    "brand": "VIETTRUNG",
    "model": "VT\/WD615",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "VIETTRUNG",
    "model": "VT\/YC6J220",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "VIETTRUNG",
    "model": "YMC-375PS8x4",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1201G60C5W\/CL",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1201H60C5W\/CL",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1201H60C5W\/HZ",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1251M6041W\/CL",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1254K56C6C1\/TG",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1254K56C6C1\/TTCM",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1254K56C6C1\/TTCM",
    "seats": "5.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.57",
    "maxTonnage": "5.57"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1254K56C6C1\/TTCM",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1254K56C6C1\/VT",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1254K56C6C1\/VT",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1254K56C6C1\/VT",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1257M4341W\/TTCM",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1257M5847C\/VT",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1313N5061C1\/CKGT",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1314K46G6C1\/TTCM",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667C1\/VT",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667N1\/CKGT",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667N1\/CKGT",
    "seats": "17.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.21",
    "maxTonnage": "17.21"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667N1\/TT",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667N1\/VIMID",
    "seats": "17.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.6",
    "maxTonnage": "17.6"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667W\/GMC",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667W\/HZ",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667W\/VL",
    "seats": "14.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.52",
    "maxTonnage": "14.52"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667W\/VL",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1317N4667W\/YMC",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1387N30B1V\/HZ",
    "seats": "17.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.05",
    "maxTonnage": "17.05"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1387N30B1W\/TTCM",
    "seats": "19.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.2",
    "maxTonnage": "19.2"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ1387N30B1W\/TTCM",
    "seats": "22.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.3",
    "maxTonnage": "22.3"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3164H4015A\/HZ",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3257N3447A1\/DT",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3257N3447D-V",
    "seats": "10.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.04",
    "maxTonnage": "10.04"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3257N3447E1-HD",
    "seats": "11.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.75",
    "maxTonnage": "11.75"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3257N3447E1-V",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/DNVN",
    "seats": "16.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.05",
    "maxTonnage": "16.05"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/DNVN",
    "seats": "16.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.55",
    "maxTonnage": "16.55"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/DNVN",
    "seats": "16.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.75",
    "maxTonnage": "16.75"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/DNVN",
    "seats": "17.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.1",
    "maxTonnage": "17.1"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/DT",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/TTCM",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/TTCM",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/TTCM",
    "seats": "16.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.55",
    "maxTonnage": "16.55"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/TTCM",
    "seats": "16.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.75",
    "maxTonnage": "16.75"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/TTCM",
    "seats": "16.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.9",
    "maxTonnage": "16.9"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/VT",
    "seats": "16.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.33",
    "maxTonnage": "16.33"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3067W\/VT",
    "seats": "16.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.9",
    "maxTonnage": "16.9"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3267D-U",
    "seats": "15.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.77",
    "maxTonnage": "15.77"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3267D1",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3267E1-U",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ3317N3267E1-V",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257N3247N1B\/ALP",
    "seats": "14.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.42",
    "maxTonnage": "14.42"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257N3247N1B\/CKGT",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257N3247N1B\/CKGT",
    "seats": "14.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.27",
    "maxTonnage": "14.27"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257N3247N1B\/DNVN",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257N3247N1B\/VIMID",
    "seats": "13.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.18",
    "maxTonnage": "13.18"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257S3241W\/ALP",
    "seats": "13.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.47",
    "maxTonnage": "13.47"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257V3247N1B\/CKGT",
    "seats": "12.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.81",
    "maxTonnage": "12.81"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257V3247N1B\/CKGT",
    "seats": "13.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.24",
    "maxTonnage": "13.24"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257V3247N1B\/VIMID",
    "seats": "12.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.87",
    "maxTonnage": "12.87"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257V3247N1B\/VIMID",
    "seats": "13.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.34",
    "maxTonnage": "13.34"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ4257V3247Q1B\/TTCM",
    "seats": "13.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.07",
    "maxTonnage": "13.07"
  },
  {
    "brand": "VIETTRUNG",
    "model": "ZZ5164XXYG5616C1\/TTCM",
    "seats": "8.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.85",
    "maxTonnage": "8.85"
  },
  {
    "brand": "VIETTRUNG",
    "model": "34A",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "35",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "45",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "45-03",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "50A",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "50B",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "50C",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "50D",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VIETTRUNG",
    "model": "CNC129S",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "VIETTRUNG",
    "model": "CNC950TD",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "VIETTRUNG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "VINATRUCK",
    "model": "HYUNDAI UNITED",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINATRUCK",
    "model": "STARBUS",
    "seats": "49 chỗ",
    "minSeat": "49",
    "maxSeat": "49",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINATRUCK",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINATRUCK",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINATRUCK",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINATRUCK",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINATRUCK",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINATRUCK",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINATRUCK",
    "model": "14008D",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "VINATRUCK",
    "model": "14008T-MB",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "VINATRUCK",
    "model": "2210D",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "VINATRUCK",
    "model": "2810D2A",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "VINATRUCK",
    "model": "2810D2A-TL",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "VINATRUCK",
    "model": "2810DA",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "VINATRUCK",
    "model": "2810DG",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "Việt Nam",
    "model": "2810TG",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "Việt Nam",
    "model": "2815D",
    "seats": "1.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.31",
    "maxTonnage": "1.31"
  },
  {
    "brand": "WANSHAN",
    "model": "2815D1",
    "seats": "1.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.31",
    "maxTonnage": "1.31"
  },
  {
    "brand": "WANSHAN",
    "model": "3810DA",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "WANSHAN",
    "model": "3810DA1",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "WANSHAN",
    "model": "3810DA2",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "WANSHAN",
    "model": "3810T",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "WANSHAN",
    "model": "3810T1",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "WANSHAN",
    "model": "3812DA",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "WANSHAN",
    "model": "3812DA1",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "YINGTIAN",
    "model": "3812DA2",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "YINGTIAN",
    "model": "3812T",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "YINGTIAN",
    "model": "3812T-MB",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "YINGTIAN",
    "model": "4020D",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "YINGTIAN",
    "model": "4020DA",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "YINGTIAN",
    "model": "4020DA1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "YINGTIAN",
    "model": "4025D1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "YINGTIAN",
    "model": "4025D2",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025D2A",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025D2A-TC",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025D2B",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DA",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DA1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DA2",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG2",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG3",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG3A",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG3B",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG3B-1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG3B-TC",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG3B-TC1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025DG3C",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ĐÔNG PHONG",
    "model": "4025QT",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "4025QT1",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "4025QT3",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "4025QT4",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "4025QT5",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "4025QT6",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "4025QT7",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "4025QT8",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "4025QT9",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ĐẠI NAM",
    "model": "5220D2A",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5220D2A-1",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5220D2A-2",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5830D",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5830D2",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5830D3",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5830DA",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5830DGA",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5840D",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5840D1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5840D2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5840DG",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ĐẠI VIỆT",
    "model": "5840DG1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "5840DGA",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "5840DGA1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "5840DQ",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "5840DQ1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "7525T",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "7525T-MB",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "CUU_LONG",
    "model": "7540D",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "7540D2A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "7540D2A1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "7540DA1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550D2A",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550D2B",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550DGA",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550DGA-1",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550DGA1",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550DQ",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550DQ1",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550DQ2",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550QT2",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550QT3",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550QT4",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "CUU_LONG",
    "model": "7550QT5",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "CUU_LONG",
    "model": "9640D2A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "9640DA",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "9650D2A",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "CUU_LONG",
    "model": "9650DA",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "9650T",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "CUU_LONG",
    "model": "9650T-MB",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "9650T2",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "CUU_LONG",
    "model": "9650T2-MB",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "9670D2A",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "9670D2A-TT",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "9970DA",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DF10570T-MB",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DF10570T-MB1",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "CUU_LONG",
    "model": "DF11880T-MB",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DF11880T-MB1",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "DF9670T-MB",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "DF9670T-MB1",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 1.6T3A",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 1.8T2A",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 10307D",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 2.95T3",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 2.95T3",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 3.2T2",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 3.45T1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 3.45T1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 3810D",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 4215T1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 4215T1-MB",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 7027T2",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 7027T2",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 7027T3",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 7050T",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 7050T-MB",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 7050T-SX",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 7050T\/LK",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9670D-N",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9670D-N",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9670D-N1",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9670D-N1",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9670D-T750",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9670D-T860",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9670D-T860",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9670D1-T900",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9960T",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA 9960T1",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA1",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA1.5T1",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA1.6T3",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA1.6T5",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA1.7T",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA1.8T",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA1.8T2",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA1.8T4",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA12080D",
    "seats": "7.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.86",
    "maxTonnage": "7.86"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA12080D-HD",
    "seats": "7.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.86",
    "maxTonnage": "7.86"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA2.6T1",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA2.70T5",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA2.75T3",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA2.75T3A",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA2.8T",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA2.90T4",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA2.95T2",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA2.95T2A",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3.2T1",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3.2T3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3.2T3-LK",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3.45T",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3.45T2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3.45T2-LK",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3.50T",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3810T-MB",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3810T1",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA3810T1-MB",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA4215T",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA4215T-MB",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA4815T",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA4815T-MB",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA6027T",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA6027T-MB",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA6027T1-MB",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA7027T",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA7027T1",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA7032D",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA7050T-MB1",
    "seats": "2.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.91",
    "maxTonnage": "2.91"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA7050T-MB1",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9670D-N1",
    "seats": "4.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.03",
    "maxTonnage": "4.03"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9670DA-1",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9670DA-2",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9670DA-3",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9670DA-4",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9950D-T700",
    "seats": "3.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.72",
    "maxTonnage": "3.72"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9950D-T700",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9970T",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9970T",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9970T-MB",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9970T1",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9970T1",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9970T2-MB",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9970T3",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9970T3-MB",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9975T-MB",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "CUU_LONG",
    "model": "DFA9975T-MB",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208D",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208D-1",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208D-2",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208D-2",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208D-2",
    "seats": "7.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.47",
    "maxTonnage": "7.47"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208T-MB1",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208T-MB2",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208T-MB2",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC13208T-SX",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC3815D-T400",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC3815D-T550",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6025D-PD",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6025D-PD1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6025D-PH",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6025D-PH1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6025D2-PD",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6025D2-PH",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC66235D",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6625D",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6625D-T600",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6625D2",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC6625D2-T600",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC7525D",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC7525D2",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D-T550",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D-T650",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D-T650A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D-T750",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D2-T550",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D2-T650",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D2-T650A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8135D2-T750",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8535D2-T650",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8550D",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC8550D2",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9050D-T600",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9050D-T700",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9050D-T800",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9050D2-T600",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9050D2-T700",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9050D2-T800",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9060D-T600",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9060D-T700",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9060D2-T600",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9060D2-T700",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9650D-T700",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9650D2",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9650D2-T700",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9650T2-MB",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9670D2",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "KC9670D2-T800",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "CUU_LONG",
    "model": "KY1016T",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "CUU_LONG",
    "model": "KY1016T-MB",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "CUU_LONG",
    "model": "PY10507D2",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "PY10570T-MB2",
    "seats": "4.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.37",
    "maxTonnage": "4.37"
  },
  {
    "brand": "CUU_LONG",
    "model": "PY10570T-MB2",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "CUU_LONG",
    "model": "PY10570T-MB2",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "CUU_LONG",
    "model": "PY11809D",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "CUU_LONG",
    "model": "PY11880T-MB",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "CUU_LONG",
    "model": "PY8535T2-MB",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "CUU_LONG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "CUU_LONG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "CUU_LONG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "CUU_LONG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "CUU_LONG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB 3810T1",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB 3810T1-MB",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3810D",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3810D-T550",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812D-T550",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812D3N-T550",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812D3N1-T550",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812T1",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812T1-MB",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812T3N",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812T3N-1",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812T3N-1",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812T3N-1",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB3812T3N-MB",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB5220D",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB5225D",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB5225D2",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "CUU_LONG",
    "model": "ZB6020D",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "CADILLAC",
    "model": "CTS",
    "seats": "2.0T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CADILLAC",
    "model": "CTS",
    "seats": "3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CADILLAC",
    "model": "Escalade",
    "seats": "6.2-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CADILLAC",
    "model": "Escalade",
    "seats": "ESV-PLATINIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CADILLAC",
    "model": "Escalade",
    "seats": "ESV-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CADILLAC",
    "model": "SRX",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CADILLAC",
    "model": "SRX",
    "seats": "3.6-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CADILLAC",
    "model": "SRX",
    "seats": "4.6-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CADILLAC",
    "model": "STS",
    "seats": "3.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHANGAN",
    "model": "Honor",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Aveo",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Aveo",
    "seats": "LT-1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Aveo",
    "seats": "LT-1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Aveo",
    "seats": "LTZ-1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Aveo",
    "seats": "LTZ-1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Camaro",
    "seats": "CONVERTIBLE-2.0-TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Camaro",
    "seats": "RS-3.6-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LS-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LT-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LT-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LT-MAXX-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LT-MAXX-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LTZ-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LTZ-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LTZ-MAXX-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "LTZ-MAXX-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Captiva",
    "seats": "REVV-LTZ-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "HIGH-COUNTRY-2.5AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "HIGH-COUNTRY-2.5L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "HIGH-COUNTRY-2.8L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "LT-2.5L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "LT-2.5L-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "LT-2.5L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "LTZ-2.5L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "LTZ-2.8L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "LTZ-2.8L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Colorado",
    "seats": "STORM-2.5L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Cruze",
    "seats": "LS-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Cruze",
    "seats": "LT-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Cruze",
    "seats": "LT-1.6L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Cruze",
    "seats": "LT-1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Cruze",
    "seats": "LTZ-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Cruze",
    "seats": "LTZ-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Cruze",
    "seats": "LTZ-1.8L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Lacetti",
    "seats": "1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Orlando",
    "seats": "LS-1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Orlando",
    "seats": "LT-1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Orlando",
    "seats": "LT-1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Orlando",
    "seats": "LTZ",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Orlando",
    "seats": "LTZ-1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Orlando",
    "seats": "LTZ-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "DUO-VAN-1.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LITE-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LITE-VAN-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LS-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LS-1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LS-1.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LT-0.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LT-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LT-1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LT-1.0-MT-SUPER",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LT-1.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LTZ-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "LTZ-1.0-AT-ZEST",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "VAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "VAN-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "VAN-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Spark",
    "seats": "VAN-1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Suburban",
    "seats": "6.0-V8-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Tahoe",
    "seats": "LTZ-5.3-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Trailblazer",
    "seats": "LT-2.5L-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Trailblazer",
    "seats": "LT-2.5L-VGT-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Trailblazer",
    "seats": "LT-2.5L-VGT-4X4-AT-L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Trailblazer",
    "seats": "LTZ-2.5L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Trailblazer",
    "seats": "LTZ-2.5L-VGT-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Trailblazer",
    "seats": "LTZ-2.8L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Trax",
    "seats": "1.4-LT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Vivant",
    "seats": "CDX-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Vivant",
    "seats": "CDX-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHEVROLET",
    "model": "Vivant",
    "seats": "SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHRYSLER",
    "model": "200",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHRYSLER",
    "model": "300C",
    "seats": "2.7-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHRYSLER",
    "model": "300C",
    "seats": "3.5-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHRYSLER",
    "model": "300C",
    "seats": "5.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CHRYSLER",
    "model": "PT Cruiser",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BC095",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BC212MA",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BC212MA-D2",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BC212MA-WL",
    "seats": "65 chỗ",
    "minSeat": "65",
    "maxSeat": "65",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BC312MA",
    "seats": "90 chỗ",
    "minSeat": "90",
    "maxSeat": "90",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BC312MB",
    "seats": "71 chỗ",
    "minSeat": "71",
    "maxSeat": "71",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BF106",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BF106\/THACO-KTP",
    "seats": "55 chỗ",
    "minSeat": "55",
    "maxSeat": "55",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BH115E",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BH115E-G2",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BH116",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BH116-SF",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BH116E4",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS 090",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090-D1",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090-D3",
    "seats": "56 chỗ",
    "minSeat": "56",
    "maxSeat": "56",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090-D4",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090-DL",
    "seats": "71 chỗ",
    "minSeat": "71",
    "maxSeat": "71",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090-DL2",
    "seats": "71 chỗ",
    "minSeat": "71",
    "maxSeat": "71",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090A",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090D",
    "seats": "56 chỗ",
    "minSeat": "56",
    "maxSeat": "56",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090D5",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090D6",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090D7",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS090D8",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS105-D3",
    "seats": "97 chỗ",
    "minSeat": "97",
    "maxSeat": "97",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS105-D4",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS106",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS106A",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS106D",
    "seats": "83 chỗ",
    "minSeat": "83",
    "maxSeat": "83",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS106D3",
    "seats": "81 chỗ",
    "minSeat": "81",
    "maxSeat": "81",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BS106D4",
    "seats": "81 chỗ",
    "minSeat": "81",
    "maxSeat": "81",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "BX212DS",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "CK327",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "DAEWOO",
    "model": "CK327",
    "seats": "9.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.85",
    "maxTonnage": "9.85"
  },
  {
    "brand": "DAEWOO",
    "model": "CT3",
    "seats": "10.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.9",
    "maxTonnage": "10.9"
  },
  {
    "brand": "DAEWOO",
    "model": "CTĐ",
    "seats": "9.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.72",
    "maxTonnage": "9.72"
  },
  {
    "brand": "DAEWOO",
    "model": "F3C5F",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "DAEWOO",
    "model": "F3C5F",
    "seats": "5.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.51",
    "maxTonnage": "5.51"
  },
  {
    "brand": "DAEWOO",
    "model": "F6CEF",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "DAEWOO",
    "model": "F6CEF",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "DAEWOO",
    "model": "F6CEF",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "DAEWOO",
    "model": "F8CEF",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "DAEWOO",
    "model": "F8CEF",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "DAEWOO",
    "model": "F8CEF",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "DAEWOO",
    "model": "F8CEF",
    "seats": "7.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.52",
    "maxTonnage": "7.52"
  },
  {
    "brand": "DAEWOO",
    "model": "F8CEF",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "DAEWOO",
    "model": "F8CEF",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "DAEWOO",
    "model": "F8CEF",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "DAEWOO",
    "model": "F8CEF",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "DAEWOO",
    "model": "FX12",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "FX120",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "FX120 LIMOUSINE",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6103SB",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6117HKB",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6117HKC",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6117HKC-E4",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6117HKC2",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6117HKD",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6117HKD2",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6117WS",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6900HG",
    "seats": "64 chỗ",
    "minSeat": "64",
    "maxSeat": "64",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6901HG",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6901HG2",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GDW6901HG3",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GL6127HK",
    "seats": "49 chỗ",
    "minSeat": "49",
    "maxSeat": "49",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GL8KN",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "DAEWOO",
    "model": "GT9CM",
    "seats": "13.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.46",
    "maxTonnage": "13.46"
  },
  {
    "brand": "DAEWOO",
    "model": "GT9CM",
    "seats": "17.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.02",
    "maxTonnage": "17.02"
  },
  {
    "brand": "DAEWOO",
    "model": "GT9CM",
    "seats": "19.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.2",
    "maxTonnage": "19.2"
  },
  {
    "brand": "DAEWOO",
    "model": "HC6AA",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "DAEWOO",
    "model": "HC6AA",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "DAEWOO",
    "model": "HU6AA",
    "seats": "13.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.75",
    "maxTonnage": "13.75"
  },
  {
    "brand": "DAEWOO",
    "model": "HU6AA",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DAEWOO",
    "model": "HU6AA",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "DAEWOO",
    "model": "HU8AA",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "DAEWOO",
    "model": "K4DEF",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "DAEWOO",
    "model": "K4DVF",
    "seats": "10.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.44",
    "maxTonnage": "10.44"
  },
  {
    "brand": "DAEWOO",
    "model": "K4MVF",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.02",
    "maxTonnage": "10.02"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.33",
    "maxTonnage": "10.33"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.45",
    "maxTonnage": "10.45"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.56",
    "maxTonnage": "10.56"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.64",
    "maxTonnage": "10.64"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.66",
    "maxTonnage": "10.66"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "10.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.7",
    "maxTonnage": "10.7"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "11.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.48",
    "maxTonnage": "11.48"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "11.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.84",
    "maxTonnage": "11.84"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "12.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.54",
    "maxTonnage": "12.54"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "9.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.15",
    "maxTonnage": "9.15"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "9.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.24",
    "maxTonnage": "9.24"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "9.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.57",
    "maxTonnage": "9.57"
  },
  {
    "brand": "DAEWOO",
    "model": "K7CEF",
    "seats": "9.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.98",
    "maxTonnage": "9.98"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "10.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.65",
    "maxTonnage": "10.65"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "11.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.55",
    "maxTonnage": "11.55"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "11.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.9",
    "maxTonnage": "11.9"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "13.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.15",
    "maxTonnage": "13.15"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "13.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.47",
    "maxTonnage": "13.47"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "13.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.97",
    "maxTonnage": "13.97"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "DAEWOO",
    "model": "K9KEF",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "DAEWOO",
    "model": "K9LEF",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "DAEWOO",
    "model": "K9LEF",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6A1",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6A1",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6A1",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6A1-TMB",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "11.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.84",
    "maxTonnage": "11.84"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "11.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.97",
    "maxTonnage": "11.97"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "12.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.15",
    "maxTonnage": "12.15"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "12.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.45",
    "maxTonnage": "12.45"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "13.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.13",
    "maxTonnage": "13.13"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "DAEWOO",
    "model": "KC6C1-VTL",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "DAEWOO",
    "model": "M9AEF",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DAEWOO",
    "model": "M9AEF",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "DAEWOO",
    "model": "M9AEF",
    "seats": "15.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.92",
    "maxTonnage": "15.92"
  },
  {
    "brand": "DAEWOO",
    "model": "M9AEF",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "11.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.52",
    "maxTonnage": "11.52"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "15.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.67",
    "maxTonnage": "15.67"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "16.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.15",
    "maxTonnage": "16.15"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "16.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.16",
    "maxTonnage": "16.16"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "16.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.75",
    "maxTonnage": "16.75"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "16.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.9",
    "maxTonnage": "16.9"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "17.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.1",
    "maxTonnage": "17.1"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "17.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.3",
    "maxTonnage": "17.3"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "17.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.55",
    "maxTonnage": "17.55"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "17.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.6",
    "maxTonnage": "17.6"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CEF",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CVF",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CVF",
    "seats": "11.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.35",
    "maxTonnage": "11.35"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CVF",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CVF",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CVF",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DAEWOO",
    "model": "M9CVF",
    "seats": "18.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.6",
    "maxTonnage": "18.6"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CSF",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CVF",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CVF",
    "seats": "14.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.31",
    "maxTonnage": "14.31"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CVF",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CVF",
    "seats": "17.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.46",
    "maxTonnage": "17.46"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CVF",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CVF",
    "seats": "18.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.5",
    "maxTonnage": "18.5"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CVF",
    "seats": "20.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.6",
    "maxTonnage": "20.6"
  },
  {
    "brand": "DAEWOO",
    "model": "P9CVF",
    "seats": "21.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.4",
    "maxTonnage": "21.4"
  },
  {
    "brand": "DAEWOO",
    "model": "PHUMINH",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "DAEWOO",
    "model": "PMS",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "DAEWOO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "DAEWOO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "DAEWOO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "DAEWOO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DAEWOO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "DAEWOO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "DAEWOO",
    "model": "V3TEF",
    "seats": "Đầu kéo",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIHATSU",
    "model": "DEVAN",
    "seats": "0.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.51",
    "maxTonnage": "0.51"
  },
  {
    "brand": "DAIHATSU",
    "model": "DL-DH",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "DAIHATSU",
    "model": "HIJET JUMBO",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "DAIHATSU",
    "model": "HIJET JUMBO",
    "seats": "0.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.89",
    "maxTonnage": "0.89"
  },
  {
    "brand": "DAIHATSU",
    "model": "HIJET JUMBO",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "DAIHATSU",
    "model": "HIJET JUMBO",
    "seats": "1.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.13",
    "maxTonnage": "1.13"
  },
  {
    "brand": "DAIHATSU",
    "model": "HIJET Q-BIC",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "DAIHATSU",
    "model": "S92LP",
    "seats": "0.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.78",
    "maxTonnage": "0.78"
  },
  {
    "brand": "DAIHATSU",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "DAIHATSU",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "DAIHATSU",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "DAIHATSU",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DAIHATSU",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "DAIHATSU",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "DAIHATSU",
    "model": "VICTOR",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "DFSK",
    "model": "EQ1020TF",
    "seats": "0.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.71",
    "maxTonnage": "0.71"
  },
  {
    "brand": "DFSK",
    "model": "EQ1020TF",
    "seats": "0.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.76",
    "maxTonnage": "0.76"
  },
  {
    "brand": "DFSK",
    "model": "EQ1020TF ",
    "seats": "0.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.43",
    "maxTonnage": "0.43"
  },
  {
    "brand": "DFSK",
    "model": "EQ1021GF24Q7",
    "seats": "0.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.43",
    "maxTonnage": "0.43"
  },
  {
    "brand": "DFSK",
    "model": "EQ1021GF24Q7",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "DFSK",
    "model": "EQ1021GF24Q7",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "DFSK",
    "model": "EQ1021TF22QN11",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "DFSK",
    "model": "EQ1021TF22QN11",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "DFSK",
    "model": "EQ1021TF22QN11",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "DFSK",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "DFSK",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "DFSK",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "DFSK",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DFSK",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "DFSK",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1020D",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1020D",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1020D",
    "seats": "0.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.78",
    "maxTonnage": "0.78"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1020D-1",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1020D-2",
    "seats": "0.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.67",
    "maxTonnage": "0.67"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1020D-2",
    "seats": "0.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.72",
    "maxTonnage": "0.72"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1020D-2",
    "seats": "0.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.78",
    "maxTonnage": "0.78"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1020D-2",
    "seats": "0.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.87",
    "maxTonnage": "0.87"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1021",
    "seats": "0.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.72",
    "maxTonnage": "0.72"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1021",
    "seats": "0.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.77",
    "maxTonnage": "0.77"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1021",
    "seats": "0.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.79",
    "maxTonnage": "0.79"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1021",
    "seats": "0.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.81",
    "maxTonnage": "0.81"
  },
  {
    "brand": "DONGBEN",
    "model": "DB1021",
    "seats": "0.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.87",
    "maxTonnage": "0.87"
  },
  {
    "brand": "DONGBEN",
    "model": "DBQ20",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "DONGBEN",
    "model": "DBQ20",
    "seats": "1.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.89",
    "maxTonnage": "1.89"
  },
  {
    "brand": "DONGBEN",
    "model": "DBQ20",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "DONGBEN",
    "model": "DBT30",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "DONGBEN",
    "model": "DBT30",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "DONGBEN",
    "model": "DBT30",
    "seats": "1.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.12",
    "maxTonnage": "1.12"
  },
  {
    "brand": "DONGBEN",
    "model": "DBX30",
    "seats": "T¶i van",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGBEN",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "DONGBEN",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "DONGBEN",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "DONGBEN",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DONGBEN",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGBEN",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "1800TD",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "DONGFENG",
    "model": "6HK1-ASU",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "DONGFENG",
    "model": "6HK1\/VPM",
    "seats": "15.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.25",
    "maxTonnage": "15.25"
  },
  {
    "brand": "DONGFENG",
    "model": "C260 33",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "DONGFENG",
    "model": "CK327-PB",
    "seats": "13.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.02",
    "maxTonnage": "13.02"
  },
  {
    "brand": "DONGFENG",
    "model": "CK327-PB1",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "DONGFENG",
    "model": "CK327-TL",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "DONGFENG",
    "model": "CK327\/C260",
    "seats": "11.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.05",
    "maxTonnage": "11.05"
  },
  {
    "brand": "DONGFENG",
    "model": "CKGT\/C230",
    "seats": "11.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.25",
    "maxTonnage": "11.25"
  },
  {
    "brand": "DONGFENG",
    "model": "CKGT\/L315",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "DONGFENG",
    "model": "CKGT\/L315",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "DONGFENG",
    "model": "CKGT\/L315",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DONGFENG",
    "model": "CKXD-BNL",
    "seats": "14.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.76",
    "maxTonnage": "14.76"
  },
  {
    "brand": "DONGFENG",
    "model": "CM7",
    "seats": "2.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC100TD1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC120KM1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC120KM4x4",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC130KM1",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC130KM1\/GC",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC130S\/NL",
    "seats": "5.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.91",
    "maxTonnage": "5.91"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC130S\/PN",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC135TD1",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC135TD1\/ER",
    "seats": "4.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.54",
    "maxTonnage": "4.54"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC140KM1",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC140TD4x4",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC140TD4x4\/NL",
    "seats": "6.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.64",
    "maxTonnage": "6.64"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160KM1",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160KM1\/PN",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160KM1\/XTX",
    "seats": "7.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.33",
    "maxTonnage": "7.33"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160KM2",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160KM3",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160KM3",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160KM3\/CX",
    "seats": "6.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.96",
    "maxTonnage": "6.96"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160MB",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160MB2",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160MB2\/VL",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160S\/ER",
    "seats": "4.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.48",
    "maxTonnage": "4.48"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160TD1\/PHUMINH",
    "seats": "5.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.24",
    "maxTonnage": "5.24"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160TD2",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC160TD2\/PHUMINH",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC180T",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC190KM6x2",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC190KM6x2\/PN",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC1TD1",
    "seats": "0.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.78",
    "maxTonnage": "0.78"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC1TD1",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC210KM6x2",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC240TD6x2",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC240TD6x2\/PN",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "CNC3450TD1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "DONGFENG",
    "model": "CNCHD225KM6x2",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "DONGFENG",
    "model": "CNCHD225KM6x2",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "DONGFENG",
    "model": "CNCHD225KM6x2",
    "seats": "12.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.88",
    "maxTonnage": "12.88"
  },
  {
    "brand": "DONGFENG",
    "model": "CNCHD225KM6x2\/NL",
    "seats": "9.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.96",
    "maxTonnage": "9.96"
  },
  {
    "brand": "DONGFENG",
    "model": "CNCT465i",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CNCT465i",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "DONGFENG",
    "model": "CNCT465i",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "DONGFENG",
    "model": "CP-L315",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DONGFENG",
    "model": "CT8.40TD1",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "DONGFENG",
    "model": "CT9.0TL1",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "CTD\/B190",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "DONGFENG",
    "model": "CTĐ\/C260",
    "seats": "10.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.49",
    "maxTonnage": "10.49"
  },
  {
    "brand": "DONGFENG",
    "model": "CTĐ\/C260",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "DONGFENG",
    "model": "CTĐ\/L315",
    "seats": "15.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.84",
    "maxTonnage": "15.84"
  },
  {
    "brand": "DONGFENG",
    "model": "CTĐ\/YC6J180",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "DONGFENG",
    "model": "DATECHCO\/L315",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "DONGFENG",
    "model": "DFH1160B21\/HH",
    "seats": "9.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.15",
    "maxTonnage": "9.15"
  },
  {
    "brand": "DONGFENG",
    "model": "DFH1310A3\/LEXIM",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "DONGFENG",
    "model": "DFH3310A1\/DNC",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1120B\/HGA",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX2\/HUH",
    "seats": "5.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.54",
    "maxTonnage": "5.54"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX2\/PHUMINH",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX2\/PHUMINH",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5-VT",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/AS",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/CDMB",
    "seats": "8.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.95",
    "maxTonnage": "8.95"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/HH",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/HH",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/HH",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/HH",
    "seats": "9.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.35",
    "maxTonnage": "9.35"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/HH",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/KM",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/MK",
    "seats": "9.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.35",
    "maxTonnage": "9.35"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/TUP",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1160BX5\/TUP",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/HGA",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/HGA",
    "seats": "10.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.57",
    "maxTonnage": "10.57"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/HGA",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/HGA",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/HH",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/HH",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/KM",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/TTCM",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1203A\/TTCM",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A\/URENCO9",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2.GMC",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2.GMC",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/DAESCO",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/DOL",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/HH",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/TC",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/TC",
    "seats": "10.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.33",
    "maxTonnage": "10.33"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/TC",
    "seats": "11.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.15",
    "maxTonnage": "11.15"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/TC",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/TC",
    "seats": "11.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.48",
    "maxTonnage": "11.48"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/TC",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/VT",
    "seats": "11.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.3",
    "maxTonnage": "11.3"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A2\/ĐL",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A8.PHUMINH",
    "seats": "10.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.44",
    "maxTonnage": "10.44"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A8\/CTĐ",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A8\/PHUMINH",
    "seats": "9.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.35",
    "maxTonnage": "9.35"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A8\/PHUMINH",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A9\/DONGLINH",
    "seats": "11.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.9",
    "maxTonnage": "11.9"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A9\/DONGLINH",
    "seats": "12.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.65",
    "maxTonnage": "12.65"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250A9\/KM",
    "seats": "13.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.75",
    "maxTonnage": "13.75"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250B\/VT",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1250B\/VT",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/CK327",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "8.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.93",
    "maxTonnage": "8.93"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/HGA",
    "seats": "9.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.9",
    "maxTonnage": "9.9"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/TN",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1253AX\/TTCM",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1-TCN1",
    "seats": "15.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.15",
    "maxTonnage": "15.15"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1.GMC",
    "seats": "11.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.72",
    "maxTonnage": "11.72"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/CK327",
    "seats": "12.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.38",
    "maxTonnage": "12.38"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/DAESCO",
    "seats": "14.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.55",
    "maxTonnage": "14.55"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/HH",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/TC",
    "seats": "10.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.44",
    "maxTonnage": "10.44"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/TC",
    "seats": "12.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.46",
    "maxTonnage": "12.46"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/TC",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/TC",
    "seats": "12.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.61",
    "maxTonnage": "12.61"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/TC",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/TC",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/TC",
    "seats": "15.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.36",
    "maxTonnage": "15.36"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/TRC",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/VT",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A1\/VT01",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/CONECO",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/DAESCO",
    "seats": "17.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.38",
    "maxTonnage": "17.38"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/HGA",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/HGA",
    "seats": "18.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.4",
    "maxTonnage": "18.4"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/HH",
    "seats": "11.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.55",
    "maxTonnage": "11.55"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/HH",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/HP",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/PMS",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A4\/VM",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311A9\/HH",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/HH",
    "seats": "17.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.24",
    "maxTonnage": "17.24"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/HH",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/HTA",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/MK",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/TUP",
    "seats": "10.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.85",
    "maxTonnage": "10.85"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/TUP",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/TUP",
    "seats": "11.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.3",
    "maxTonnage": "11.3"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/TUP",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/TUP",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/TUP",
    "seats": "15.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.62",
    "maxTonnage": "15.62"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/TUP",
    "seats": "16.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.25",
    "maxTonnage": "16.25"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311AX3\/TUP",
    "seats": "16.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.65",
    "maxTonnage": "16.65"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL1311XA3\/TUP",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL3160BX6A-VT",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL3160BXA\/DUCNGUYEN",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL3250A\/TC",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL3260A\/TC",
    "seats": "10.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.87",
    "maxTonnage": "10.87"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL3310A\/TC",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5160XXYBX1-VT",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5160XXYBX1-VT",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5160XXYBX1-VT",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5160XXYBX1\/DUCNGUYEN",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5160XXYBX1\/DUCNGUYEN",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5160XXYBX1\/HH",
    "seats": "8.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.66",
    "maxTonnage": "8.66"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5160XXYBX1\/TUANPHUONG",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5160XXYBX1\/TUP",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "DONGFENG",
    "model": "DFL5250GJBA\/VX",
    "seats": "12.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.66",
    "maxTonnage": "12.66"
  },
  {
    "brand": "DONGFENG",
    "model": "DFZ5258GPSSZ5D\/TUP",
    "seats": "11.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.34",
    "maxTonnage": "11.34"
  },
  {
    "brand": "DONGFENG",
    "model": "DHZ6840RC1",
    "seats": "48 chỗ",
    "minSeat": "48",
    "maxSeat": "48",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "DKV\/ISB18050",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "DONGFENG",
    "model": "DKV\/ISL9",
    "seats": "9.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.55",
    "maxTonnage": "9.55"
  },
  {
    "brand": "DONGFENG",
    "model": "DNC\/ISB18050",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "DNVN\/L315",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "DONGFENG",
    "model": "DNVN\/YC6L340",
    "seats": "21.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.8",
    "maxTonnage": "21.8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1011T",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1011T",
    "seats": "0.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.73",
    "maxTonnage": "0.73"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1036T42DA",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1091ZE-1",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1091ZE-1",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1091ZE-1",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1091ZE-1",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1091ZE-1",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1100G5ADJAC\/TRUONGGIANG",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1126GBJ\/TC",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1126GBJ\/TC",
    "seats": "5.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.71",
    "maxTonnage": "5.71"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1126GBJ\/TC",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1126GBJ\/TC",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1126GBJ\/TC",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1126GBJ\/TCXT",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1141K7DJ2\/ĐL",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1160ZZ4GJ1\/VL",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1161GKJ\/TTCM",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1161ZEJ-1",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1161ZEJ-1",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G1\/HT",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G1\/TC",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G1\/TC",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G7D1\/HH",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G7D1\/HH",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G7D1\/TC",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G7D1\/TC",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G7D1\/TCLC",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G7D1\/TCMP",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G7D1\/TCN",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1168G7D1\/TCTL",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1202W\/TC",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1202W\/TC",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1202WJ\/HH",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1240GE7\/HB",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1250GZ4DJ3\/HH",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1250GZ4DJ3\/HH",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1250GZ4DJ3\/VL",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1250LZ3G1-VT",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1250LZ3G1\/TT",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1250LZ3G1\/TT",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1254G2\/TC",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1254G2\/TC",
    "seats": "10.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.7",
    "maxTonnage": "10.7"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1254G2\/TC",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1254WJ2\/LEXIM",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1254WJ2\/TINPHAT",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/AK",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/ALP",
    "seats": "17.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.12",
    "maxTonnage": "17.12"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/CK327",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/HH",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/HH",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/TINPHAT",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/TTCM",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/TTCM",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1298VJ\/TTCM",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1310GZ4DJ1\/VL",
    "seats": "17.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.39",
    "maxTonnage": "17.39"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1310LZ3G\/TT",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1310LZ3G\/VT",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1310VFJ\/ALP",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/ALP",
    "seats": "21.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.5",
    "maxTonnage": "21.5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/TT",
    "seats": "21.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.35",
    "maxTonnage": "21.35"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/TTCM",
    "seats": "18.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.8",
    "maxTonnage": "18.8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/VM",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/VM",
    "seats": "19.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.5",
    "maxTonnage": "19.5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/VM",
    "seats": "20.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20",
    "maxTonnage": "20"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/VT",
    "seats": "20.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.31",
    "maxTonnage": "20.31"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/VT",
    "seats": "21.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.75",
    "maxTonnage": "21.75"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398W\/VT",
    "seats": "22.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.38",
    "maxTonnage": "22.38"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398WJ\/ALP",
    "seats": "21.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.5",
    "maxTonnage": "21.5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398WJ\/DNVN",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398WJ\/DNVN",
    "seats": "18.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.8",
    "maxTonnage": "18.8"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1398WJ\/DNVN",
    "seats": "22.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.05",
    "maxTonnage": "22.05"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1420WJ\/TTCM",
    "seats": "18.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.5",
    "maxTonnage": "18.5"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ1420WJ\/TTCM",
    "seats": "21.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.05",
    "maxTonnage": "21.05"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ3101GL46D3\/SAOBAC",
    "seats": "4.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.52",
    "maxTonnage": "4.52"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ3258GL1\/DNVN",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ3258GL1\/TTCM",
    "seats": "13.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.26",
    "maxTonnage": "13.26"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ3258GL1\/TTCM",
    "seats": "13.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.46",
    "maxTonnage": "13.46"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ5168XXYKJ1\/TT",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "DONGFENG",
    "model": "EQ5311GFJ\/TNT",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DONGFENG",
    "model": "Fengxing",
    "seats": "M3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "Fengxing",
    "seats": "T5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "Forthing",
    "seats": "T5 EVO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "HD210",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "DONGFENG",
    "model": "HG-TMB",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA-TL",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B190",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B210",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/B210",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C230",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C245",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "12.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.25",
    "maxTonnage": "12.25"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "13.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.58",
    "maxTonnage": "13.58"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/C260",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "11.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.77",
    "maxTonnage": "11.77"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "12.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.65",
    "maxTonnage": "12.65"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "14.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.59",
    "maxTonnage": "14.59"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "14.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.65",
    "maxTonnage": "14.65"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "17.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.45",
    "maxTonnage": "17.45"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "17.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.85",
    "maxTonnage": "17.85"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L315",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L340",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L340",
    "seats": "14.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.75",
    "maxTonnage": "14.75"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L340",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L340",
    "seats": "17.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.55",
    "maxTonnage": "17.55"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L340",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L340",
    "seats": "18.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.8",
    "maxTonnage": "18.8"
  },
  {
    "brand": "DONGFENG",
    "model": "HGA\/L340",
    "seats": "21.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.55",
    "maxTonnage": "21.55"
  },
  {
    "brand": "DONGFENG",
    "model": "HH.TM15",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "8.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.75",
    "maxTonnage": "8.75"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B170",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B190",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B190",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B190",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B190",
    "seats": "9.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.15",
    "maxTonnage": "9.15"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B190",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B210",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B210",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/B210",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C230",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C230",
    "seats": "10.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.33",
    "maxTonnage": "10.33"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C230",
    "seats": "10.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.7",
    "maxTonnage": "10.7"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C230",
    "seats": "10.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.85",
    "maxTonnage": "10.85"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C230",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C230",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C230",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C260",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C260",
    "seats": "11.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.35",
    "maxTonnage": "11.35"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C260",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C260",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C260",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C260",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C260",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/C260",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "14.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.59",
    "maxTonnage": "14.59"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "14.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.76",
    "maxTonnage": "14.76"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "17.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.85",
    "maxTonnage": "17.85"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "18.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.2",
    "maxTonnage": "18.2"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L315",
    "seats": "21.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.45",
    "maxTonnage": "21.45"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L375",
    "seats": "14.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.95",
    "maxTonnage": "14.95"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L375",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L375",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/L375",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/TM13",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/YC6J180",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "DONGFENG",
    "model": "HH\/YC6J180",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "HIệP HòA",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "DONGFENG",
    "model": "HP\/L340",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "DONGFENG",
    "model": "HP\/L340",
    "seats": "21.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.55",
    "maxTonnage": "21.55"
  },
  {
    "brand": "DONGFENG",
    "model": "HTA\/ISB18050",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "DONGFENG",
    "model": "HUH\/B170",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DONGFENG",
    "model": "HUH\/L340",
    "seats": "19.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.1",
    "maxTonnage": "19.1"
  },
  {
    "brand": "DONGFENG",
    "model": "KM-EQH160",
    "seats": "3.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.41",
    "maxTonnage": "3.41"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/B190",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/B190",
    "seats": "9.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.78",
    "maxTonnage": "9.78"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/C260",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/C260",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/EQH160",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/L315",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/L315",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/L315",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "DONGFENG",
    "model": "KM\/ZD30D13",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "DONGFENG",
    "model": "KMC\/C260",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "DONGFENG",
    "model": "L315-30",
    "seats": "16.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.6",
    "maxTonnage": "16.6"
  },
  {
    "brand": "DONGFENG",
    "model": "LAMBERET\/B210",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "DONGFENG",
    "model": "LAMBERET\/L315",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "DONGFENG",
    "model": "LEXIM\/C260",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "DONGFENG",
    "model": "LEXIM\/L315",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "DONGFENG",
    "model": "LZ1240MN\/CK327",
    "seats": "10.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.49",
    "maxTonnage": "10.49"
  },
  {
    "brand": "DONGFENG",
    "model": "LZ3260M\/HIệP",
    "seats": "8.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.96",
    "maxTonnage": "8.96"
  },
  {
    "brand": "DONGFENG",
    "model": "PHUMINH\/B190",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DONGFENG",
    "model": "PHUMINH\/C260",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "DONGFENG",
    "model": "PMS\/L315",
    "seats": "15.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.33",
    "maxTonnage": "15.33"
  },
  {
    "brand": "DONGFENG",
    "model": "PMS\/L315",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DONGFENG",
    "model": "QP-L315",
    "seats": "16.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.8",
    "maxTonnage": "16.8"
  },
  {
    "brand": "DONGFENG",
    "model": "QTH-TL",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "DONGFENG",
    "model": "SGCD\/C260",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "DONGFENG",
    "model": "SGCD\/L315",
    "seats": "16.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.85",
    "maxTonnage": "16.85"
  },
  {
    "brand": "DONGFENG",
    "model": "TC-MP",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "DONGFENG",
    "model": "TC-TL",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "DONGFENG",
    "model": "TINPHAT\/C230",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "DONGFENG",
    "model": "TINPHAT\/L315",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "DONGFENG",
    "model": "TINPHAT\/L315",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "DONGFENG",
    "model": "TMT\/YC6L310",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "DONGFENG",
    "model": "TMT\/YC6L310",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "DONGFENG",
    "model": "TMT\/YC6L310",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DONGFENG",
    "model": "TMT\/YC6L310",
    "seats": "18.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.65",
    "maxTonnage": "18.65"
  },
  {
    "brand": "DONGFENG",
    "model": "TMT\/YC6L310",
    "seats": "21.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.65",
    "maxTonnage": "21.65"
  },
  {
    "brand": "DONGFENG",
    "model": "TN\/C230",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "DONGFENG",
    "model": "TN\/L315",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "DONGFENG",
    "model": "TRC-X",
    "seats": "16.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.13",
    "maxTonnage": "16.13"
  },
  {
    "brand": "DONGFENG",
    "model": "TRUONGGIANG-5TC",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "DONGFENG",
    "model": "TT-14",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "DONGFENG",
    "model": "TT\/L315",
    "seats": "14.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.82",
    "maxTonnage": "14.82"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM-C260",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM-C260",
    "seats": "9.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.08",
    "maxTonnage": "9.08"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/B190",
    "seats": "7.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.77",
    "maxTonnage": "7.77"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/C260",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/C260",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/C260",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/ISL95",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/L315",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/L315",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/L315",
    "seats": "14.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.74",
    "maxTonnage": "14.74"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/L315",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/L315",
    "seats": "17.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.6",
    "maxTonnage": "17.6"
  },
  {
    "brand": "DONGFENG",
    "model": "TTCM\/L340",
    "seats": "17.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.6",
    "maxTonnage": "17.6"
  },
  {
    "brand": "DONGFENG",
    "model": "TUP\/ISB18050",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "DONGFENG",
    "model": "TUP\/ISB18050",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "DONGFENG",
    "model": "TUP\/ISB18050",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "TUP\/ISB18050",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "TUP\/ISL9",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "DONGFENG",
    "model": "TUP\/L315",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "DONGFENG",
    "model": "TUP\/YC6L310",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "DONGFENG",
    "model": "TUP\/YC6L31050",
    "seats": "11.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.25",
    "maxTonnage": "11.25"
  },
  {
    "brand": "DONGFENG",
    "model": "TV-XT",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DONGFENG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "DONGFENG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "DONGFENG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "DONGFENG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DONGFENG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGFENG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGFENG",
    "model": "VL\/C260",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "DONGFENG",
    "model": "VL\/C260",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "VL\/C260",
    "seats": "9.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.24",
    "maxTonnage": "9.24"
  },
  {
    "brand": "DONGFENG",
    "model": "VL\/L315",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "DONGFENG",
    "model": "VL\/L315",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DONGFENG",
    "model": "VM\/6HK1",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "DONGFENG",
    "model": "VM\/6HK1",
    "seats": "16.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.9",
    "maxTonnage": "16.9"
  },
  {
    "brand": "DONGFENG",
    "model": "VM\/6HK1",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "DONGFENG",
    "model": "VM\/L340",
    "seats": "19.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.5",
    "maxTonnage": "19.5"
  },
  {
    "brand": "DONGFENG",
    "model": "VM\/L340",
    "seats": "20.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20",
    "maxTonnage": "20"
  },
  {
    "brand": "DONGFENG",
    "model": "VSV\/L315",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "DONGFENG",
    "model": "VSV\/L315",
    "seats": "11.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.44",
    "maxTonnage": "11.44"
  },
  {
    "brand": "DONGFENG",
    "model": "VT\/B210",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "VUL\/C26033",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "DONGFENG",
    "model": "VUL\/ISB18050",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DONGFENG",
    "model": "X30\/L315",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "DONGFENG",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DONGFENG",
    "model": "ĐL-BNL",
    "seats": "12.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.21",
    "maxTonnage": "12.21"
  },
  {
    "brand": "DONGFENG",
    "model": "ĐL\/C260",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "DONGFENG",
    "model": "ĐL\/C260",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "DONGFENG",
    "model": "ĐL\/CY4102",
    "seats": "4.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.67",
    "maxTonnage": "4.67"
  },
  {
    "brand": "DONGFENG",
    "model": "ĐL\/L315",
    "seats": "10.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.7",
    "maxTonnage": "10.7"
  },
  {
    "brand": "DONGFENG",
    "model": "ĐL\/L315",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "DONGSUNG",
    "model": "DFB2.5",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "DONGSUNG",
    "model": "DFB8.0",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "DONGSUNG",
    "model": "DS7000",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DONGSUNG",
    "model": "N757P3I-VT",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "DONGSUNG",
    "model": "N757P3I-VT",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "DONGSUNG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "DONGSUNG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "DONGSUNG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "DONGSUNG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DONGSUNG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "DONGSUNG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "DONGSUNG",
    "model": "VT 5840D",
    "seats": "3.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.81",
    "maxTonnage": "3.81"
  },
  {
    "brand": "DONGSUNG",
    "model": "VT490QZL",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "DOOSUNG",
    "model": "Rơ-mooc bồn",
    "seats": "DV",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DOOSUNG",
    "model": "Rơ-mooc, sơ mi rơ-mooc",
    "seats": "DVBD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "DHB12S1-H345",
    "seats": "36 chỗ",
    "minSeat": "36",
    "maxSeat": "36",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "DHB12S1-W375",
    "seats": "36 chỗ",
    "minSeat": "36",
    "maxSeat": "36",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "DHB8S1-W180-E4",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "DHB8S2-W180",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "DHB8S2-W180-E4",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "DOTHANH IZ49",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "DO_THANH",
    "model": "HD85-TK",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "DO_THANH",
    "model": "HD85-TMB",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98-TK",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98-TMB",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98-TMB1",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98-TMB2",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98\/DONGLINH",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98\/DONGNAM",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98\/HIEPHOA",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98\/IMAE",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98\/TINPHAT",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98\/TTCM",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98S-TK",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98S-TMB",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98S-TMB1",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98S\/BHH",
    "seats": "5.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.74",
    "maxTonnage": "5.74"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98S\/CKGT",
    "seats": "4.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.89",
    "maxTonnage": "4.89"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98S\/CKGT",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "DO_THANH",
    "model": "HD98S\/VL",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-E4-TK",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-E4-TL",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-E4-TMB",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-E4-TMB1",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-E4-TMB2",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-E4\/CMN",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-TK",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-TK1",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-TL",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-TMB",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-TMB1",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49-TMB2",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49\/DELTA",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49\/TN-TBN",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49\/TPH",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49\/TTI",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ49\/ĐH",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TBH",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TBH1",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TBH2",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TBH3",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TK",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TKH",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TL",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TLH",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-TMB",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65-XTL",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/AKS",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/BNT",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/CMN",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/DAIHAN",
    "seats": "3.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.39",
    "maxTonnage": "3.39"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/DONGNAM",
    "seats": "2.09 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.09",
    "maxTonnage": "2.09"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/DONGNAM",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/DONGNAM",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/HDN",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/KPI",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/LEXIM",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/MIKA",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/TKI",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/TN-TKS",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/TN-TKS-4995O2",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/TPH",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65\/TTCM",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65S-TĐ",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "DO_THANH",
    "model": "IZ65S-TĐH",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120S",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120S",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120S",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120S",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120S",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120S",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.02",
    "maxTonnage": "7.02"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD120SL",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD88",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD88",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD88",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD88",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD88",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD88\/TT",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.13",
    "maxTonnage": "5.13"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.36",
    "maxTonnage": "5.36"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.78",
    "maxTonnage": "5.78"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.88",
    "maxTonnage": "5.88"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.39",
    "maxTonnage": "6.39"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99S",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99S",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99S",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99S",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99S",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "DO_THANH",
    "model": "MIGHTY HD99S",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "DO_THANH",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "DO_THANH",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "DO_THANH",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "DO_THANH",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "DO_THANH",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "DO_THANH",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "DO_THANH",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DO_THANH",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Cielo",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Damas",
    "seats": "0.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Espero",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Gentra",
    "seats": "SX-1.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Gentra",
    "seats": "SX-1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "GentraX",
    "seats": "SX-1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lacetti",
    "seats": "1.6-AT-CDX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lacetti",
    "seats": "CDX-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lacetti",
    "seats": "CDX-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lacetti",
    "seats": "EX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lacetti",
    "seats": "EX-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lacetti",
    "seats": "MAX-1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lacetti",
    "seats": "SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lanos",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lanos",
    "seats": "LS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Lanos",
    "seats": "SX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Leganza",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Leganza",
    "seats": "CDX-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Magnus",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Magnus",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Magnus",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "0.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "GROOVE-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "JAZZ-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "JOY-0.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "JOY-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "S-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "SE-0.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "SE-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "SUPER-0.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "SX-0.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "SX-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "VAN-0.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "VAN-0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Matiz",
    "seats": "VAN-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Nubira",
    "seats": "II-1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Nubira",
    "seats": "II-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Prince",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Racer",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAEWOO",
    "model": "Winstorm",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIHATSU",
    "model": "Applause",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIHATSU",
    "model": "Charade",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIHATSU",
    "model": "Citivan",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIHATSU",
    "model": "Feroza",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIHATSU",
    "model": "Hijet",
    "seats": "JUMBO-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIHATSU",
    "model": "Terios",
    "seats": "1.3-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DAIHATSU",
    "model": "Terios",
    "seats": "1.5-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DODGE",
    "model": "Caliber",
    "seats": "SXT-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DODGE",
    "model": "Caravan",
    "seats": "SXT-4.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "DODGE",
    "model": "Journey",
    "seats": "RT-2.7-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FAIRY",
    "model": "BJ 1043V",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "FAIRY",
    "model": "BJ 3042D",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "FAIRY",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "FAIRY",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "FAIRY",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "FAIRY",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FAIRY",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "FAIRY",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "FAW",
    "model": "ATM",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "FAW",
    "model": "CA 6110",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FAW",
    "model": "CA1010A2",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "FAW",
    "model": "CA1031K4",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "FAW",
    "model": "CA1031K4",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "FAW",
    "model": "CA1031K4",
    "seats": "1.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.17",
    "maxTonnage": "1.17"
  },
  {
    "brand": "FAW",
    "model": "CA1041AD",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "FAW",
    "model": "CA1041K2L2",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "FAW",
    "model": "CA1041K2L2",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "FAW",
    "model": "CA1061HK26L4",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "FAW",
    "model": "CA1061HK26L4",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "FAW",
    "model": "CA1061HK26L4",
    "seats": "2.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.96",
    "maxTonnage": "2.96"
  },
  {
    "brand": "FAW",
    "model": "CA1061HK26L4",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "FAW",
    "model": "CA1061HK26L4",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L4E",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L4E",
    "seats": "8.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.51",
    "maxTonnage": "8.51"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L6E4Z",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L6E4Z",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L6E4Z",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L6E4Z",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L6E4Z",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L6E4Z",
    "seats": "9.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.28",
    "maxTonnage": "9.28"
  },
  {
    "brand": "FAW",
    "model": "CA1160P62K1L6E4Z",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "FAW",
    "model": "CA1251PK2E3L10T3A95",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "FAW",
    "model": "CA1251PK2E3L10T3A95",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "FAW",
    "model": "CA1255P2K2E3L3T1A92",
    "seats": "12.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.35",
    "maxTonnage": "12.35"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1",
    "seats": "10.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.25",
    "maxTonnage": "10.25"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1",
    "seats": "11.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.01",
    "maxTonnage": "11.01"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1",
    "seats": "11.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.25",
    "maxTonnage": "11.25"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1",
    "seats": "8.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.37",
    "maxTonnage": "8.37"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1.HT",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "FAW",
    "model": "CA1258P1K2L11T1\/1",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "FAW",
    "model": "CA1310P63K2L6T4E",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "FAW",
    "model": "CA1310P63K2L6T4E",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "FAW",
    "model": "CA1310P66K2L7T4E",
    "seats": "17.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.6",
    "maxTonnage": "17.6"
  },
  {
    "brand": "FAW",
    "model": "CA1311P1K2L7T4EA80",
    "seats": "16.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.25",
    "maxTonnage": "16.25"
  },
  {
    "brand": "FAW",
    "model": "CA1311P21K2L11T4A92",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "FAW",
    "model": "CA1311P21K2L11T4A92",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "FAW",
    "model": "CA1312P21K2L2T4A2",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "FAW",
    "model": "CA1313P2K2L7T4EA80",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "FAW",
    "model": "CA1313P2K2L7T4EA80",
    "seats": "17.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.75",
    "maxTonnage": "17.75"
  },
  {
    "brand": "FAW",
    "model": "CA1313P2K2L7T4EA80",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "FAW",
    "model": "CA1369P4K2L11T6",
    "seats": "20.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.04",
    "maxTonnage": "20.04"
  },
  {
    "brand": "FAW",
    "model": "CA3041D",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "FAW",
    "model": "CA3121PK2",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "FAW",
    "model": "CA3166P4K2",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "FAW",
    "model": "CA3250P1K2T1",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "FAW",
    "model": "CA3250P1K2T1EA80",
    "seats": "13.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.57",
    "maxTonnage": "13.57"
  },
  {
    "brand": "FAW",
    "model": "CA4258P1K2T1",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "FAW",
    "model": "CA5166XXYP1K2L5",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "FAW",
    "model": "CA5166XXYP1K2L5\/1",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "FAW",
    "model": "CA5312CLXYP21K2L2T4A2",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "FAW",
    "model": "CAH1121K28L6R5",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "FAW",
    "model": "CAH1121K28L6R5",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "FAW",
    "model": "CAH1121K28L6R5",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "FAW",
    "model": "CAH1121K28L6R5",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "FAW",
    "model": "CK327-XT",
    "seats": "5.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.33",
    "maxTonnage": "5.33"
  },
  {
    "brand": "FAW",
    "model": "CKGT",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "FAW",
    "model": "CKGT\/BF6M2012",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "FAW",
    "model": "CKGT\/CA6DL1",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "FAW",
    "model": "HB-BF6M1013",
    "seats": "11.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.37",
    "maxTonnage": "11.37"
  },
  {
    "brand": "FAW",
    "model": "HIệP HOà-TC.735N",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "FAW",
    "model": "HT-CA6DF3",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "FAW",
    "model": "HT-CA6DF3",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "FAW",
    "model": "HT-CA6DF3",
    "seats": "13.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.66",
    "maxTonnage": "13.66"
  },
  {
    "brand": "FAW",
    "model": "HT-CA6DF3",
    "seats": "7.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.97",
    "maxTonnage": "7.97"
  },
  {
    "brand": "FAW",
    "model": "HT-CA6DF3",
    "seats": "8.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.23",
    "maxTonnage": "8.23"
  },
  {
    "brand": "FAW",
    "model": "HT-CA6DF3",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "FAW",
    "model": "HT-CA6DL1",
    "seats": "15.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.52",
    "maxTonnage": "15.52"
  },
  {
    "brand": "FAW",
    "model": "HT.MB-74",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "FAW",
    "model": "HT.MB-75",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "FAW",
    "model": "HT.TTC-68",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "FAW",
    "model": "HT.TTC-76",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "FAW",
    "model": "SGCD\/CA6DL1",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "FAW",
    "model": "SGCD\/CA6DL1",
    "seats": "17.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.55",
    "maxTonnage": "17.55"
  },
  {
    "brand": "FAW",
    "model": "SGCD\/CA6DL1",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "FAW",
    "model": "TB\/CA6DL1",
    "seats": "17.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.75",
    "maxTonnage": "17.75"
  },
  {
    "brand": "FAW",
    "model": "TRD\/BF6M2012",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "FAW",
    "model": "TRD\/CA6DF3",
    "seats": "13.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.75",
    "maxTonnage": "13.75"
  },
  {
    "brand": "FAW",
    "model": "TRD\/CA6DL1",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "FAW",
    "model": "TTCM\/BF6M1013",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "FAW",
    "model": "TTCM\/BF6M1013",
    "seats": "19.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.9",
    "maxTonnage": "19.9"
  },
  {
    "brand": "FAW",
    "model": "TTCM\/CA6DL2",
    "seats": "13.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.47",
    "maxTonnage": "13.47"
  },
  {
    "brand": "FAW",
    "model": "TTCM\/CA6DL2",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "FAW",
    "model": "TTCM\/CA6DL2",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "FAW",
    "model": "TTCM\/CA6DL2",
    "seats": "18.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.5",
    "maxTonnage": "18.5"
  },
  {
    "brand": "FAW",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "FAW",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "FAW",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "FAW",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FAW",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "FAW",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "FAW",
    "model": "VIETDANG\/CA6DL1",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DF3",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DF3",
    "seats": "11.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.55",
    "maxTonnage": "11.55"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DF3",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DF3",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DF3",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "13.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.39",
    "maxTonnage": "13.39"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL1",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "FAW",
    "model": "VL\/CA6DL2",
    "seats": "17.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.85",
    "maxTonnage": "17.85"
  },
  {
    "brand": "FAW",
    "model": "VL6X2-MP",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "FAW",
    "model": "VL8X4-MP",
    "seats": "17.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.17",
    "maxTonnage": "17.17"
  },
  {
    "brand": "FAW",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FAW",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FAW",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FAW",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FAW",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FAW",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "TC-IVC",
    "seats": "6.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.14",
    "maxTonnage": "6.14"
  },
  {
    "brand": "FIAT",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "FIAT",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "FIAT",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "FIAT",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FIAT",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "FIAT",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "FORCIA",
    "model": "950KM",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "FORCIA",
    "model": "990TL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "FORCIA",
    "model": "EURO950TĐ",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "FORCIA",
    "model": "EURO950TĐ-1",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "FORCIA",
    "model": "FC457T",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "FORCIA",
    "model": "HN1200KM",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "FORCIA",
    "model": "HN1250TL",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "FORCIA",
    "model": "HN1450TĐ",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "FORCIA",
    "model": "HN1490T",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "FORCIA",
    "model": "HN666T",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "FORCIA",
    "model": "HN666TĐ",
    "seats": "0.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.67",
    "maxTonnage": "0.67"
  },
  {
    "brand": "FORCIA",
    "model": "HN888TĐ1",
    "seats": "0.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.82",
    "maxTonnage": "0.82"
  },
  {
    "brand": "FORCIA",
    "model": "HN888TĐ2",
    "seats": "0.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.82",
    "maxTonnage": "0.82"
  },
  {
    "brand": "FORCIA",
    "model": "HN888TĐ2",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "FORCIA",
    "model": "HN888TĐ2",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "FORCIA",
    "model": "HN950TĐ1",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "FORCIA",
    "model": "HN950TĐ1",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "FORCIA",
    "model": "HN950TĐ2",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "FORCIA",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "FORCIA",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "FORCIA",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "FORCIA",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FORCIA",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "FORCIA",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "FORD",
    "model": "TRANSIT",
    "seats": "10 chỗ",
    "minSeat": "10",
    "maxSeat": "10",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "TRANSIT",
    "seats": "13 chỗ",
    "minSeat": "13",
    "maxSeat": "13",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "TRANSIT",
    "seats": "16 chỗ",
    "minSeat": "16",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "TRANSIT - cứu thương",
    "seats": "8 chỗ",
    "minSeat": "8",
    "maxSeat": "8",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "TRANSIT - cứu thương",
    "seats": "9 chỗ",
    "minSeat": "9",
    "maxSeat": "9",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "BJ1022V3JA3-2",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "BJ1022V3JA3-2A",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "BJ1036V3JB",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "BJ1043V8JB5-1",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "THACO",
    "model": "BJ1046V8JB",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "THACO",
    "model": "BJ3032D8JB",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "BJ3052E(C",
    "seats": "3.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.81",
    "maxTonnage": "3.81"
  },
  {
    "brand": "THACO",
    "model": "THACO FC950",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "THACO",
    "model": "THACO FC950",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "THACO",
    "model": "THACO FC9500",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "THACO FD1600",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "THACO",
    "model": "THACO FD1600",
    "seats": "8.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.17",
    "maxTonnage": "8.17"
  },
  {
    "brand": "THACO",
    "model": "THACO FD1600B",
    "seats": "7.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.02",
    "maxTonnage": "7.02"
  },
  {
    "brand": "THACO",
    "model": "THACO FD250",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "THACO",
    "model": "THACO FD345",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "THACO",
    "model": "THACO FD350",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "THACO",
    "model": "THACO FD500",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "THACO",
    "model": "THACO FD500",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "THACO",
    "model": "THACO FD500A",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "THACO",
    "model": "THACO FD650",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "THACO",
    "model": "THACO FD650",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "THACO",
    "model": "THACO FD850",
    "seats": "7.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.58",
    "maxTonnage": "7.58"
  },
  {
    "brand": "THACO",
    "model": "THACO FD850",
    "seats": "7.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.82",
    "maxTonnage": "7.82"
  },
  {
    "brand": "THACO",
    "model": "THACO FD8500",
    "seats": "6.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.66",
    "maxTonnage": "6.66"
  },
  {
    "brand": "THACO",
    "model": "THACO FD8500",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "THACO",
    "model": "THACO FD8500",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "THACO",
    "model": "THACO FD8500A",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "THACO",
    "model": "THACO FD900",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "THACO",
    "model": "THACO FD9000",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "THACO FD9000",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "THACO",
    "model": "THACO FD950",
    "seats": "8.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.37",
    "maxTonnage": "8.37"
  },
  {
    "brand": "THACO",
    "model": "THACO FD9500",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "THACO",
    "model": "THACO FD9500",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "THACO",
    "model": "THACO FD9500",
    "seats": "9.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.05",
    "maxTonnage": "9.05"
  },
  {
    "brand": "THACO",
    "model": "THACO FD9500",
    "seats": "9.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.06",
    "maxTonnage": "9.06"
  },
  {
    "brand": "THACO",
    "model": "THACO FD9500",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD1000B",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD150C",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD1600A",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD1600B",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD250C",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD250C",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD250C",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD250D",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD345C",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD345D",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD345D",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD420",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD490C",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD490C",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD600C",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD600C",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD800C",
    "seats": "6.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.14",
    "maxTonnage": "6.14"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD800C",
    "seats": "6.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.89",
    "maxTonnage": "6.89"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD800C",
    "seats": "7.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.02",
    "maxTonnage": "7.02"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD800C",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "THACO",
    "model": "THACO FLD900A",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "FOTON",
    "model": "AUMAN C160A.E4-CS",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "FOTON",
    "model": "AUMAN C160B.E4-CS",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "FOTON",
    "model": "AUMAN C160B.E4-CS",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "FOTON",
    "model": "AUMAN C240.E4-CS",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "FOTON",
    "model": "AUMAN C240.E4-CS",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "FOTON",
    "model": "AUMAN EST FV400.E4",
    "seats": "14.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.77",
    "maxTonnage": "14.77"
  },
  {
    "brand": "FOTON",
    "model": "AUMAN EST FV400A.E4",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "FOTON",
    "model": "AUMAN EST FV400A.E4",
    "seats": "13.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.62",
    "maxTonnage": "13.62"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JB5-2",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JB5-2",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JB5-2",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JB5-2",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JB5-2-THACO",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JB5-2-THACO",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JE6-F",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JE6-F",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JE6-F",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JE6-F-THACO",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JE6-F-THACO",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "FOTON",
    "model": "BJ1043V8JE6-F-ĐL.TCR",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "FOTON",
    "model": "BJ1046V8JB6",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "FOTON",
    "model": "BJ1063VCJFA",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "FOTON",
    "model": "BJ1063VCJFA",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "FOTON",
    "model": "BJ1128VJPFG",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "FOTON",
    "model": "BJ1168VLPEG",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "FOTON",
    "model": "BJ1258VMPJE",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "FOTON",
    "model": "BJ1258VMPJE",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "FOTON",
    "model": "BJ1268VMPHN",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "FOTON",
    "model": "BJ1268VMPJP",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "FOTON",
    "model": "BJ1311VNPKJ",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "FOTON",
    "model": "BJ150T-4A",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "FOTON",
    "model": "BJ5203VKCJP-1",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "FOTON",
    "model": "BJ5243VMCGP",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "FOTON",
    "model": "BJ5249VHCJJ",
    "seats": "10.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.68",
    "maxTonnage": "10.68"
  },
  {
    "brand": "FOTON",
    "model": "BJ5249VHCJJ",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "FOTON",
    "model": "BJ5319VNCJF-1",
    "seats": "12.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.46",
    "maxTonnage": "12.46"
  },
  {
    "brand": "FOTON",
    "model": "GRATOUR-SX12",
    "seats": "0.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.83",
    "maxTonnage": "0.83"
  },
  {
    "brand": "FOTON",
    "model": "GRATOUR-SX12",
    "seats": "0.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.89",
    "maxTonnage": "0.89"
  },
  {
    "brand": "FOTON",
    "model": "GRATOUR-SX12",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "FOTON",
    "model": "GRATOUR-SX13",
    "seats": "0.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.82",
    "maxTonnage": "0.82"
  },
  {
    "brand": "FOTON",
    "model": "GRATOUR-SX13",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "FOTON",
    "model": "GRATOUR-SX13",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "FOTON",
    "model": "HT1250T",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "FOTON",
    "model": "HT1490T",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "FOTON",
    "model": "HT1950TĐ",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "FOTON",
    "model": "HT1950TĐ1",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1350",
    "seats": "12.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.92",
    "maxTonnage": "12.92"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1350",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1350",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1350",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400",
    "seats": "11.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.9",
    "maxTonnage": "11.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400B",
    "seats": "12.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.75",
    "maxTonnage": "12.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400B",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400B",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400B",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400B",
    "seats": "13.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.75",
    "maxTonnage": "13.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400B",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1400B-CS",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "12.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.45",
    "maxTonnage": "12.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "12.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.83",
    "maxTonnage": "12.83"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "13.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.02",
    "maxTonnage": "13.02"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "13.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.03",
    "maxTonnage": "13.03"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "13.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.25",
    "maxTonnage": "13.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "13.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.98",
    "maxTonnage": "13.98"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "14.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.56",
    "maxTonnage": "14.56"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "14.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.75",
    "maxTonnage": "14.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C1500-CS",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "7.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.98",
    "maxTonnage": "7.98"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.04",
    "maxTonnage": "8.04"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.08",
    "maxTonnage": "8.08"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.14",
    "maxTonnage": "8.14"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "9.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.11",
    "maxTonnage": "9.11"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "9.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.25",
    "maxTonnage": "9.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "7.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.47",
    "maxTonnage": "7.47"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "8.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.44",
    "maxTonnage": "8.44"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "9.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.05",
    "maxTonnage": "9.05"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160.E4-CS",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C160L-CS",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240B",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240C",
    "seats": "11.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.66",
    "maxTonnage": "11.66"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240C",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240C",
    "seats": "12.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.25",
    "maxTonnage": "12.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240C",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240C",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240C",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240C",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C240C",
    "seats": "9.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.45",
    "maxTonnage": "9.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "10.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.81",
    "maxTonnage": "10.81"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "10.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.95",
    "maxTonnage": "10.95"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "11.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.56",
    "maxTonnage": "11.56"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "13.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.56",
    "maxTonnage": "13.56"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "13.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.76",
    "maxTonnage": "13.76"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "15.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.05",
    "maxTonnage": "15.05"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "15.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.11",
    "maxTonnage": "15.11"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "15.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.19",
    "maxTonnage": "15.19"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "15.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.25",
    "maxTonnage": "15.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "15.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.46",
    "maxTonnage": "15.46"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "16.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.86",
    "maxTonnage": "16.86"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "16.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.88",
    "maxTonnage": "16.88"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "17.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.61",
    "maxTonnage": "17.61"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300B",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300C",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C300D",
    "seats": "16.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.3",
    "maxTonnage": "16.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "10.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.91",
    "maxTonnage": "10.91"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "11.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.45",
    "maxTonnage": "11.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "11.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.69",
    "maxTonnage": "11.69"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "14.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.21",
    "maxTonnage": "14.21"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "17.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.4",
    "maxTonnage": "17.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "18.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.25",
    "maxTonnage": "18.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "18.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.26",
    "maxTonnage": "18.26"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "18.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.51",
    "maxTonnage": "18.51"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "18.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.63",
    "maxTonnage": "18.63"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "18.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.81",
    "maxTonnage": "18.81"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "18.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.85",
    "maxTonnage": "18.85"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "18.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.88",
    "maxTonnage": "18.88"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "19.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.02",
    "maxTonnage": "19.02"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "19.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.24",
    "maxTonnage": "19.24"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "19.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.6",
    "maxTonnage": "19.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C34",
    "seats": "20.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.5",
    "maxTonnage": "20.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C340-CS",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C340-CS",
    "seats": "18.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.19",
    "maxTonnage": "18.19"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C340-CS",
    "seats": "18.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.4",
    "maxTonnage": "18.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C340-CS",
    "seats": "18.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.51",
    "maxTonnage": "18.51"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C340-CS",
    "seats": "19.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19",
    "maxTonnage": "19"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C340-CS",
    "seats": "19.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.11",
    "maxTonnage": "19.11"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN C340-XITEC",
    "seats": "17.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.76",
    "maxTonnage": "17.76"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240",
    "seats": "9.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.58",
    "maxTonnage": "9.58"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240B",
    "seats": "12.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.55",
    "maxTonnage": "12.55"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240B",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240GTL",
    "seats": "10.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.62",
    "maxTonnage": "10.62"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240GTL",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D240GTL",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D300",
    "seats": "17.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.7",
    "maxTonnage": "17.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D300A",
    "seats": "17.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.3",
    "maxTonnage": "17.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D300B",
    "seats": "17.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.3",
    "maxTonnage": "17.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D300GTL",
    "seats": "13.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.42",
    "maxTonnage": "13.42"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN D340",
    "seats": "20.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.15",
    "maxTonnage": "20.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN EST C300.E4-CS",
    "seats": "16.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.8",
    "maxTonnage": "16.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN ETX D240.E4-CS",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN ETX D240.E4-CS",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN ETX D300.E4",
    "seats": "16.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.6",
    "maxTonnage": "16.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN GTL D240.E4",
    "seats": "10.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.25",
    "maxTonnage": "10.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMAN GTL D300.E4",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMARK-XCX",
    "seats": "2.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.07",
    "maxTonnage": "2.07"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMARK198B-CS",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMARK500A -CS",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMARK500A-CS",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMARK500A-CS",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMARK700A-CS",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO AUMARK900A-CS",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "FOTON",
    "model": "THACO M4 350.E4-CS",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "FOTON",
    "model": "THACO M4 350.E4-CS",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO M4 350.E4-CS",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FOTON",
    "model": "THACO M4 600.E4-CS",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO M4 600.E4-CS",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "FOTON",
    "model": "THACO M4 600.E4-CS",
    "seats": "5.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.17",
    "maxTonnage": "5.17"
  },
  {
    "brand": "FOTON",
    "model": "THACO M4 650.E4-CS",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO M4 650.E4-CS",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLIIN345.E4-CS",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN 500B-CS",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN 500B-CS",
    "seats": "4.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.56",
    "maxTonnage": "4.56"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN 500B-CS",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN 900A-CS",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN198A-CS",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN198A-CS",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN198A-CS",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN198A-CS",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN345-CS",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN345-CS",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN345.E4-CS",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN345.E4-CS",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN345.E4-CS",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN345.E4-CS",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN345.E4-CS",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350-CS",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350-CS",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350-CS",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350-CS",
    "seats": "3.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.14",
    "maxTonnage": "3.14"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350-CS",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350-CS",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "3.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.36",
    "maxTonnage": "3.36"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN350.E4-CS",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN360-CS",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN360-CS",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN360-CS",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN360-CS",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500.E4-CS",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500.E4-CS",
    "seats": "3.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.58",
    "maxTonnage": "3.58"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500.E4-CS",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500.E4-CS",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500.E4-CS",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500.E4-CS",
    "seats": "5.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.34",
    "maxTonnage": "5.34"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500.E4-CS",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "3.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.37",
    "maxTonnage": "3.37"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "3.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.53",
    "maxTonnage": "3.53"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "3.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.96",
    "maxTonnage": "3.96"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.07",
    "maxTonnage": "4.07"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.22",
    "maxTonnage": "4.22"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN500B-CS-VTL",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700.E4-CS",
    "seats": "5.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.13",
    "maxTonnage": "5.13"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700.E4-CS",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700.E4-CS",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700.E4-CS",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "4.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.54",
    "maxTonnage": "4.54"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.06",
    "maxTonnage": "6.06"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.57",
    "maxTonnage": "6.57"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700B-CS",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "4.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.66",
    "maxTonnage": "4.66"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.02",
    "maxTonnage": "5.02"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.03",
    "maxTonnage": "5.03"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.04",
    "maxTonnage": "5.04"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.12",
    "maxTonnage": "5.12"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.16",
    "maxTonnage": "5.16"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.41",
    "maxTonnage": "5.41"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "6.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.82",
    "maxTonnage": "6.82"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "6.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.89",
    "maxTonnage": "6.89"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "7.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.03",
    "maxTonnage": "7.03"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN700C-CS",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "6.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.78",
    "maxTonnage": "6.78"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN720.E4-CS",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900A-CS",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900A-CS",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900A-CS",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900A-CS",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900A-CS",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900A-CS",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "7.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.76",
    "maxTonnage": "7.76"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "8.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.95",
    "maxTonnage": "8.95"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN900B-CS",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "9.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.06",
    "maxTonnage": "9.06"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "FOTON",
    "model": "THACO OLLIN950A-CS",
    "seats": "9.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.9",
    "maxTonnage": "9.9"
  },
  {
    "brand": "FOTON",
    "model": "THACO XITEC 6000L",
    "seats": "5.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.04",
    "maxTonnage": "5.04"
  },
  {
    "brand": "FOTON",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "FOTON",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "FOTON",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "FOTON",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FOTON",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "FOTON",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "FUSO",
    "model": "BE639JLMSDD1E",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "BE639JLMSDD1E\/THACO",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "BE639JLMSDD1EXE",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "BE639JLMSDD2",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "BE639JLMSDD2\/THACO",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "BE639JLMSDD5",
    "seats": "22 chỗ",
    "minSeat": "22",
    "maxSeat": "22",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "BE639JLMSDD5\/THACO",
    "seats": "22 chỗ",
    "minSeat": "22",
    "maxSeat": "22",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "CANTER 4.99",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "FUSO",
    "model": "CANTER 4.99",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "FUSO",
    "model": "CANTER 4.99",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "FUSO",
    "model": "CANTER 4.99",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "FUSO",
    "model": "CANTER 4.99",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "FUSO",
    "model": "CANTER 4.99",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "FUSO",
    "model": "CANTER 4.99",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "FUSO",
    "model": "CANTER 4.99",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.58",
    "maxTonnage": "1.58"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "2.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.02",
    "maxTonnage": "2.02"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE73",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "1.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.66",
    "maxTonnage": "1.66"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "2.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.08",
    "maxTonnage": "2.08"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.36",
    "maxTonnage": "3.36"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.47",
    "maxTonnage": "3.47"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE84",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.12",
    "maxTonnage": "3.12"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.31",
    "maxTonnage": "3.31"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.36",
    "maxTonnage": "3.36"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.55",
    "maxTonnage": "3.55"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "3.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.99",
    "maxTonnage": "3.99"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "FUSO",
    "model": "CANTER FE85",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "FUSO",
    "model": "CANTER4.99",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "FUSO",
    "model": "CANTER4.99",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "FUSO",
    "model": "CANTER4.99",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "FUSO",
    "model": "CANTER4.99",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "FUSO",
    "model": "CANTER6.5",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "FUSO",
    "model": "FA 1014R",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "FUSO",
    "model": "FA 1014R",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "FUSO",
    "model": "FA 1014R",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "FUSO",
    "model": "FA 1014R",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "FUSO",
    "model": "FA 1014R",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.58",
    "maxTonnage": "6.58"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.68",
    "maxTonnage": "6.68"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "FUSO",
    "model": "FI",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "FUSO",
    "model": "FI 1317R",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "FUSO",
    "model": "FI 1317R",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "FUSO",
    "model": "FI 1317R",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "FUSO",
    "model": "FI 1317R",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "FUSO",
    "model": "FI 1317R",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "FUSO",
    "model": "FI 1317R",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "FUSO",
    "model": "FI 1317R",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.14",
    "maxTonnage": "8.14"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.82",
    "maxTonnage": "8.82"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.85",
    "maxTonnage": "8.85"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER 16 SL",
    "seats": "8.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.85",
    "maxTonnage": "8.85"
  },
  {
    "brand": "FUSO",
    "model": "FIGHTER16",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "11.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.15",
    "maxTonnage": "11.15"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "12.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.15",
    "maxTonnage": "12.15"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "12.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.65",
    "maxTonnage": "12.65"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "FUSO",
    "model": "FJ",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "10.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.79",
    "maxTonnage": "10.79"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "10.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.9",
    "maxTonnage": "10.9"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "11.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.66",
    "maxTonnage": "11.66"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "12.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.05",
    "maxTonnage": "12.05"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.33",
    "maxTonnage": "14.33"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "14.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.95",
    "maxTonnage": "14.95"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "FUSO",
    "model": "FJY1WT2L",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "FUSO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "FUSO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "FUSO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "FUSO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "FUSO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "FUSO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "FUSO",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FUSO",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FERRARI",
    "model": "488",
    "seats": "GTB",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "500",
    "seats": "1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "500",
    "seats": "1.3-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "500",
    "seats": "ABARTH-595-ESSEESSE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "500",
    "seats": "ABARTH-595-ESSEESSE-1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Albea",
    "seats": "ELX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Albea",
    "seats": "HLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Bravo",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Doblo",
    "seats": "1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Siena",
    "seats": "1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Siena",
    "seats": "ED-1.3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Siena",
    "seats": "ELX-1.3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Siena",
    "seats": "HL-1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Siena",
    "seats": "HLX-1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FIAT",
    "model": "Tempra",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "E450",
    "seats": "SUPER-DUTY-6.8-V10",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "EcoSport",
    "seats": "AMBIENTE-1.5L-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "EcoSport",
    "seats": "AMBIENTE-1.5L-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "EcoSport",
    "seats": "ECOSPORT-TITAN-1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "EcoSport",
    "seats": "TITANIUM-1.0-ECOBOOST",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "EcoSport",
    "seats": "TITANIUM-1.5L-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "EcoSport",
    "seats": "TITANIUM-BLACK-1.5L-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "EcoSport",
    "seats": "TREND-1.5L-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "EcoSport",
    "seats": "TREND-1.5L-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Edge",
    "seats": "SPORT-3.7-V6-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Escape",
    "seats": "2.0L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Escape",
    "seats": "2.3-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Escape",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Escape",
    "seats": "LIMITED-3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Escape",
    "seats": "XLS-2.3L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Escape",
    "seats": "XLT-2.3L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Escape",
    "seats": "XLT-3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Escape",
    "seats": "XLT-3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "2.5L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "2.5L-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "2.5L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "2.6L-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "2.6L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "AMBIENTE-2.0-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "AMBIENTE-2.0-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "TITANIUM-2.0L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "TITANIUM-2.0L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "TITANIUM-2.2L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "TITANIUM-3.2L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Everest",
    "seats": "TREND-2.0L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Explorer",
    "seats": "3.5-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Explorer",
    "seats": "4.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Explorer",
    "seats": "4.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Explorer",
    "seats": "LIMITED-2.3-ECOBOOST",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Explorer",
    "seats": "LIMITED-2.3L-ECOBOOST",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F 150",
    "seats": "HARLEY-DAVIDSON-5.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F 150",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F 150",
    "seats": "PLATINUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F 150",
    "seats": "PLATINUM-3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F 150",
    "seats": "RAPTOR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F150",
    "seats": "HARLEY-DAVIDSON-5.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F150",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F150",
    "seats": "LIMITED-3.5-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F150",
    "seats": "PLATINUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F150",
    "seats": "RAPTOR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "F150",
    "seats": "RAPTOR-3.5-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Fiesta",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Fiesta",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Fiesta",
    "seats": "S-1.0-AT-ECOBOOST",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Fiesta",
    "seats": "S-1.0AT-ECOBOOST",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Fiesta",
    "seats": "S-1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Fiesta",
    "seats": "S-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Fiesta",
    "seats": "TITANIUM-1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Fiesta",
    "seats": "TREND-1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Flex",
    "seats": "LIMITED-ECOBOOST",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "2.0-AT-GHIA",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "S-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "SPORT-1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "TITANIUM-1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "TITANIUM-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "TREND-1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "TREND-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus",
    "seats": "TREND-1.6L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Focus C Max",
    "seats": "FORCUS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Imax",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Laser",
    "seats": "DELUXE-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Laser",
    "seats": "GHIA-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Laser",
    "seats": "GHIA-1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Laser",
    "seats": "LX-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Laser",
    "seats": "LXI-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mondeo",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mondeo",
    "seats": "2.3-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mondeo",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mustang",
    "seats": "2.3-ECOBOOST-CONVERTBILE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mustang",
    "seats": "2.3-ECOBOOST-FASTBACK",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mustang",
    "seats": "2.3-FASTBACK-PRE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mustang",
    "seats": "2.3L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mustang",
    "seats": "ECOBOOST",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mustang",
    "seats": "ECOBOOST-FASTBACK",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mustang",
    "seats": "GT-5.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Mustang",
    "seats": "GT-FASTBACK",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "2.0-BI-TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "2.0L-BI-TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "RAPTOR-2.0L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRACK-3.2AT-4X4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRACK-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRAK-2.0-BITURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRAK-2.0L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRAK-2.0L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRAK-2.2L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRAK-2.2L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRAK-2.5L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRAK-3.2-4X4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "WILDTRAK-3.2L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XL-2.2L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XL-2.5L-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XL-2.5L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XL-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XLS-2.2L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XLS-2.2L-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XLT-2.2L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XLT-2.2L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XLT-2.5L-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XLT-2.5L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XLT-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Ranger",
    "seats": "XLT-LIMITED-2.0L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Tourneo",
    "seats": "LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Tourneo",
    "seats": "LIMOUSINE-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Tourneo",
    "seats": "TITANIUM-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Tourneo",
    "seats": "TITANIUM-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Tourneo",
    "seats": "TREND-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "2.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "2.3L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "2.4L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "2.4L-9-SEATER",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "DCAR-X-LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "DCAR-X-PLUS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "DCARX-LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMOUSINE-DCAR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMOUSINE-LIFE-STYLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMOUSINE-LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMOUSINE-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMOUSINE-TC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMOUSINE-TRUNG-CAP",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LIMOUSINE-VIP",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "MID",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "STANDARD-MID",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "STANDARD-MID-LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "SVP",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "SVP-LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "SVPDIESEL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "VAN-2.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "VAN-2.4L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FORD",
    "model": "Transit",
    "seats": "VAN-2.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "FREIGHTLINER",
    "model": "Cascadia",
    "seats": "38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "38",
    "maxTonnage": "38"
  },
  {
    "brand": "FREIGHTLINER",
    "model": "Century",
    "seats": "15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "GIAI PHONG",
    "model": "BA2450GMC",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "GIAI PHONG",
    "model": "BA7D4DB.FAW-GM",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "GIAI PHONG",
    "model": "BA7D4DB.FAW-GM",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "GIAI PHONG",
    "model": "D4DB.FAW-GM",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DFSK.TL-MB.GMC",
    "seats": "0.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.86",
    "maxTonnage": "0.86"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DFSK.TL-MB.GMC",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DFSK.TL-MB.GMC",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DT1028",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DT1246.YJ",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DT2046.4X4",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DT2046.4X4-T",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DT4881.YJ",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DT4881.YJ-1",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DT5090.4X4",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "DT5090.4x4-1",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "FAW.E5T8-GMC",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "GIAI PHONG",
    "model": "FAW.E5T8-GMC",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "GIAI PHONG",
    "model": "FAW.E5T8-GMC",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "GIAI PHONG",
    "model": "FAW.E5T8-GMC",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "GIAI PHONG",
    "model": "FAW.E5T8-GMC",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "GIAI PHONG",
    "model": "FAW.E5T8-GMC",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "GIAI PHONG",
    "model": "FAW.E5T8-GMC",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "GIAI PHONG",
    "model": "FAW.E5T8-GMC",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "GIAI PHONG",
    "model": "KM8D4DB.FAW-GM",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0836.FAW",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0836.FAW",
    "seats": "0.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.81",
    "maxTonnage": "0.81"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0836.FAW-1",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0836.FAW-1",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0836.FAW-1",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0836.FAW-1",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0836.FAW-1",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0836.FAW-1",
    "seats": "0.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.81",
    "maxTonnage": "0.81"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0936.FAW",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0936.FAW",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0936.FAW",
    "seats": "0.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.77",
    "maxTonnage": "0.77"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0936.FAW",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T0936.FAW",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1028",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1029.YJ",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1036.YJ",
    "seats": "1.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.14",
    "maxTonnage": "1.14"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1036.YJ",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1246.YJ",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1246.YJ",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1246.YJ-1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1546.YJ",
    "seats": "1.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.36",
    "maxTonnage": "1.36"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1546.YJ",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1546.YJ-1",
    "seats": "1.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.24",
    "maxTonnage": "1.24"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1546.YJ-1",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1846.YJ",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T1846.YJ",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T2270.YJ",
    "seats": "1.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.78",
    "maxTonnage": "1.78"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T2270.YJ",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T2270.YJ",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T2570.YJ",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T2570.YJ",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T3070.YJ",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T3070.YJ",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T3575.YJ",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T4075.YJ",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T4075.YJ",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T4081.YJ",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T4081.YJ",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T4081.YJ",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T5090.YJ",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T5090.YJ",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6085.FAW-GM",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6085.FAW-GM",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6085.FAW-GM\/MPB",
    "seats": "5.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.37",
    "maxTonnage": "5.37"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6085.FAW-GM\/MPB",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6105.FAW-GM",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6105.FAW-GM",
    "seats": "6.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.36",
    "maxTonnage": "6.36"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6105.FAW-GM",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6105.FAW-GM",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6105.FAW-GM\/MB",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T6950.FAW-GM",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T7105.FAW-GM",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T7105.FAW-GM",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T7105.FAW-GM",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T7105.FAW-GM.TK1",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T7105.FAW-GM\/MB-16",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "GIAI PHONG",
    "model": "T7500.FAW-GM",
    "seats": "7.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.31",
    "maxTonnage": "7.31"
  },
  {
    "brand": "GIAI PHONG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "GIAI PHONG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "GIAI PHONG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "GIAI PHONG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "GIAI PHONG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "GIAI PHONG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "GMC",
    "model": "Savana",
    "seats": "EXPLORER-LIMITED-SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "GAZ",
    "model": "Gazele",
    "seats": "NEXT-VAN-2.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "30S",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "35S",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "45S",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "45SB",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "C45S",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "COUNTY K29S",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K29S",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K29S-E5",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K34S",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K35S-E5",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K38G-E5",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K41G",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K42G-H380W",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K43G",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K43G-H380",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K43G-Y380W",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K47S",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K47S-E4",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K47S-E5",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE K47S-H380",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE TK45G-330",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE TK45G-375",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE TK47S",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE U29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE U29-H245",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE U35",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "UNIVERSE U39",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAECO",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HINO",
    "model": "CB",
    "seats": "4.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.31",
    "maxTonnage": "4.31"
  },
  {
    "brand": "HINO",
    "model": "DROPSIDE",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "ER",
    "seats": "4.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.28",
    "maxTonnage": "4.28"
  },
  {
    "brand": "HINO",
    "model": "ER",
    "seats": "6.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.48",
    "maxTonnage": "6.48"
  },
  {
    "brand": "HINO",
    "model": "ER.TL.01",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "ER.TL.02",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "ERTLFC",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "FC",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "FC",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "FC",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC114SA",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC3JE",
    "seats": "3.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.29",
    "maxTonnage": "3.29"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "3.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.36",
    "maxTonnage": "3.36"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "3.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.57",
    "maxTonnage": "3.57"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "3.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.62",
    "maxTonnage": "3.62"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "3.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.74",
    "maxTonnage": "3.74"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "3.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.99",
    "maxTonnage": "3.99"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "4.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.18",
    "maxTonnage": "4.18"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "4.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.23",
    "maxTonnage": "4.23"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "4.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.28",
    "maxTonnage": "4.28"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "4.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.31",
    "maxTonnage": "4.31"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.21",
    "maxTonnage": "5.21"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.54",
    "maxTonnage": "5.54"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.66",
    "maxTonnage": "5.66"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.71",
    "maxTonnage": "5.71"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "5.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.74",
    "maxTonnage": "5.74"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "6.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.08",
    "maxTonnage": "6.08"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC3JEUA",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FC3JJUA",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HINO",
    "model": "FC3JL",
    "seats": "2.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.32",
    "maxTonnage": "2.32"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.01",
    "maxTonnage": "4.01"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.28",
    "maxTonnage": "4.28"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.63",
    "maxTonnage": "4.63"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.68",
    "maxTonnage": "4.68"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.73",
    "maxTonnage": "4.73"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.84",
    "maxTonnage": "4.84"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.34",
    "maxTonnage": "5.34"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.41",
    "maxTonnage": "5.41"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.59",
    "maxTonnage": "5.59"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC3JLUA",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "2.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.91",
    "maxTonnage": "2.91"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "3.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.36",
    "maxTonnage": "3.36"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "3.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.52",
    "maxTonnage": "3.52"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "3.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.86",
    "maxTonnage": "3.86"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.01",
    "maxTonnage": "4.01"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.62",
    "maxTonnage": "4.62"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "4.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.94",
    "maxTonnage": "4.94"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.17",
    "maxTonnage": "5.17"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.74",
    "maxTonnage": "5.74"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA - TL",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA-SAE3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA-TL",
    "seats": "2.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.91",
    "maxTonnage": "2.91"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA-TL",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA-TL",
    "seats": "5.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.46",
    "maxTonnage": "5.46"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA-TL",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESA-TL",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "2.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.98",
    "maxTonnage": "2.98"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.47",
    "maxTonnage": "3.47"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.62",
    "maxTonnage": "3.62"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.88",
    "maxTonnage": "3.88"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.89",
    "maxTonnage": "3.89"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.18",
    "maxTonnage": "4.18"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.21",
    "maxTonnage": "4.21"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.22",
    "maxTonnage": "4.22"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.36",
    "maxTonnage": "4.36"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.37",
    "maxTonnage": "4.37"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.46",
    "maxTonnage": "4.46"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.51",
    "maxTonnage": "4.51"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.64",
    "maxTonnage": "4.64"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.73",
    "maxTonnage": "4.73"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.06",
    "maxTonnage": "5.06"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.23",
    "maxTonnage": "5.23"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.28",
    "maxTonnage": "5.28"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.48",
    "maxTonnage": "5.48"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.58",
    "maxTonnage": "5.58"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.63",
    "maxTonnage": "5.63"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.64",
    "maxTonnage": "5.64"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.66",
    "maxTonnage": "5.66"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.67",
    "maxTonnage": "5.67"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.72",
    "maxTonnage": "5.72"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.74",
    "maxTonnage": "5.74"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.79",
    "maxTonnage": "5.79"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.81",
    "maxTonnage": "5.81"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.88",
    "maxTonnage": "5.88"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.11",
    "maxTonnage": "6.11"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JESW",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "2.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.97",
    "maxTonnage": "2.97"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "4.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.57",
    "maxTonnage": "4.57"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "4.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.62",
    "maxTonnage": "4.62"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.36",
    "maxTonnage": "5.36"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.74",
    "maxTonnage": "5.74"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.78",
    "maxTonnage": "5.78"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "HINO",
    "model": "FC9JETA",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JETC",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSA",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSA",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSA",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSA",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSA",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSA-TL",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "2.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.47",
    "maxTonnage": "2.47"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "2.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.48",
    "maxTonnage": "2.48"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "4.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.37",
    "maxTonnage": "4.37"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "4.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.56",
    "maxTonnage": "4.56"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.52",
    "maxTonnage": "5.52"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.79",
    "maxTonnage": "5.79"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JJSW-TL",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "4.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.37",
    "maxTonnage": "4.37"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.27",
    "maxTonnage": "5.27"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTA",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JJTC",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "5.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.27",
    "maxTonnage": "5.27"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "5.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.62",
    "maxTonnage": "5.62"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA TL6,4",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "5.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.23",
    "maxTonnage": "5.23"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "5.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.67",
    "maxTonnage": "5.67"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TL6,4",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "4.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.26",
    "maxTonnage": "4.26"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2-LAMBERET",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSA-TV2-TCE3",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.91",
    "maxTonnage": "3.91"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.37",
    "maxTonnage": "4.37"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.62",
    "maxTonnage": "4.62"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.71",
    "maxTonnage": "4.71"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.82",
    "maxTonnage": "4.82"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.84",
    "maxTonnage": "4.84"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "4.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.97",
    "maxTonnage": "4.97"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.44",
    "maxTonnage": "5.44"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.76",
    "maxTonnage": "5.76"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-TL",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-TL",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-TL",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-TL",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-TL",
    "seats": "5.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.44",
    "maxTonnage": "5.44"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-VD",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-VD",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-VT",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW-VT",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JLSW\/HIEPHOA",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.52",
    "maxTonnage": "4.52"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTA-VT",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.21",
    "maxTonnage": "5.21"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JLTC",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "3.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.38",
    "maxTonnage": "3.38"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "5.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.07",
    "maxTonnage": "5.07"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTA",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FC9JNTC-VT",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "HINO",
    "model": "FCCB",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HINO",
    "model": "FCDB",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FF3HMSA",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG",
    "seats": "7.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.11",
    "maxTonnage": "7.11"
  },
  {
    "brand": "HINO",
    "model": "FG",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "FG",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HINO",
    "model": "FG",
    "seats": "9.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.73",
    "maxTonnage": "9.73"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "6.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.38",
    "maxTonnage": "6.38"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "6.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.48",
    "maxTonnage": "6.48"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "6.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.72",
    "maxTonnage": "6.72"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "7.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.98",
    "maxTonnage": "7.98"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "8.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.36",
    "maxTonnage": "8.36"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HINO",
    "model": "FG1JJUB",
    "seats": "9.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.75",
    "maxTonnage": "9.75"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "4.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.16",
    "maxTonnage": "4.16"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "4.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.78",
    "maxTonnage": "4.78"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.16",
    "maxTonnage": "6.16"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.62",
    "maxTonnage": "6.62"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.17",
    "maxTonnage": "8.17"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.69",
    "maxTonnage": "8.69"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "8.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.95",
    "maxTonnage": "8.95"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB",
    "seats": "9.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.73",
    "maxTonnage": "9.73"
  },
  {
    "brand": "HINO",
    "model": "FG1JPUB-LAMBERET",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-A",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-A",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-A",
    "seats": "8.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.04",
    "maxTonnage": "8.04"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-A",
    "seats": "8.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.14",
    "maxTonnage": "8.14"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-A",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-A",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "7.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.59",
    "maxTonnage": "7.59"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JJ7A-B",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "3.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.79",
    "maxTonnage": "3.79"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "4.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.64",
    "maxTonnage": "4.64"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "4.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.72",
    "maxTonnage": "4.72"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "4.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.96",
    "maxTonnage": "4.96"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.04",
    "maxTonnage": "5.04"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.49",
    "maxTonnage": "5.49"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.53",
    "maxTonnage": "5.53"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.59",
    "maxTonnage": "5.59"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.82",
    "maxTonnage": "5.82"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.94",
    "maxTonnage": "5.94"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.16",
    "maxTonnage": "6.16"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.21",
    "maxTonnage": "6.21"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.64",
    "maxTonnage": "6.64"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.66",
    "maxTonnage": "6.66"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.71",
    "maxTonnage": "6.71"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.83",
    "maxTonnage": "6.83"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.04",
    "maxTonnage": "7.04"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.11",
    "maxTonnage": "7.11"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.14",
    "maxTonnage": "7.14"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.28",
    "maxTonnage": "7.28"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.32",
    "maxTonnage": "7.32"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.37",
    "maxTonnage": "7.37"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.63",
    "maxTonnage": "7.63"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.77",
    "maxTonnage": "7.77"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "7.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.88",
    "maxTonnage": "7.88"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.12",
    "maxTonnage": "8.12"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.14",
    "maxTonnage": "8.14"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.28",
    "maxTonnage": "8.28"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.29",
    "maxTonnage": "8.29"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.51",
    "maxTonnage": "8.51"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.56",
    "maxTonnage": "8.56"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.58",
    "maxTonnage": "8.58"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.75",
    "maxTonnage": "8.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB",
    "seats": "9.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.25",
    "maxTonnage": "9.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB-CONECO",
    "seats": "7.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.24",
    "maxTonnage": "7.24"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB-TL",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB-TL",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB-TL",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSB-TL",
    "seats": "8.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.51",
    "maxTonnage": "8.51"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSC",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSC",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSC",
    "seats": "8.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.51",
    "maxTonnage": "8.51"
  },
  {
    "brand": "HINO",
    "model": "FG8JJSC",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "6.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.84",
    "maxTonnage": "6.84"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "6.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.92",
    "maxTonnage": "6.92"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "7.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.83",
    "maxTonnage": "7.83"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.22",
    "maxTonnage": "8.22"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.67",
    "maxTonnage": "8.67"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "8.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.75",
    "maxTonnage": "8.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-E",
    "seats": "9.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.01",
    "maxTonnage": "9.01"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "6.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.06",
    "maxTonnage": "6.06"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JP7A-F",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.43",
    "maxTonnage": "6.43"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "8.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.56",
    "maxTonnage": "8.56"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB TL9,4",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB TL9,4",
    "seats": "6.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.88",
    "maxTonnage": "6.88"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB TL9,4",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB TL9,4",
    "seats": "8.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.85",
    "maxTonnage": "8.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "6.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.64",
    "maxTonnage": "6.64"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TL9,4-LAMBERET",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "5.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.53",
    "maxTonnage": "5.53"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.52",
    "maxTonnage": "6.52"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.57",
    "maxTonnage": "6.57"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.76",
    "maxTonnage": "6.76"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.77",
    "maxTonnage": "6.77"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.41",
    "maxTonnage": "7.41"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.43",
    "maxTonnage": "7.43"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.46",
    "maxTonnage": "7.46"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.79",
    "maxTonnage": "7.79"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "7.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.96",
    "maxTonnage": "7.96"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.31",
    "maxTonnage": "8.31"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.77",
    "maxTonnage": "8.77"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1-TCE5",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSB-TV1-VD",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSC-VD",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSH",
    "seats": "8.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.75",
    "maxTonnage": "8.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.38",
    "maxTonnage": "6.38"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.42",
    "maxTonnage": "6.42"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.58",
    "maxTonnage": "6.58"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.44",
    "maxTonnage": "7.44"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.67",
    "maxTonnage": "7.67"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.87",
    "maxTonnage": "7.87"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-TL",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-TL",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-TL",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-TL",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-TL",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-TL",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-TL",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-TL",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-VD",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSL-VT",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSN-VT",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.66",
    "maxTonnage": "6.66"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.86",
    "maxTonnage": "6.86"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.02",
    "maxTonnage": "7.02"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU-TL",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU-VD",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU-VD",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JPSU-VD",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A",
    "seats": "6.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.53",
    "maxTonnage": "6.53"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "5.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.93",
    "maxTonnage": "5.93"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.28",
    "maxTonnage": "6.28"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.83",
    "maxTonnage": "6.83"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.27",
    "maxTonnage": "7.27"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.58",
    "maxTonnage": "7.58"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.66",
    "maxTonnage": "7.66"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-G",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "7.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.54",
    "maxTonnage": "7.54"
  },
  {
    "brand": "HINO",
    "model": "FG8JT7A-H",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HINO",
    "model": "FGCB",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HINO",
    "model": "FGDB",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HINO",
    "model": "FL",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "12.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.52",
    "maxTonnage": "12.52"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "13.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.64",
    "maxTonnage": "13.64"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA-RGV",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA.MB",
    "seats": "10.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.91",
    "maxTonnage": "10.91"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA.MB",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FL1JTUA.MB",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A",
    "seats": "13.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.55",
    "maxTonnage": "13.55"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A",
    "seats": "15.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.25",
    "maxTonnage": "15.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "12.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.65",
    "maxTonnage": "12.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "13.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.33",
    "maxTonnage": "13.33"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.06",
    "maxTonnage": "14.06"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.16",
    "maxTonnage": "14.16"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.43",
    "maxTonnage": "14.43"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.82",
    "maxTonnage": "14.82"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-J",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "13.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.15",
    "maxTonnage": "13.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "14.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.04",
    "maxTonnage": "14.04"
  },
  {
    "brand": "HINO",
    "model": "FL8JT7A-K",
    "seats": "14.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.06",
    "maxTonnage": "14.06"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "13.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.68",
    "maxTonnage": "13.68"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.53",
    "maxTonnage": "14.53"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.75",
    "maxTonnage": "14.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "14.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.95",
    "maxTonnage": "14.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "15.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.05",
    "maxTonnage": "15.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "15.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.25",
    "maxTonnage": "15.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "16.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.3",
    "maxTonnage": "16.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "13.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.15",
    "maxTonnage": "13.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "14.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.06",
    "maxTonnage": "14.06"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "14.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.31",
    "maxTonnage": "14.31"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "14.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.82",
    "maxTonnage": "14.82"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.15",
    "maxTonnage": "15.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.35",
    "maxTonnage": "15.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "15.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.9",
    "maxTonnage": "15.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6X2",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "11.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.45",
    "maxTonnage": "11.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "11.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.55",
    "maxTonnage": "11.55"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "11.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.78",
    "maxTonnage": "11.78"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "11.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.85",
    "maxTonnage": "11.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "11.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.9",
    "maxTonnage": "11.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "11.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.99",
    "maxTonnage": "11.99"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.07",
    "maxTonnage": "13.07"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.18",
    "maxTonnage": "13.18"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.47",
    "maxTonnage": "13.47"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.53",
    "maxTonnage": "13.53"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.68",
    "maxTonnage": "13.68"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.69",
    "maxTonnage": "13.69"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.01",
    "maxTonnage": "14.01"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.06",
    "maxTonnage": "14.06"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.07",
    "maxTonnage": "14.07"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.28",
    "maxTonnage": "14.28"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.29",
    "maxTonnage": "14.29"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.31",
    "maxTonnage": "14.31"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.35",
    "maxTonnage": "14.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.43",
    "maxTonnage": "14.43"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.65",
    "maxTonnage": "14.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.66",
    "maxTonnage": "14.66"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.82",
    "maxTonnage": "14.82"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.91",
    "maxTonnage": "14.91"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "14.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.95",
    "maxTonnage": "14.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.05",
    "maxTonnage": "15.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.09 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.09",
    "maxTonnage": "15.09"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.15",
    "maxTonnage": "15.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.25",
    "maxTonnage": "15.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.35",
    "maxTonnage": "15.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.55",
    "maxTonnage": "15.55"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.62",
    "maxTonnage": "15.62"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.68",
    "maxTonnage": "15.68"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.72",
    "maxTonnage": "15.72"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.75",
    "maxTonnage": "15.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.9",
    "maxTonnage": "15.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "15.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.95",
    "maxTonnage": "15.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "16.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.25",
    "maxTonnage": "16.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "8.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.32",
    "maxTonnage": "8.32"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-LAMBERET",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-TL",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-TL",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-TL",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-TL",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-TL",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-VD",
    "seats": "13.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.75",
    "maxTonnage": "13.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-VD",
    "seats": "14.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.31",
    "maxTonnage": "14.31"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-VD",
    "seats": "15.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.05",
    "maxTonnage": "15.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2-VD",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA 6x2\/CP-XTM 12.3",
    "seats": "14.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.76",
    "maxTonnage": "14.76"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA6X2",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA6x2",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSA6x2",
    "seats": "15.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.75",
    "maxTonnage": "15.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG",
    "seats": "15.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.85",
    "maxTonnage": "15.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6X2",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "14.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.06",
    "maxTonnage": "14.06"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "15.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.05",
    "maxTonnage": "15.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSG 6x2",
    "seats": "15.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.9",
    "maxTonnage": "15.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.35",
    "maxTonnage": "14.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "10.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.65",
    "maxTonnage": "10.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "11.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.25",
    "maxTonnage": "11.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "11.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.45",
    "maxTonnage": "11.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.35",
    "maxTonnage": "14.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "14.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.95",
    "maxTonnage": "14.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "15.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.15",
    "maxTonnage": "15.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2-VD",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6X2-VD",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.04",
    "maxTonnage": "11.04"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.35",
    "maxTonnage": "11.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.75",
    "maxTonnage": "11.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "11.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.95",
    "maxTonnage": "11.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.25",
    "maxTonnage": "12.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.35",
    "maxTonnage": "12.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.65",
    "maxTonnage": "12.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "12.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.94",
    "maxTonnage": "12.94"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.25",
    "maxTonnage": "13.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.55",
    "maxTonnage": "13.55"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.35",
    "maxTonnage": "14.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.55",
    "maxTonnage": "14.55"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.65",
    "maxTonnage": "14.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.75",
    "maxTonnage": "14.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "14.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.95",
    "maxTonnage": "14.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.05",
    "maxTonnage": "15.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.15",
    "maxTonnage": "15.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.25",
    "maxTonnage": "15.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.26",
    "maxTonnage": "15.26"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.35",
    "maxTonnage": "15.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.55",
    "maxTonnage": "15.55"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2",
    "seats": "15.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.75",
    "maxTonnage": "15.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2-VD",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2-VD",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2-VT",
    "seats": "14.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.54",
    "maxTonnage": "14.54"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL 6x2\/VL-TACN35",
    "seats": "10.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.68",
    "maxTonnage": "10.68"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "10.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.65",
    "maxTonnage": "10.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "14.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.35",
    "maxTonnage": "14.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "14.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.55",
    "maxTonnage": "14.55"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "15.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.75",
    "maxTonnage": "15.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "8.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.59",
    "maxTonnage": "8.59"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2",
    "seats": "9.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.55",
    "maxTonnage": "9.55"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2-LAMBERET",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2-TMB-TV26",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2-VD",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL 6x2\/VIETDANG-MB",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL-TL6x2",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL6x2",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL6x2",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JTSL6x2",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "12.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.05",
    "maxTonnage": "12.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "12.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.15",
    "maxTonnage": "12.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "12.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.75",
    "maxTonnage": "12.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.15",
    "maxTonnage": "13.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.35",
    "maxTonnage": "14.35"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.78",
    "maxTonnage": "14.78"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "14.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.95",
    "maxTonnage": "14.95"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-M",
    "seats": "15.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.05",
    "maxTonnage": "15.05"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-N",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-N",
    "seats": "12.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.75",
    "maxTonnage": "12.75"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-N",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-N",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-N",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HINO",
    "model": "FL8JW7A-N",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FM",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "HINO",
    "model": "FM",
    "seats": "12.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.71",
    "maxTonnage": "12.71"
  },
  {
    "brand": "HINO",
    "model": "FM",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "10.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.87",
    "maxTonnage": "10.87"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "12.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.71",
    "maxTonnage": "12.71"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "13.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.43",
    "maxTonnage": "13.43"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "HINO",
    "model": "FM1JNUA",
    "seats": "6.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.81",
    "maxTonnage": "6.81"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSD",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSD",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSD",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSD",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSD 6x4",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSD 6x4",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSD 6x4",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSM",
    "seats": "13.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.36",
    "maxTonnage": "13.36"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSM",
    "seats": "13.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.58",
    "maxTonnage": "13.58"
  },
  {
    "brand": "HINO",
    "model": "FM2PKSM 6x4",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FM2PKUM",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A",
    "seats": "10.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.35",
    "maxTonnage": "10.35"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-Q",
    "seats": "10.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.55",
    "maxTonnage": "10.55"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-Q",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-Q",
    "seats": "13.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.69",
    "maxTonnage": "13.69"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-Q",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-Q",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-Q",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "10.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.05",
    "maxTonnage": "10.05"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "10.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.65",
    "maxTonnage": "10.65"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "HINO",
    "model": "FM8JN7A-R",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "10.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.04",
    "maxTonnage": "10.04"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "10.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.45",
    "maxTonnage": "10.45"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "10.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.7",
    "maxTonnage": "10.7"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "11.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.3",
    "maxTonnage": "11.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "11.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.85",
    "maxTonnage": "11.85"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA",
    "seats": "9.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.57",
    "maxTonnage": "9.57"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "10.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.35",
    "maxTonnage": "10.35"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "11.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.11",
    "maxTonnage": "11.11"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "11.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.33",
    "maxTonnage": "11.33"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "13.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.21",
    "maxTonnage": "13.21"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "13.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.43",
    "maxTonnage": "13.43"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "13.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.69",
    "maxTonnage": "13.69"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "13.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.76",
    "maxTonnage": "13.76"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "13.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.84",
    "maxTonnage": "13.84"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6X4\/CONECO-TTL",
    "seats": "14.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.66",
    "maxTonnage": "14.66"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.35",
    "maxTonnage": "10.35"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.55",
    "maxTonnage": "10.55"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.68",
    "maxTonnage": "10.68"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.71",
    "maxTonnage": "10.71"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "10.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.86",
    "maxTonnage": "10.86"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "11.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.25",
    "maxTonnage": "11.25"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "11.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.34",
    "maxTonnage": "11.34"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "11.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.47",
    "maxTonnage": "11.47"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "12.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.11",
    "maxTonnage": "12.11"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "12.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.41",
    "maxTonnage": "12.41"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "12.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.42",
    "maxTonnage": "12.42"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "12.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.54",
    "maxTonnage": "12.54"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "12.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.55",
    "maxTonnage": "12.55"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.03",
    "maxTonnage": "13.03"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.11",
    "maxTonnage": "13.11"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.21",
    "maxTonnage": "13.21"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.25",
    "maxTonnage": "13.25"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.37",
    "maxTonnage": "13.37"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.44",
    "maxTonnage": "13.44"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.48",
    "maxTonnage": "13.48"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.55",
    "maxTonnage": "13.55"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.69",
    "maxTonnage": "13.69"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.77",
    "maxTonnage": "13.77"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.87",
    "maxTonnage": "13.87"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.92",
    "maxTonnage": "13.92"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "13.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.97",
    "maxTonnage": "13.97"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.06",
    "maxTonnage": "14.06"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.24",
    "maxTonnage": "14.24"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.36",
    "maxTonnage": "14.36"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.38",
    "maxTonnage": "14.38"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.54",
    "maxTonnage": "14.54"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "14.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.95",
    "maxTonnage": "14.95"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "15.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.17",
    "maxTonnage": "15.17"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "15.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.35",
    "maxTonnage": "15.35"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "16.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.3",
    "maxTonnage": "16.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "9.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.75",
    "maxTonnage": "9.75"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "9.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.9",
    "maxTonnage": "9.9"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4",
    "seats": "9.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.99",
    "maxTonnage": "9.99"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4\/VL-TTL11",
    "seats": "14.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.52",
    "maxTonnage": "14.52"
  },
  {
    "brand": "HINO",
    "model": "FM8JNSA 6x4\/ĐPT-XT14",
    "seats": "14.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.55",
    "maxTonnage": "14.55"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A",
    "seats": "12.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.25",
    "maxTonnage": "12.25"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "12.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.55",
    "maxTonnage": "12.55"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "14.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.65",
    "maxTonnage": "14.65"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "8.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.94",
    "maxTonnage": "8.94"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "9.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.75",
    "maxTonnage": "9.75"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "HINO",
    "model": "FM8JW7A-U",
    "seats": "9.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.99",
    "maxTonnage": "9.99"
  },
  {
    "brand": "HINO",
    "model": "FT1JGU",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "FY2PYUA-MPZ",
    "seats": "10.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.45",
    "maxTonnage": "10.45"
  },
  {
    "brand": "HINO",
    "model": "GD1JLUA",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HINO",
    "model": "HC",
    "seats": "4.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.66",
    "maxTonnage": "4.66"
  },
  {
    "brand": "HINO",
    "model": "HH",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "HINO",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "HINO",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HINO",
    "model": "HINO FL8JTSA 6x2",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HINO",
    "model": "HNTH",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HINO",
    "model": "HOOKLIFT",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HINO",
    "model": "HP",
    "seats": "5.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.21",
    "maxTonnage": "5.21"
  },
  {
    "brand": "HINO",
    "model": "HPFC112",
    "seats": "4.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.31",
    "maxTonnage": "4.31"
  },
  {
    "brand": "HINO",
    "model": "KM",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "KMFC114SA",
    "seats": "4.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.76",
    "maxTonnage": "4.76"
  },
  {
    "brand": "HINO",
    "model": "KMFF3HMSA",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "MBTLFF",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "PN",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HINO",
    "model": "TB",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "TBTLFC",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "TC",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "TC",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "TC",
    "seats": "4.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.68",
    "maxTonnage": "4.68"
  },
  {
    "brand": "HINO",
    "model": "TC",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HINO",
    "model": "TC",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HINO",
    "model": "TC114",
    "seats": "3.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.73",
    "maxTonnage": "3.73"
  },
  {
    "brand": "HINO",
    "model": "TCTL02",
    "seats": "5.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.94",
    "maxTonnage": "5.94"
  },
  {
    "brand": "HINO",
    "model": "TH",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "THTL01",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HINO",
    "model": "TK",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "TK",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "TK",
    "seats": "6.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.62",
    "maxTonnage": "6.62"
  },
  {
    "brand": "HINO",
    "model": "TKTLFF",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HINO",
    "model": "TĐ",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HINO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "HINO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "HINO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "HINO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "HINO",
    "model": "WU302L",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "HINO",
    "model": "WU302L",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HINO",
    "model": "WU302L-HKMLHD3",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "HINO",
    "model": "WU302L-HKMLHD3",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HINO",
    "model": "WU302L-HKMLHD3",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HINO",
    "model": "WU302L-HKMLHD3",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HINO",
    "model": "WU302L-HKMLHD3",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "HINO",
    "model": "WU302L-HKMLHD3",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HINO",
    "model": "WU302L-HKMLHD3",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "HINO",
    "model": "WU302L-HKMLHD3",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "0.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.72",
    "maxTonnage": "0.72"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "1.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.62",
    "maxTonnage": "1.62"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "1.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.84",
    "maxTonnage": "1.84"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "2.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.22",
    "maxTonnage": "2.22"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "WU342L",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "WU342L -NKMRHD3",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HBMMB3",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HBMMB3",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HBMMB3",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HBMMB3",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HBMMB3",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HBMMB3-LAMBERET",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HBMMB3\/TL",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HBMMB3\/TL",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "2.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.63",
    "maxTonnage": "2.63"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "2.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.99",
    "maxTonnage": "2.99"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMRHD3",
    "seats": "4.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.47",
    "maxTonnage": "4.47"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "2.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.93",
    "maxTonnage": "2.93"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "WU342L-HKMTJD3",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.43",
    "maxTonnage": "2.43"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.52",
    "maxTonnage": "2.52"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.87",
    "maxTonnage": "2.87"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "2.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.99",
    "maxTonnage": "2.99"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.39",
    "maxTonnage": "3.39"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.47",
    "maxTonnage": "3.47"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.55",
    "maxTonnage": "3.55"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "3.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.98",
    "maxTonnage": "3.98"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3-VT",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3-VT",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMRHD3\/VD.TK",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.52",
    "maxTonnage": "2.52"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.73",
    "maxTonnage": "2.73"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.86",
    "maxTonnage": "2.86"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.94",
    "maxTonnage": "2.94"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "2.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.97",
    "maxTonnage": "2.97"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.63",
    "maxTonnage": "3.63"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.07",
    "maxTonnage": "4.07"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.24",
    "maxTonnage": "4.24"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.52",
    "maxTonnage": "4.52"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-NKMTJD3-VT",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-TL",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "HINO",
    "model": "WU342L-TL",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HINO",
    "model": "WU342L-TL",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "HINO",
    "model": "WU342L-TL",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HINO",
    "model": "WU342L-TL",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "HINO",
    "model": "WU342L-TL",
    "seats": "2.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.17",
    "maxTonnage": "2.17"
  },
  {
    "brand": "HINO",
    "model": "WU342L-TL",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "HINO",
    "model": "WU352L",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HINO",
    "model": "WU352L",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "WU352L",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "WU352L",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "WU352L",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HINO",
    "model": "WU352L",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "WU352L",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMQHD3",
    "seats": "3.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.55",
    "maxTonnage": "3.55"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.16",
    "maxTonnage": "3.16"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.52",
    "maxTonnage": "3.52"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "WU352L-NKMRJD3-VT",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "2.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.91",
    "maxTonnage": "2.91"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.26",
    "maxTonnage": "3.26"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.93",
    "maxTonnage": "3.93"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "3.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.99",
    "maxTonnage": "3.99"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "WU422L",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "WU422L-LAMBERET",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TBE2",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.47",
    "maxTonnage": "3.47"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.78",
    "maxTonnage": "3.78"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL",
    "seats": "3.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.93",
    "maxTonnage": "3.93"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TL-TCE2",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TLS",
    "seats": "1.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.77",
    "maxTonnage": "1.77"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TLS",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TLS",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TLS",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TLS",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "WU422L-TLS",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HINO",
    "model": "WU432L",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "WU432L",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "WU432L",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "WU432L",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3",
    "seats": "4.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.31",
    "maxTonnage": "4.31"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3-TL",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3-TL",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3-TLS",
    "seats": "4.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.39",
    "maxTonnage": "4.39"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3-TLS",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "WU432L-HKFTB3-TLS",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "WU432L-NKMTJD3",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "XTH10",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HINO",
    "model": "XZU342L",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "XZU342L",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU342L",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "XZU342L",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "XZU342L",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "XZU342L",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "XZU342L",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMRKD3",
    "seats": "2.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.07",
    "maxTonnage": "2.07"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMRKD3",
    "seats": "2.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.11",
    "maxTonnage": "2.11"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMRKD3",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMRKD3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMRKD3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMRKD3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMRKD3",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMRKD3",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "3.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.99",
    "maxTonnage": "3.99"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "HINO",
    "model": "XZU342L-HKMTKD3",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HINO",
    "model": "XZU352L",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "XZU352L-HKMRKD8",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HINO",
    "model": "XZU650L",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.22",
    "maxTonnage": "1.22"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.28",
    "maxTonnage": "1.28"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.64",
    "maxTonnage": "1.64"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3\/TL",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3\/TL",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMK3\/TL",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.47",
    "maxTonnage": "1.47"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HINO",
    "model": "XZU650L-HBMMN3",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "2.09 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.09",
    "maxTonnage": "2.09"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "XZU720L",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.05",
    "maxTonnage": "2.05"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.53",
    "maxTonnage": "2.53"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.09 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.09",
    "maxTonnage": "3.09"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.36",
    "maxTonnage": "3.36"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.55",
    "maxTonnage": "3.55"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.72",
    "maxTonnage": "3.72"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.73",
    "maxTonnage": "3.73"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3\/TL",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3\/TL",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3\/TL",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3\/TL",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRL3\/TL",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.26",
    "maxTonnage": "3.26"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "XZU720L-HKFRP3",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "HINO",
    "model": "XZU730L",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU730L",
    "seats": "3.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.52",
    "maxTonnage": "3.52"
  },
  {
    "brand": "HINO",
    "model": "XZU730L",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HINO",
    "model": "XZU730L",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "XZU730L",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "XZU730L",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "XZU730L",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "XZU730L",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.52",
    "maxTonnage": "3.52"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.55",
    "maxTonnage": "3.55"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.49",
    "maxTonnage": "4.49"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.71",
    "maxTonnage": "4.71"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3\/TL",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3\/TL",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTL3\/TL",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.71",
    "maxTonnage": "3.71"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.92",
    "maxTonnage": "3.92"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "HINO",
    "model": "XZU730L-HKFTP3",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HINO",
    "model": "Xe chở tiền",
    "seats": "Xe chở tiền",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HINO",
    "model": "ZS1EPVA",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "HINO",
    "model": "ĐLTLFF",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HINO",
    "model": "ĐLTLFP",
    "seats": "6.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.64",
    "maxTonnage": "6.64"
  },
  {
    "brand": "HOA_MAI",
    "model": "2,5 tấn",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1000",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1000A",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1250",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1250A",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1500",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1500A",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1600A",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1600A",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1600A-E2TL",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1800",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1800A",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1800B",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1800TK",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1800TL",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD1900",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD2000A",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD2000TL",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD2000TL",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD2350",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD2350A",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD2500",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD2500A",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3000",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3000A",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3000B",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3200A",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3250",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3250.4x4",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3450",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3450A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3450A-MP",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3450B",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3450MP",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3480A",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3600",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3600MP",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD3900A",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4000A",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4450A",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4500",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4500.4x4",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4500A.4x4",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4650",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4650.4x4",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4650A",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4850A",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4950",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4950A",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD4950MP",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5000",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5000A",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5000A-MP",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5000B",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5000C",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5000MP",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5250A",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5500A",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD550A",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5550A.4X4",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD5850A",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD6000A",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD6450A",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD6450B",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD6500",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD680A",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD680A-TL",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD700",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD7000",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD720A",
    "seats": "0.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.72",
    "maxTonnage": "0.72"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD7350A",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD7600A",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD7800A",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD8000A",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD900A-TL",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD990",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD990A",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD990B",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD990TK",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HOA_MAI",
    "model": "HD990TL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HOA_MAI",
    "model": "HG990A",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HOA_MAI",
    "model": "HM990TL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HOA_MAI",
    "model": "SHIYANDF 4.85",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "HOA_MAI",
    "model": "T.3T",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "HOA_MAI",
    "model": "T.3T",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ 3T (4x4)",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ2,5TA",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ2TA",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ2TA-1",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ3T",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ3T (4x4)-1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ3TA",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ3TB",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ3TB1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ3Tc",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ3Tc-1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ4,5T",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ4,5TA",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "TĐ4,5TB",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "HOA_MAI",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "HOA_MAI",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "HOA_MAI",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HOA_MAI",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "HOA_MAI",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "HOANG TRA",
    "model": "34-02",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "42",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "48",
    "seats": "48 chỗ",
    "minSeat": "48",
    "maxSeat": "48",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "51",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA 6110",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA 6110-3K1",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA-K28",
    "seats": "28 chỗ",
    "minSeat": "28",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA1031K4.SX",
    "seats": "0.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.97",
    "maxTonnage": "0.97"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA1031K4.SX",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA1031K4.SX",
    "seats": "1.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.11",
    "maxTonnage": "1.11"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA1041K2L2.SX",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA1041K2L2.SX",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA1041K2L2.SX",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA3041K5L",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA6110\/125-3K1",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "CA6710D.BUS40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "CAK6710D",
    "seats": "28 chỗ",
    "minSeat": "28",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT1250T",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT1250T",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT1840T",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT1840T",
    "seats": "1.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.84",
    "maxTonnage": "1.84"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT1990T",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT1990T",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT7900SX",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT7900SX",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT860T",
    "seats": "0.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.25",
    "maxTonnage": "0.25"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT860T",
    "seats": "0.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.34",
    "maxTonnage": "0.34"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT860T",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT860T",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT860T",
    "seats": "0.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.86",
    "maxTonnage": "0.86"
  },
  {
    "brand": "HOANG TRA",
    "model": "FHT980B",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "HOANG TRA",
    "model": "HD-K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "HD72-K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "HYUNDAI D4AL",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "NJ4102ZQ",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "TOYOTA 1RZ",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "TOYOTA 2RZ",
    "seats": "28 chỗ",
    "minSeat": "28",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "HOANG TRA",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "HOANG TRA",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "HOANG TRA",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HOANG TRA",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "HOANG TRA",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "HOANG TRA",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "YC6701C1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "YC6701C1.BUS40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "YC6701C6",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "YC6701C6.BUS40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOANG TRA",
    "model": "YC6701C6.BUS40-01",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HOWO",
    "model": "CKGT\/WD615",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "HOWO",
    "model": "CONECO\/MC07.34-30",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "HOWO",
    "model": "CONECO\/MC07.34-30",
    "seats": "16.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.49",
    "maxTonnage": "16.49"
  },
  {
    "brand": "HOWO",
    "model": "CSC5164GSS3\/CTĐ",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "HOWO",
    "model": "CSC5164GSS3\/DKV",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "HOWO",
    "model": "DKV\/YC6JA18050",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HOWO",
    "model": "DNVN\/MC07.31-30",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "HOWO",
    "model": "DONGNAM-MC07.34",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "HOWO",
    "model": "HH\/MC07.34-30",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "HOWO",
    "model": "HUH\/MC07.34-30",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "HOWO",
    "model": "TTCM\/MC07.34-30",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "HOWO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "HOWO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "HOWO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "HOWO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HOWO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "HOWO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "HOWO",
    "model": "VIMID\/MC07.34",
    "seats": "17.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.98",
    "maxTonnage": "17.98"
  },
  {
    "brand": "HOWO",
    "model": "VL\/MC07.34-30",
    "seats": "16.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.01",
    "maxTonnage": "16.01"
  },
  {
    "brand": "HOWO",
    "model": "VL\/WD615.62",
    "seats": "11.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.84",
    "maxTonnage": "11.84"
  },
  {
    "brand": "HOWO",
    "model": "VL\/WD615.62",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "HOWO",
    "model": "VL\/WD615.62",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1257M4341W\/CONECO",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1257M4341W\/VL",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1257M464GC1\/CONECO",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1257M464GC1\/CTĐ",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1257M464GC1\/CTĐ",
    "seats": "9.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.58",
    "maxTonnage": "9.58"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1257S4341W\/KIENMINH",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1257S4341W\/KIENMINH.XER",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1317N3867A\/VL",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1317N4667W\/TTCM",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1317N466GC1\/CDMB",
    "seats": "14.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.66",
    "maxTonnage": "14.66"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1317N466GC1\/DNVN-TMB15",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1317N466GC1\/KPI",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1317N466GC1\/TTCM",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1387N30B1W\/TTCM",
    "seats": "20.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.01",
    "maxTonnage": "20.01"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1387N30B1W\/TTCM",
    "seats": "22.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.3",
    "maxTonnage": "22.3"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1387N370GLB\/CONECO",
    "seats": "19.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.17",
    "maxTonnage": "19.17"
  },
  {
    "brand": "HOWO",
    "model": "ZZ1387N370GLB\/DNVN",
    "seats": "21.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.9",
    "maxTonnage": "21.9"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3257N3447A1\/COLAVI.21",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3257N3447A1\/COLAVI.22",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3257N3447A1\/DNVN",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3257N3447A1\/DUCNGUYEN-TD09",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3257N3447A1\/HH",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3257N3447A1\/TTCM",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3257N3447A1\/VL",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/ALP",
    "seats": "16.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.8",
    "maxTonnage": "16.8"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/COLAVI.19",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/COLAVI.20",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/DNVN",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/DNVN",
    "seats": "16.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.3",
    "maxTonnage": "16.3"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/DNVN",
    "seats": "16.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.9",
    "maxTonnage": "16.9"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/DNVN-TD",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/DNVN-TD13",
    "seats": "16.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.25",
    "maxTonnage": "16.25"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/DNVN-TD13",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/DNVN-TD13",
    "seats": "16.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.45",
    "maxTonnage": "16.45"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/DNVN-TD13",
    "seats": "16.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.7",
    "maxTonnage": "16.7"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/HH",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "HOWO",
    "model": "ZZ3317N3067W\/VT",
    "seats": "16.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.3",
    "maxTonnage": "16.3"
  },
  {
    "brand": "HOWO",
    "model": "ZZ5257GJB",
    "seats": "8 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HYUNDAI",
    "model": "19TON\/SAOBAC-TT",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HYUNDAI",
    "model": "AERO SPACE",
    "seats": "Xe khách",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY",
    "seats": "25 chỗ",
    "minSeat": "25",
    "maxSeat": "25",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY 2-2",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY 29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY 29A",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY 3-1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY SL",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY-ĐV",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "COUNTY-ĐVI",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100",
    "seats": "0.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.92",
    "maxTonnage": "0.92"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100",
    "seats": "0.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.93",
    "maxTonnage": "0.93"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 A2",
    "seats": "0.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.84",
    "maxTonnage": "0.84"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 A2",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 A2",
    "seats": "0.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.89",
    "maxTonnage": "0.89"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 A2",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 A2",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 A2",
    "seats": "1.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.02",
    "maxTonnage": "1.02"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 A2",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 A2",
    "seats": "1.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.22",
    "maxTonnage": "1.22"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER 1.25",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER 1.25",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER 1.25",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER 1.25",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER 1.25",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER 1.25",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER 1.25",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER1.25",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 PORTER1.25",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.83",
    "maxTonnage": "0.83"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.87",
    "maxTonnage": "0.87"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "1.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.02",
    "maxTonnage": "1.02"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "1.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.08",
    "maxTonnage": "1.08"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "1.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.17",
    "maxTonnage": "1.17"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "H100 T2",
    "seats": "1.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.28",
    "maxTonnage": "1.28"
  },
  {
    "brand": "HYUNDAI",
    "model": "H350",
    "seats": "16 chỗ",
    "minSeat": "16",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "H350I",
    "seats": "16 chỗ",
    "minSeat": "16",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HB80",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD1000",
    "seats": "410PS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "3.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.32",
    "maxTonnage": "3.32"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.31",
    "maxTonnage": "4.31"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.74",
    "maxTonnage": "4.74"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.76",
    "maxTonnage": "4.76"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.81",
    "maxTonnage": "4.81"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "5.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.78",
    "maxTonnage": "5.78"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "5.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.91",
    "maxTonnage": "5.91"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD120",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD160",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "5.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.23",
    "maxTonnage": "5.23"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "6.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.21",
    "maxTonnage": "6.21"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "6.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.27",
    "maxTonnage": "6.27"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.11",
    "maxTonnage": "7.11"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.71",
    "maxTonnage": "7.71"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "8.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.14",
    "maxTonnage": "8.14"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD170",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "10.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.15",
    "maxTonnage": "10.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "10.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.75",
    "maxTonnage": "10.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "10.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.9",
    "maxTonnage": "10.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "10.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.95",
    "maxTonnage": "10.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.05",
    "maxTonnage": "11.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.18",
    "maxTonnage": "11.18"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.28",
    "maxTonnage": "11.28"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.3",
    "maxTonnage": "11.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.35",
    "maxTonnage": "11.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.45",
    "maxTonnage": "11.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.55",
    "maxTonnage": "11.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.84",
    "maxTonnage": "11.84"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.85",
    "maxTonnage": "11.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.87",
    "maxTonnage": "11.87"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.9",
    "maxTonnage": "11.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "11.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.95",
    "maxTonnage": "11.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.05",
    "maxTonnage": "12.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.15",
    "maxTonnage": "12.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.21",
    "maxTonnage": "12.21"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.35",
    "maxTonnage": "12.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.37",
    "maxTonnage": "12.37"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.39",
    "maxTonnage": "12.39"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.44",
    "maxTonnage": "12.44"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.45",
    "maxTonnage": "12.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.65",
    "maxTonnage": "12.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.75",
    "maxTonnage": "12.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.84",
    "maxTonnage": "12.84"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.85",
    "maxTonnage": "12.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.15",
    "maxTonnage": "13.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.25",
    "maxTonnage": "13.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.46",
    "maxTonnage": "13.46"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.62",
    "maxTonnage": "13.62"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.75",
    "maxTonnage": "13.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.83",
    "maxTonnage": "13.83"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.94",
    "maxTonnage": "13.94"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "14.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.11",
    "maxTonnage": "14.11"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "9.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.32",
    "maxTonnage": "9.32"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD210",
    "seats": "9.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.9",
    "maxTonnage": "9.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD240",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "10.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.23",
    "maxTonnage": "10.23"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "10.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.45",
    "maxTonnage": "10.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "12.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.85",
    "maxTonnage": "12.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "13.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.15",
    "maxTonnage": "13.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "6.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.38",
    "maxTonnage": "6.38"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "6.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.48",
    "maxTonnage": "6.48"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD250",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "11.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.76",
    "maxTonnage": "11.76"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "11.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.84",
    "maxTonnage": "11.84"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.15",
    "maxTonnage": "12.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.21",
    "maxTonnage": "12.21"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.42",
    "maxTonnage": "12.42"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.56",
    "maxTonnage": "12.56"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.65",
    "maxTonnage": "12.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "12.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.73",
    "maxTonnage": "12.73"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "8.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.78",
    "maxTonnage": "8.78"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.24",
    "maxTonnage": "9.24"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.35",
    "maxTonnage": "9.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.57",
    "maxTonnage": "9.57"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.8",
    "maxTonnage": "9.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD260",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "11.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.14",
    "maxTonnage": "11.14"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "11.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.58",
    "maxTonnage": "11.58"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.1",
    "maxTonnage": "12.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.14",
    "maxTonnage": "12.14"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.26",
    "maxTonnage": "12.26"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.47",
    "maxTonnage": "12.47"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "12.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.87",
    "maxTonnage": "12.87"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270",
    "seats": "7.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.81",
    "maxTonnage": "7.81"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD270 - MIXER",
    "seats": "10.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.97",
    "maxTonnage": "10.97"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "11.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.95",
    "maxTonnage": "11.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "15.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.33",
    "maxTonnage": "15.33"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "15.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.48",
    "maxTonnage": "15.48"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "15.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.77",
    "maxTonnage": "15.77"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "15.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.86",
    "maxTonnage": "15.86"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.16",
    "maxTonnage": "16.16"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.35",
    "maxTonnage": "16.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.55",
    "maxTonnage": "16.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.72",
    "maxTonnage": "16.72"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.86",
    "maxTonnage": "16.86"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "16.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.97",
    "maxTonnage": "16.97"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310",
    "seats": "18.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.4",
    "maxTonnage": "18.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD310-YMC.CD01",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.65",
    "maxTonnage": "10.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.75",
    "maxTonnage": "10.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.95",
    "maxTonnage": "10.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "10.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.97",
    "maxTonnage": "10.97"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.05",
    "maxTonnage": "11.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.25",
    "maxTonnage": "11.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.3",
    "maxTonnage": "11.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.45",
    "maxTonnage": "11.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.55",
    "maxTonnage": "11.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.75",
    "maxTonnage": "11.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.9",
    "maxTonnage": "11.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "11.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.95",
    "maxTonnage": "11.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.67",
    "maxTonnage": "13.67"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.52",
    "maxTonnage": "14.52"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.76",
    "maxTonnage": "14.76"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.04",
    "maxTonnage": "15.04"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.12",
    "maxTonnage": "15.12"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.17",
    "maxTonnage": "15.17"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.23",
    "maxTonnage": "15.23"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.28",
    "maxTonnage": "15.28"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.33",
    "maxTonnage": "15.33"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.53",
    "maxTonnage": "15.53"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.55",
    "maxTonnage": "15.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.58",
    "maxTonnage": "15.58"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.69",
    "maxTonnage": "15.69"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.71",
    "maxTonnage": "15.71"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.77",
    "maxTonnage": "15.77"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.79",
    "maxTonnage": "15.79"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.85",
    "maxTonnage": "15.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.9",
    "maxTonnage": "15.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.05",
    "maxTonnage": "16.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.06",
    "maxTonnage": "16.06"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.14",
    "maxTonnage": "16.14"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.22",
    "maxTonnage": "16.22"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.38",
    "maxTonnage": "16.38"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.47",
    "maxTonnage": "16.47"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.64",
    "maxTonnage": "16.64"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.65",
    "maxTonnage": "16.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.7",
    "maxTonnage": "16.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.8",
    "maxTonnage": "16.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.9",
    "maxTonnage": "16.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "16.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.95",
    "maxTonnage": "16.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.05",
    "maxTonnage": "17.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.1",
    "maxTonnage": "17.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.15",
    "maxTonnage": "17.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.22",
    "maxTonnage": "17.22"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.25",
    "maxTonnage": "17.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.3",
    "maxTonnage": "17.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.35",
    "maxTonnage": "17.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.5",
    "maxTonnage": "17.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.55",
    "maxTonnage": "17.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.56",
    "maxTonnage": "17.56"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.6",
    "maxTonnage": "17.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.65",
    "maxTonnage": "17.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.67",
    "maxTonnage": "17.67"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.7",
    "maxTonnage": "17.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.75",
    "maxTonnage": "17.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.76",
    "maxTonnage": "17.76"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.78",
    "maxTonnage": "17.78"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.85",
    "maxTonnage": "17.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.94",
    "maxTonnage": "17.94"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "18.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.07",
    "maxTonnage": "18.07"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "18.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.2",
    "maxTonnage": "18.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "18.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.3",
    "maxTonnage": "18.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "18.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.5",
    "maxTonnage": "18.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "18.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.6",
    "maxTonnage": "18.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "19.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.11",
    "maxTonnage": "19.11"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD320",
    "seats": "9.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.65",
    "maxTonnage": "9.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "13.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.64",
    "maxTonnage": "13.64"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "13.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.86",
    "maxTonnage": "13.86"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "14.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.73",
    "maxTonnage": "14.73"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "15.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.46",
    "maxTonnage": "15.46"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "15.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.55",
    "maxTonnage": "15.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "16.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.15",
    "maxTonnage": "16.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "17.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.34",
    "maxTonnage": "17.34"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "17.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.55",
    "maxTonnage": "17.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "17.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.56",
    "maxTonnage": "17.56"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "17.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.63",
    "maxTonnage": "17.63"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "17.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.76",
    "maxTonnage": "17.76"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "17.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.84",
    "maxTonnage": "17.84"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "17.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.97",
    "maxTonnage": "17.97"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.15",
    "maxTonnage": "18.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.24",
    "maxTonnage": "18.24"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.36",
    "maxTonnage": "18.36"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.5",
    "maxTonnage": "18.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.59",
    "maxTonnage": "18.59"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.7",
    "maxTonnage": "18.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.77",
    "maxTonnage": "18.77"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.8",
    "maxTonnage": "18.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "18.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.87",
    "maxTonnage": "18.87"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.16",
    "maxTonnage": "19.16"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.24",
    "maxTonnage": "19.24"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.26",
    "maxTonnage": "19.26"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.39",
    "maxTonnage": "19.39"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.65",
    "maxTonnage": "19.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.7",
    "maxTonnage": "19.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.75",
    "maxTonnage": "19.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.8",
    "maxTonnage": "19.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.85",
    "maxTonnage": "19.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "19.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.98",
    "maxTonnage": "19.98"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "20.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20",
    "maxTonnage": "20"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "20.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.1",
    "maxTonnage": "20.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "20.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.35",
    "maxTonnage": "20.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "20.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.4",
    "maxTonnage": "20.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "20.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.6",
    "maxTonnage": "20.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "20.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.72",
    "maxTonnage": "20.72"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "20.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.9",
    "maxTonnage": "20.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "21.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21",
    "maxTonnage": "21"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "21.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.46",
    "maxTonnage": "21.46"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD360",
    "seats": "21.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.5",
    "maxTonnage": "21.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD370",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD60 CARGO TRUCK",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD60 DUMP TRUCK",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD600",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD600",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD600",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.73",
    "maxTonnage": "1.73"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.01",
    "maxTonnage": "2.01"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.12",
    "maxTonnage": "2.12"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.22",
    "maxTonnage": "2.22"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD65",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD70",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "4.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.74",
    "maxTonnage": "4.74"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.04",
    "maxTonnage": "5.04"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.68",
    "maxTonnage": "5.68"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.72",
    "maxTonnage": "5.72"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.83",
    "maxTonnage": "5.83"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.89",
    "maxTonnage": "5.89"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.94",
    "maxTonnage": "5.94"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.18",
    "maxTonnage": "6.18"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.29",
    "maxTonnage": "6.29"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD700",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "2.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.33",
    "maxTonnage": "2.33"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD72",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.26",
    "maxTonnage": "3.26"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.29",
    "maxTonnage": "3.29"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD78",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "HD800",
    "seats": "Tải",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HK46",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HM K29A",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HM K29B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY",
    "seats": "EX8L\/VP.TM1",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "5.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.04",
    "maxTonnage": "5.04"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "6.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.49",
    "maxTonnage": "6.49"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "6.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.64",
    "maxTonnage": "6.64"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.03",
    "maxTonnage": "7.03"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.83",
    "maxTonnage": "7.83"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY 2017",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.46",
    "maxTonnage": "1.46"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.73",
    "maxTonnage": "1.73"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.88",
    "maxTonnage": "1.88"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.93",
    "maxTonnage": "1.93"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.97",
    "maxTonnage": "1.97"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.26",
    "maxTonnage": "2.26"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.34",
    "maxTonnage": "2.34"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.38",
    "maxTonnage": "2.38"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD65",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.55",
    "maxTonnage": "2.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.11",
    "maxTonnage": "3.11"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.14",
    "maxTonnage": "3.14"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.24",
    "maxTonnage": "3.24"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.26",
    "maxTonnage": "3.26"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.28",
    "maxTonnage": "3.28"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.31",
    "maxTonnage": "3.31"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.34",
    "maxTonnage": "3.34"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD72",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "3.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.34",
    "maxTonnage": "3.34"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY HD78",
    "seats": "4.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.22",
    "maxTonnage": "4.22"
  },
  {
    "brand": "HYUNDAI",
    "model": "MIGHTY II-KANG",
    "seats": "1.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.66",
    "maxTonnage": "1.66"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "3.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.74",
    "maxTonnage": "3.74"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.66",
    "maxTonnage": "6.66"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.86",
    "maxTonnage": "6.86"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110S",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 110SP",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY 75S",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.84",
    "maxTonnage": "1.84"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.86",
    "maxTonnage": "1.86"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.88",
    "maxTonnage": "1.88"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.89",
    "maxTonnage": "1.89"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.93",
    "maxTonnage": "1.93"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.96",
    "maxTonnage": "1.96"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.04",
    "maxTonnage": "2.04"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.05",
    "maxTonnage": "2.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.13",
    "maxTonnage": "2.13"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.14",
    "maxTonnage": "2.14"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.16",
    "maxTonnage": "2.16"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.17",
    "maxTonnage": "2.17"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.22",
    "maxTonnage": "2.22"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.23",
    "maxTonnage": "2.23"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.24",
    "maxTonnage": "2.24"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.28",
    "maxTonnage": "2.28"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.29",
    "maxTonnage": "2.29"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.32",
    "maxTonnage": "2.32"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.33",
    "maxTonnage": "2.33"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.42",
    "maxTonnage": "2.42"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N 250",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.33",
    "maxTonnage": "2.33"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW MIGHTY N250SL",
    "seats": "2.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.55",
    "maxTonnage": "2.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "0.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.82",
    "maxTonnage": "0.82"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "0.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.88",
    "maxTonnage": "0.88"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.21",
    "maxTonnage": "1.21"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.24",
    "maxTonnage": "1.24"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.33",
    "maxTonnage": "1.33"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.36",
    "maxTonnage": "1.36"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.37",
    "maxTonnage": "1.37"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.38",
    "maxTonnage": "1.38"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.39",
    "maxTonnage": "1.39"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.41",
    "maxTonnage": "1.41"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.42",
    "maxTonnage": "1.42"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.44",
    "maxTonnage": "1.44"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.46",
    "maxTonnage": "1.46"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.48",
    "maxTonnage": "1.48"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW PORTER 150",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW SUPER AERO CITY\/ĐN-KTP",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "PORTER",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HYUNDAI",
    "model": "PORTER II",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "HYUNDAI",
    "model": "QT01",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "SOLATI",
    "seats": "16 chỗ",
    "minSeat": "16",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Strarex - cứu thương",
    "seats": "06 chỗ",
    "minSeat": "6",
    "maxSeat": "6",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "11.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.95",
    "maxTonnage": "11.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "16.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.65",
    "maxTonnage": "16.65"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "20.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20",
    "maxTonnage": "20"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "20.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.45",
    "maxTonnage": "20.45"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "21.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.61",
    "maxTonnage": "21.61"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO",
    "seats": "24.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "24.1",
    "maxTonnage": "24.1"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "15.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.91",
    "maxTonnage": "15.91"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "16.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.6",
    "maxTonnage": "16.6"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "17.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17",
    "maxTonnage": "17"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "17.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.4",
    "maxTonnage": "17.4"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "17.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.7",
    "maxTonnage": "17.7"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "17.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.85",
    "maxTonnage": "17.85"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "HYUNDAI",
    "model": "TRAGO XCIENT",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE ADVANCE",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE MODERN",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE PREMIUM",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "e-COUNTY 2-2",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "e-COUNTY 3-1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "e-COUNTY XL 2-2A",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "e-COUNTY XL 2-2B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAIMA",
    "model": "2",
    "seats": "DX-1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAIMA",
    "model": "3",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAIMA",
    "model": "7",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HAIMA",
    "model": "S7",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "2.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "2.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "3.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "COUPE-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "EX-3.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Accord",
    "seats": "MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Brio",
    "seats": "G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Brio",
    "seats": "RS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "CRV",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "CRV",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "CRV",
    "seats": "2.4-AT---TG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "CRV",
    "seats": "2.4-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "CRV",
    "seats": "E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "CRV",
    "seats": "EX-L-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "CRV",
    "seats": "G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "CRV",
    "seats": "L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "City",
    "seats": "1.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "City",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "City",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "City",
    "seats": "1.5TOP",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "1.3-AT-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "1.5G-VTEC-TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "1.5L-VTEC-TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "1.8-E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "E-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "G-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "RS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Civic",
    "seats": "RS-1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Concerto",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Element",
    "seats": "SC-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "HR V",
    "seats": "G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "HR V",
    "seats": "L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "HR V",
    "seats": "L-MUGEN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Jazz",
    "seats": "RS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Jazz",
    "seats": "V",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Jazz",
    "seats": "VX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Odyssey",
    "seats": "2.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Odyssey",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HONDA",
    "model": "Odyssey",
    "seats": "3.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Accent",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Accent",
    "seats": "1.4-AT-FACEFIFT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Accent",
    "seats": "1.4-AT-FACELIFT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Accent",
    "seats": "1.4-ATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Accent",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Accent",
    "seats": "1.4-MT-BASE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Accent",
    "seats": "1.4-MT-FACELIFT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Accent",
    "seats": "1.4AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Avante",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Avante",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Avante",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Avante",
    "seats": "M16-GDI-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Avante",
    "seats": "S16-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Azera",
    "seats": "2.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Azera",
    "seats": "3.3-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Click",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Click",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "2017",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "29-CHO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "3.9L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "3.9L-THAN-DAI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "3.9L-TIEU-CHUAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "D4DD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "DONG-VANG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "LIMOUISNIE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "NEW",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "SL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "THAN-DAI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "THAN-DAI-7.6M",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "County",
    "seats": "XE-KHACH-29-CHO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Creta",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Creta",
    "seats": "1.6-AT-GAS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "FACELIFT-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "GLS-1.6AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Elantra",
    "seats": "SPORT-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Eon",
    "seats": "0.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Equus",
    "seats": "LIMOUSINE-VL-500",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Equus",
    "seats": "VS-380",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Equus",
    "seats": "VS-460",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Excel",
    "seats": "1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Galloper",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Galloper",
    "seats": "2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Galloper",
    "seats": "3.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Genesis",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Genesis",
    "seats": "3.3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Genesis",
    "seats": "BH330",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Genesis",
    "seats": "BH380",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Getz",
    "seats": "1.1-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Getz",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Getz",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Getz",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Getz",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grace",
    "seats": "2.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grace",
    "seats": "VAN-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grace",
    "seats": "VAN-2.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "CUU-THUONG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "CUU-THUONG-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "CUU-THUONG-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "LIMOUSINE-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "VAN-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "VAN-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grand Starex",
    "seats": "VAN-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grandeur",
    "seats": "HG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Grandeur",
    "seats": "HG-300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Kona",
    "seats": "1.6-TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Kona",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Kona",
    "seats": "2.0-ATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Marcia",
    "seats": "MOBIHOME",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Matrix",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Palisade",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.2-CRDI-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.2-MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.2L-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.2L-HTRAC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.4L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.4L-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.4L-HTRAC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.7-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.7-MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "2.7L-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "FE-MLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "GOLD-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "GOLD-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "MLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "MLX-2.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "MLX-2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "PREMIUM-2.2L-HTRAC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "PREMIUM-2.4L-HTRAC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Santa Fe",
    "seats": "SLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Solati",
    "seats": "CITY-LIMO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Solati",
    "seats": "H350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Solati",
    "seats": "H350-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Solati",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Solati",
    "seats": "LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Sonata",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Sonata",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Sonata",
    "seats": "Y20",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Sonata",
    "seats": "Y20-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Starex",
    "seats": "2.4MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Starex",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Starex",
    "seats": "2.5-AT-LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Starex",
    "seats": "2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Starex",
    "seats": "VAN-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Starex",
    "seats": "VAN-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Terracan",
    "seats": "3.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Trajet",
    "seats": "2.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "1.6-AT-TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "2.0-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "2.0-AT-CRDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "2.0-ATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "2.0AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "2.4-AT-LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "IX35-2.0-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "LMX-2.0-EVGT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "LMX-2.0-VVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "LX-2.0-EVGT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tucson",
    "seats": "LX-2.0-VVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tuscani",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Tuscani",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "32",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "410-PS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "ADVANCED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "ADVANCED-380PS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "AERO-HI-CLASS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "AERO-TOWN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "EXPRESS-NOBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "GALAXY-VIP",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "NOBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "NOLBE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "PREMIUM-410PS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe",
    "seats": "U47-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Universe Xpress Luxury",
    "seats": "TRACO120-K47",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Veloster",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Veloster",
    "seats": "1.6-AT-GDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Veracruz",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Veracruz",
    "seats": "3.8-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Verna",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Verna",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Verna",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "XG",
    "seats": "300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "1.1-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "1.1-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "1.25AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "GRAND-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "GRAND-1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "GRAND-1.0-MT-BASE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "GRAND-1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "GRAND-1.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i10",
    "seats": "GRAND-1.2-MT-BASE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i20",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i20",
    "seats": "ACTIVE-1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i30",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i30",
    "seats": "1.6-AT-CRDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i30",
    "seats": "CW",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "i30",
    "seats": "CW-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA 6110",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA 6110-3K1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA6110\/125-3K1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "TOYOTA 2RZ",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE-V1006",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "B.Is.01",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "CH.Is.01",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "ISUZU",
    "model": "CYH51W",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "ISUZU",
    "model": "CYH51Y",
    "seats": "14.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.62",
    "maxTonnage": "14.62"
  },
  {
    "brand": "ISUZU",
    "model": "CYH51Y",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "ISUZU",
    "model": "CYH51Y",
    "seats": "16.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.95",
    "maxTonnage": "16.95"
  },
  {
    "brand": "ISUZU",
    "model": "CYZ51Q",
    "seats": "10.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.24",
    "maxTonnage": "10.24"
  },
  {
    "brand": "ISUZU",
    "model": "CYZ51Q",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "ISUZU",
    "model": "D-MAX",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "ISUZU",
    "model": "D-MAX",
    "seats": "0.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.74",
    "maxTonnage": "0.74"
  },
  {
    "brand": "ISUZU",
    "model": "D-MAX",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "ISUZU",
    "model": "D-MAX",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ISUZU",
    "model": "DL.Is.01",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "ER-ISU",
    "seats": "2.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.97",
    "maxTonnage": "2.97"
  },
  {
    "brand": "ISUZU",
    "model": "ER.Is.01",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "ISUZU",
    "model": "ER.Is.02",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ISUZU",
    "model": "ERFSR",
    "seats": "3.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.84",
    "maxTonnage": "3.84"
  },
  {
    "brand": "ISUZU",
    "model": "ERTLISU",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90HE4",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90HE4",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90HE4",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90HE4",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90HE4",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90HE4",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90HE4",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90HE4",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90LE4",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90LE4",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90LE4",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90LE4",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90LE4",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "6.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.18",
    "maxTonnage": "6.18"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-16",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-16",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-16",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-16",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-16",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-16",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.07",
    "maxTonnage": "5.07"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.38",
    "maxTonnage": "5.38"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.82",
    "maxTonnage": "5.82"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "6.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.18",
    "maxTonnage": "6.18"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90N-190",
    "seats": "6.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.44",
    "maxTonnage": "6.44"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.02",
    "maxTonnage": "5.02"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "ISUZU",
    "model": "FRR90NE4",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "ISUZU",
    "model": "FSR33F",
    "seats": "3.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.84",
    "maxTonnage": "3.84"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33H",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33H",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33H",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33H",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33H-STD",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33H-STD",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P-STD",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P-STD",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "ISUZU",
    "model": "FTR33P-STD",
    "seats": "8.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.42",
    "maxTonnage": "8.42"
  },
  {
    "brand": "ISUZU",
    "model": "FTS33H",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVM",
    "seats": "10.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.89",
    "maxTonnage": "10.89"
  },
  {
    "brand": "ISUZU",
    "model": "FVM",
    "seats": "12.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.75",
    "maxTonnage": "12.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVM23S",
    "seats": "7.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.44",
    "maxTonnage": "7.44"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "11.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.45",
    "maxTonnage": "11.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "11.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.53",
    "maxTonnage": "11.53"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "11.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.99",
    "maxTonnage": "11.99"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "12.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.83",
    "maxTonnage": "12.83"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.05",
    "maxTonnage": "13.05"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.25",
    "maxTonnage": "13.25"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.46",
    "maxTonnage": "13.46"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.62",
    "maxTonnage": "13.62"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.75",
    "maxTonnage": "13.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "13.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.97",
    "maxTonnage": "13.97"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.35",
    "maxTonnage": "14.35"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.51",
    "maxTonnage": "14.51"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.55",
    "maxTonnage": "14.55"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.65",
    "maxTonnage": "14.65"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.75",
    "maxTonnage": "14.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.05",
    "maxTonnage": "15.05"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.15",
    "maxTonnage": "15.15"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.33",
    "maxTonnage": "15.33"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.35",
    "maxTonnage": "15.35"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.39",
    "maxTonnage": "15.39"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.55",
    "maxTonnage": "15.55"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "15.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.65",
    "maxTonnage": "15.65"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T-15",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T-15",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T-15",
    "seats": "13.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.84",
    "maxTonnage": "13.84"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T-15",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T-15-C16",
    "seats": "13.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.68",
    "maxTonnage": "13.68"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T-15-C16",
    "seats": "14.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.51",
    "maxTonnage": "14.51"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T-15-C16",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34T-15-C16",
    "seats": "15.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.54",
    "maxTonnage": "15.54"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "13.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.46",
    "maxTonnage": "13.46"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "13.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.69",
    "maxTonnage": "13.69"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "14.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.06",
    "maxTonnage": "14.06"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "14.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.16",
    "maxTonnage": "14.16"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34TE4",
    "seats": "15.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.7",
    "maxTonnage": "15.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "10.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.9",
    "maxTonnage": "10.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "11.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.3",
    "maxTonnage": "11.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "11.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.6",
    "maxTonnage": "11.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "12.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.55",
    "maxTonnage": "12.55"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "12.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.85",
    "maxTonnage": "12.85"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.55",
    "maxTonnage": "13.55"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.55",
    "maxTonnage": "14.55"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.65",
    "maxTonnage": "14.65"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.68",
    "maxTonnage": "14.68"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.75",
    "maxTonnage": "14.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "15.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.45",
    "maxTonnage": "15.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34W",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "10.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.9",
    "maxTonnage": "10.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "11.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.08",
    "maxTonnage": "11.08"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "12.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.55",
    "maxTonnage": "12.55"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "13.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.43",
    "maxTonnage": "13.43"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "13.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.95",
    "maxTonnage": "13.95"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.45",
    "maxTonnage": "14.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.75",
    "maxTonnage": "14.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "14.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.9",
    "maxTonnage": "14.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "15.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.1",
    "maxTonnage": "15.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "15.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.4",
    "maxTonnage": "15.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVM34WE4",
    "seats": "9.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.99",
    "maxTonnage": "9.99"
  },
  {
    "brand": "ISUZU",
    "model": "FVR",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "6.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.81",
    "maxTonnage": "6.81"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "7.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.11",
    "maxTonnage": "7.11"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "7.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.63",
    "maxTonnage": "7.63"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "7.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.89",
    "maxTonnage": "7.89"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "8.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.64",
    "maxTonnage": "8.64"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "8.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.75",
    "maxTonnage": "8.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34L",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "6.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.16",
    "maxTonnage": "6.16"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "6.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.33",
    "maxTonnage": "6.33"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "6.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.58",
    "maxTonnage": "6.58"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "7.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.11",
    "maxTonnage": "7.11"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "8.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.44",
    "maxTonnage": "8.44"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34LE4",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.33",
    "maxTonnage": "7.33"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.06",
    "maxTonnage": "8.06"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.17",
    "maxTonnage": "8.17"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.76",
    "maxTonnage": "8.76"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "8.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.9",
    "maxTonnage": "8.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34Q",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "7.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.43",
    "maxTonnage": "7.43"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "7.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.91",
    "maxTonnage": "7.91"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34QE4",
    "seats": "8.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.85",
    "maxTonnage": "8.85"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.01",
    "maxTonnage": "7.01"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.69",
    "maxTonnage": "8.69"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34S",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.54",
    "maxTonnage": "7.54"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.75",
    "maxTonnage": "7.75"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34SE4",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34T",
    "seats": "6.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.56",
    "maxTonnage": "6.56"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34T",
    "seats": "7.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.57",
    "maxTonnage": "7.57"
  },
  {
    "brand": "ISUZU",
    "model": "FVR34W",
    "seats": "14.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.05",
    "maxTonnage": "14.05"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ",
    "seats": "12.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.39",
    "maxTonnage": "12.39"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34P",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "10.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.7",
    "maxTonnage": "10.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "13.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.15",
    "maxTonnage": "13.15"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "14.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.24",
    "maxTonnage": "14.24"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "ISUZU",
    "model": "FVZ34QE4",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "ISUZU",
    "model": "GAS FVM",
    "seats": "7.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.44",
    "maxTonnage": "7.44"
  },
  {
    "brand": "ISUZU",
    "model": "HC-ISUZU",
    "seats": "3.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.77",
    "maxTonnage": "3.77"
  },
  {
    "brand": "ISUZU",
    "model": "HC.Is.01",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "HP NQR71R",
    "seats": "4.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.12",
    "maxTonnage": "4.12"
  },
  {
    "brand": "ISUZU",
    "model": "HPNKR55E",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "ISUZU",
    "model": "HT-08",
    "seats": "2.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.16",
    "maxTonnage": "2.16"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.18",
    "maxTonnage": "1.18"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.21",
    "maxTonnage": "1.21"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "ISUZU",
    "model": "NHR55E-FL",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "ISUZU",
    "model": "NKR55E",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "ISUZU",
    "model": "NKR55E",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "ISUZU",
    "model": "NKR55E",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NKR55E",
    "seats": "1.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.84",
    "maxTonnage": "1.84"
  },
  {
    "brand": "ISUZU",
    "model": "NKR55E",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "NKR55L",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "NKR55LR",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66E",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66E",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66E",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66E",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66E",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.63",
    "maxTonnage": "1.63"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.64",
    "maxTonnage": "1.64"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.66",
    "maxTonnage": "1.66"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.76",
    "maxTonnage": "1.76"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66L-STD05",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66LR",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "ISUZU",
    "model": "NKR66LR",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NKR77LLPACJAY",
    "seats": "1.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.56",
    "maxTonnage": "1.56"
  },
  {
    "brand": "ISUZU",
    "model": "NKR77LLPACJAY",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ISUZU",
    "model": "NKR77LLPACJAY",
    "seats": "3.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.62",
    "maxTonnage": "3.62"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.72",
    "maxTonnage": "0.72"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.92",
    "maxTonnage": "0.92"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.94",
    "maxTonnage": "0.94"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "1.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.02",
    "maxTonnage": "1.02"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "1.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.04",
    "maxTonnage": "1.04"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "ISUZU",
    "model": "NLR55E",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "ISUZU",
    "model": "NMR77EE4",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "NMR77EE4",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ISUZU",
    "model": "NMR77EE4",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "ISUZU",
    "model": "NMR77EE4",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "ISUZU",
    "model": "NMR77EE4",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85E",
    "seats": "1.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.56",
    "maxTonnage": "1.56"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85E",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85E",
    "seats": "1.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.71",
    "maxTonnage": "1.71"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85E",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85E",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85E",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85E",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.37",
    "maxTonnage": "1.37"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.58",
    "maxTonnage": "1.58"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85H",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "1.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.92",
    "maxTonnage": "1.92"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "ISUZU",
    "model": "NMR85HE4",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66L",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "1.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.23",
    "maxTonnage": "1.23"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.17",
    "maxTonnage": "3.17"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.31",
    "maxTonnage": "3.31"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.34",
    "maxTonnage": "3.34"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.37",
    "maxTonnage": "3.37"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.43",
    "maxTonnage": "3.43"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.53",
    "maxTonnage": "3.53"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "ISUZU",
    "model": "NPR66P",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.52",
    "maxTonnage": "2.52"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.39",
    "maxTonnage": "3.39"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.42",
    "maxTonnage": "3.42"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.46",
    "maxTonnage": "3.46"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.52",
    "maxTonnage": "3.52"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.54",
    "maxTonnage": "3.54"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.55",
    "maxTonnage": "3.55"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.64",
    "maxTonnage": "3.64"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.68",
    "maxTonnage": "3.68"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K-16",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K-16",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85K-16",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.16",
    "maxTonnage": "3.16"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.39",
    "maxTonnage": "3.39"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.89",
    "maxTonnage": "3.89"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.93",
    "maxTonnage": "3.93"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "3.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.99",
    "maxTonnage": "3.99"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "ISUZU",
    "model": "NPR85KE4",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR66P",
    "seats": "3.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.67",
    "maxTonnage": "3.67"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "2.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.97",
    "maxTonnage": "2.97"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "3.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.23",
    "maxTonnage": "3.23"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "3.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.63",
    "maxTonnage": "3.63"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "3.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.76",
    "maxTonnage": "3.76"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "3.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.77",
    "maxTonnage": "3.77"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.02",
    "maxTonnage": "4.02"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.24",
    "maxTonnage": "4.24"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.47",
    "maxTonnage": "4.47"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.57",
    "maxTonnage": "4.57"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.71",
    "maxTonnage": "4.71"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.73",
    "maxTonnage": "4.73"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "5.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.13",
    "maxTonnage": "5.13"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "5.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.17",
    "maxTonnage": "5.17"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR71R ",
    "seats": "4.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.29",
    "maxTonnage": "4.29"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "2.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.87",
    "maxTonnage": "2.87"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.23",
    "maxTonnage": "3.23"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.55",
    "maxTonnage": "3.55"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.87",
    "maxTonnage": "3.87"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.06",
    "maxTonnage": "4.06"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.32",
    "maxTonnage": "4.32"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.61",
    "maxTonnage": "4.61"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.01",
    "maxTonnage": "5.01"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.11",
    "maxTonnage": "5.11"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.14",
    "maxTonnage": "5.14"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L-16",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L-16",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L-16",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L-16",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75L-16",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "3.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.42",
    "maxTonnage": "3.42"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.74",
    "maxTonnage": "5.74"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75LE4",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.58",
    "maxTonnage": "3.58"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.33",
    "maxTonnage": "5.33"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M",
    "seats": "5.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.48",
    "maxTonnage": "5.48"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M-16",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M-16",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M-16",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M-16",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M-16",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M-16",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M-16",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75M-16",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "3.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.34",
    "maxTonnage": "3.34"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.33",
    "maxTonnage": "4.33"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.35",
    "maxTonnage": "4.35"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.13",
    "maxTonnage": "5.13"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.2",
    "maxTonnage": "5.2"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.37",
    "maxTonnage": "5.37"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.76",
    "maxTonnage": "5.76"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "ISUZU",
    "model": "NQR75ME4",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.3",
    "maxTonnage": "0.3"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.89",
    "maxTonnage": "0.89"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.91",
    "maxTonnage": "0.91"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.96",
    "maxTonnage": "0.96"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F-16",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F-16",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F-16",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F-16",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F-16",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F-16",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F-16",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55F-16",
    "seats": "2.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.55",
    "maxTonnage": "2.55"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.87",
    "maxTonnage": "1.87"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.89",
    "maxTonnage": "1.89"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "2.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.05",
    "maxTonnage": "2.05"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "2.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.26",
    "maxTonnage": "2.26"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.63",
    "maxTonnage": "2.63"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-16",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55H-VAN16",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55HL",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR55HL",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.36",
    "maxTonnage": "1.36"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.79",
    "maxTonnage": "1.79"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.22",
    "maxTonnage": "2.22"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.42",
    "maxTonnage": "2.42"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4-VT",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4-VT",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77FE4-VT",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "1.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.39",
    "maxTonnage": "1.39"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "1.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.62",
    "maxTonnage": "1.62"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.05",
    "maxTonnage": "2.05"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.47",
    "maxTonnage": "2.47"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.64",
    "maxTonnage": "2.64"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.83",
    "maxTonnage": "2.83"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4-VT",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4-VT",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4-VT",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4-VT",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "ISUZU",
    "model": "QKR77HE4-VT",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "ISUZU",
    "model": "RĐ NQR71R",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "ISUZU",
    "model": "TB.IS.02",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "ISUZU",
    "model": "TB2,5T - ISU",
    "seats": "2.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.58",
    "maxTonnage": "2.58"
  },
  {
    "brand": "ISUZU",
    "model": "TB2T - ISU",
    "seats": "1.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.81",
    "maxTonnage": "1.81"
  },
  {
    "brand": "ISUZU",
    "model": "TC-ISU",
    "seats": "3.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.74",
    "maxTonnage": "3.74"
  },
  {
    "brand": "ISUZU",
    "model": "TC.Is.02",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "ISUZU",
    "model": "TC1-ISU",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "ISUZU",
    "model": "TC5,5T - ISU",
    "seats": "4.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.67",
    "maxTonnage": "4.67"
  },
  {
    "brand": "ISUZU",
    "model": "TK.IS.05",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "ISUZU",
    "model": "TK.Is.02",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "ISUZU",
    "model": "TK.Is.03",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "TK.Is.04",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "ISUZU",
    "model": "TĐ.Is.01",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "ISUZU",
    "model": "VACCUM",
    "seats": "4.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.24",
    "maxTonnage": "4.24"
  },
  {
    "brand": "ISUZU",
    "model": "VACUUM",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "ISUZU",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "IVECO",
    "model": "DAILY S2000",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "IVECO",
    "model": "DAILY S2000",
    "seats": "2.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.59",
    "maxTonnage": "2.59"
  },
  {
    "brand": "IVECO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "IVECO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "IVECO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "IVECO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "IVECO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "IVECO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "INFINITI",
    "model": "FX",
    "seats": "35",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "INFINITI",
    "model": "JX",
    "seats": "35",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "INFINITI",
    "model": "JX",
    "seats": "35-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "INFINITI",
    "model": "QX",
    "seats": "60-3.5-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "INFINITI",
    "model": "QX",
    "seats": "70-3.7-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "INTERNATIONAL",
    "model": "Durastar",
    "seats": "38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "38",
    "maxTonnage": "38"
  },
  {
    "brand": "INTERNATIONAL",
    "model": "Prostar",
    "seats": "38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "38",
    "maxTonnage": "38"
  },
  {
    "brand": "INTERNATIONAL",
    "model": "Transtar",
    "seats": "38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "38",
    "maxTonnage": "38"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-1.9L-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-2.5-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-2.5-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-2.5-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-2.5-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-3.0-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-3.0-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-3.0-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-3.0-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-PRESTIGE-1.9L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-PRESTIGE-1.9L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Dmax",
    "seats": "LS-PRESTIGE-3.0L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "LS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "LX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "V-SPEC-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "V-SPEC-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "V-SPEC-2.5MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "V-SPEC-STREET-CUSTOM-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "V-SPEC-X-TREME-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "X-TREME-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Hi lander",
    "seats": "X-TREME-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "MU",
    "seats": "7-3.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "MU-X",
    "seats": "X-1.9-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "MU-X",
    "seats": "X-1.9-4X2-AT-LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "MU-X",
    "seats": "X-1.9-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "MU-X",
    "seats": "X-2.5-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "MU-X",
    "seats": "X-3.0-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "MU-X",
    "seats": "X-3.0L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "MU-X",
    "seats": "X-3.0L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Trooper",
    "seats": "2.3-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Trooper",
    "seats": "LS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Trooper",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Trooper",
    "seats": "SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "1020\/X99TB1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "1020\/X99TK1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "1020\/X99TL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "1025\/ASU",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "JAC",
    "model": "1025\/CTĐ",
    "seats": "1.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.38",
    "maxTonnage": "1.38"
  },
  {
    "brand": "JAC",
    "model": "1025\/PHUMAN",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "JAC",
    "model": "1025\/TN",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "JAC",
    "model": "1025\/TN",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "1025\/X125TB",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "1025\/X125TB1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "1025\/X125TK",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "1025\/X125TK1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "1025\/X125TL",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "1025\/X150TB",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "1025\/X150TB1",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "1025\/X150TB2",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "1025\/X150TK",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "1025\/X150TK1",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "1025\/X150TL",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "1025\/X99TB",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "1025\/X99TB1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "1025\/X99TK",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "1025\/X99TK1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "1025\/X99TL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "1030\/H350XTL",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "JAC",
    "model": "1030\/L240TB1",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "JAC",
    "model": "1047\/L250TB1",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "JAC",
    "model": "1047\/L250TK1",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "JAC",
    "model": "1047\/L250TK1",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "JAC",
    "model": "1047\/L250TL",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "JAC",
    "model": "1048\/L350TB1",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "JAC",
    "model": "1048\/L500TB1",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "JAC",
    "model": "1048\/L500TK1",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "JAC",
    "model": "1081\/N650TB1",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "JAC",
    "model": "1081\/N650TK1",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "JAC",
    "model": "1081\/N650TKBN",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "JAC",
    "model": "4950LD\/KM1",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "JAC",
    "model": "B350",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "JAC",
    "model": "CA6DF2-22",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "JAC",
    "model": "CA6DF2-26",
    "seats": "12.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.05",
    "maxTonnage": "12.05"
  },
  {
    "brand": "JAC",
    "model": "CA6DF2D-19",
    "seats": "8.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.95",
    "maxTonnage": "8.95"
  },
  {
    "brand": "JAC",
    "model": "CA6DF3-24E3F",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF2",
    "seats": "12.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.45",
    "maxTonnage": "12.45"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF2",
    "seats": "12.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.65",
    "maxTonnage": "12.65"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF2",
    "seats": "9.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.75",
    "maxTonnage": "9.75"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF2D",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF2D",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF3",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF3",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF3",
    "seats": "8.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.75",
    "maxTonnage": "8.75"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF3",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF3",
    "seats": "9.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.15",
    "maxTonnage": "9.15"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF3",
    "seats": "9.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.45",
    "maxTonnage": "9.45"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/CA6DF3",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/WP10",
    "seats": "11.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.87",
    "maxTonnage": "11.87"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/WP10",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/WP10",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "JAC",
    "model": "CKGT\/WP12",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "JAC",
    "model": "HB-CA6DF2",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "JAC",
    "model": "HB-CA6DF2D",
    "seats": "9.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.55",
    "maxTonnage": "9.55"
  },
  {
    "brand": "JAC",
    "model": "HB-MP",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "JAC",
    "model": "HB\/CA6DF2",
    "seats": "12.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.93",
    "maxTonnage": "12.93"
  },
  {
    "brand": "JAC",
    "model": "HB\/CA6DF2",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "JAC",
    "model": "HB\/WD615",
    "seats": "17.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.2",
    "maxTonnage": "17.2"
  },
  {
    "brand": "JAC",
    "model": "HFC 1032KW",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "JAC",
    "model": "HFC 1032KW",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JAC",
    "model": "HFC 1042K2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC 3045K",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "JAC",
    "model": "HFC 3045K",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "JAC",
    "model": "HFC 3045K",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K\/KM1",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K\/KM2",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K\/TB",
    "seats": "0.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.43",
    "maxTonnage": "0.43"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K\/TK1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K1",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K1\/KM1",
    "seats": "1.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.26",
    "maxTonnage": "1.26"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K1\/KM2",
    "seats": "1.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.26",
    "maxTonnage": "1.26"
  },
  {
    "brand": "JAC",
    "model": "HFC1020K1\/TK1",
    "seats": "1.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.14",
    "maxTonnage": "1.14"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/KM1",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/KM2",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/KM3",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/KM5",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/TB1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/TK1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/TK1B",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/TK2B",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1025K1\/TK3B",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ\/KM1",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ\/KM2",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ\/KM3",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ\/KM4",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ\/TK1",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1025KZ\/TK2",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K\/KM1",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K\/KM2",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K\/TK1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K1",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K1\/KM1",
    "seats": "1.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.29",
    "maxTonnage": "1.29"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K1\/KM2",
    "seats": "1.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.29",
    "maxTonnage": "1.29"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K1\/TK1",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K2\/TK1B",
    "seats": "1.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.26",
    "maxTonnage": "1.26"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K2L\/TB",
    "seats": "0.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.78",
    "maxTonnage": "0.78"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K3\/KM1",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K3\/KM2",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K3\/TK1B",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K3\/TL",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K4\/KM1",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K4\/KM1",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K4\/TK1B",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1030K4\/TL",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1032KW.TK1",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "JAC",
    "model": "HFC1032KW.TK2",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "JAC",
    "model": "HFC1035K",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JAC",
    "model": "HFC1035K\/KM1",
    "seats": "0.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.82",
    "maxTonnage": "0.82"
  },
  {
    "brand": "JAC",
    "model": "HFC1035K\/KM2",
    "seats": "0.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.82",
    "maxTonnage": "0.82"
  },
  {
    "brand": "JAC",
    "model": "HFC1035K\/TK1",
    "seats": "0.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.73",
    "maxTonnage": "0.73"
  },
  {
    "brand": "JAC",
    "model": "HFC1040K",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1040K1",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1040KZ",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1040KZ\/KM1",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "JAC",
    "model": "HFC1040KZ\/KM2",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "JAC",
    "model": "HFC1040KZ\/TK1",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K\/KM1",
    "seats": "1.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.53",
    "maxTonnage": "1.53"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K\/KM2",
    "seats": "1.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.53",
    "maxTonnage": "1.53"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K\/KM3",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K\/KM4",
    "seats": "1.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.53",
    "maxTonnage": "1.53"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K\/TB",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K\/TK1",
    "seats": "1.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.43",
    "maxTonnage": "1.43"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K\/TK2",
    "seats": "1.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.43",
    "maxTonnage": "1.43"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K3",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K3\/KM2",
    "seats": "1.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.88",
    "maxTonnage": "1.88"
  },
  {
    "brand": "JAC",
    "model": "HFC1041K3\/TK1",
    "seats": "1.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.78",
    "maxTonnage": "1.78"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K\/KM1",
    "seats": "1.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.69",
    "maxTonnage": "1.69"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K\/KM2",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K\/KM3",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K\/TK1",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K1\/KM1",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K1\/KM2",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K1\/TK1A",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K1\/TK1B",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K2\/KM1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K2\/KM2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K2\/KM3",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K2\/TK2B",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1042K2\/TL",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K\/KM1",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K\/KM2",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K\/TK1",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K1",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K2\/KM1",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K2\/KM2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K2\/KM3",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K2\/TK1A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1044K2\/TK1B",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1045K",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K\/KM1",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K\/TK1",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K2",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K2\/KM1",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K2\/KM2",
    "seats": "2.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.83",
    "maxTonnage": "2.83"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K2\/TK1",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K3",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K3\/KM2",
    "seats": "2.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.13",
    "maxTonnage": "2.13"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K3\/KM3",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K3\/KM4",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K3\/TK1",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K3\/TK2",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K4\/AK",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K4\/KM1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K4\/KM2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K4\/TK1A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K4\/TK1B",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1047K4\/TL",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/COV",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/CTĐ",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/CTĐ",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/HTA",
    "seats": "3.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.39",
    "maxTonnage": "3.39"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/KM1",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/TAN",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/TK1B",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/TL",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/TUP",
    "seats": "3.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.96",
    "maxTonnage": "3.96"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/TUP",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "JAC",
    "model": "HFC1048K\/TUP",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "JAC",
    "model": "HFC1061 KT2",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K\/KM1",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K\/TK1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K1\/KM1",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K1\/KM2",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K1\/TK1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K2\/KM1",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K2\/TK1B",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/CTĐ",
    "seats": "4.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.52",
    "maxTonnage": "4.52"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/CTĐ",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/CTĐ",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/KM1",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/KM1",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/TK1B",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/TL",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/TTCM",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "JAC",
    "model": "HFC1061K3\/TTCM",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT\/KM1",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT\/KM2",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT\/KM3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT\/KM4",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT\/TK1",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT\/TK2",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT1\/KM1",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT1\/KM2",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT1\/KM3",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT1\/KM3",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT1\/KM4",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT1\/TK1",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT1\/TK2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1061KT2",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "JAC",
    "model": "HFC1071L1\/KM1",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K\/KM2",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K\/KM4",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K\/THV",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K\/TK1",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K\/TK1",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K1\/AK",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K1\/KM1",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "JAC",
    "model": "HFC1083K1\/TK1",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "JAC",
    "model": "HFC1090L1\/KM1",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "JAC",
    "model": "HFC1161P3K1A50S3V",
    "seats": "9.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.35",
    "maxTonnage": "9.35"
  },
  {
    "brand": "JAC",
    "model": "HFC1183K1\/HUH",
    "seats": "6.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.81",
    "maxTonnage": "6.81"
  },
  {
    "brand": "JAC",
    "model": "HFC1183K1\/KM1",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1183K1\/KM3",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "JAC",
    "model": "HFC1183K1\/TINPHAT",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1183K1\/TK1B",
    "seats": "7.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.15",
    "maxTonnage": "7.15"
  },
  {
    "brand": "JAC",
    "model": "HFC1183K1\/TK2B",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "JAC",
    "model": "HFC1183K1\/TTCM",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "JAC",
    "model": "HFC1202K1R1\/TRACI",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "JAC",
    "model": "HFC1253K1R1",
    "seats": "11.09 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.09",
    "maxTonnage": "11.09"
  },
  {
    "brand": "JAC",
    "model": "HFC1253K1R1\/HB",
    "seats": "12.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.55",
    "maxTonnage": "12.55"
  },
  {
    "brand": "JAC",
    "model": "HFC1253K1R1\/ĐL",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "JAC",
    "model": "HFC1255KR1\/HB",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/CKGT",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/CKGT",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/KM1",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/KM3",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/TTCM",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/TTCM",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/TTCM",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/VIMID",
    "seats": "17.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.95",
    "maxTonnage": "17.95"
  },
  {
    "brand": "JAC",
    "model": "HFC1304K1R1LT\/VL",
    "seats": "15.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.3",
    "maxTonnage": "15.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1312KR1\/CK327",
    "seats": "11.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.47",
    "maxTonnage": "11.47"
  },
  {
    "brand": "JAC",
    "model": "HFC1312KR1\/HB",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/AK",
    "seats": "18.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.85",
    "maxTonnage": "18.85"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/AKS",
    "seats": "19.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.69",
    "maxTonnage": "19.69"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/CDMB",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/CKGT",
    "seats": "18.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.3",
    "maxTonnage": "18.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/CKGT",
    "seats": "18.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.35",
    "maxTonnage": "18.35"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/CKGT",
    "seats": "21.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.7",
    "maxTonnage": "21.7"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/CKGT",
    "seats": "21.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.9",
    "maxTonnage": "21.9"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/CP",
    "seats": "18.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.5",
    "maxTonnage": "18.5"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/KM1",
    "seats": "21.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.7",
    "maxTonnage": "21.7"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/KM3",
    "seats": "22.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22",
    "maxTonnage": "22"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/MK",
    "seats": "20.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "20.8",
    "maxTonnage": "20.8"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/TKI",
    "seats": "19.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.25",
    "maxTonnage": "19.25"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/TTCM",
    "seats": "19.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.2",
    "maxTonnage": "19.2"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/TTCM",
    "seats": "19.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.4",
    "maxTonnage": "19.4"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/TTCM",
    "seats": "21.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.9",
    "maxTonnage": "21.9"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/TUP",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/VANLOI",
    "seats": "19.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.32",
    "maxTonnage": "19.32"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/VIMID",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/VIMID",
    "seats": "19.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.3",
    "maxTonnage": "19.3"
  },
  {
    "brand": "JAC",
    "model": "HFC1341KR1T\/VIMID",
    "seats": "22.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.03",
    "maxTonnage": "22.03"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K\/KM1",
    "seats": "9.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.15",
    "maxTonnage": "9.15"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K\/TC",
    "seats": "8.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.35",
    "maxTonnage": "8.35"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K\/TK1B",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K\/VL",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K1\/KM1",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K1\/KM1",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K1\/TK1B",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K1\/TK2B",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K1\/TL",
    "seats": "8.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.85",
    "maxTonnage": "8.85"
  },
  {
    "brand": "JAC",
    "model": "HFC1383K1\/TUP",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "JAC",
    "model": "HFC3031K",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "JAC",
    "model": "HFC3045K",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "JAC",
    "model": "HFC3072K",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC3090K",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "JAC",
    "model": "HFC3301KR1",
    "seats": "16.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.6",
    "maxTonnage": "16.6"
  },
  {
    "brand": "JAC",
    "model": "HFC350D",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "HFC830D",
    "seats": "7.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.78",
    "maxTonnage": "7.78"
  },
  {
    "brand": "JAC",
    "model": "HK6730K",
    "seats": "28 chỗ",
    "minSeat": "28",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "N200.CS",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "JAC",
    "model": "N350TK1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "JAC",
    "model": "N500TK1",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "JAC",
    "model": "QTH\/CA6DF2",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "JAC",
    "model": "QTH\/CA6DF2",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "JAC",
    "model": "QTH\/CA6DF2",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "JAC",
    "model": "QTH\/WP10",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "JAC",
    "model": "ROSA HK6730K",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "SUNRAY-V6",
    "seats": "16 chỗ",
    "minSeat": "16",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "TB\/CA6DF2",
    "seats": "9.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.75",
    "maxTonnage": "9.75"
  },
  {
    "brand": "JAC",
    "model": "TRA 1083K-TRACI",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "JAC",
    "model": "TRA 1083K-TRACI",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "JAC",
    "model": "TRA 1083K-TRACI",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "JAC",
    "model": "TRA1020K-TRACI",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "TRA1020K-TRACI",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "JAC",
    "model": "TRA1020K-TRACI",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "JAC",
    "model": "TRA1025H-TRACI",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "JAC",
    "model": "TRA1025H-TRACI",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "JAC",
    "model": "TRA1025H-TRACI",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "TRA1025T-TRACI",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "JAC",
    "model": "TRA1025T-TRACI",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "JAC",
    "model": "TRA1025T-TRACI",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JAC",
    "model": "TRA1040KSV",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "JAC",
    "model": "TRA1040KSV",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "JAC",
    "model": "TRA1040KSV",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "JAC",
    "model": "TRA1040KV",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "JAC",
    "model": "TRA1040KV",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "JAC",
    "model": "TRA1040KV",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JAC",
    "model": "TRA1041K-TRACI",
    "seats": "1.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.62",
    "maxTonnage": "1.62"
  },
  {
    "brand": "JAC",
    "model": "TRA1041K-TRACI",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "JAC",
    "model": "TRA1041K-TRACI",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "JAC",
    "model": "TRA1041K-TRACI",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "JAC",
    "model": "TRA1044H-TRACI",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "JAC",
    "model": "TRA1044H-TRACI",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "JAC",
    "model": "TRA1044H-TRACI",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "JAC",
    "model": "TRA1044H-TRACI",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "JAC",
    "model": "TRA1045K",
    "seats": "2.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.12",
    "maxTonnage": "2.12"
  },
  {
    "brand": "JAC",
    "model": "TRA1045K",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "JAC",
    "model": "TRA1045K",
    "seats": "2.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.21",
    "maxTonnage": "2.21"
  },
  {
    "brand": "JAC",
    "model": "TRA1045K",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "JAC",
    "model": "TRA1045K",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "JAC",
    "model": "TRA1047K-TRACI",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "JAC",
    "model": "TRA1047K-TRACI",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "JAC",
    "model": "TRA1047K-TRACI",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "JAC",
    "model": "TRA1047K-TRACI",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "JAC",
    "model": "TRA1047K-TRACI",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "JAC",
    "model": "TRA1062V-TRACI",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "JAC",
    "model": "TRA1062V-TRACI",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "JAC",
    "model": "TRA1062V-TRACI",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "JAC",
    "model": "TRA1083K-TRACI",
    "seats": "5.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.74",
    "maxTonnage": "5.74"
  },
  {
    "brand": "JAC",
    "model": "TRD\/CA6DF2",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "JAC",
    "model": "TT\/CA6DF2",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "JAC",
    "model": "TT\/CA6DF3",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "JAC",
    "model": "TT\/WP10",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "JAC",
    "model": "TTCM\/CA6DF2",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "JAC",
    "model": "TTCM\/CA6DF2",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "JAC",
    "model": "TTCM\/CA6DF2D",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "JAC",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "JAC",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "JAC",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "JAC",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "JAC",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "JAC",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "JAC",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAC",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA1010A2",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA1010A2",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA1010A2",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "JRD",
    "model": "Daily",
    "seats": "2.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JRD",
    "model": "Daily",
    "seats": "II-2.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JRD",
    "model": "EXCEL C",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "JRD",
    "model": "EXCEL C.TK",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "JRD",
    "model": "EXCEL C.TM",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "JRD",
    "model": "EXCEL D",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "JRD",
    "model": "EXCEL D.TK",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "JRD",
    "model": "EXCEL D.TM",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "JRD",
    "model": "EXCEL I",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "JRD",
    "model": "EXCEL I\/TK",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "JRD",
    "model": "EXCEL I\/TM",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JRD",
    "model": "EXCEL I\/TMB",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "JRD",
    "model": "EXCEL I\/TMB1",
    "seats": "1.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.36",
    "maxTonnage": "1.36"
  },
  {
    "brand": "JRD",
    "model": "EXCEL II",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "JRD",
    "model": "EXCEL S",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "JRD",
    "model": "EXCEL S.TK1",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "JRD",
    "model": "EXCEL S.TM1",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "JRD",
    "model": "MANJIA I",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "JRD",
    "model": "MANJIA I\/TK",
    "seats": "0.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.44",
    "maxTonnage": "0.44"
  },
  {
    "brand": "JRD",
    "model": "MANJIA I\/TM",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "JRD",
    "model": "MANJIA II\/TM",
    "seats": "0.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.35",
    "maxTonnage": "0.35"
  },
  {
    "brand": "JRD",
    "model": "STORM I",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "JRD",
    "model": "STORM I\/TK",
    "seats": "0.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.77",
    "maxTonnage": "0.77"
  },
  {
    "brand": "JRD",
    "model": "STORM I\/TM",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "JRD",
    "model": "Travel",
    "seats": "1.1-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JRD",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "JRD",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "JRD",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "JRD",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "JRD",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "JRD",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "JAGUAR",
    "model": "E Type",
    "seats": "FISRT-EDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "E-Pace",
    "seats": "PACE-FIRST-EDITION-P250-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "E-Pace",
    "seats": "PACE-R-DYNAMIC-P250-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "E-Pace",
    "seats": "PACE-S-P250-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "F Type",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "F-Pace",
    "seats": "PACE-",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "F-Pace",
    "seats": "PACE-PRESTIGE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "F-Pace",
    "seats": "PACE-PURE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "F-Pace",
    "seats": "PACE-R-DYNAMIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "F-Pace",
    "seats": "PACE-R-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "X Type",
    "seats": "2.1-V6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "X Type",
    "seats": "SPORTWAGON-3.0-V6-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XE",
    "seats": "2.0-T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XE",
    "seats": "25T-PORTFOLIO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XE",
    "seats": "25T-PRESTIGE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XF",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XF",
    "seats": "PREMIUM-LUXURY-3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XF",
    "seats": "PRESTIGE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XF",
    "seats": "PURE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XJ series",
    "seats": "XJL-3.0-PORTFOLIO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JAGUAR",
    "model": "XJ series",
    "seats": "XJL-5.0-SUPERCHARGED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JEEP",
    "model": "Compass",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JEEP",
    "model": "Gladiator",
    "seats": "RUBICON",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JEEP",
    "model": "Grand cherokee",
    "seats": "5.7-MT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JEEP",
    "model": "Wrangler",
    "seats": "RUBICON-2.0-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "JEEP",
    "model": "Wrangler",
    "seats": "RUBICON-UNLIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KAMAZ",
    "model": "4308\/LEXIM",
    "seats": "4.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.36",
    "maxTonnage": "4.36"
  },
  {
    "brand": "KAMAZ",
    "model": "43253-790",
    "seats": "3.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.63",
    "maxTonnage": "3.63"
  },
  {
    "brand": "KAMAZ",
    "model": "43253-790",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "KAMAZ",
    "model": "43265\/IMAE",
    "seats": "6.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.88",
    "maxTonnage": "6.88"
  },
  {
    "brand": "KAMAZ",
    "model": "43265\/IMAE",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "KAMAZ",
    "model": "43265\/IMAE",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "KAMAZ",
    "model": "43265\/IMAE",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "KAMAZ",
    "model": "43265\/TK",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "KAMAZ",
    "model": "43265\/TKI",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "KAMAZ",
    "model": "43265\/VMIC",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "KAMAZ",
    "model": "53228\/IMAE",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "KAMAZ",
    "model": "53228\/IMAE",
    "seats": "10.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.75",
    "maxTonnage": "10.75"
  },
  {
    "brand": "KAMAZ",
    "model": "53228\/IMAE",
    "seats": "11.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.76",
    "maxTonnage": "11.76"
  },
  {
    "brand": "KAMAZ",
    "model": "53228\/IMAE",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "KAMAZ",
    "model": "53228\/IMAE",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "KAMAZ",
    "model": "53228\/IMAE",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "KAMAZ",
    "model": "53229",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "KAMAZ",
    "model": "53229 Z157",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "11.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.76",
    "maxTonnage": "11.76"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "12.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.16",
    "maxTonnage": "12.16"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "12.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.41",
    "maxTonnage": "12.41"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "12.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.45",
    "maxTonnage": "12.45"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "12.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.94",
    "maxTonnage": "12.94"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "14.09 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.09",
    "maxTonnage": "14.09"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1044",
    "seats": "9.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.53",
    "maxTonnage": "9.53"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "10.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.08",
    "maxTonnage": "10.08"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "11.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.28",
    "maxTonnage": "11.28"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "11.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.69",
    "maxTonnage": "11.69"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "11.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.75",
    "maxTonnage": "11.75"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "13.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.45",
    "maxTonnage": "13.45"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-15",
    "seats": "9.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.1",
    "maxTonnage": "9.1"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "10.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.25",
    "maxTonnage": "10.25"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "10.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.38",
    "maxTonnage": "10.38"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "10.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.6",
    "maxTonnage": "10.6"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "11.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.4",
    "maxTonnage": "11.4"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "11.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.64",
    "maxTonnage": "11.64"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "11.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.84",
    "maxTonnage": "11.84"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "12.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.38",
    "maxTonnage": "12.38"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "12.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.64",
    "maxTonnage": "12.64"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "14.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.85",
    "maxTonnage": "14.85"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-1740",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-MBKM",
    "seats": "13.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.91",
    "maxTonnage": "13.91"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-PMSC",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "KAMAZ",
    "model": "53229-SAMCO",
    "seats": "10.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.63",
    "maxTonnage": "10.63"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/CK327",
    "seats": "12.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.59",
    "maxTonnage": "12.59"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/HIEP HOA",
    "seats": "10.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.22",
    "maxTonnage": "10.22"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/HIEP HOA",
    "seats": "12.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.91",
    "maxTonnage": "12.91"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/IMAE",
    "seats": "11.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.87",
    "maxTonnage": "11.87"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/IMAE",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/IMAE",
    "seats": "13.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.44",
    "maxTonnage": "13.44"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/IMAE",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/IMAE",
    "seats": "13.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.85",
    "maxTonnage": "13.85"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/IMAE",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/KPI",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/MP",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/MP10",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/MP12XD",
    "seats": "10.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.08",
    "maxTonnage": "10.08"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/MP17X",
    "seats": "13.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.08",
    "maxTonnage": "13.08"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMS",
    "seats": "11.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.64",
    "maxTonnage": "11.64"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMS",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMS",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMS",
    "seats": "13.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.44",
    "maxTonnage": "13.44"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMS",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMSC",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMSC",
    "seats": "11.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.76",
    "maxTonnage": "11.76"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMSC",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMSC",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMSC",
    "seats": "13.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.28",
    "maxTonnage": "13.28"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/PMSC",
    "seats": "9.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.96",
    "maxTonnage": "9.96"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/SGCD",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/SGCD",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/TK-MB",
    "seats": "14.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.15",
    "maxTonnage": "14.15"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/TTCM",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VANXUAN",
    "seats": "12.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.35",
    "maxTonnage": "12.35"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VANXUAN",
    "seats": "13.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.08",
    "maxTonnage": "13.08"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VANXUAN",
    "seats": "13.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.33",
    "maxTonnage": "13.33"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VL",
    "seats": "13.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.32",
    "maxTonnage": "13.32"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VL",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VMIC",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VMIC",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VMIC",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VMIC",
    "seats": "13.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.9",
    "maxTonnage": "13.9"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VMIC",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/VMIC",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/Z751",
    "seats": "13.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.29",
    "maxTonnage": "13.29"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/ĐL",
    "seats": "11.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.7",
    "maxTonnage": "11.7"
  },
  {
    "brand": "KAMAZ",
    "model": "53229\/ĐN",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "KAMAZ",
    "model": "53229C\/TEC",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "KAMAZ",
    "model": "54115\/Z751",
    "seats": "10.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.56",
    "maxTonnage": "10.56"
  },
  {
    "brand": "KAMAZ",
    "model": "55111-0000017",
    "seats": "12.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.93",
    "maxTonnage": "12.93"
  },
  {
    "brand": "KAMAZ",
    "model": "55111-01",
    "seats": "12.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.93",
    "maxTonnage": "12.93"
  },
  {
    "brand": "KAMAZ",
    "model": "55111-15",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "KAMAZ",
    "model": "55111\/VANXUAN",
    "seats": "11.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.62",
    "maxTonnage": "11.62"
  },
  {
    "brand": "KAMAZ",
    "model": "55111\/VANXUAN",
    "seats": "12.09 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.09",
    "maxTonnage": "12.09"
  },
  {
    "brand": "KAMAZ",
    "model": "65115-023",
    "seats": "13.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.87",
    "maxTonnage": "13.87"
  },
  {
    "brand": "KAMAZ",
    "model": "65115-15",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "KAMAZ",
    "model": "65115-15",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "KAMAZ",
    "model": "65115-726",
    "seats": "10.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.79",
    "maxTonnage": "10.79"
  },
  {
    "brand": "KAMAZ",
    "model": "65115-726",
    "seats": "12.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.73",
    "maxTonnage": "12.73"
  },
  {
    "brand": "KAMAZ",
    "model": "65115-726",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "KAMAZ",
    "model": "65115-726",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "KAMAZ",
    "model": "65115-726",
    "seats": "13.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.65",
    "maxTonnage": "13.65"
  },
  {
    "brand": "KAMAZ",
    "model": "65117-1729",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "KAMAZ",
    "model": "65117-1729",
    "seats": "13.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.15",
    "maxTonnage": "13.15"
  },
  {
    "brand": "KAMAZ",
    "model": "65117-1729",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "KAMAZ",
    "model": "65117-1729",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "KAMAZ",
    "model": "65117-1729",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "KAMAZ",
    "model": "65117-1729",
    "seats": "9.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.88",
    "maxTonnage": "9.88"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/CTĐ",
    "seats": "8.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.47",
    "maxTonnage": "8.47"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/CXD",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/HIEPPHAT",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/IMAE",
    "seats": "10.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.7",
    "maxTonnage": "10.7"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/IMAE",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/IMAE",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/IMAE",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/IMAE",
    "seats": "14.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.2",
    "maxTonnage": "14.2"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/SGCD",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/SGCD",
    "seats": "12.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.88",
    "maxTonnage": "12.88"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/SGCD",
    "seats": "13.21 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.21",
    "maxTonnage": "13.21"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/SGCD",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "KAMAZ",
    "model": "65117\/VMIC",
    "seats": "10.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.45",
    "maxTonnage": "10.45"
  },
  {
    "brand": "KAMAZ",
    "model": "6520-728",
    "seats": "11.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.3",
    "maxTonnage": "11.3"
  },
  {
    "brand": "KAMAZ",
    "model": "6520-728",
    "seats": "11.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.61",
    "maxTonnage": "11.61"
  },
  {
    "brand": "KAMAZ",
    "model": "6520\/VMIC",
    "seats": "11.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.75",
    "maxTonnage": "11.75"
  },
  {
    "brand": "KAMAZ",
    "model": "6540 ANTAMOT",
    "seats": "11.26 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.26",
    "maxTonnage": "11.26"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/ATM",
    "seats": "7.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.66",
    "maxTonnage": "7.66"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/HGA",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/HIEPPHAT",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "14.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.6",
    "maxTonnage": "14.6"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "16.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.8",
    "maxTonnage": "16.8"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "16.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.81",
    "maxTonnage": "16.81"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "17.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.02",
    "maxTonnage": "17.02"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "17.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.7",
    "maxTonnage": "17.7"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "17.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.96",
    "maxTonnage": "17.96"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "18.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.31",
    "maxTonnage": "18.31"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "18.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.51",
    "maxTonnage": "18.51"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "19.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.3",
    "maxTonnage": "19.3"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/IMAE",
    "seats": "19.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.41",
    "maxTonnage": "19.41"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/PMS",
    "seats": "15.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.8",
    "maxTonnage": "15.8"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/PMS",
    "seats": "16.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.29",
    "maxTonnage": "16.29"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/PMS",
    "seats": "16.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.49",
    "maxTonnage": "16.49"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/SGCD",
    "seats": "17.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.76",
    "maxTonnage": "17.76"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/SGCD",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/TK",
    "seats": "17.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.91",
    "maxTonnage": "17.91"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/TK",
    "seats": "17.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.96",
    "maxTonnage": "17.96"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/TT",
    "seats": "17.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.91",
    "maxTonnage": "17.91"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/TkI",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/VL",
    "seats": "16.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.98",
    "maxTonnage": "16.98"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/VL",
    "seats": "17.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.76",
    "maxTonnage": "17.76"
  },
  {
    "brand": "KAMAZ",
    "model": "6540\/VMIC",
    "seats": "17.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.31",
    "maxTonnage": "17.31"
  },
  {
    "brand": "KAMAZ",
    "model": "BT01",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "KAMAZ",
    "model": "BT02",
    "seats": "11.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.94",
    "maxTonnage": "11.94"
  },
  {
    "brand": "KAMAZ",
    "model": "KM",
    "seats": "14.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.25",
    "maxTonnage": "14.25"
  },
  {
    "brand": "KAMAZ",
    "model": "KMXT - OXY",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "KAMAZ",
    "model": "NĐNL16",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "KAMAZ",
    "model": "TBT-KAM",
    "seats": "10.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.91",
    "maxTonnage": "10.91"
  },
  {
    "brand": "KAMAZ",
    "model": "TH-KAM",
    "seats": "14.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.07",
    "maxTonnage": "14.07"
  },
  {
    "brand": "KAMAZ",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "KAMAZ",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "KAMAZ",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "KAMAZ",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "KAMAZ",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "KAMAZ",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "KAMAZ",
    "model": "XD 16",
    "seats": "13.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.28",
    "maxTonnage": "13.28"
  },
  {
    "brand": "KAMAZ",
    "model": "XDK16",
    "seats": "13.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.28",
    "maxTonnage": "13.28"
  },
  {
    "brand": "KAMAZ",
    "model": "XT14 3K",
    "seats": "11.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.76",
    "maxTonnage": "11.76"
  },
  {
    "brand": "KAMAZ",
    "model": "XT14 4K",
    "seats": "11.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.76",
    "maxTonnage": "11.76"
  },
  {
    "brand": "KAMAZ",
    "model": "XT16 04",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "KAMAZ",
    "model": "XTK 10.5",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "KAMAZ",
    "model": "XTK 14.NL",
    "seats": "11.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.75",
    "maxTonnage": "11.75"
  },
  {
    "brand": "KAMAZ",
    "model": "XTK 17",
    "seats": "13.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.94",
    "maxTonnage": "13.94"
  },
  {
    "brand": "KAMAZ",
    "model": "XTK 17X",
    "seats": "13.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.08",
    "maxTonnage": "13.08"
  },
  {
    "brand": "KAMAZ",
    "model": "XTK12N",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "KAMAZ",
    "model": "XTK12XD",
    "seats": "10.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.08",
    "maxTonnage": "10.08"
  },
  {
    "brand": "KAMAZ",
    "model": "XTK17",
    "seats": "13.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.57",
    "maxTonnage": "13.57"
  },
  {
    "brand": "KAMAZ",
    "model": "XTNĐ16",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "KENBO",
    "model": "6312B5-8475",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "KENBO",
    "model": "6312B5-8475",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KENBO",
    "model": "6540\/VMIC",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "KENBO",
    "model": "6540\/VMIC",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "KENBO",
    "model": "KB0.68",
    "seats": "Tải",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KENBO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "KENBO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "KENBO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "KENBO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "KENBO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "KENBO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "THACO",
    "model": "K2700 II",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "K2700\/THACO",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "KIA",
    "model": "KIA D4BH",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "KIA",
    "model": "KIA J2",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "MAZ 6312B5-8475",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "MAZ 6312B5-8475",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "MAZ-5340B3-8475",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "KIA",
    "model": "AM928",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "BONGO III",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "KIA",
    "model": "BONGO III",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KIA",
    "model": "COMBI",
    "seats": "25 chỗ",
    "minSeat": "25",
    "maxSeat": "25",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "CRDI-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "CRDI-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "EX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "EX-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "EXMT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "LX-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "S-SX-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "S-SX-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "SX-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "SXAT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carens",
    "seats": "SXMT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "2.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "2.7-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "2.9-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "EX-2.7-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "GS-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "GS-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "LS-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Carnival",
    "seats": "LX-2.9-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "1.6-AT-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "1.6-AT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "1.6-SMT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "1.6MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "2.0-AT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "GLS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "KOUP-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "KOUP-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "SIGNATURE-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "SIGNATURE-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "STANDARD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "STANDARD-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Cerato",
    "seats": "STANDARD-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Concord",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.46",
    "maxTonnage": "1.46"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K200",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "KIA",
    "model": "FRONTIER K250",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "EX-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "KOUP-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "S-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "S-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "SI-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "SLI-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "SX-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Forte",
    "seats": "SX-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "K2700",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "KIA",
    "model": "K2700",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "KIA",
    "model": "K2700",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.83",
    "maxTonnage": "0.83"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.84",
    "maxTonnage": "0.84"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.93",
    "maxTonnage": "0.93"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.96",
    "maxTonnage": "0.96"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "1.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.03",
    "maxTonnage": "1.03"
  },
  {
    "brand": "KIA",
    "model": "K2700II",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "KIA",
    "model": "K3",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "K3",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "K3",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "1.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.13",
    "maxTonnage": "1.13"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "KIA",
    "model": "K3000S",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "KIA",
    "model": "K3000SP",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "KIA",
    "model": "K3600SP",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "KIA",
    "model": "K3600SP",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "KIA",
    "model": "K7",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "1.0-AT-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "1.1-AT-SPOT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "1.1-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "1.25MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "EX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "EX-1.1-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "EX-1.1-MT-SPORTPACK",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "EX-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "EX-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "EX-MTH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "LX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "LX-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "LX-1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "LX-1.1-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "S-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SI-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SI-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SLX-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SLX-1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "STANDARD-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "STANDARD-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SX-1.1-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SX-1.1-AT-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SX-1.1-AT-SPORTPACK",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "SX-1.1-MT-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "VAN-1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Morning",
    "seats": "VAN-1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "NEW COSMOS",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "2.0-AT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "2.0-ATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "2.0-GAT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "2.4-AT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "2.4-GAT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "2.4-GT-LINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Optima",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "POWER COMBI",
    "seats": "25 chỗ",
    "minSeat": "25",
    "maxSeat": "25",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "PREGIO",
    "seats": "15 chỗ",
    "minSeat": "15",
    "maxSeat": "15",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Picanto",
    "seats": "1.1-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Picanto",
    "seats": "1.25-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Picanto",
    "seats": "1.25-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Picanto",
    "seats": "S-1.25-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Picanto",
    "seats": "S-1.25-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Pride",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Pride",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Pride",
    "seats": "BETA",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Pride",
    "seats": "CD5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Quoris",
    "seats": "3.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "RHINO",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "KIA",
    "model": "Retona",
    "seats": "CRUISER-2.0-TD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rio",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rio",
    "seats": "1.4-ATH-5DR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rio",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rio",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rio",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rio",
    "seats": "EX-1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rio",
    "seats": "EX-1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "2.0-GMT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "DAT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "DELUXE-G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "DMT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "DULUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "GAT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "GAT-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "GATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "GMT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Rondo",
    "seats": "STANDARD-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "SELTOS",
    "seats": "1.4L Turbo Deluxe",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "SELTOS",
    "seats": "1.4L Turbo Luxury",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "SELTOS",
    "seats": "1.4L Turbo Premium",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "SELTOS",
    "seats": "1.6 Premium",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "SONET",
    "seats": "AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "SONET",
    "seats": "MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "2.2-DAT-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "2.2-DAT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "2.2L-DAT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "2.2L-DATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "3.3-GAT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "3.3L-GAT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "3.3L-GATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "DELUXE-D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "LUXURY-D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "LUXURY-G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "PLATINUM-D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "PLATINUM-D-2.2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "PLATINUM-G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sedona",
    "seats": "PREMIUM-D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Soluto",
    "seats": "1.4-AT-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Soluto",
    "seats": "1.4-AT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Soluto",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Soluto",
    "seats": "1.4-MT-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "2.2-DAT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "2.4-GAT-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "2.4-GAT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "2.5-AT-CRDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "DATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "DATH-2.2L-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "DELUXE-G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "DMT-2.2L-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "GAT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "GAT-2.4L-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "GAT-2.4L-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "GATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "GATH-2.4L-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "GMT-2.4L-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "LIMITED-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "LIMITED-2.4-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "PREMIUM-D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "PREMIUM-G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sorento",
    "seats": "S-GAT-2.4L-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Soul",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Soul",
    "seats": "4U-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Spectra",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Spectra",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sportage",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sportage",
    "seats": "2.0-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sportage",
    "seats": "2.0-MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sportage",
    "seats": "GT-LINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sportage",
    "seats": "LIMITED-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Sportage",
    "seats": "TLX-2.0-AT-CRDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "TC",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "KIA",
    "model": "TOWNERS",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "KIA",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "KIA",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "KIA",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "KIA",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "KIA",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "KIA",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "KIA",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "KIA",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA1031",
    "seats": "10.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.08",
    "maxTonnage": "10.08"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA1031",
    "seats": "10.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.48",
    "maxTonnage": "10.48"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA1031",
    "seats": "11.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.04",
    "maxTonnage": "11.04"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA1031",
    "seats": "11.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.56",
    "maxTonnage": "11.56"
  },
  {
    "brand": "LADA",
    "model": "2107",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAMBOGHINI",
    "model": "Huracan",
    "seats": "LP580-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAMBOGHINI",
    "model": "Huracan",
    "seats": "LP610-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAMBOGHINI",
    "model": "Urus",
    "seats": "4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAMBOGHINI",
    "model": "Urus",
    "seats": "4.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Defender",
    "seats": "110-FIRST-EDITION-P400-3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Defender",
    "seats": "90-2.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery",
    "seats": "3.5-V8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery",
    "seats": "HSE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery",
    "seats": "HSE-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery",
    "seats": "LR3-HSE-4.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery",
    "seats": "LR5-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery",
    "seats": "SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery Sport",
    "seats": "HSE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery Sport",
    "seats": "HSE-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery Sport",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Discovery Sport",
    "seats": "SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Freelander",
    "seats": "LR2-HSE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-3.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-4.4-SDV8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-5.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-LWB",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-LWB-2.0-P400E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-LWB-3.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-LWB-3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-LWB-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-LWB-5.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "AUTOBIOGRAPHY-LWB-BLACK-EDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "HSE-3.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "HSE-3.0-I6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "HSE-3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "HSE-4.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "HSE-LWB-3.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "HSE-SUPERCHARDGE-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "HSE-SUPERCHARDGED-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "SUPERCHARGED-4.2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "SUPERCHARGED-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "SUPERCHARGED-LWB-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "SUPERCHARGED-LWB-5.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "SV-AUTOBIOGRAPHY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "SV-AUTOBIOGRAPHY-LWB-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "SVAUTOBIOGRAPHY-LWB-3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "SVAUTOBIOGRAPHY-LWB-5.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "VOGUE-3.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover",
    "seats": "VOGUE-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "DYNAMIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "DYNAMIC-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "HSE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "HSE-DYNAMIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "HSE-DYNAMIC-CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "PRESTIGE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "PURE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "PURE-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "R-DYNAMIC-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "R-DYNAMIC-SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Evoque",
    "seats": "SE-PLUS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "2.0L-PHEV",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "AUTOBIOGRAPHY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "AUTOBIOGRAPHY-5.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "AUTOBIOGRAPHY-5.0-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "HSE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "HSE-2.0-SI4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "HSE-3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "HSE-DYNAMIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "HSE-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "SE-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Sport",
    "seats": "SUPERCHARGED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "HSE-R-DYNAMIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "R---DYNAMIC-SE-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "R-DYNAMIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "R-DYNAMIC-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "R-DYNAMIC-HSE-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "R-DYNAMIC-S-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "R-DYNAMIC-SE-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LAND ROVER",
    "model": "Range Rover Velar",
    "seats": "SE-3.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "ES",
    "seats": "250",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "ES",
    "seats": "300-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "ES",
    "seats": "300-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "ES",
    "seats": "300H",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "ES",
    "seats": "330",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "ES",
    "seats": "350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "GS",
    "seats": "200T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "GS",
    "seats": "300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "GS",
    "seats": "350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "GS",
    "seats": "350-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "GX",
    "seats": "460",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "GX",
    "seats": "460-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "GX",
    "seats": "470",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "HS",
    "seats": "250H",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "IS",
    "seats": "250",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "IS",
    "seats": "250C",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "IS",
    "seats": "300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "400",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "430",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "460",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "460-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "460L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "460L-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "500",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "500H",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LS",
    "seats": "600HL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LX",
    "seats": "470",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LX",
    "seats": "570",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LX",
    "seats": "570-BLACK-EDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LX",
    "seats": "570-BLACK-EDITION-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LX",
    "seats": "570-INSPIRATION-SERIES",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LX",
    "seats": "570-MBS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LX",
    "seats": "570-SUPER-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "LX",
    "seats": "570-SUPER-SPORT-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "NX",
    "seats": "200T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "NX",
    "seats": "200T-F-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "NX",
    "seats": "300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RC",
    "seats": "200T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RC",
    "seats": "350-F-SPORT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "200T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "330",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "350-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "350-F-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "350-F-SPORT-",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "350-FSPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "350L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LEXUS",
    "model": "RX",
    "seats": "450H",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LINCOLN",
    "model": "Corsair",
    "seats": "RESERVE-2.3-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LINCOLN",
    "model": "Navigator",
    "seats": "BLACK-LABEL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LINCOLN",
    "model": "Town car",
    "seats": "EXECUTIVE-LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LUXGEN",
    "model": "7 MPV",
    "seats": "CEO-ROYALOUNGE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LUXGEN",
    "model": "M7",
    "seats": "2.2-T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LUXGEN",
    "model": "S5",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "LUXGEN",
    "model": "U7",
    "seats": "2.2T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "CANTER (TK) FE535",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "CANTER (TK) FE645",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "CANTER (TT",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "CANTER - SAMCO",
    "seats": "10.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.3",
    "maxTonnage": "10.3"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "11.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.65",
    "maxTonnage": "11.65"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "12.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.8",
    "maxTonnage": "12.8"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "13.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.12",
    "maxTonnage": "13.12"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "13.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.35",
    "maxTonnage": "13.35"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "13.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.4",
    "maxTonnage": "13.4"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "14.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.8",
    "maxTonnage": "14.8"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "PASO 990D",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "TTCM\/D2066",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "MAN (XE TAI)",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "437041-268",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "437041-268",
    "seats": "4.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.4",
    "maxTonnage": "4.4"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "437041-268",
    "seats": "5.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.01",
    "maxTonnage": "5.01"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "533603-220",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "533603-220",
    "seats": "8.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.57",
    "maxTonnage": "8.57"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "543203-220",
    "seats": "8.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.77",
    "maxTonnage": "8.77"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "551605-271",
    "seats": "11.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.5",
    "maxTonnage": "11.5"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "555102-2120",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "555102-2123",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "555102-223",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "630300-2140",
    "seats": "10.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.28",
    "maxTonnage": "10.28"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "630305-220",
    "seats": "10.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.1",
    "maxTonnage": "10.1"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "630305-220",
    "seats": "10.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.25",
    "maxTonnage": "10.25"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "630305-220",
    "seats": "10.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.4",
    "maxTonnage": "10.4"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "630305-220",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "630305-220",
    "seats": "13.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.17",
    "maxTonnage": "13.17"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "630305-220",
    "seats": "9.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.13",
    "maxTonnage": "9.13"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "6312B5-8475",
    "seats": "12.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.45",
    "maxTonnage": "12.45"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "6312B5-8475",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "6312B5-8475",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "6312B5-8475",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "6312B5-8475",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "642205-220",
    "seats": "12.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.3",
    "maxTonnage": "12.3"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "642205-220",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "642205-222",
    "seats": "14.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.47",
    "maxTonnage": "14.47"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "642208-232",
    "seats": "14.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.07",
    "maxTonnage": "14.07"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "643008-020",
    "seats": "9.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.62",
    "maxTonnage": "9.62"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "651705-282",
    "seats": "10.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.52",
    "maxTonnage": "10.52"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "MAZ 6312B5",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "MAZ-5340B3",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "MAZ_DAU_KEO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "MEKONG",
    "model": "CA1031K4",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "MEKONG",
    "model": "CA1031K4",
    "seats": "1.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.11",
    "maxTonnage": "1.11"
  },
  {
    "brand": "MEKONG",
    "model": "PASO 1.5TD",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "MEKONG",
    "model": "PASO 2.0TD",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "MEKONG",
    "model": "PASO 2.0TD",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "MEKONG",
    "model": "PASO 2.5TD",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "MEKONG",
    "model": "PASO 990D",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "MEKONG",
    "model": "PASO 990D",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "MEKONG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "MEKONG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "MEKONG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "MEKONG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "MEKONG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "MEKONG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CITY LINER OF 8000",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "EURO 2 CONNECTOR",
    "seats": "84 chỗ",
    "minSeat": "84",
    "maxSeat": "84",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "EURO 2 CONNECTOR 1",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "EURO 2 CONNECTOR 2",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "EURO 2 GALAXY",
    "seats": "170 chỗ",
    "minSeat": "17",
    "maxSeat": "17",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "EURO II CITY STAR",
    "seats": "90 chỗ",
    "minSeat": "90",
    "maxSeat": "90",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "ORIENT STAR",
    "seats": "49 chỗ",
    "minSeat": "49",
    "maxSeat": "49",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "SPRINTER 311CDI",
    "seats": "16 chỗ",
    "minSeat": "16",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "SPRINTER 313 CDI",
    "seats": "16 chỗ",
    "minSeat": "16",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "STAR AIRBUS",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "STAR EXPRESS",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "TK.Me.01",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "TOURIST LINER",
    "seats": "44 chỗ",
    "minSeat": "44",
    "maxSeat": "44",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "TĐ.Me.01",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER \/THQ",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER 3,5T",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE515",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE515B8LDD3",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "0.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.81",
    "maxTonnage": "0.81"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.54",
    "maxTonnage": "1.54"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.58",
    "maxTonnage": "1.58"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.66",
    "maxTonnage": "1.66"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.67",
    "maxTonnage": "1.67"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.72",
    "maxTonnage": "1.72"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE535E6LDD3",
    "seats": "3.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.18",
    "maxTonnage": "3.18"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "1.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.62",
    "maxTonnage": "1.62"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "1.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.82",
    "maxTonnage": "1.82"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "1.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.88",
    "maxTonnage": "1.88"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "2.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.55",
    "maxTonnage": "2.55"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.14",
    "maxTonnage": "3.14"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.23 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.23",
    "maxTonnage": "3.23"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.27",
    "maxTonnage": "3.27"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.32",
    "maxTonnage": "3.32"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE645E SAMCO",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "3.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.71",
    "maxTonnage": "3.71"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "3.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.83",
    "maxTonnage": "3.83"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "3.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.88",
    "maxTonnage": "3.88"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.07",
    "maxTonnage": "4.07"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.11",
    "maxTonnage": "4.11"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE659F6LDD3",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.16",
    "maxTonnage": "1.16"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.68",
    "maxTonnage": "1.68"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.69",
    "maxTonnage": "1.69"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.87",
    "maxTonnage": "1.87"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.06",
    "maxTonnage": "2.06"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.27 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.27",
    "maxTonnage": "2.27"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.34",
    "maxTonnage": "3.34"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE73PE6SLDD1",
    "seats": "3.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.61",
    "maxTonnage": "3.61"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.14",
    "maxTonnage": "3.14"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.75",
    "maxTonnage": "3.75"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.93",
    "maxTonnage": "3.93"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1",
    "seats": "4.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.38",
    "maxTonnage": "4.38"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE84PE6SLDD1 ",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "2.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.46",
    "maxTonnage": "2.46"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.7",
    "maxTonnage": "4.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PG6SLDD1",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PHZSLDD1",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER FE85PHZSLDD1",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER-SAMCO",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER-SAMCO",
    "seats": "3.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.24",
    "maxTonnage": "3.24"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER\/HIEP HOA",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER\/HIEP HOA",
    "seats": "2.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.98",
    "maxTonnage": "2.98"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER\/THQ",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CANTER\/TK",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "CX.Mi.01",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "MITSUBISHI",
    "model": "DL - MITSUBISHI",
    "seats": "2.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.05",
    "maxTonnage": "2.05"
  },
  {
    "brand": "MITSUBISHI",
    "model": "ER.Mi.01",
    "seats": "2.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.19",
    "maxTonnage": "2.19"
  },
  {
    "brand": "MITSUBISHI",
    "model": "FE535E ",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "FE645E",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "FN617R",
    "seats": "15.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.6",
    "maxTonnage": "15.6"
  },
  {
    "brand": "MITSUBISHI",
    "model": "FV517\/HT",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "MITSUBISHI",
    "model": "MI.LC",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "MIT CANTER.TĐ",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Mi.01",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TB.Mi.01",
    "seats": "3.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.24",
    "maxTonnage": "3.24"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TC-MITS",
    "seats": "2.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.62",
    "maxTonnage": "2.62"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TCMICA",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TH.Mi.01",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TK.Mi.01",
    "seats": "3.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.18",
    "maxTonnage": "3.18"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TT3,5T-MITS",
    "seats": "2.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.24",
    "maxTonnage": "2.24"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TT4T - MIT",
    "seats": "3.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.29",
    "maxTonnage": "3.29"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TT4T - MITSU",
    "seats": "3.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.36",
    "maxTonnage": "3.36"
  },
  {
    "brand": "MITSUBISHI",
    "model": "TĐMICA",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "MASERATI",
    "model": "Ghibli",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MASERATI",
    "model": "GranTurismo",
    "seats": "MC-SPORT-LINE-4.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MASERATI",
    "model": "Quattroporte",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "1.5-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "1.5L-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "NEW",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "PREMIUM-SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "SEDAN-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "SPORT-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "2",
    "seats": "SPORT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "-1.5L-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5-HATCHBACK",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5-SEDAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5-SPORT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5-SPORT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L-FACELIFT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L-SPORT-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L-SPORT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.5L-SPORT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "2.0-SEDAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "2.0L-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "2.0L-SEDAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "2.0L-SIGNATURE-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "2.0L-SPORT-SIGNATURE-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "2.0L-SPORT-SIGNATURE-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "ALL--NEW",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "ALL-NEW",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "ALL-NEW-1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "HATCHBACK-1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "S-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "S-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "SPORT-1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "SPORT-2.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "3",
    "seats": "SPORT-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "323",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "323",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "323",
    "seats": "CLASSIC-GLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "323",
    "seats": "GLXI-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "323",
    "seats": "STANDARD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "323",
    "seats": "STANDARD-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "2.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "2.0L-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "2.3-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "2.5L-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "DELUXE-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "LUXURY-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "PREMIUM-2.0AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "6",
    "seats": "PREMIUM-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "626",
    "seats": "1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "626",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "929",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "929",
    "seats": "3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "2.2L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "2.2L-4X2-ATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "2.2L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "3.0L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "3.2L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "3.2L-4X4-ATH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "DELUXE-2.2L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "DELUXE-4X2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "LUXURY-2.2L-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "PREMIUM-3.2L-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "STANDARD-2.2L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "BT 50",
    "seats": "STANDARD-4X4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX 9",
    "seats": "3.7-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX-3",
    "seats": "1.5 Deluxe",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX-30",
    "seats": "Luxury",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX-30",
    "seats": "Premium",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX8",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX8",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX8",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX8",
    "seats": "PREMIUM-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "CX9",
    "seats": "3.7-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.0-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.0-DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.0-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.0-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.5-AT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.5-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.5-SIGNATURE-PREMIUM-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.5-SIGNATURE-PREMIUM-2WD-I-ACTIV",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "2.5-SIGNATURE-PREMIUM-AWD-I-ACTIV",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "DELUXE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx5",
    "seats": "PREMIUM-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx8",
    "seats": "2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Cx8",
    "seats": "2.5-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "MX 5",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "Premacy",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "RX 8",
    "seats": "1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MAZDA",
    "model": "RX 8",
    "seats": "R3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MEKONG",
    "model": "Premio",
    "seats": "GX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MEKONG",
    "model": "Pronto",
    "seats": "DX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MEKONG",
    "model": "Pronto",
    "seats": "GX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MEKONG",
    "model": "Pronto",
    "seats": "II",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MEKONG",
    "model": "Star",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MEKONG",
    "model": "Star",
    "seats": "2.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MEKONG",
    "model": "Star",
    "seats": "2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "A class",
    "seats": "A200",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "A class",
    "seats": "A250",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "A class",
    "seats": "A250-SPORT-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "300-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C180",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C180-CLASSIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C180-ELEGANCE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C180-KOMPRESSOR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C180-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200-AVANTGARDE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200-CGI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200-ELEGANCE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200-EXCLUSIVE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200-KOMPRESSOR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200-KOMPRESSOR-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C200-KOMPRESSOR-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C230-AVANTGARDE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C240-AVANTGARDE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C250",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C250-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C250-CGI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C250-EXCLUSIVE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C280-AVANTGARDE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C300-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "C class",
    "seats": "C300-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CL class",
    "seats": "CL-550-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLA class",
    "seats": "CLA-200",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLA class",
    "seats": "CLA-250",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLA class",
    "seats": "CLA-250-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLA class",
    "seats": "CLA-45-AMG-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLK class",
    "seats": "CLK-200-CABRIOLET",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLS class",
    "seats": "CLS-300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLS class",
    "seats": "CLS-350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLS class",
    "seats": "CLS-500",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLS class",
    "seats": "CLS-500-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "CLS class",
    "seats": "CLS-550",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "180",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E-180",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E180",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E200",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E200-EDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E200-EXCLUSIVE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E200-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E220-CDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E230",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E230-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E230-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E240",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E240-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E250",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E250-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E280",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E300",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E300-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E300-AMG-CBU",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E350-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E350-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E400",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "E class",
    "seats": "E400-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "G class",
    "seats": "63",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "G class",
    "seats": "63-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "G class",
    "seats": "G290",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "G class",
    "seats": "G500",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "G class",
    "seats": "G63-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "350-4MATIC-BLUETECH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "550",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "GL-320-CDI-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "GL-350-BLUETEC-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "GL-350-CDI-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "GL-400-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "GL-450-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "GL-500-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "GL-550-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GL",
    "seats": "GL-550-AMG-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLA class",
    "seats": "GLA-200",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLA class",
    "seats": "GLA-250-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLA class",
    "seats": "GLA-45-AMG-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLB",
    "seats": "200-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLC",
    "seats": "200",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLC",
    "seats": "200-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLC",
    "seats": "250-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLC",
    "seats": "300-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLC",
    "seats": "300-4MATIC-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLC",
    "seats": "300-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLC",
    "seats": "300-COUPE-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLE Class",
    "seats": "GLE-300D-AMG-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLE Class",
    "seats": "GLE-350D-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLE Class",
    "seats": "GLE-400-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLE Class",
    "seats": "GLE-400-4MATIC-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLE Class",
    "seats": "GLE-400-4MATIC-EXCLUSIVE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLE Class",
    "seats": "GLE-43-AMG-4MATIC-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLE Class",
    "seats": "GLE-450-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLE Class",
    "seats": "GLE-450-AMG-4MATIC-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLK Class",
    "seats": "GLK220-CDI-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLK Class",
    "seats": "GLK250-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLK Class",
    "seats": "GLK250-AMG-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLK Class",
    "seats": "GLK280-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLK Class",
    "seats": "GLK300-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLK Class",
    "seats": "GLK300-AMG-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLS",
    "seats": "350D-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLS",
    "seats": "400-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLS",
    "seats": "450-4MACTIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLS",
    "seats": "450-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "GLS",
    "seats": "500-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "MB",
    "seats": "100",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "MB",
    "seats": "100D-VAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "MB",
    "seats": "140",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "MB",
    "seats": "140D",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "ML Class",
    "seats": "3.5-V6---4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "ML Class",
    "seats": "ML350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "ML Class",
    "seats": "ML400-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "ML Class",
    "seats": "ML400-AMG-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "ML Class",
    "seats": "ML400-CDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "ML Class",
    "seats": "ML500",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "ML Class",
    "seats": "ML63-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Maybach",
    "seats": "S400",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Maybach",
    "seats": "S450-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Maybach",
    "seats": "S560-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Maybach",
    "seats": "S600",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Maybach",
    "seats": "S650",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "R class",
    "seats": "R350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "R class",
    "seats": "R350-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "R class",
    "seats": "R500-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "450L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "MAYBACH-S400",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "MAYBACH-S450-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "MAYBACH-S500",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "MAYBACH-S560-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "MAYBACH-S600",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "MAYBACH-S650",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S280",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S300L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S400",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S400-HYBRID",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S400L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S430",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S450-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S450L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S450L-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S500",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S500-4MATIC-COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S500-SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S500L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S550",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S600",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S63-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "S class",
    "seats": "S63-AMG-4MATIC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "SL class",
    "seats": "SL550",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "SLK class",
    "seats": "SLK-200",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "SLK class",
    "seats": "SLK-350",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "311-CDI-2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "311-ESP",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "313",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "313-2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "313-CDI-2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "313-ESP",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "313-LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "BUSINESS-311",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "EXECUTIVE-313",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "PANEL-VAN-311",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "SPECIAL-311-CDI-2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "SPECIAL-313-CDI-2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "VAN-2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Sprinter",
    "seats": "VAN-311-CDI-2.2L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "V class",
    "seats": "V220D-CDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "V class",
    "seats": "V250-AMG",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "V class",
    "seats": "V250-AVANTGARDE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "V class",
    "seats": "V250-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MERCEDES BENZ",
    "model": "Vito",
    "seats": "TOURER-121",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "3DR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "5DR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "CLUBMAN-JCW",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "CLUBMAN-JCW-ALL4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "CLUBMAN-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "CLUBMAN-S-ALL4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "COUNTRYMAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "COUNTRYMAN-JCW",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "COUNTRYMAN-JCW-ALL4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "COUNTRYMAN-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "JCW-3DR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "JCW-CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "S-3DR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "S-5-DOOR-LCI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "S-5DR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "S-CLUBMAN-LCI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "Cooper",
    "seats": "S-CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MINI",
    "model": "One",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Attrage",
    "seats": "1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Attrage",
    "seats": "1.2-CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Attrage",
    "seats": "1.2-CVT-ECO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Attrage",
    "seats": "1.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Attrage",
    "seats": "1.2-MT-ECO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Attrage",
    "seats": "1.2CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Attrage",
    "seats": "1.2MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Colt",
    "seats": "PLUS-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Eclipse",
    "seats": "GS-SPORT-SPYDER",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Eclipse",
    "seats": "SPYDER",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Grandis",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Jolie",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Jolie",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Jolie",
    "seats": "GLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Jolie",
    "seats": "MB",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Jolie",
    "seats": "SS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "L300",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "L300",
    "seats": "VAN-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "L300",
    "seats": "VAN-2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "FORTIS-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "FORTIS-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "FOTIS-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "GALA-GLX-1.6AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "GLX-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "GLX-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Lancer",
    "seats": "GLXI-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Mirage",
    "seats": "1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Mirage",
    "seats": "1.2-CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Mirage",
    "seats": "1.2-CVT-ECO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Mirage",
    "seats": "1.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Mirage",
    "seats": "1.2-MT-ECO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Mirage",
    "seats": "1.2CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Mirage",
    "seats": "1.2MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander",
    "seats": "2.0-CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander",
    "seats": "2.0-CVT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander",
    "seats": "2.0-CVT-SPECIAL-EDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander",
    "seats": "2.0-STD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander",
    "seats": "2.4-CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander",
    "seats": "2.4-CVT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander",
    "seats": "2.4-CVT-SPECIAL-EDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander",
    "seats": "2.4-SE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Outlander Sport",
    "seats": "2.0-CVT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "2.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "2.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "2.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "3.0-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero",
    "seats": "3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-2.4D-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-2.4D-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-2.5AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-3.0G-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-3.0G-4X2-AT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-3.0G-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-3.0G-4X4-AT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-D-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-D-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-D-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-G-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Pajero Sport",
    "seats": "SPORT-G-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X2-AT-MIVEC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X2-AT-MIVEC-BASE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X2-AT-MIVEC-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X4-AT-MIVEC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X4-AT-MIVEC-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "4X4-MT-MIVEC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "ATHLETE-4X2-AT-MIVEC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "GL-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "GLS-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "GLS-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Triton",
    "seats": "GLX-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Xpander",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Xpander",
    "seats": "1.5-AT-SPECIAL-EDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Xpander",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Zinger",
    "seats": "GL-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Zinger",
    "seats": "GLS-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "MITSUBISHI",
    "model": "Zinger",
    "seats": "GLS-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "350Z",
    "seats": "COUPE-3.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Almera",
    "seats": "CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Almera",
    "seats": "MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Armada",
    "seats": "LE-PLATINUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Bluebird",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Bluebird",
    "seats": "LE-1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Bluebird",
    "seats": "SE-1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Bluebird",
    "seats": "SE-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Bluebird",
    "seats": "SELECT-SALOON-1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Bluebird",
    "seats": "SSS-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Bluebird",
    "seats": "SUPER-SELECT-1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Bluebird",
    "seats": "SYLPHY-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Cedric",
    "seats": "3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Cedric",
    "seats": "3.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Cefiro",
    "seats": "2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Grand livina",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Grand livina",
    "seats": "1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Juke",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Murano",
    "seats": "SL-3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Murano",
    "seats": "SL-3.5-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "E-2.5-MT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "E-2.5MT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "EL-2.5-AT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "EL-2.5AT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "EL-A-IVI-2.5-AT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "EL-PREMIUM-R",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "EL-PREMIUM-Z",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "LE-2.5-MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "LE-2.5MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "SL-2.5-MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "SL-2.5MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "VL-2.5-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "VL-PREMIUM-R",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "VL-PREMIUM-Z",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Navara",
    "seats": "XE-2.5AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Pathfinder",
    "seats": "2.4-MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Pathfinder",
    "seats": "3.0-MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Patrol",
    "seats": "4.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Pixo",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Primera",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Qashqai",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Qashqai",
    "seats": "AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Qashqai",
    "seats": "LE-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Qashqai",
    "seats": "SE-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Quest",
    "seats": "SE-3.5-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Quest",
    "seats": "SL-3.5-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Rogue",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Rogue",
    "seats": "SL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sentra",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Stanza",
    "seats": "2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "1.5MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "Q-SERIES-XT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "Q-SERIES-XV-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "SUPER-SALOON",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "XL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "XT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "XT-Q-SERIES",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "XV",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "XV-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Sunny",
    "seats": "XV-PREMIUM-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Teana",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Teana",
    "seats": "2.5-SL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Terra",
    "seats": "2.5-AT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Terra",
    "seats": "E-2.5-AT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Terra",
    "seats": "S-2.5-MT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Terra",
    "seats": "V-2.5-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Terrano",
    "seats": "2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Terrano",
    "seats": "II-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Tiida",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Tiida",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.0-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.0-2WD-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.0-SL-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.0-SL-2WD-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.0-SL-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.0-VL-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.5-SV-2WD-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.5-SV-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.5-SV-4WD-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "2.5-SV-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "V-SERIES-2.0-SL-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "V-SERIES-2.0-SL-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "V-SERIES-2.0-SL-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "V-SERIES-2.5-SV-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "V-SERIES-2.5-SV-LUXURY-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "X trail",
    "seats": "V-SERIES-2.5-SV-PREMIUM-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "NISSAN",
    "model": "Xterra",
    "seats": "2.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "OPEL",
    "model": "Vectra",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "107",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "2008",
    "seats": "Active",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "2008",
    "seats": "GT Line",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "206",
    "seats": "CC-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "207",
    "seats": "1.6-AT-CC",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "208",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "3008",
    "seats": "1.6-AL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "3008",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "3008",
    "seats": "1.6-AT-FL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "3008",
    "seats": "1.6-AT-TCM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "3008",
    "seats": "1.6-TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "3008",
    "seats": "ACTIVE-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "3008",
    "seats": "AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "405",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "405",
    "seats": "MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "408",
    "seats": "PREMIUM-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "5008",
    "seats": "1.6-AL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "5008",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "5008",
    "seats": "1.6-AT-TCM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "5008",
    "seats": "ACTIVE-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "508",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "RCZ",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "Traveller",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PEUGEOT",
    "model": "Traveller",
    "seats": "PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "911",
    "seats": "CARRERA",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayenne",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayenne",
    "seats": "3.0-V6-DIESEL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayenne",
    "seats": "3.2I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayenne",
    "seats": "3.6-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayenne",
    "seats": "COUPE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayenne",
    "seats": "GTS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayenne",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayenne",
    "seats": "TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Cayman",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Macan",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Macan",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Panamera",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Panamera",
    "seats": "3.6-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Panamera",
    "seats": "4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Panamera",
    "seats": "4S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Panamera",
    "seats": "4S-2.9-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Panamera",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Panamera",
    "seats": "TURBO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "PORSCHE",
    "model": "Panamera",
    "seats": "TURBO-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "RENAULT",
    "model": "KERAX",
    "seats": "Xe tải",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "RENAULT",
    "model": "19",
    "seats": "1.9-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "RENAULT",
    "model": "Duster",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "RENAULT",
    "model": "Fluence",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "RENAULT",
    "model": "Koleos",
    "seats": "2.5-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "RENAULT",
    "model": "Megane",
    "seats": "RS-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ROLLS - ROYCE",
    "model": "Cullinan",
    "seats": "6.75-V12",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ROLLS - ROYCE",
    "model": "Ghost",
    "seats": "6.6-V12",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ROLLS - ROYCE",
    "model": "Ghost",
    "seats": "EWB-6.6-V12",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ROLLS - ROYCE",
    "model": "Ghost",
    "seats": "SERIES-II",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ROLLS - ROYCE",
    "model": "Ghost",
    "seats": "SERIES-II-EWB",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ROLLS - ROYCE",
    "model": "Phantom",
    "seats": "EWB",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "ALLERGO SI KGP1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "ALLERGO SI KGP1E4",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "ALLERGO SI KGP2E4",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "B.Is.01A",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BB1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BB1A",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BB2",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BB3",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BB4",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BE2",
    "seats": "66 chỗ",
    "minSeat": "66",
    "maxSeat": "66",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BE3",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BE4",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BE5",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BE6",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BE7",
    "seats": "17 chỗ",
    "minSeat": "17",
    "maxSeat": "17",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BE8",
    "seats": "37 chỗ",
    "minSeat": "37",
    "maxSeat": "37",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BE9",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BF1",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BF2",
    "seats": "75 chỗ",
    "minSeat": "75",
    "maxSeat": "75",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG1",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG2",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG3",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG4",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG4i",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG4w",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG5",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG5A",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG6",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG6A",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG6i",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG6w",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG7",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG7i",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG7w",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG8",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG8A",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BG9",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGA",
    "seats": "28 chỗ",
    "minSeat": "28",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGAi",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGAw",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGP1",
    "seats": "24 chỗ",
    "minSeat": "24",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGP2",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGP2a",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGP3",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGP4",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGP5",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGP6",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGP7",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGQ1",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGQ2",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGQ3",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BGQ4",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BL1",
    "seats": "48 chỗ",
    "minSeat": "48",
    "maxSeat": "48",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BL2",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BSA1",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BSB1",
    "seats": "61 chỗ",
    "minSeat": "61",
    "maxSeat": "61",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BT1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BT3",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BT4",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "BV1",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY BES1",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY BGC2",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY BGP8",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY BGQ5",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY BGQ5-1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY BGQ6",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY H60 BF3",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY H68 BF4",
    "seats": "68 chỗ",
    "minSeat": "68",
    "maxSeat": "68",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY H68 BF4-1",
    "seats": "68 chỗ",
    "minSeat": "68",
    "maxSeat": "68",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "CITY M75 BXM1",
    "seats": "75 chỗ",
    "minSeat": "75",
    "maxSeat": "75",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "ERIS03",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "SAMCO",
    "model": "ERKA01",
    "seats": "10.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.63",
    "maxTonnage": "10.63"
  },
  {
    "brand": "SAMCO",
    "model": "ERKA02",
    "seats": "10.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.63",
    "maxTonnage": "10.63"
  },
  {
    "brand": "SAMCO",
    "model": "ERMI02",
    "seats": "1.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.82",
    "maxTonnage": "1.82"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX CI KGQA",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX CI KGQAE4",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX CI KGQB",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX CI KGQBE4",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX CI KGQCE4",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX CI KGQDE4",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX GI KGQ10",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX GI KGQ1E4",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX GI KGQ2E4",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX GI KGQ9",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX LI KGQ3",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX LI KGQ3-1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX LI KGQ6",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX LIMOUSINE KGQ5",
    "seats": "17 chỗ",
    "minSeat": "17",
    "maxSeat": "17",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX LIMOUSINE KGQ8",
    "seats": "17 chỗ",
    "minSeat": "17",
    "maxSeat": "17",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX SI KGQ4",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX SI KGQ4-1",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "FELIX SI KGQ7",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "GROWIN CM.29 KSW1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "HD",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "HLKA01",
    "seats": "13.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.49",
    "maxTonnage": "13.49"
  },
  {
    "brand": "SAMCO",
    "model": "KES1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KES2",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KES3",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KFC1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KFE1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KFE2",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KFE3",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KFE4",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KFE5",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KFE5-1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KFEB1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KGQ1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "KGQ2",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "PRIMAS KFE6",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "PRIMAS SI KGV1",
    "seats": "37 chỗ",
    "minSeat": "37",
    "maxSeat": "37",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "SE3",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "SAMCO",
    "model": "SE4",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "SAMCO",
    "model": "SG1",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "SAMCO",
    "model": "TB.Mi.01",
    "seats": "3.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.24",
    "maxTonnage": "3.24"
  },
  {
    "brand": "SAMCO",
    "model": "TBIS03",
    "seats": "8.06 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.06",
    "maxTonnage": "8.06"
  },
  {
    "brand": "SAMCO",
    "model": "TBIS04",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "SAMCO",
    "model": "TCMI01",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "SAMCO",
    "model": "TCMI02",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "SAMCO",
    "model": "TE1",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "SAMCO",
    "model": "TG1",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "SAMCO",
    "model": "TK.Da.02",
    "seats": "0.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.89",
    "maxTonnage": "0.89"
  },
  {
    "brand": "SAMCO",
    "model": "TKMI02",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "SAMCO",
    "model": "TM1",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "SAMCO",
    "model": "TM2",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "SAMCO",
    "model": "TM5",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "SAMCO",
    "model": "TNIS01",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "SAMCO",
    "model": "TĐIS02",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "SAMCO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "SAMCO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "SAMCO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "SAMCO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "SAMCO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "SAMCO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "SAMCO",
    "model": "WENDA GI.34 KGV4",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "WENDA LH KFE7",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "WENDA LH KFE9",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "WENDA LI.42 KGV5",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "WENDA SD KSD1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "WENDA SD KSD2",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "WENDA SH KFE8",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "WENDA SH KFE8-1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "XDIS01",
    "seats": "4.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.28",
    "maxTonnage": "4.28"
  },
  {
    "brand": "SAMCO",
    "model": "XDMI01",
    "seats": "11.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.16",
    "maxTonnage": "11.16"
  },
  {
    "brand": "SAMCO",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMCO",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMSUNG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "SAMSUNG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "SAMSUNG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "SAMSUNG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "SAMSUNG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "SAMSUNG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "SAMSUNG",
    "model": "Ô tô tải",
    "seats": "16.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.8",
    "maxTonnage": "16.8"
  },
  {
    "brand": "SAMSUNG",
    "model": "Ô tô tải",
    "seats": "22.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.35",
    "maxTonnage": "22.35"
  },
  {
    "brand": "SCANIA",
    "model": "P114CB6x4HZ",
    "seats": "9.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.65",
    "maxTonnage": "9.65"
  },
  {
    "brand": "SCANIA",
    "model": "P114CB6x4NZ",
    "seats": "10.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.02",
    "maxTonnage": "10.02"
  },
  {
    "brand": "SCANIA",
    "model": "P114CB6x4NZ",
    "seats": "9.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.9",
    "maxTonnage": "9.9"
  },
  {
    "brand": "SCANIA",
    "model": "P114CB8X4HZ",
    "seats": "9.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.94",
    "maxTonnage": "9.94"
  },
  {
    "brand": "SCANIA",
    "model": "P114CB8x4NZ",
    "seats": "14.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.19",
    "maxTonnage": "14.19"
  },
  {
    "brand": "SCANIA",
    "model": "P124CB8x4NZ",
    "seats": "13.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.83",
    "maxTonnage": "13.83"
  },
  {
    "brand": "SCANIA",
    "model": "P310 CB6x4EHZ",
    "seats": "11.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.49",
    "maxTonnage": "11.49"
  },
  {
    "brand": "SCANIA",
    "model": "P310CB6x4EHZ",
    "seats": "10.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.15",
    "maxTonnage": "10.15"
  },
  {
    "brand": "SCANIA",
    "model": "P310CB6x4EHZ",
    "seats": "10.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.91",
    "maxTonnage": "10.91"
  },
  {
    "brand": "SCANIA",
    "model": "P310CB6x4EHZ",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "SCANIA",
    "model": "P340 CB6x4EHZ",
    "seats": "11.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.92",
    "maxTonnage": "11.92"
  },
  {
    "brand": "SCANIA",
    "model": "P340 CB6x4EHZ",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "SCANIA",
    "model": "P340CB6x4EHZ",
    "seats": "10.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.55",
    "maxTonnage": "10.55"
  },
  {
    "brand": "SCANIA",
    "model": "P340CB6x4EHZ",
    "seats": "9.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.17",
    "maxTonnage": "9.17"
  },
  {
    "brand": "SCANIA",
    "model": "P340CB6x4EHZ",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "SCANIA",
    "model": "P340CB6x4ENZ",
    "seats": "10.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.43",
    "maxTonnage": "10.43"
  },
  {
    "brand": "SCANIA",
    "model": "P340CB6x4ENZ",
    "seats": "9.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.17",
    "maxTonnage": "9.17"
  },
  {
    "brand": "SCANIA",
    "model": "P360 CB6X4EHZ",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "SCANIA",
    "model": "P360 CB6X4EHZ",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "SCANIA",
    "model": "P380 CB8X4ENZ",
    "seats": "15.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "15"
  },
  {
    "brand": "SCANIA",
    "model": "P380 CB8x4EHZ",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "SCANIA",
    "model": "P380 CB8x4EHZ",
    "seats": "16.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.87",
    "maxTonnage": "16.87"
  },
  {
    "brand": "SCANIA",
    "model": "P94CB6x4HZ",
    "seats": "10.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.33",
    "maxTonnage": "10.33"
  },
  {
    "brand": "SCANIA",
    "model": "P94CB6x4NZ",
    "seats": "10.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.33",
    "maxTonnage": "10.33"
  },
  {
    "brand": "SCANIA",
    "model": "P94CB6x4NZ",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "SCANIA",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "SCANIA",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "SCANIA",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "SCANIA",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "SCANIA",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "SCANIA",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "SHACMAN",
    "model": "BT\/WP10",
    "seats": "17.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.8",
    "maxTonnage": "17.8"
  },
  {
    "brand": "SHACMAN",
    "model": "COV\/WP6",
    "seats": "11.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.96",
    "maxTonnage": "11.96"
  },
  {
    "brand": "SHACMAN",
    "model": "DELTA-CXMCD",
    "seats": "15.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.15",
    "maxTonnage": "15.15"
  },
  {
    "brand": "SHACMAN",
    "model": "HP\/WP10",
    "seats": "22.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.1",
    "maxTonnage": "22.1"
  },
  {
    "brand": "SHACMAN",
    "model": "RUI 3252",
    "seats": "9.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.75",
    "maxTonnage": "9.75"
  },
  {
    "brand": "SHACMAN",
    "model": "SX1317HR406",
    "seats": "16.28 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.28",
    "maxTonnage": "16.28"
  },
  {
    "brand": "SHACMAN",
    "model": "SX3257HR324",
    "seats": "12.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.6",
    "maxTonnage": "12.6"
  },
  {
    "brand": "SHACMAN",
    "model": "SX3315MR306U",
    "seats": "16.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.4",
    "maxTonnage": "16.4"
  },
  {
    "brand": "SHACMAN",
    "model": "SX3317HR306",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "SHACMAN",
    "model": "SX3317HR306",
    "seats": "16.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.5",
    "maxTonnage": "16.5"
  },
  {
    "brand": "SHACMAN",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "SHACMAN",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "SHACMAN",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "SHACMAN",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "SHACMAN",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "SHACMAN",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "SHACMAN",
    "model": "X30\/WP10",
    "seats": "19.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.24",
    "maxTonnage": "19.24"
  },
  {
    "brand": "SHACMAN",
    "model": "X30\/WP10",
    "seats": "19.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.8",
    "maxTonnage": "19.8"
  },
  {
    "brand": "SRM",
    "model": "T30",
    "seats": "Tải",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "12A",
    "seats": "12 chỗ",
    "minSeat": "12",
    "maxSeat": "12",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "12B",
    "seats": "12 chỗ",
    "minSeat": "12",
    "maxSeat": "12",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY-LAMBERET",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY-QUYEN AUTO",
    "seats": "0.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.42",
    "maxTonnage": "0.42"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY-QUYEN AUTO",
    "seats": "0.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.54",
    "maxTonnage": "0.54"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY-QUYEN AUTO",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ADT",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AK",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AK",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AK",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ANLAC",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ANLAC",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ANLAC",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ANLAC",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ANLAC",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ANLAC",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ANLAC",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ANLAC",
    "seats": "0.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.71",
    "maxTonnage": "0.71"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AS",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AV",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AV",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AV",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AV",
    "seats": "0.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.66",
    "maxTonnage": "0.66"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/AVS",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/BTH",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/BTR",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/CDMN",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/CDMN",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/CKGT",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/CKGT",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/CKGT",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/CMN",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/CP",
    "seats": "0.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.4",
    "maxTonnage": "0.4"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DELTA",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DELTA",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DELTA",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DELTA",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DELTA",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.47",
    "maxTonnage": "0.47"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.61",
    "maxTonnage": "0.61"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.64",
    "maxTonnage": "0.64"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DV",
    "seats": "0.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.67",
    "maxTonnage": "0.67"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/DVI",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HGA",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HIEPHOA",
    "seats": "0.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.41",
    "maxTonnage": "0.41"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HIEPHOA",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HP",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HP",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HP",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HP",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HP",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HP",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/HP-TK",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/KIENMINH",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/LAMBERET",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NAMYEN",
    "seats": "0.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.4",
    "maxTonnage": "0.4"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.64",
    "maxTonnage": "0.64"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.66",
    "maxTonnage": "0.66"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/NISU",
    "seats": "0.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.67",
    "maxTonnage": "0.67"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGCD",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGCD",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.51",
    "maxTonnage": "0.51"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.54",
    "maxTonnage": "0.54"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.64",
    "maxTonnage": "0.64"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/SGS",
    "seats": "0.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.66",
    "maxTonnage": "0.66"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/THQ",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TINPHAT",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TINPHAT",
    "seats": "0.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.63",
    "maxTonnage": "0.63"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TRONGTHIEN",
    "seats": "0.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.4",
    "maxTonnage": "0.4"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TRONGTHIEN",
    "seats": "0.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.51",
    "maxTonnage": "0.51"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TRONGTHIEN",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TRONGTHIEN",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TRONGTHIEN",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TRONGTHIEN",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TRONGTHIEN",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TS",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TS",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TS",
    "seats": "0.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.64",
    "maxTonnage": "0.64"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TS",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TTCM",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TTCM",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/TTCM",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VA",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VA",
    "seats": "0.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.67",
    "maxTonnage": "0.67"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VANDAO",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VANDAO",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VANDAO",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VANDAO",
    "seats": "0.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.64",
    "maxTonnage": "0.64"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VANDAO",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VANDAO",
    "seats": "0.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.71",
    "maxTonnage": "0.71"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VANDAO",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VN",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VN",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VĐ",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VĐ",
    "seats": "0.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.67",
    "maxTonnage": "0.67"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/VĐ",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "SUZUKI",
    "model": "CARRY\/ĐTP",
    "seats": "0.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.35",
    "maxTonnage": "0.35"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/PT",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/PT",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/ĐN",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/ĐN",
    "seats": "0.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.54",
    "maxTonnage": "0.54"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/ĐN",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/ĐN",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/ĐV",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/ĐV",
    "seats": "0.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.54",
    "maxTonnage": "0.54"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/ĐV",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410-K\/ĐV",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K",
    "seats": "0.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.42",
    "maxTonnage": "0.42"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K-ANLAC",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K-ANLAC",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K-QUYEN AUTO",
    "seats": "0.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.37",
    "maxTonnage": "0.37"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K-QUYEN AUTO",
    "seats": "0.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.47",
    "maxTonnage": "0.47"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K-QUYEN AUTO",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K-QUYEN AUTO",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ALL",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/AV",
    "seats": "0.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.43",
    "maxTonnage": "0.43"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/CKGT",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/CKGT",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/CTĐ",
    "seats": "0.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.41",
    "maxTonnage": "0.41"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.43",
    "maxTonnage": "0.43"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.44",
    "maxTonnage": "0.44"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.47",
    "maxTonnage": "0.47"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/HP",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/KPI",
    "seats": "0.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.32",
    "maxTonnage": "0.32"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/LAMBERET",
    "seats": "0.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.41",
    "maxTonnage": "0.41"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/NISU",
    "seats": "0.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.39",
    "maxTonnage": "0.39"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/NISU",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/NISU",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/NISU",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/NISU",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/NTL",
    "seats": "0.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.47",
    "maxTonnage": "0.47"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/NTL",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/PT",
    "seats": "0.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.44",
    "maxTonnage": "0.44"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/PT",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/PT",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/SGS",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/SGS",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/SGS",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/SGS",
    "seats": "0.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.54",
    "maxTonnage": "0.54"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/SGS",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/SGS",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/SGS",
    "seats": "0.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.61",
    "maxTonnage": "0.61"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/THQ",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/TRONGTHIEN",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/TRONGTHIEN",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/TS",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/TS",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/TTCM",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/TTCM",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/VA",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/VĐ",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/VĐ",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐL",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐL",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐL",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐN-TB",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐN-TKD",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐN-TKN",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐN-TLD",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐN-TMD",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐN-TMN",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐN-TĐL",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐV-TB",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐV-TB",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐV-TKD",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐV-TKN",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐV-TLD",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐV-TMD",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐV-TMN",
    "seats": "0.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.54",
    "maxTonnage": "0.54"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K\/ĐV-TMN",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4-ANLAC",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4-ANLAC",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4-ANLAC",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4-ANLAC",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4-ANLAC",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4-SAMCO",
    "seats": "0.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.35",
    "maxTonnage": "0.35"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/ADT",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/AK",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/AV",
    "seats": "0.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.47",
    "maxTonnage": "0.47"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/AV",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/CKGT",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/CMN",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/DELTA",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/DELTA",
    "seats": "0.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.47",
    "maxTonnage": "0.47"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/DELTA",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/DELTA",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/DV",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/DV",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/DV",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/DVI",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/HIEPHOA",
    "seats": "0.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.32",
    "maxTonnage": "0.32"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/HP",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/HP",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/HP",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/KIENMINH",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/KM",
    "seats": "0.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.42",
    "maxTonnage": "0.42"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/LAMBERET",
    "seats": "0.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.37",
    "maxTonnage": "0.37"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/NISU",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/PHUMAN",
    "seats": "0.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.42",
    "maxTonnage": "0.42"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/PHUMAN",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/PHUMAN",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/SGCD",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/SGS",
    "seats": "0.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.4",
    "maxTonnage": "0.4"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/SGS",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/SGS",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/SGS",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/TRONGTHIEN",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/TRONGTHIEN",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/TTCM",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/VA",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/VANDAO",
    "seats": "0.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.42",
    "maxTonnage": "0.42"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/VANDAO",
    "seats": "0.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.46",
    "maxTonnage": "0.46"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/VANDAO",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/VANDAO",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/VN",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/VN",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "SUZUKI",
    "model": "SK410K4\/ĐTP",
    "seats": "0.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.4",
    "maxTonnage": "0.4"
  },
  {
    "brand": "SUZUKI",
    "model": "TK.A",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "TK.B",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "TKC",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "SUZUKI",
    "model": "TĐ.A",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "SUZUKI",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "SUZUKI",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "SUZUKI",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "SUZUKI",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "SUZUKI",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "SUZUKI",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "SUZUKI",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "SC1-A",
    "seats": "0.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.88",
    "maxTonnage": "0.88"
  },
  {
    "brand": "SYM",
    "model": "SC1-A2",
    "seats": "0.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.88",
    "maxTonnage": "0.88"
  },
  {
    "brand": "SYM",
    "model": "SJ1-A",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "SYM",
    "model": "SJ1-A",
    "seats": "1.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.96",
    "maxTonnage": "1.96"
  },
  {
    "brand": "SYM",
    "model": "T1000",
    "seats": "VAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-D\/12TL\/HIEPHOA",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-D\/12TL\/HIEPHOA",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-D\/12TL\/HIEPHOA",
    "seats": "0.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.67",
    "maxTonnage": "0.67"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-D\/12TL\/HIEPHOA",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-D\/12TL\/HIEPHOA",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-D\/12TL\/HIEPHOA",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-E4",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-E4",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-E4",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-E4",
    "seats": "0.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.69",
    "maxTonnage": "0.69"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-E4",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-E4-12MB\/DUL",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-E4-12MB\/DUL",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "SYM",
    "model": "TMT SUPER ACE-E4-12MB\/DUL",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "SYM",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "SYM",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "SYM",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "SYM",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "SYM",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "SYM",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "SYM",
    "model": "ULTRA814",
    "seats": "0.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.72",
    "maxTonnage": "0.72"
  },
  {
    "brand": "SYM",
    "model": "ULTRA814",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "SYM",
    "model": "V11-SC3-C2",
    "seats": "11 chỗ",
    "minSeat": "11",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "V5",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SYM",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMSUNG",
    "model": "QM5",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SAMSUNG",
    "model": "SM3",
    "seats": "SE-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SMART",
    "model": "Fortwo",
    "seats": "0.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SMART",
    "model": "Fortwo",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Actyon",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Korando",
    "seats": "TX-5-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Korando",
    "seats": "TX-5-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Korando",
    "seats": "TX-5-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Korando",
    "seats": "TX-5-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Kyron",
    "seats": "2.0XDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Musso",
    "seats": "2.3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Musso",
    "seats": "2.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Musso",
    "seats": "2.3-MT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Musso",
    "seats": "2.9",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Musso",
    "seats": "LIBERO-2.3-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Rexton",
    "seats": "2.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Rexton",
    "seats": "3.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Stavic",
    "seats": "2.7-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SSANGYONG",
    "model": "Tivoli",
    "seats": "1.6-AT-2WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "BRZ",
    "seats": "SE-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Forester",
    "seats": "2.0I-L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Forester",
    "seats": "2.0I-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Forester",
    "seats": "2.0I-S-EYESIGHT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Forester",
    "seats": "2.0XT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Legacy",
    "seats": "GT-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Legacy",
    "seats": "WAGON-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Levorg",
    "seats": "1.6-GT-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Outback",
    "seats": "2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Outback",
    "seats": "2.5I",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Outback",
    "seats": "2.5I-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Outback",
    "seats": "2.5I-S-EYESIGHT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Outback",
    "seats": "3.6R",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Outback",
    "seats": "EYESIGHT-2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "Tribeca",
    "seats": "3.6-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "WRX",
    "seats": "STI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "XV",
    "seats": "2.0I-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUBARU",
    "model": "XV",
    "seats": "2.0I-S-EYESIGHT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "APV",
    "seats": "GL-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "APV",
    "seats": "GLX-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Celerio",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Celerio",
    "seats": "1.0-CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Celerio",
    "seats": "1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Ciaz",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Cultis wagon",
    "seats": "NHAP-KHAU",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Ertiga",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Ertiga",
    "seats": "GL-1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Ertiga",
    "seats": "GL-1.5MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Ertiga",
    "seats": "GLX-1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Grand vitara",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Grand vitara",
    "seats": "XL-7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Jimny",
    "seats": "4AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Jimny",
    "seats": "5MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "SX4",
    "seats": "Cross 1.5AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "SX4",
    "seats": "Cross 1.5MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Super Carry Van",
    "seats": "BLIND-VAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Super Carry Van",
    "seats": "WINDOW-VAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Swift",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Swift",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Swift",
    "seats": "GL-1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Swift",
    "seats": "GLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Swift",
    "seats": "GLX-1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Swift",
    "seats": "GLX-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Swift",
    "seats": "RS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Swift",
    "seats": "SPECIAL-1.2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Vitara",
    "seats": "1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Vitara",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Vitara",
    "seats": "JLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "Wagon R+",
    "seats": "1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "SUZUKI",
    "model": "XL7",
    "seats": "GLX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TATA",
    "model": "SUPER ACE\/05TK",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "TATA",
    "model": "TMT SUPER ACE",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "TATA",
    "model": "TMT SUPER ACE",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "TATA",
    "model": "TMT SUPER ACE",
    "seats": "0.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.87",
    "maxTonnage": "0.87"
  },
  {
    "brand": "TATA",
    "model": "TMT SUPER ACE",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "TATA",
    "model": "TMT SUPER ACE",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "TATA",
    "model": "TMT SUPER ACE",
    "seats": "1.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.13",
    "maxTonnage": "1.13"
  },
  {
    "brand": "TATA",
    "model": "TMT SUPER ACE",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "TATA",
    "model": "TMT SUPER ACE",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "TATA",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "TATA",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "TATA",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "TATA",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "TATA",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "TATA",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "TATA",
    "model": "ULTRA814",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "TATA",
    "model": "ULTRA814",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "TATA",
    "model": "ULTRA814",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "TATA",
    "model": "ULTRA814",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "TATA",
    "model": "ULTRA814",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-MB1",
    "seats": "0.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.97",
    "maxTonnage": "0.97"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-TK1",
    "seats": "0.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.93",
    "maxTonnage": "0.93"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-TL1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-TL2",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-VT",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-VT",
    "seats": "0.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.91",
    "maxTonnage": "0.91"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-VT",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-VTL",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-VTL",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 100-VTL",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190-VT",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190-VTL",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190-VTL",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190-VTL",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190-VTL",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "TERACO",
    "model": "TERA 190-VTL",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 230-VTL",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240-VTL",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240-VTL",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L-MB",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L-MB1",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L-TK",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L-TL",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L-VT",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L-VTL",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L-VTL",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240L-VTL",
    "seats": "2.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.46",
    "maxTonnage": "2.46"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240S",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240S",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240S",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240S-MB",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240S-TK",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240S-TL",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "TERACO",
    "model": "TERA 240S-VTL",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TERACO",
    "model": "TERA 245L",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "TERACO",
    "model": "TERA 245L",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "TERACO",
    "model": "TERA 245L-MB",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TERACO",
    "model": "TERA 245L-TL",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 245L-VTL",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.42",
    "maxTonnage": "2.42"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.87",
    "maxTonnage": "2.87"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-MB",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-MB1",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-TK",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-TL",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-TL",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-VT",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-VTL",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-VTL",
    "seats": "2.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.37",
    "maxTonnage": "2.37"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-VTL",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-VTL",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-VTL",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-VTL",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "TERACO",
    "model": "TERA 250-VTL",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "TERACO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "TERACO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "TERACO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "TERACO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "TERACO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "TERACO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "THACO",
    "model": "53228\/IMAE",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "THACO",
    "model": "6540\/IMAE ",
    "seats": "16.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.8",
    "maxTonnage": "16.8"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C1790",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C2300",
    "seats": "13.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.6",
    "maxTonnage": "13.6"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C2400",
    "seats": "14.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.22",
    "maxTonnage": "14.22"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C2400A",
    "seats": "12.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.4",
    "maxTonnage": "12.4"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C2400A",
    "seats": "12.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.58",
    "maxTonnage": "12.58"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C2400A",
    "seats": "12.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.95",
    "maxTonnage": "12.95"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C2400A",
    "seats": "14.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.3",
    "maxTonnage": "14.3"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C2400A",
    "seats": "14.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.7",
    "maxTonnage": "14.7"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C3000",
    "seats": "16.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.3",
    "maxTonnage": "16.3"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C3000A",
    "seats": "10.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.69",
    "maxTonnage": "10.69"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C3000A",
    "seats": "10.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.8",
    "maxTonnage": "10.8"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C3000A",
    "seats": "11.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.46",
    "maxTonnage": "11.46"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C3000A",
    "seats": "17.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.1",
    "maxTonnage": "17.1"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C3000A",
    "seats": "17.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.15",
    "maxTonnage": "17.15"
  },
  {
    "brand": "THACO",
    "model": "AUMAN C3000A",
    "seats": "7.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.42",
    "maxTonnage": "7.42"
  },
  {
    "brand": "THACO",
    "model": "AUMAN D2550",
    "seats": "11.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.2",
    "maxTonnage": "11.2"
  },
  {
    "brand": "THACO",
    "model": "AUMAN D3300",
    "seats": "15.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.5",
    "maxTonnage": "15.5"
  },
  {
    "brand": "THACO",
    "model": "AUMAN KRM3300",
    "seats": "15.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.2",
    "maxTonnage": "15.2"
  },
  {
    "brand": "THACO",
    "model": "AUMAN1290",
    "seats": "10.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.65",
    "maxTonnage": "10.65"
  },
  {
    "brand": "THACO",
    "model": "AUMAN1290",
    "seats": "12.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.71",
    "maxTonnage": "12.71"
  },
  {
    "brand": "THACO",
    "model": "AUMAN1290",
    "seats": "12.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.9",
    "maxTonnage": "12.9"
  },
  {
    "brand": "THACO",
    "model": "AUMAN820",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "THACO",
    "model": "AUMAN990",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "THACO",
    "model": "AUMAN990",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "THACO",
    "model": "AUMAN990",
    "seats": "9.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.9",
    "maxTonnage": "9.9"
  },
  {
    "brand": "THACO",
    "model": "AUMAND1300",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "THACO",
    "model": "AUMARK198",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "THACO",
    "model": "AUMARK198",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "THACO",
    "model": "AUMARK198",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "THACO",
    "model": "AUMARK198A",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "THACO",
    "model": "AUMARK250",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "THACO",
    "model": "AUMARK250",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "THACO",
    "model": "AUMARK250",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "THACO",
    "model": "AUMARK250",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "AUMARK250A",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "THACO",
    "model": "AUMARK250A",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "AUMARK250CD",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "AUMARK345",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "AUMARK345",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "AUMARK345",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "THACO",
    "model": "AUMARK345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "AUMARK450",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "THACO",
    "model": "AUMARK450",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "THACO",
    "model": "AUMARK450",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FC099L",
    "seats": "0.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.83",
    "maxTonnage": "0.83"
  },
  {
    "brand": "THACO",
    "model": "FC099L",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "THACO",
    "model": "FC099L",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FC125",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "THACO",
    "model": "FC125",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "THACO",
    "model": "FC125",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "FC150",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "FC150",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "THACO",
    "model": "FC150",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "THACO",
    "model": "FC150",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "THACO",
    "model": "FC200",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "THACO",
    "model": "FC200",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "THACO",
    "model": "FC200",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FC200\/QTH",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "THACO",
    "model": "FC2200",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "THACO",
    "model": "FC2200",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "FC2300",
    "seats": "0.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.88",
    "maxTonnage": "0.88"
  },
  {
    "brand": "THACO",
    "model": "FC2300",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "THACO",
    "model": "FC2300",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FC250",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "THACO",
    "model": "FC250",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "THACO",
    "model": "FC250",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "THACO",
    "model": "FC250",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "FC2600",
    "seats": "0.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.88",
    "maxTonnage": "0.88"
  },
  {
    "brand": "THACO",
    "model": "FC2600",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "THACO",
    "model": "FC2600",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FC3300",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "THACO",
    "model": "FC3300",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "THACO",
    "model": "FC3300",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "FC345",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "FC345",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "FC345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FC350",
    "seats": "2.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.74",
    "maxTonnage": "2.74"
  },
  {
    "brand": "THACO",
    "model": "FC350",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FC350",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "FC350",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "THACO",
    "model": "FC3900",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "THACO",
    "model": "FC3900",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "THACO",
    "model": "FC3900",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FC4100",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "FC4100",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "FC4100",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FC4100K",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "FC4100K",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "FC4100K",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FC4200",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FC4200",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "THACO",
    "model": "FC450",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "THACO",
    "model": "FC450",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FC450\/QUOCVIET",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "THACO",
    "model": "FC4800",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FC4800",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "THACO",
    "model": "FC4800\/ATM",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FC499",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "THACO",
    "model": "FC500",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FC500",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "THACO",
    "model": "FC500",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "THACO",
    "model": "FC600",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FC700",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "THACO",
    "model": "FC700",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "FD099",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FD125",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "FD200",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FD200A",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FD200B",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FD2200",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "FD2300A",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FD2700",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FD2700A",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FD345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FD345A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FD35",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FD3500A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FD3800",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FD3800A",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FD4100",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FD4100A",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FD4100A1",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FD4200A",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FD450",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FD499",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "THACO",
    "model": "FD600",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FD600\/CITYPACK",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "THACO",
    "model": "FD600A",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FD600B",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FD800",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "THACO",
    "model": "FLC099",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FLC125",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "THACO",
    "model": "FLC125",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "THACO",
    "model": "FLC125",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "FLC150",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "FLC150",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "THACO",
    "model": "FLC150",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "THACO",
    "model": "FLC150",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "THACO",
    "model": "FLC198",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "THACO",
    "model": "FLC198",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "THACO",
    "model": "FLC198",
    "seats": "1.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.78",
    "maxTonnage": "1.78"
  },
  {
    "brand": "THACO",
    "model": "FLC198",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "THACO",
    "model": "FLC250",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "THACO",
    "model": "FLC250",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "THACO",
    "model": "FLC250",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "THACO",
    "model": "FLC250",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "THACO",
    "model": "FLC250",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "FLC300",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "THACO",
    "model": "FLC300",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "THACO",
    "model": "FLC300",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FLC345",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "THACO",
    "model": "FLC345",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "THACO",
    "model": "FLC345",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FLC345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FLC345A",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FLC345A",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "THACO",
    "model": "FLC345A",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "FLC345A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FLC345A",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "THACO",
    "model": "FLC345B",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "THACO",
    "model": "FLC345B",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "FLC345B",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "FLC345B",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FLC450",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "THACO",
    "model": "FLC450",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FLC450\/QUOCVIET",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "THACO",
    "model": "FLC450A",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "THACO",
    "model": "FLC450A",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FLC600A",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "THACO",
    "model": "FLC600A",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "THACO",
    "model": "FLC600A",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FLC600B",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "FLC700A",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "THACO",
    "model": "FLC800",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "FLC800",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "THACO",
    "model": "FLC800",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "THACO",
    "model": "FLC800",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "THACO",
    "model": "FLD",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FLD099A",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FLD099B",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FLD1000",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "FLD150",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "THACO",
    "model": "FLD150A",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "THACO",
    "model": "FLD200A",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FLD250",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "FLD250A",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "THACO",
    "model": "FLD250A",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "FLD250B",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "THACO",
    "model": "FLD300",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FLD345A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FLD345B",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FLD345B\/HUH",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "THACO",
    "model": "FLD499",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "THACO",
    "model": "FLD500",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "THACO",
    "model": "FLD600A",
    "seats": "4.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.07",
    "maxTonnage": "4.07"
  },
  {
    "brand": "THACO",
    "model": "FLD600A",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "THACO",
    "model": "FLD600A",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FLD600B",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "THACO",
    "model": "FLD600B",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "THACO",
    "model": "FLD600B",
    "seats": "5.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.36",
    "maxTonnage": "5.36"
  },
  {
    "brand": "THACO",
    "model": "FLD600B",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "THACO",
    "model": "FLD600B",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "THACO",
    "model": "FLD600B\/VL",
    "seats": "5.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.04",
    "maxTonnage": "5.04"
  },
  {
    "brand": "THACO",
    "model": "FLD700",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "FLD700A",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "THACO",
    "model": "FLD750",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "THACO",
    "model": "FLD750",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "THACO",
    "model": "FLD800",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "THACO",
    "model": "FLD800",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "THACO",
    "model": "FLD800B",
    "seats": "7.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.13",
    "maxTonnage": "7.13"
  },
  {
    "brand": "THACO",
    "model": "FLD800B",
    "seats": "7.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.63",
    "maxTonnage": "7.63"
  },
  {
    "brand": "THACO",
    "model": "FLD800B\/HUH",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER 125",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER 140",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER 140",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER 140",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER 140",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER125",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER125",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER125",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER125",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER125",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER125",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER125",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "1.34 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.34",
    "maxTonnage": "1.34"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "THACO",
    "model": "FRONTIER140",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "THACO",
    "model": "FTC345",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "FTC345",
    "seats": "3.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.05",
    "maxTonnage": "3.05"
  },
  {
    "brand": "THACO",
    "model": "FTC345",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "FTC345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "FTC450",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "THACO",
    "model": "FTC450",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "FTC700",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "THACO",
    "model": "FTC700",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "FTC820",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "THACO",
    "model": "FTC820",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "THACO",
    "model": "FTD1200",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "THACO",
    "model": "FTD1250",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "THACO",
    "model": "HB120ESL",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120S",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120S-H380",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120S-H380-1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120S-H380I",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120S-H410",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H380",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H380-1",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H380I",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H380L",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H380R",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H380RI",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H410",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H410-1",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H410L",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SL-H410R",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SLD",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SLD-B",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SLS",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SS",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB120SSL",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB70CS",
    "seats": "25 chỗ",
    "minSeat": "25",
    "maxSeat": "25",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB70CT",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB70CT-H140",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB70ES",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB70S-H140",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB73CT-H140",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB73CT-H140I",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB73CT-H140II",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB73CT-H140III",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB73S-H140",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB73S-H140I",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB73S-H140II",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB73S-H140II-1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB90ES",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB90ETS",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB90HF",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HB90LF",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HC112L",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HC550",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "THACO",
    "model": "HC600",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "HC750",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "THACO",
    "model": "HC750",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "THACO",
    "model": "HC750",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "THACO",
    "model": "HC750",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "THACO",
    "model": "HC750",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "THACO",
    "model": "HC750-MBB",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "THACO",
    "model": "HD270",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "THACO",
    "model": "HD270",
    "seats": "12.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.7",
    "maxTonnage": "12.7"
  },
  {
    "brand": "THACO",
    "model": "HD270M",
    "seats": "12.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.33",
    "maxTonnage": "12.33"
  },
  {
    "brand": "THACO",
    "model": "HD345",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "HD345",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "HD345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "1.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.74",
    "maxTonnage": "1.74"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "2.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.85",
    "maxTonnage": "2.85"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "THACO",
    "model": "HD350",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "HD450",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "THACO",
    "model": "HD450",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "THACO",
    "model": "HD450",
    "seats": "3.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.65",
    "maxTonnage": "3.65"
  },
  {
    "brand": "THACO",
    "model": "HD450",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "THACO",
    "model": "HD450",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "3.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.85",
    "maxTonnage": "3.85"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "4.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.62",
    "maxTonnage": "4.62"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "4.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.63",
    "maxTonnage": "4.63"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "4.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.75",
    "maxTonnage": "4.75"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "THACO",
    "model": "HD500",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "THACO",
    "model": "HD65",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "4.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.44",
    "maxTonnage": "4.44"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.15",
    "maxTonnage": "5.15"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.17 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.17",
    "maxTonnage": "5.17"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.35",
    "maxTonnage": "5.35"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.45",
    "maxTonnage": "5.45"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.53",
    "maxTonnage": "5.53"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.61",
    "maxTonnage": "5.61"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.63",
    "maxTonnage": "5.63"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "6.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.1",
    "maxTonnage": "6.1"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "THACO",
    "model": "HD650",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.1",
    "maxTonnage": "3.1"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.12",
    "maxTonnage": "3.12"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "THACO",
    "model": "HD72",
    "seats": "3.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.7",
    "maxTonnage": "3.7"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI 115L",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI COUNTY",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI COUNTY CITY",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI COUNTY CITY II",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI COUNTY CRDi",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI COUNTY CRDi-ATP",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB115",
    "seats": "90 chỗ",
    "minSeat": "90",
    "maxSeat": "90",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB120ESL",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB120S",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB120SL",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB120SLS",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB120SSL",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB70",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB70CS",
    "seats": "25 chỗ",
    "minSeat": "25",
    "maxSeat": "25",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB90ES",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB90ETS",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB90HF",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "HYUNDAI HB90LF",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "JB70",
    "seats": "28 chỗ",
    "minSeat": "28",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "JB80SL",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "JB80SL1",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "JB86L",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "JB90C",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "K135",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "K135",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.82",
    "maxTonnage": "1.82"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.87",
    "maxTonnage": "1.87"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.05",
    "maxTonnage": "2.05"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.1",
    "maxTonnage": "2.1"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.11",
    "maxTonnage": "2.11"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.22",
    "maxTonnage": "2.22"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "THACO",
    "model": "K165",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "THACO",
    "model": "K190",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "THACO",
    "model": "K190",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "THACO",
    "model": "K190",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "THACO",
    "model": "K190",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "THACO",
    "model": "K190",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "THACO",
    "model": "K190",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "THACO",
    "model": "K190",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "THACO",
    "model": "KB110 SEI",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB110 SEII",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB110SL",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB110SLI",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB115SEI",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB115SEII",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB120 LSI",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB120SE",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB120SEI",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB120SEII",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB120SF",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB120SH",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB80SLI",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB80SLII",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB88SE I",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB88SE II",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB88SLI",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB88SLII",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "KB90LF",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "OLLIN 450A",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "THACO",
    "model": "OLLIN 450A",
    "seats": "2.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.97",
    "maxTonnage": "2.97"
  },
  {
    "brand": "THACO",
    "model": "OLLIN 700A",
    "seats": "4.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.37",
    "maxTonnage": "4.37"
  },
  {
    "brand": "THACO",
    "model": "OLLIN 800A",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "THACO",
    "model": "OLLIN150",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "THACO",
    "model": "OLLIN150",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "THACO",
    "model": "OLLIN150",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "THACO",
    "model": "OLLIN198",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "THACO",
    "model": "OLLIN198",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "THACO",
    "model": "OLLIN198",
    "seats": "1.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.73",
    "maxTonnage": "1.73"
  },
  {
    "brand": "THACO",
    "model": "OLLIN198",
    "seats": "1.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.78",
    "maxTonnage": "1.78"
  },
  {
    "brand": "THACO",
    "model": "OLLIN198",
    "seats": "1.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.83",
    "maxTonnage": "1.83"
  },
  {
    "brand": "THACO",
    "model": "OLLIN198",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "THACO",
    "model": "OLLIN250",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "THACO",
    "model": "OLLIN250",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "THACO",
    "model": "OLLIN250",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "THACO",
    "model": "OLLIN250",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "THACO",
    "model": "OLLIN250",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "THACO",
    "model": "OLLIN250",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "THACO",
    "model": "OLLIN345",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "THACO",
    "model": "OLLIN345",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "THACO",
    "model": "OLLIN345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "OLLIN345A",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "THACO",
    "model": "OLLIN345A",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "OLLIN345A",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450",
    "seats": "3.02 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.02",
    "maxTonnage": "3.02"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450A",
    "seats": "3.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.13",
    "maxTonnage": "3.13"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450A",
    "seats": "3.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.62",
    "maxTonnage": "3.62"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450A",
    "seats": "4.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.04",
    "maxTonnage": "4.04"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450A",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "OLLIN450A",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "THACO",
    "model": "OLLIN700",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "THACO",
    "model": "OLLIN700",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "OLLIN700A",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "THACO",
    "model": "OLLIN700A",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "THACO",
    "model": "OLLIN700A",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "7.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.77",
    "maxTonnage": "7.77"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "7.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.95",
    "maxTonnage": "7.95"
  },
  {
    "brand": "THACO",
    "model": "OLLIN800A",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "THACO",
    "model": "QC45 ",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "QD20",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "QD35",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "QD45 ",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "SMRM",
    "seats": "Rơ-mooc, sơ mi rơ-mooc",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB110AP",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB110CT",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB115CT-WLF",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB115CT-WLFII",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120BRT",
    "seats": "90 chỗ",
    "minSeat": "90",
    "maxSeat": "90",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120LS-W336E4",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120LS-W336IE4",
    "seats": "25 chỗ",
    "minSeat": "25",
    "maxSeat": "25",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120LS-W375",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120LS-W375-II",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120LS-W375III",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W300",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W336",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W336E4",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W336I",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W336IE4",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W375",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W375E4",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W375I",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W375II",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W375III",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120S-W375IV",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375-II",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375E4",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375I",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375IE4",
    "seats": "24 chỗ",
    "minSeat": "24",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375III",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375IV",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375R",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375RI",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-W375RII",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-WII",
    "seats": "48 chỗ",
    "minSeat": "48",
    "maxSeat": "48",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-WW",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SL-WWII",
    "seats": "44 chỗ",
    "minSeat": "44",
    "maxSeat": "44",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SLD-W",
    "seats": "48 chỗ",
    "minSeat": "48",
    "maxSeat": "48",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SS-H300",
    "seats": "59 chỗ",
    "minSeat": "59",
    "maxSeat": "59",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB120SS-H300I",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB75S-C",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB79CT-W170E4",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB79S-W170E4",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB82S-W",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB82S-W180AS",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB82S-W180ASI",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB82S-W180ASII",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB82S-W180ASIII",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB82S-WII",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB85S-W200",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB85S-W200E4",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB85S-W200I",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB85S-W200IE4",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB85S-W200II",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB94CT-W210LFII",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB94CT-WLF",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB94CT-WLF-II",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB95S-W",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TB95S-W240",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "TC345",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "THACO",
    "model": "TC345",
    "seats": "2.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.95",
    "maxTonnage": "2.95"
  },
  {
    "brand": "THACO",
    "model": "TC345",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "TC345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "TC450",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "THACO",
    "model": "TC450",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "THACO",
    "model": "TC450",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "TC550",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "THACO",
    "model": "TC550",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "THACO",
    "model": "TD200",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "THACO",
    "model": "TD345",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "THACO",
    "model": "TD450",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "THACO",
    "model": "TD600",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "THACO",
    "model": "TD600",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "THACO",
    "model": "THACO\/K190",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "THACO",
    "model": "TOWNER700",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750",
    "seats": "0.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.45",
    "maxTonnage": "0.45"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750A",
    "seats": "0.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.44",
    "maxTonnage": "0.44"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750A",
    "seats": "0.52 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.52",
    "maxTonnage": "0.52"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750A",
    "seats": "0.53 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.53",
    "maxTonnage": "0.53"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750A",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750A",
    "seats": "0.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.65",
    "maxTonnage": "0.65"
  },
  {
    "brand": "THACO",
    "model": "TOWNER750A",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.55",
    "maxTonnage": "0.55"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.57",
    "maxTonnage": "0.57"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.58 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.58",
    "maxTonnage": "0.58"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.59",
    "maxTonnage": "0.59"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.61",
    "maxTonnage": "0.61"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "THACO",
    "model": "TOWNER800",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950A",
    "seats": "0.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.32",
    "maxTonnage": "0.32"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950A",
    "seats": "0.62 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.62",
    "maxTonnage": "0.62"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950A",
    "seats": "0.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.72",
    "maxTonnage": "0.72"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950A",
    "seats": "0.78 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.78",
    "maxTonnage": "0.78"
  },
  {
    "brand": "THACO",
    "model": "TOWNER950A",
    "seats": "0.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.88",
    "maxTonnage": "0.88"
  },
  {
    "brand": "THACO",
    "model": "TOWNER990",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "THACO",
    "model": "TOWNER990",
    "seats": "0.67 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.67",
    "maxTonnage": "0.67"
  },
  {
    "brand": "THACO",
    "model": "TOWNER990",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "THACO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "THACO",
    "model": "WT1000",
    "seats": "0.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.48",
    "maxTonnage": "0.48"
  },
  {
    "brand": "THACO",
    "model": "WT1000",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "THACO",
    "model": "WT1000",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "THACO",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HYUNDAI D4AF",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HYUNDAI D4DA",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HYUNDAI D4DC",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "HYUNDAI",
    "model": "HYUNDAI D8AY",
    "seats": "10.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.97",
    "maxTonnage": "10.97"
  },
  {
    "brand": "TMT",
    "model": "105700T",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TMT",
    "model": "105700T-MB",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "TMT",
    "model": "105700T-MB",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "TMT",
    "model": "105700T2",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "TMT",
    "model": "118765D",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "TMT",
    "model": "58300T",
    "seats": "2.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.65",
    "maxTonnage": "2.65"
  },
  {
    "brand": "TMT",
    "model": "58300T",
    "seats": "2.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.8",
    "maxTonnage": "2.8"
  },
  {
    "brand": "TMT",
    "model": "66500T",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "TMT",
    "model": "66500T",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "TMT",
    "model": "66500T-MB",
    "seats": "2.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.97",
    "maxTonnage": "2.97"
  },
  {
    "brand": "TMT",
    "model": "66500T\/CONECO",
    "seats": "3.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.42",
    "maxTonnage": "3.42"
  },
  {
    "brand": "TMT",
    "model": "88650T",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "TMT",
    "model": "88650T",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "TMT",
    "model": "88650T-MB",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "TMT",
    "model": "88650T\/CONECO",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "TMT",
    "model": "96350T2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TMT",
    "model": "CA3513T",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "TMT",
    "model": "DAISAKI NH",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "TMT",
    "model": "DAISAKI NH",
    "seats": "2.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.03",
    "maxTonnage": "2.03"
  },
  {
    "brand": "TMT",
    "model": "DAISAKI NH",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TMT",
    "model": "DAISAKI NH",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TMT",
    "model": "DAISAKI NH",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TMT",
    "model": "DAISAKI NH",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "TMT",
    "model": "DAISAKI NH",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TMT",
    "model": "DF11888D",
    "seats": "8.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.66",
    "maxTonnage": "8.66"
  },
  {
    "brand": "TMT",
    "model": "DF13285D",
    "seats": "8.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.36",
    "maxTonnage": "8.36"
  },
  {
    "brand": "TMT",
    "model": "DF41CL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "TMT",
    "model": "DF41CL-E4",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "TMT",
    "model": "DF41CS",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "TMT",
    "model": "DF41CS-E4",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "TMT",
    "model": "HD COUNTY",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TMT",
    "model": "HD6020T",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "TMT",
    "model": "HD6020TK",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "TMT",
    "model": "HD6024D",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TMT",
    "model": "HD7320MB",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "TMT",
    "model": "HD7324T",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TMT",
    "model": "HD7325T",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "TMT",
    "model": "HD7325T",
    "seats": "2.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.6",
    "maxTonnage": "2.6"
  },
  {
    "brand": "TMT",
    "model": "HD7325TK",
    "seats": "2.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.16",
    "maxTonnage": "2.16"
  },
  {
    "brand": "TMT",
    "model": "HD7335D",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "TMT",
    "model": "HD7335MB",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "TMT",
    "model": "HD7335T",
    "seats": "3.33 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.33",
    "maxTonnage": "3.33"
  },
  {
    "brand": "TMT",
    "model": "HD7335T",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "TMT",
    "model": "HD7335TK",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "TMT",
    "model": "HD7340MB",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "TMT",
    "model": "HYUNDAI HD65",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "TMT",
    "model": "HYUNDAI HD65",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "TMT",
    "model": "HYUNDAI HD65",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "TMT",
    "model": "HYUNDAI HD72",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "TMT",
    "model": "HYUNDAI HD72",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "TMT",
    "model": "HYUNDAI HD72",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "TMT",
    "model": "HYUNDAI HD72",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "TMT",
    "model": "JB4515T",
    "seats": "1.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.05",
    "maxTonnage": "1.05"
  },
  {
    "brand": "TMT",
    "model": "JB4515T",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "TMT",
    "model": "JB7560T",
    "seats": "6.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.05",
    "maxTonnage": "6.05"
  },
  {
    "brand": "TMT",
    "model": "JB7595S",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "TMT",
    "model": "KC10370D2",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TMT",
    "model": "KC105700TL",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "TMT",
    "model": "KC105700TL",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "TMT",
    "model": "KC105700TL",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "TMT",
    "model": "KC105700TS",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "TMT",
    "model": "KC105700TS",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "TMT",
    "model": "KC105700TS",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TMT",
    "model": "KC10570D2",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "TMT",
    "model": "KC10590D",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "TMT",
    "model": "KC115D",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "TMT",
    "model": "KC11880D",
    "seats": "8.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.05",
    "maxTonnage": "8.05"
  },
  {
    "brand": "TMT",
    "model": "KC11880D2",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "TMT",
    "model": "KC11880T2",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "TMT",
    "model": "KC11885T2",
    "seats": "8.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.37",
    "maxTonnage": "8.37"
  },
  {
    "brand": "TMT",
    "model": "KC11888T",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "TMT",
    "model": "KC11888T",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "TMT",
    "model": "KC11888T",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TMT",
    "model": "KC11888T",
    "seats": "8.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.8",
    "maxTonnage": "8.8"
  },
  {
    "brand": "TMT",
    "model": "KC11890D",
    "seats": "9.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9",
    "maxTonnage": "9"
  },
  {
    "brand": "TMT",
    "model": "KC11890D2",
    "seats": "8.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.65",
    "maxTonnage": "8.65"
  },
  {
    "brand": "TMT",
    "model": "KC11895D",
    "seats": "8.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.95",
    "maxTonnage": "8.95"
  },
  {
    "brand": "TMT",
    "model": "KC11895T",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "TMT",
    "model": "KC132800T",
    "seats": "4.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.65",
    "maxTonnage": "4.65"
  },
  {
    "brand": "TMT",
    "model": "KC132800T",
    "seats": "5.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.01",
    "maxTonnage": "5.01"
  },
  {
    "brand": "TMT",
    "model": "KC132800T",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "TMT",
    "model": "KC132800T",
    "seats": "5.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.65",
    "maxTonnage": "5.65"
  },
  {
    "brand": "TMT",
    "model": "KC132800T",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "TMT",
    "model": "KC13285D",
    "seats": "8.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.36",
    "maxTonnage": "8.36"
  },
  {
    "brand": "TMT",
    "model": "KC180D",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "TMT",
    "model": "KC240145T",
    "seats": "11.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.8",
    "maxTonnage": "11.8"
  },
  {
    "brand": "TMT",
    "model": "KC240145T",
    "seats": "12.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.2",
    "maxTonnage": "12.2"
  },
  {
    "brand": "TMT",
    "model": "KC240145T",
    "seats": "14.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.5",
    "maxTonnage": "14.5"
  },
  {
    "brand": "TMT",
    "model": "KC240150T",
    "seats": "13.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.2",
    "maxTonnage": "13.2"
  },
  {
    "brand": "TMT",
    "model": "KC240150T",
    "seats": "15.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.04",
    "maxTonnage": "15.04"
  },
  {
    "brand": "TMT",
    "model": "KC240150T",
    "seats": "9.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.95",
    "maxTonnage": "9.95"
  },
  {
    "brand": "TMT",
    "model": "KC240D",
    "seats": "13.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.1",
    "maxTonnage": "13.1"
  },
  {
    "brand": "TMT",
    "model": "KC340220T",
    "seats": "17.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.9",
    "maxTonnage": "17.9"
  },
  {
    "brand": "TMT",
    "model": "KC340220T",
    "seats": "22.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22",
    "maxTonnage": "22"
  },
  {
    "brand": "TMT",
    "model": "KC6650D",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "TMT",
    "model": "KC6650D2",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "TMT",
    "model": "KC7050D",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "TMT",
    "model": "KC7050D2",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "TMT",
    "model": "KC8550D2",
    "seats": "4.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.2",
    "maxTonnage": "4.2"
  },
  {
    "brand": "TMT",
    "model": "KC8550D2",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "TMT",
    "model": "KC90D",
    "seats": "4.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.45",
    "maxTonnage": "4.45"
  },
  {
    "brand": "TMT",
    "model": "KC90D2",
    "seats": "4.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.37",
    "maxTonnage": "4.37"
  },
  {
    "brand": "TMT",
    "model": "KC9665D2",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "TMT",
    "model": "KC9670D",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "TMT",
    "model": "KC9672D",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "TMT",
    "model": "KC9672D",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "TMT",
    "model": "KC9677D",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "TMT",
    "model": "KM120TL",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TMT",
    "model": "KM120TL",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "TMT",
    "model": "KM120TL",
    "seats": "6.08 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.08",
    "maxTonnage": "6.08"
  },
  {
    "brand": "TMT",
    "model": "KM3820T",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "TMT",
    "model": "KM3820T",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "TMT",
    "model": "KM52T",
    "seats": "0.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.73",
    "maxTonnage": "0.73"
  },
  {
    "brand": "TMT",
    "model": "KM52T",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "TMT",
    "model": "KM5835D",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TMT",
    "model": "KM5850T",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "TMT",
    "model": "KM6645D",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "TMT",
    "model": "KM6660T",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "TMT",
    "model": "KM6660T",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "TMT",
    "model": "KM6660T",
    "seats": "5.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.95",
    "maxTonnage": "5.95"
  },
  {
    "brand": "TMT",
    "model": "KM6660T",
    "seats": "6.12 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.12",
    "maxTonnage": "6.12"
  },
  {
    "brand": "TMT",
    "model": "KM7520T",
    "seats": "1.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.63",
    "maxTonnage": "1.63"
  },
  {
    "brand": "TMT",
    "model": "KM7520T",
    "seats": "1.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.64",
    "maxTonnage": "1.64"
  },
  {
    "brand": "TMT",
    "model": "KM7522T",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "TMT",
    "model": "KM7522T",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "TMT",
    "model": "KM7550T",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "TMT",
    "model": "KM7550T",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "TMT",
    "model": "KM7560T",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "TMT",
    "model": "KM7560T",
    "seats": "5.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.68",
    "maxTonnage": "5.68"
  },
  {
    "brand": "TMT",
    "model": "KM7560T",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "TMT",
    "model": "KM7560T",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "TMT",
    "model": "KM8870T",
    "seats": "6.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.99",
    "maxTonnage": "6.99"
  },
  {
    "brand": "TMT",
    "model": "KM8872TK",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "TMT",
    "model": "KM8875TL",
    "seats": "5.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.6",
    "maxTonnage": "5.6"
  },
  {
    "brand": "TMT",
    "model": "KM8875TL",
    "seats": "7.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.39",
    "maxTonnage": "7.39"
  },
  {
    "brand": "TMT",
    "model": "KM8875TL",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "TMT",
    "model": "KM8875TL",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "TMT",
    "model": "KM8876T",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "TMT",
    "model": "PY10570D2",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "TMT",
    "model": "PY10575T2",
    "seats": "7.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.42",
    "maxTonnage": "7.42"
  },
  {
    "brand": "TMT",
    "model": "PY9668T2",
    "seats": "6.54 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.54",
    "maxTonnage": "6.54"
  },
  {
    "brand": "TMT",
    "model": "PY9670T2",
    "seats": "6.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.88",
    "maxTonnage": "6.88"
  },
  {
    "brand": "TMT",
    "model": "TT1105T",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "TMT",
    "model": "TT1105T",
    "seats": "0.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.56",
    "maxTonnage": "0.56"
  },
  {
    "brand": "TMT",
    "model": "TT11890MB",
    "seats": "8.57 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.57",
    "maxTonnage": "8.57"
  },
  {
    "brand": "TMT",
    "model": "TT5535MB",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "TMT",
    "model": "TT9570MB",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TMT",
    "model": "TY8557T2",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "TMT",
    "model": "TY9670T",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "TMT",
    "model": "TY9670T",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "TMT",
    "model": "TY9670T",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TMT",
    "model": "TY9670T",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "TMT",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "TMT",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "TMT",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "TMT",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "TMT",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "TMT",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "TMT",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TMT",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TMT",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TMT",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TMT",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TMT",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TMT",
    "model": "ZB 3810D2N",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "TMT",
    "model": "ZB 3810T",
    "seats": "0.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.85",
    "maxTonnage": "0.85"
  },
  {
    "brand": "TMT",
    "model": "ZB 3810T",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "TMT",
    "model": "ZB3810D",
    "seats": "0.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.95",
    "maxTonnage": "0.95"
  },
  {
    "brand": "TMT",
    "model": "ZB3824D",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TMT",
    "model": "ZB5024D",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "TMT",
    "model": "ZB5025T",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "TMT",
    "model": "ZB5035D",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TMT",
    "model": "ZB5040D",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "TMT",
    "model": "ZB52T",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "TMT",
    "model": "ZB6035D",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TMT",
    "model": "ZB6045D",
    "seats": "4.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.1",
    "maxTonnage": "4.1"
  },
  {
    "brand": "TMT",
    "model": "ZB6045D",
    "seats": "4.63 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.63",
    "maxTonnage": "4.63"
  },
  {
    "brand": "TMT",
    "model": "ZB6045D",
    "seats": "4.72 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.72",
    "maxTonnage": "4.72"
  },
  {
    "brand": "TMT",
    "model": "ZB7050D",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "TMT",
    "model": "ZB7050D",
    "seats": "4.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.8",
    "maxTonnage": "4.8"
  },
  {
    "brand": "TOYOTA",
    "model": "HIACE",
    "seats": "10 chỗ",
    "minSeat": "10",
    "maxSeat": "10",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "HIACE",
    "seats": "12 chỗ",
    "minSeat": "12",
    "maxSeat": "12",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "HIACE",
    "seats": "15 chỗ",
    "minSeat": "15",
    "maxSeat": "15",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "HIACE",
    "seats": "16 chỗ",
    "minSeat": "16",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "HIACE - cứu  thương",
    "seats": "6 chỗ",
    "minSeat": "6",
    "maxSeat": "6",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "HIACE - cứu  thương",
    "seats": "7 chỗ",
    "minSeat": "7",
    "maxSeat": "7",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "HIACE - cứu  thương",
    "seats": "8 chỗ",
    "minSeat": "8",
    "maxSeat": "8",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "HIACE - cứu  thương",
    "seats": "9 chỗ",
    "minSeat": "9",
    "maxSeat": "9",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL NOBLE K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL NOBLE K29-1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL NOBLE K34",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL NOBLE K34N",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL NOBLE K34N-1",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL NOBLE K39",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL NOBLE K39-1",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL90 K29D",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL90 K29W",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "GLOBAL90 K34W",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HM CNG B70",
    "seats": "70 chỗ",
    "minSeat": "70",
    "maxSeat": "70",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "K27",
    "seats": "27 chỗ",
    "minSeat": "27",
    "maxSeat": "27",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "K45M",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW UNIVERSE NOBLE K47",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "NEW UNIVERSE NOBLE K47-410",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K42CH",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K42CH-1",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K42G",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K42G-1",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K42G-410",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K42G-410-1",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K46G",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K46G-410",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K47",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K47-1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K47-410",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K47-410-1",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K47CH",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K47H",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "UNIVERSE NOBLE K47W336",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1 EQ1173 GET",
    "seats": "7.59 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.59",
    "maxTonnage": "7.59"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 1500TL",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 2500TL",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AC K39ZD",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AC K40A9",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AC K42 UNIVERSE",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AC K42ZD",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AC K46H1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AC K46H2",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AE K47 UNIVERSE",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AE K47 UNIVERSE TH",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AE K47U UNIVERSE",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AE K47U1 UNIVERSE",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AH K30",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AT B60C1",
    "seats": "61 chỗ",
    "minSeat": "61",
    "maxSeat": "61",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AT K30I",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 AT K35I",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 B40E2\/H8",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 B40H9",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 B45",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 CA6101D84-1 K46S",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 CA6110D84-2Z K46",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 CA6110D84-3 K46",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 CA6110D84-3 K46 UNIVERSE",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 CA6110D84-3 K46A1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 CA6780D226 B50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 DHZ6990KT K52",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 EQ6100T3AC K52",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 EXPRESS HSX K40WC",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 EXPRESS HSX K45",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 HC B40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 HFC6700K3Y - B45A",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 HFC6700K3Y-B45B",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 HFC6700KY6C-B40\/H6",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 HFC6880KY1 B60",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 K29 H4",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 K29\/H6",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 K29E2\/H8",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 K29NS\/ H7",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 K51H",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 KLQ6118G B80KL",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 KLQ6770G B40KL",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 KLQ6920GQ B60KL",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 KLQ6935G B60",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 KLQ6935G B60A",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 TATA K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "1-5 TD2000",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "TRANSINCO",
    "model": "A - DHZ1130KR1 - K1C",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A - HFC6782KYZL2 - B50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A AEROCITY K1C WC1",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A AEROCITY K1C WC2",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A EP K47",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-AEROCITY-K34-WC",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-AEROTOWN-K1B1",
    "seats": "36 chỗ",
    "minSeat": "36",
    "maxSeat": "36",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-BS090-B60",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-BS106-B80",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-BS106-K42",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6101D84-1-B60E1",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6101D84-1-K35B",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6101D84-1-K46D",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6101D84-1-K46U",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6801D102- B45B",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6801D102- B50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6801D102-K1B",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6801D102-K35",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6801D102-K39B",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-2-B60E",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-2-K1C",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-2-K1E",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-2-K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-2-K46KL",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-B2E",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-B70E",
    "seats": "70 chỗ",
    "minSeat": "70",
    "maxSeat": "70",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-K2C-DHB",
    "seats": "44 chỗ",
    "minSeat": "44",
    "maxSeat": "44",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6900D210-K2C-GHNA",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6980D80 B2E2",
    "seats": "70 chỗ",
    "minSeat": "70",
    "maxSeat": "70",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6980D80-B2E1",
    "seats": "65 chỗ",
    "minSeat": "65",
    "maxSeat": "65",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6980D80-K51C",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6980D80-K51C1",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6980D80-K51C2",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6982D80-K51C3",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-CA6982D80-K51C4",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-DHZ1130KR1-K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-EQ6100KTD1-K52",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "A-HFC6832KYZL1-B60",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC B80-3",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC B80A",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC B80C",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC B80D",
    "seats": "81 chỗ",
    "minSeat": "81",
    "maxSeat": "81",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC K46A",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC K46B",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC K46G",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC K46H",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AC KL K46",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH B40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH B50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH B50A",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH B50B",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH B70",
    "seats": "70 chỗ",
    "minSeat": "70",
    "maxSeat": "70",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH B80A",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH K2C",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH K32",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH K32B",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH K34",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH K34A",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH K38A",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AH K38B",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AHK30A",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AT B45",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AT B45A",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AT B55A",
    "seats": "55 chỗ",
    "minSeat": "55",
    "maxSeat": "55",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AT B55C",
    "seats": "55 chỗ",
    "minSeat": "55",
    "maxSeat": "55",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "AT K36",
    "seats": "36 chỗ",
    "minSeat": "36",
    "maxSeat": "36",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "B40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "B60A",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "B60B",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "B80-3",
    "seats": "81 chỗ",
    "minSeat": "81",
    "maxSeat": "81",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "BA-HAI AH B80",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "BA-HAI AH K34C",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "BA-HAI AH K34D",
    "seats": "34 chỗ",
    "minSeat": "34",
    "maxSeat": "34",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "BA-HAI CA B80",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "BA-HAI CA B80A",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "BA-HAI CA K46C",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "BA-HAI CA K46D",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA 6110",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA 6110-3K1",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA B60",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA B60A",
    "seats": "60 chỗ",
    "minSeat": "60",
    "maxSeat": "60",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA B70",
    "seats": "70 chỗ",
    "minSeat": "70",
    "maxSeat": "70",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA B70 GC",
    "seats": "70 chỗ",
    "minSeat": "70",
    "maxSeat": "70",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA B70A GC",
    "seats": "70 chỗ",
    "minSeat": "70",
    "maxSeat": "70",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA CD K47",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K32",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K35",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K37A",
    "seats": "37 chỗ",
    "minSeat": "37",
    "maxSeat": "37",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K38",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K38A",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K39",
    "seats": "39 chỗ",
    "minSeat": "39",
    "maxSeat": "39",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K44B",
    "seats": "44 chỗ",
    "minSeat": "44",
    "maxSeat": "44",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K45",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K46",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K46B",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K51",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K51B",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K52",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K52A",
    "seats": "52 chỗ",
    "minSeat": "52",
    "maxSeat": "52",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA K53B",
    "seats": "53 chỗ",
    "minSeat": "53",
    "maxSeat": "53",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA6110\/125-3K1",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CD 50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "COUNTY HCM",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "DAIHATSU",
    "seats": "0.74 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.74",
    "maxTonnage": "0.74"
  },
  {
    "brand": "TRANSINCO",
    "model": "GFK50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "GFT K50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "H1 K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "H3 K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO 35S",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO 45SD",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO 50SD",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO B40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K29S1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K29S2",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K29S3",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K29SA",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K29ST",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K29ST2",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K42SR",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K50 SHB",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K50 SR",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HAECO K50 SRB",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HB DA K30",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HB JAS B45A",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HB JAS K30",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC B30-1",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC B30-2A",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC B40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC B40A",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC B40A-1",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC B40E3",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC K29B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC K29E3",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC K30",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC K32",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC K32-1C",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC K32-2C",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HC K46",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HCM B40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HCM B80",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HCM B80 TQ",
    "seats": "80 chỗ",
    "minSeat": "80",
    "maxSeat": "80",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HCM BHT 89",
    "seats": "89 chỗ",
    "minSeat": "89",
    "maxSeat": "89",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HCM K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HCM K30",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HCM K47 TQ",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HK 29DB",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HK29DD",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HKB40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HN K38A",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HN SB 50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HZ K45",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HZ K50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "ISU K32",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "ISUZU NKR55L",
    "seats": "2.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.16",
    "maxTonnage": "2.16"
  },
  {
    "brand": "TRANSINCO",
    "model": "JA B38",
    "seats": "38 chỗ",
    "minSeat": "38",
    "maxSeat": "38",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JA K27",
    "seats": "27 chỗ",
    "minSeat": "27",
    "maxSeat": "27",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JA K29A",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JA K29B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JA K30",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JA K32",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JA K32-2",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JAC B40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JAC B50A",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JAC HB\/B40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JAC K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JAC K29A1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JAC K29A2",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JAC K30B",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "JF TB",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL 2515 CD1",
    "seats": "1.61 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.61",
    "maxTonnage": "1.61"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL2815D1",
    "seats": "1.31 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.31",
    "maxTonnage": "1.31"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL5830PD",
    "seats": "2.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.81",
    "maxTonnage": "2.81"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL5830PD1",
    "seats": "2.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.84",
    "maxTonnage": "2.84"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL5830PD1A",
    "seats": "2.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.81",
    "maxTonnage": "2.81"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL5840PD1",
    "seats": "3.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.81",
    "maxTonnage": "3.81"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL5840PD1A",
    "seats": "3.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.81",
    "maxTonnage": "3.81"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL5840PD1Aa",
    "seats": "3.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.81",
    "maxTonnage": "3.81"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL5840PD1B",
    "seats": "3.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.84",
    "maxTonnage": "3.84"
  },
  {
    "brand": "TRANSINCO",
    "model": "JL5840PD1C",
    "seats": "3.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.81",
    "maxTonnage": "3.81"
  },
  {
    "brand": "TRANSINCO",
    "model": "K22A",
    "seats": "22 chỗ",
    "minSeat": "22",
    "maxSeat": "22",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K27",
    "seats": "27 chỗ",
    "minSeat": "27",
    "maxSeat": "27",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K28",
    "seats": "28 chỗ",
    "minSeat": "28",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K29 H5B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K29A1",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K29A3B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K29A3C",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K29D4DB",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K30",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K32",
    "seats": "32 chỗ",
    "minSeat": "32",
    "maxSeat": "32",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K35",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K36",
    "seats": "36 chỗ",
    "minSeat": "36",
    "maxSeat": "36",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K42",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K44",
    "seats": "44 chỗ",
    "minSeat": "44",
    "maxSeat": "44",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K44 - DH",
    "seats": "44 chỗ",
    "minSeat": "44",
    "maxSeat": "44",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K46",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K47A",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K51A",
    "seats": "51 chỗ",
    "minSeat": "51",
    "maxSeat": "51",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "KM K55",
    "seats": "55 chỗ",
    "minSeat": "55",
    "maxSeat": "55",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "KM K58",
    "seats": "58 chỗ",
    "minSeat": "58",
    "maxSeat": "58",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS 27FAW",
    "seats": "27 chỗ",
    "minSeat": "27",
    "maxSeat": "27",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS 29 FAW I",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS 29 ISUZU",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS 29F",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS 29Y2B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS B40 JAC",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS B42",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS B42F",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS B50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS K29 JAC",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS K29C-08",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NADIBUS K29E2-TH",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT B78HDP",
    "seats": "78 chỗ",
    "minSeat": "78",
    "maxSeat": "78",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT HK 29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT HK29A",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT HK29B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT TK29B",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT TK29HA",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "SM1010",
    "seats": "0.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.5",
    "maxTonnage": "0.5"
  },
  {
    "brand": "TRANSINCO",
    "model": "SM1020E",
    "seats": "2.37 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.37",
    "maxTonnage": "2.37"
  },
  {
    "brand": "TRANSINCO",
    "model": "TCK",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "TRANSINCO",
    "model": "THM",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "TRANSINCO",
    "model": "TK29C",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "TK35",
    "seats": "35 chỗ",
    "minSeat": "35",
    "maxSeat": "35",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "TKB40",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "TKB40A",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "TKB50",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "TKH29",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "TKHB42",
    "seats": "40 chỗ",
    "minSeat": "40",
    "maxSeat": "40",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "TRANSINCO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "TRANSINCO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "TRANSINCO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "TRANSINCO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "TRANSINCO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "ZD K47C",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CIMC",
    "model": "CIMC",
    "seats": "Rơ-mooc bồn chuyên dụng",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "CIMC",
    "model": "CIMC",
    "seats": "Rơ-mooc, sơ mi rơ-mooc",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "HYUNDAI",
    "model": "HYUNDAI TMB",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "HYUNDAI",
    "model": "HYUNDAI-TMB",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "HYUNDAI",
    "model": "HYUNDAI.D6AC-TMB",
    "seats": "13.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13",
    "maxTonnage": "13"
  },
  {
    "brand": "KIA",
    "model": "KIA D4AL-TK",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "KIA",
    "model": "KIA TTĐ",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "KIA",
    "model": "KIA-TK",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KIA",
    "model": "KIA-TK",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "KIA",
    "model": "KIA-TK",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "KIA",
    "model": "KIA-TK",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "KIA",
    "model": "KIA-TKB",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KIA",
    "model": "KIA-TMB",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "KIA",
    "model": "KIA-TMB",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "KIA",
    "model": "KIA-TMB",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "8T-TMB",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DF-0.855TM15",
    "seats": "0.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.86",
    "maxTonnage": "0.86"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DF-0.995TL05",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DF-0.995TL05",
    "seats": "0.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.84",
    "maxTonnage": "0.84"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DF-0.995TL05",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DF5,7B4x2",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM - EQ7TB",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM - EQ7TB",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM - TD1,8TA",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM - TD2,35TB",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM - TD4,99HD",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM -TD8180",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM 3,8T-KM",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM 4,98TB",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM 4,98TB",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM 6,9B4x2-1",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM 6,9B4x2-2",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM 8TA-KM",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM 8TA-TMB",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM 8TB-TMB",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ1.25T4x2",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ1.8T4x2",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8X4",
    "seats": "12.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.41",
    "maxTonnage": "12.41"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8X4",
    "seats": "13.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.8",
    "maxTonnage": "13.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8x4",
    "seats": "11.44 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.44",
    "maxTonnage": "11.44"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8x4",
    "seats": "12.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12.5",
    "maxTonnage": "12.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8x4",
    "seats": "13.84 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.84",
    "maxTonnage": "13.84"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8x4",
    "seats": "14.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.18",
    "maxTonnage": "14.18"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8x4",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8x4",
    "seats": "18.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18.7",
    "maxTonnage": "18.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ10TE8x4",
    "seats": "19.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "19.1",
    "maxTonnage": "19.1"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ2,5TC4x4",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ3,45TC4x4",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ3,8T4x2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ3.45T-KM",
    "seats": "3.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.15",
    "maxTonnage": "3.15"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ4.98T",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ4.98T-KM",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ5T-TMB",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ6T-TMB",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7140TA-KM",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7140TA-KM",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7T-TMB",
    "seats": "6.89 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.89",
    "maxTonnage": "6.89"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7T4x4",
    "seats": "6.14 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.14",
    "maxTonnage": "6.14"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7TA-KM",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7TC4x2",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7TE4X2",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7TE4X2",
    "seats": "8.88 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.88",
    "maxTonnage": "8.88"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7TE4x2",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7TE4x2",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7TE4x2",
    "seats": "8.87 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.87",
    "maxTonnage": "8.87"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ7TE4x2",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TB4x2",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TC4x2-KM",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TC4x2L",
    "seats": "5.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.22",
    "maxTonnage": "5.22"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TC4x2L",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TE 4x2",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TE 4x2",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TE 4x2",
    "seats": "8.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.25",
    "maxTonnage": "8.25"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TE4X2",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ8TE4x2",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TB6x2",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TC6x2-KM",
    "seats": "7.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.9",
    "maxTonnage": "7.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TC6x2-KM",
    "seats": "8.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.6",
    "maxTonnage": "8.6"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TE6X4",
    "seats": "9.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.9",
    "maxTonnage": "9.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TE6x4",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TE6x4",
    "seats": "13.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.18",
    "maxTonnage": "13.18"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TE6x4",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TE6x4",
    "seats": "13.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.7",
    "maxTonnage": "13.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM EQ9TE6x4",
    "seats": "14.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.4",
    "maxTonnage": "14.4"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD 1,25B",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD0,97TA",
    "seats": "0.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.97",
    "maxTonnage": "0.97"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD12TC6x4",
    "seats": "12.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "12",
    "maxTonnage": "12"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD2,5TC4x4",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD3,45TC4x4",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD7TA",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD7TB",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD8,5YC4x4",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TD990KC4x2",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TL2350A",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TL2350A",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TL900A",
    "seats": "0.68 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.68",
    "maxTonnage": "0.68"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TL900A",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT1,25TA",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT1,25TA",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT1,8TA",
    "seats": "1.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.6",
    "maxTonnage": "1.6"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT1,8TA",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT1850TB",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT1850TB",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT2,5B",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT2,5B",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT3,45T",
    "seats": "3.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.13",
    "maxTonnage": "3.13"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT3,45T",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT3,8B",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM TT3,8T",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC11TE10x4",
    "seats": "13.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.86",
    "maxTonnage": "13.86"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC11TE10x4",
    "seats": "21.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "21.81",
    "maxTonnage": "21.81"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC75TF4x2MP",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC7TF4X2",
    "seats": "5.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.79",
    "maxTonnage": "5.79"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC7TF4x2",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC7TF4x2",
    "seats": "9.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.2",
    "maxTonnage": "9.2"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC7TF4x4",
    "seats": "6.38 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.38",
    "maxTonnage": "6.38"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC7TF4x4",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4X4MP",
    "seats": "5.56 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.56",
    "maxTonnage": "5.56"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4X4MP",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4X4MP",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4x4 MP",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4x4MP",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4x4MP",
    "seats": "6.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.93",
    "maxTonnage": "6.93"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4x4MP",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4x4MP",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC88TF4x4MP",
    "seats": "8.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.7",
    "maxTonnage": "8.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC8TA",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC8TA",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC9TF6x4",
    "seats": "13.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.3",
    "maxTonnage": "13.3"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM YC9TF6x4",
    "seats": "14.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.1",
    "maxTonnage": "14.1"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM- TD2,5B",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM- TD3,45B",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM- TD3,45M",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-3,45TD",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-EQ3,45T4x4",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-EQ6T4x4",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-EQ6T4x4",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-EQ8T4x2",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-EQ9T6x2",
    "seats": "9.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.4",
    "maxTonnage": "9.4"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD 3,45TA 4x2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD 4,98TC 4x4",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD2,35TA",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD2,35TC",
    "seats": "2.09 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.09",
    "maxTonnage": "2.09"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD2,35TC",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD2,35TC",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD2,5TA",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD3,45T4x2",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD4,95T",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD4,98T4x4",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD4,98TA",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD4,98TA 4x4",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD4,98TA 4x4",
    "seats": "5.18 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.18",
    "maxTonnage": "5.18"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD4,98TB",
    "seats": "4.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.98",
    "maxTonnage": "4.98"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD4,99T",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD5B-1",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD5T4X4",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD6,5T4x4",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD6,5TB",
    "seats": "6.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.79",
    "maxTonnage": "6.79"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD6,6T4x2",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD7,5TA",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD7T",
    "seats": "6.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.98",
    "maxTonnage": "6.98"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD7TA4x4",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD7TB-1",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD7TB4x4",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "DFM-TD8T4x2",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "IU 8,5B4x2-2015",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "KY5 0.825TM08",
    "seats": "0.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.83",
    "maxTonnage": "0.83"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "KY5 0.995TL01",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "KY5-0.855BO09",
    "seats": "0.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.86",
    "maxTonnage": "0.86"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "T3-0.66TM06",
    "seats": "0.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.66",
    "maxTonnage": "0.66"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "T3-0.81TL03",
    "seats": "0.64 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.64",
    "maxTonnage": "0.64"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "T3-0.81TL03",
    "seats": "0.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.81",
    "maxTonnage": "0.81"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "T3-0.81TM10",
    "seats": "0.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.81",
    "maxTonnage": "0.81"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "T3-0.98TL04",
    "seats": "0.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.76",
    "maxTonnage": "0.76"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "T3-0.98TL04",
    "seats": "0.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.8",
    "maxTonnage": "0.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "T3-0.98TL04",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TD2,5T",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TD4,5T",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TD4,5T 4x4",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-DF18,3B10X4-1",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-DF18,3B10X4-2",
    "seats": "18.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "18",
    "maxTonnage": "18"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-DFM 8,0B4x2-1",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-DFM 8,0B4x2-2",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-DFM 8,3B4x2",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-DFM 8,5B4x2",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-FA8,0B4X2-1",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-FA8,0B4X2-2",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-FA8,1B4x4",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-FA8,5B4x2",
    "seats": "8.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.75",
    "maxTonnage": "8.75"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-FA8,6T4x4",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-KA3,8B4x2-1",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-KA3,8B4x2-2",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-TC8,1B 4x2-1",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-TC8,1B 4x2-1",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "TG-TC8,1B 4x2-2",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "US13B6x6-1",
    "seats": "13.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.04",
    "maxTonnage": "13.04"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "US13B6x6-2",
    "seats": "13.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.04",
    "maxTonnage": "13.04"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "US22,5MB10x4",
    "seats": "22.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "22.79",
    "maxTonnage": "22.79"
  },
  {
    "brand": "TRUONG GIANG",
    "model": "US8,5B4x4",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "THACO",
    "model": "Mobihome",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Mobihome",
    "seats": "TB120SL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Mobihome",
    "seats": "TB120SL-W375E4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Mobihome",
    "seats": "TB120SL-W375IIE4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Towner",
    "seats": "82S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Towner",
    "seats": "TB82S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "THACO",
    "model": "Towner",
    "seats": "TB85S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "4 Runner",
    "seats": "3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "4 Runner",
    "seats": "3.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "4 Runner",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "4 Runner",
    "seats": "SR5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "4 Runner",
    "seats": "SR5-2.2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "4 Runner",
    "seats": "SR5-2.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "86",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Alphard",
    "seats": "2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Alphard",
    "seats": "3.5-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Alphard",
    "seats": "EXECUTIVE-LOUNGE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Alphard",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Avalon",
    "seats": "3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Avalon",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Avalon",
    "seats": "XLS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Avanza",
    "seats": "1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Avanza",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Aygo",
    "seats": "1.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Aygo",
    "seats": "1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "2.0-E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "2.0E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "2.0G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "2.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "2.4G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "2.5G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "2.5Q",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "3.0V",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "3.5Q",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "DX-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "GL-1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "GL-2.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "GL-2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "GLI-2.2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "GLI-2.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "GLX-2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "GRANDE-3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-2.2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-2.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-3.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-3.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "LE-3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "SE-2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "SE-2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "SE-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLE-2.2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLE-2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLE-2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLE-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLE-3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLE-3.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLE-3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLI-2.2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Camry",
    "seats": "XLI-2.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Celica",
    "seats": "2.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "ALTIS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "DX-1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "GL-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "GLI-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "GLI-1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "J-1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "LE-1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "LE-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "S-1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "XL-1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "XLI-1.3-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "XLI-1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "XLI-1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "XLI-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla",
    "seats": "XLI-1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla Cross",
    "seats": "1.8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "1.8-G-CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "1.8E-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "1.8E-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "1.8G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "1.8G-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "1.8G-CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "1.8G-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "2.0RS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "2.0V",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "2.0V-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "2.0V-SPORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corolla altis",
    "seats": "2.0Z",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corona",
    "seats": "1.6-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corona",
    "seats": "GL-1.6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corona",
    "seats": "GL-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Corona",
    "seats": "GLI-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Cressida",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Cressida",
    "seats": "2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Cressida",
    "seats": "2.4-DIESEL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Cressida",
    "seats": "3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Cressida",
    "seats": "GL-2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Crown",
    "seats": "2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Crown",
    "seats": "ROYAL-SALOON-2.4D-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Crown",
    "seats": "ROYAL-SALOON-3.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Crown",
    "seats": "SUPER-SALOON-3.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Estima",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fj cruiser",
    "seats": "4.0-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fj cruiser",
    "seats": "GXR-4.0-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.4G-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.4G-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.5G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.5V-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.7-V-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.7V-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.7V-4X2-AT-TRD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.7V-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "2.8V-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "FOTUNE-",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "SR5-2.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "SR5-4.0V-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "TRD-SPORTIVO-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "TRD-SPORTIVO-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Fortuner",
    "seats": "V",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "LIMOUSINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "SUPER-WAGON-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "SUPER-WAGON-2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "SUPER-WAGON-2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "VAN-2.0",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "VAN-2.4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "VAN-2.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hiace",
    "seats": "VAN-2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Highlander",
    "seats": "2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Highlander",
    "seats": "3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Highlander",
    "seats": "LE-2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Highlander",
    "seats": "LIMITED-3.5-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Highlander",
    "seats": "SE-2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "2.4E-4X2-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "2.4E-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "2.4G-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "2.5E-4X2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "2.8G-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "2.8L-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "3.0G-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "3.0G-4X4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Hilux",
    "seats": "G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "2.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "2.0-VENTURER",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "2.0E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "2.0G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "2.0J",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "2.0MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "2.0V",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "G-SR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "GSR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "J",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Innova",
    "seats": "V",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "3.5L-TURBO-2020",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "3.9MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "4.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "4.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "4.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "4.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "4.6-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "5.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "EX.R-5.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "GX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "GX-4.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "GX-4.2",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "GX-4.2-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "GX-4.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "GX.R-4.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "GX.R-4.5-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "II-2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "LJ72",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "PRADO-GX-2.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX-4.5-V8-EXECUTIVE-LOUNGE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX-4.6-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX-4.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX.E-5.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX.R-4.6-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX.R-4.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX.R-5.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX.R-5.7-V8-MBS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX.S-4.6-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Land Cruiser",
    "seats": "VX.S-5.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prado",
    "seats": "GX-2.7-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prado",
    "seats": "GX-2.7-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prado",
    "seats": "GX-3.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prado",
    "seats": "TXL-2.7L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prado",
    "seats": "VX-2.7L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prado",
    "seats": "VX-4.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prado",
    "seats": "VX-4.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Previa",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Previa",
    "seats": "GL-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Previa",
    "seats": "GL-3.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prius",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Prius",
    "seats": "C-FOUR",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LE-2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LE-2.5-FWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LIMITED-2.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LIMITED-2.4-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LIMITED-2.4-FWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LIMITED-3.5-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LIMITED-3.5-V6-FWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LIMITED2.5-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "LIMITED2.5-AT-FWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "XLE-2.5-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "XLE-2.5-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "RAV4",
    "seats": "XLE-2.5-FWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Raize",
    "seats": "1.0 Turbo",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Rush",
    "seats": "1.5G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Rush",
    "seats": "1.5S-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sequoia",
    "seats": "LIMITED-4.7-AT-4WD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sequoia",
    "seats": "PLATINUM-5.7-V8",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "3.5-LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "LE-2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "LE-3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "LE-3.5-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "LIMITED-3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "LIMITED-3.5-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "XLE-3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Sienna",
    "seats": "XLE-LIMITED-3.3-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Tacoma",
    "seats": "SR5-4.0-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Tacoma",
    "seats": "TRD-PRO-3.5-V6",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Tercel",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Tundra",
    "seats": "1794-EDITION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Tundra",
    "seats": "5.7-V8-4X4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Veloz Cross",
    "seats": "CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Veloz Cross",
    "seats": "CVT Top",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Venza",
    "seats": "2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Venza",
    "seats": "2.7-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Venza",
    "seats": "3.5",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Venza",
    "seats": "3.5-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Venza",
    "seats": "XLE-2.7",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.3",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.3J",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.3MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.5-LIMO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.5-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.5-TRD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.5E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.5E-CVT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.5E-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.5G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "1.5MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Vios",
    "seats": "LIMO",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Wigo",
    "seats": "1.2G-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Wigo",
    "seats": "1.2G-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Wish",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "XA",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "1.0-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "1.3-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "1.3E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "1.3G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "1.5E",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "1.5G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "G",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "G1.5AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "RS-1.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Yaris",
    "seats": "S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Zace",
    "seats": "1.8-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Zace",
    "seats": "DX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Zace",
    "seats": "GL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Zace",
    "seats": "LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TOYOTA",
    "model": "Zace",
    "seats": "SURF",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "UAZ",
    "model": "Hunter",
    "seats": "2.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "UAZ",
    "model": "Hunter",
    "seats": "GL-3.0T",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "UAZ",
    "model": "Hunter",
    "seats": "LX",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "UAZ",
    "model": "Hunter",
    "seats": "TROPPY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "UAZ",
    "model": "Simbir",
    "seats": "2.7-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "EXPRESS K47",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HD-2F",
    "seats": "45 chỗ",
    "minSeat": "45",
    "maxSeat": "45",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HD-2F E2",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HD-2FC E2",
    "seats": "46 chỗ",
    "minSeat": "46",
    "maxSeat": "46",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HK42",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HK42A",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HK47",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "HN47S",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K43-2F",
    "seats": "43 chỗ",
    "minSeat": "43",
    "maxSeat": "43",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K47",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K47-31",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K47D",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K47WP10",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "K47WP7",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT HK47AC",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT HK47DS",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT TG42",
    "seats": "42 chỗ",
    "minSeat": "42",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT TK29YC",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT TK47",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT TK47A",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT TK47FAW",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "NGT TK47YC",
    "seats": "47 chỗ",
    "minSeat": "47",
    "maxSeat": "47",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VEAM",
    "model": "437041-265",
    "seats": "4.04 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.04",
    "maxTonnage": "4.04"
  },
  {
    "brand": "VEAM",
    "model": "437041-265",
    "seats": "4.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.6",
    "maxTonnage": "4.6"
  },
  {
    "brand": "VEAM",
    "model": "437041-265",
    "seats": "5.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.05",
    "maxTonnage": "5.05"
  },
  {
    "brand": "VEAM",
    "model": "533603-225",
    "seats": "5.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.82",
    "maxTonnage": "5.82"
  },
  {
    "brand": "VEAM",
    "model": "533603-225",
    "seats": "5.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.85",
    "maxTonnage": "5.85"
  },
  {
    "brand": "VEAM",
    "model": "533603-225",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "VEAM",
    "model": "533603-225",
    "seats": "8.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.3",
    "maxTonnage": "8.3"
  },
  {
    "brand": "VEAM",
    "model": "551605-275",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "VEAM",
    "model": "555102-225",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "VEAM",
    "model": "BULL 2.5",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "VEAM",
    "model": "BULL 2.5",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "BULL CHASSIS",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "BULL MB2.5",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "BULL TD2.5",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "BULL TK 2.5",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "BULL TL 2.5",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "VEAM",
    "model": "BULL TL 2.5",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "CAMEL MB 4.5",
    "seats": "3.69 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.69",
    "maxTonnage": "3.69"
  },
  {
    "brand": "VEAM",
    "model": "CAMEL MB 4.5",
    "seats": "4.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.49",
    "maxTonnage": "4.49"
  },
  {
    "brand": "VEAM",
    "model": "CAMEL TL 4.5",
    "seats": "4.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.49",
    "maxTonnage": "4.49"
  },
  {
    "brand": "VEAM",
    "model": "CUB 1.25",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "CUB CHASSIS",
    "seats": "1.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.07",
    "maxTonnage": "1.07"
  },
  {
    "brand": "VEAM",
    "model": "CUB MB 1.25",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "CUB TD 1.25",
    "seats": "1.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.24",
    "maxTonnage": "1.24"
  },
  {
    "brand": "VEAM",
    "model": "CUB TD 1.25",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "CUB TK 1.25",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "CUB TK1.25",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "CUB TL 1.25",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "VEAM",
    "model": "CUB TL 1.25",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "VEAM",
    "model": "CUB TL 1.25",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "DRAGON MB 2.5",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "DRAGON TL 2.5",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "VEAM",
    "model": "DRAGON TL 2.5",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "DRAGON TL 2.5",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "VEAM",
    "model": "DRAGON TL 2.5",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "FOX CHASSIS",
    "seats": "1.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.24",
    "maxTonnage": "1.24"
  },
  {
    "brand": "VEAM",
    "model": "FOX MB 1.5",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "FOX TD 1.5",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "FOX TD1.5",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "FOX TK 1.5",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "FOX TK 1.5",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "VEAM",
    "model": "FOX TK1.5",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "FOX TL 1.5",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "FOX TL1.5",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "VEAM",
    "model": "FOX TL1.5",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "FOX1.5",
    "seats": "1.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.24",
    "maxTonnage": "1.24"
  },
  {
    "brand": "VEAM",
    "model": "FOX1.5",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "VEAM",
    "model": "FOX1.5",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "HD345 CS",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "VEAM",
    "model": "HD65TK",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "HD65TL",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "VEAM",
    "model": "HD65TL",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "VEAM",
    "model": "HD65TL",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "VEAM",
    "model": "HD65TL",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VEAM",
    "model": "HD72TK",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "VEAM",
    "model": "HD72TL",
    "seats": "2.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.9",
    "maxTonnage": "2.9"
  },
  {
    "brand": "VEAM",
    "model": "HD72TL",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "VEAM",
    "model": "HD72TL",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VEAM",
    "model": "HD800",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "2.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.75",
    "maxTonnage": "2.75"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "4.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.66",
    "maxTonnage": "4.66"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "4.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.71",
    "maxTonnage": "4.71"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "5.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.25",
    "maxTonnage": "5.25"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "5.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.3",
    "maxTonnage": "5.3"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "5.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.75",
    "maxTonnage": "5.75"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "5.92 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.92",
    "maxTonnage": "5.92"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.22",
    "maxTonnage": "6.22"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.25",
    "maxTonnage": "6.25"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.49",
    "maxTonnage": "6.49"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.66",
    "maxTonnage": "6.66"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.7",
    "maxTonnage": "6.7"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.71",
    "maxTonnage": "6.71"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.03",
    "maxTonnage": "7.03"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.05",
    "maxTonnage": "7.05"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.1",
    "maxTonnage": "7.1"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.55",
    "maxTonnage": "7.55"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "7.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.94",
    "maxTonnage": "7.94"
  },
  {
    "brand": "VEAM",
    "model": "HD800 MB",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "VEAM",
    "model": "HD800 TK",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "VEAM",
    "model": "HD800 TK",
    "seats": "6.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.6",
    "maxTonnage": "6.6"
  },
  {
    "brand": "VEAM",
    "model": "HD800 TK",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "VEAM",
    "model": "HD800 TK",
    "seats": "7.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.6",
    "maxTonnage": "7.6"
  },
  {
    "brand": "VEAM",
    "model": "HD800 TK",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "VEAM",
    "model": "HD800 TK",
    "seats": "7.76 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.76",
    "maxTonnage": "7.76"
  },
  {
    "brand": "VEAM",
    "model": "HD800 TK",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/AKH",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/CDMB",
    "seats": "6.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.15",
    "maxTonnage": "6.15"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/CDMB",
    "seats": "7.03 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.03",
    "maxTonnage": "7.03"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/CDV",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/CKGT",
    "seats": "6.71 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.71",
    "maxTonnage": "6.71"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/CKGT",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/TTCM",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/TTCM",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "VEAM",
    "model": "HD800\/VL-XTOXY",
    "seats": "4.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.16",
    "maxTonnage": "4.16"
  },
  {
    "brand": "VEAM",
    "model": "HYUNDAI HD65MB",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VEAM",
    "model": "HYUNDAI HD65TL",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "VEAM",
    "model": "HYUNDAI HD65TL",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "VEAM",
    "model": "HYUNDAI HD65TL",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VEAM",
    "model": "HYUNDAI HD72MB",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VEAM",
    "model": "HYUNDAI HD72TL",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "LION MB 3.5",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "VEAM",
    "model": "LION MB 3.5",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "LION TK3.5",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "LION TL 3.5",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "VEAM",
    "model": "LION TL 3.5",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "NEW MIGHTY",
    "seats": "6.11 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.11",
    "maxTonnage": "6.11"
  },
  {
    "brand": "VEAM",
    "model": "NEW MIGHTY",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "VEAM",
    "model": "NEW MIGHTY",
    "seats": "7.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.07",
    "maxTonnage": "7.07"
  },
  {
    "brand": "VEAM",
    "model": "NEW MIGHTY",
    "seats": "7.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.16",
    "maxTonnage": "7.16"
  },
  {
    "brand": "VEAM",
    "model": "NEW MIGHTY",
    "seats": "7.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.25",
    "maxTonnage": "7.25"
  },
  {
    "brand": "VEAM",
    "model": "PUMA 2.0",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "VEAM",
    "model": "PUMA 2.0",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "VEAM",
    "model": "PUMA 2.0",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "VEAM",
    "model": "PUMA 2.0",
    "seats": "1.93 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.93",
    "maxTonnage": "1.93"
  },
  {
    "brand": "VEAM",
    "model": "PUMA 2.0",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "PUMA CHASSIS",
    "seats": "1.94 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.94",
    "maxTonnage": "1.94"
  },
  {
    "brand": "VEAM",
    "model": "PUMA MB2.0",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "PUMA TD2.0",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "PUMA TK 2.0",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "PUMA TL2.0",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "VEAM",
    "model": "PUMA TL2.0",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "RABBIT 1.0",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "RABBIT MB 1.0",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "RABBIT TD 1.0",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "RABBIT TD1.0",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "RABBIT TK 1.0",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "RABBIT TK1.0",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "RABBIT TL1.0",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "TIGER MB 3.0",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "VEAM",
    "model": "TIGER MB 3.0",
    "seats": "2.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.99",
    "maxTonnage": "2.99"
  },
  {
    "brand": "VEAM",
    "model": "TIGER TK3.0",
    "seats": "2.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.99",
    "maxTonnage": "2.99"
  },
  {
    "brand": "VEAM",
    "model": "TIGER TL 3.0",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "VEAM",
    "model": "TIGER TL 3.0",
    "seats": "2.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.99",
    "maxTonnage": "2.99"
  },
  {
    "brand": "VEAM",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "VEAM",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "VEAM",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "VEAM",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VEAM",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "VEAM",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "VEAM",
    "model": "VB100",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "VB1110",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "VEAM",
    "model": "VB125",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "VB150",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "VB160",
    "seats": "1.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.35",
    "maxTonnage": "1.35"
  },
  {
    "brand": "VEAM",
    "model": "VB200",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "VEAM",
    "model": "VB350",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VB650",
    "seats": "6.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.32",
    "maxTonnage": "6.32"
  },
  {
    "brand": "VEAM",
    "model": "VB652",
    "seats": "6.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.49",
    "maxTonnage": "6.49"
  },
  {
    "brand": "VEAM",
    "model": "VB653",
    "seats": "6.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.49",
    "maxTonnage": "6.49"
  },
  {
    "brand": "VEAM",
    "model": "VB750",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "VEAM",
    "model": "VB750-1",
    "seats": "7.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.2",
    "maxTonnage": "7.2"
  },
  {
    "brand": "VEAM",
    "model": "VB950",
    "seats": "9.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.5",
    "maxTonnage": "9.5"
  },
  {
    "brand": "VEAM",
    "model": "VB980",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "VEAM",
    "model": "VPB900",
    "seats": "8.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.45",
    "maxTonnage": "8.45"
  },
  {
    "brand": "VEAM",
    "model": "VPT095 MB",
    "seats": "0.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.81",
    "maxTonnage": "0.81"
  },
  {
    "brand": "VEAM",
    "model": "VPT095 MB",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "VEAM",
    "model": "VPT095 MB",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "VPT095 TL",
    "seats": "0.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.49",
    "maxTonnage": "0.49"
  },
  {
    "brand": "VEAM",
    "model": "VPT095 TL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "VPT095\/MB",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "VPT095\/TK",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "VEAM",
    "model": "VPT095\/TL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "VPT095\/TL1",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "VPT350\/MB",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VPT350\/TK",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VPT350\/TL",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VPT450\/MB",
    "seats": "4.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.49",
    "maxTonnage": "4.49"
  },
  {
    "brand": "VEAM",
    "model": "VPT880 MB-1",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "VEAM",
    "model": "VPT880 MB-2",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "VEAM",
    "model": "VPT950\/MB",
    "seats": "9.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.3",
    "maxTonnage": "9.3"
  },
  {
    "brand": "VEAM",
    "model": "VPT950\/TK",
    "seats": "8.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.55",
    "maxTonnage": "8.55"
  },
  {
    "brand": "VEAM",
    "model": "VT100 MB",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "VT100 MB",
    "seats": "1.01 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.01",
    "maxTonnage": "1.01"
  },
  {
    "brand": "VEAM",
    "model": "VT100 MB",
    "seats": "1.19 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.19",
    "maxTonnage": "1.19"
  },
  {
    "brand": "VEAM",
    "model": "VT100 TK",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VEAM",
    "model": "VT1100 MB",
    "seats": "10.66 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.66",
    "maxTonnage": "10.66"
  },
  {
    "brand": "VEAM",
    "model": "VT1100 MB",
    "seats": "11.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11",
    "maxTonnage": "11"
  },
  {
    "brand": "VEAM",
    "model": "VT1100 MB",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "VEAM",
    "model": "VT1100 MB",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "VEAM",
    "model": "VT1100 MB",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "VEAM",
    "model": "VT1100 MB",
    "seats": "8.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.15",
    "maxTonnage": "8.15"
  },
  {
    "brand": "VEAM",
    "model": "VT125 MB",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "VT125 TK",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "VT150 MB",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "VT150 TK",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "VT150A MB",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "VT150A TK",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "VT158 TK",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "VT160 CS",
    "seats": "1.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.46",
    "maxTonnage": "1.46"
  },
  {
    "brand": "VEAM",
    "model": "VT160 CS",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "VT160 CS",
    "seats": "1.81 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.81",
    "maxTonnage": "1.81"
  },
  {
    "brand": "VEAM",
    "model": "VT200 MB",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "VEAM",
    "model": "VT200 MB",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT200 TK",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT200-1",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT200-2",
    "seats": "2.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.05",
    "maxTonnage": "2.05"
  },
  {
    "brand": "VEAM",
    "model": "VT200A MB",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT200A TK",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT200A TK",
    "seats": "2.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.15",
    "maxTonnage": "2.15"
  },
  {
    "brand": "VEAM",
    "model": "VT201",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT250 MB",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "VEAM",
    "model": "VT250 MB",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "VT250 TK",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "VEAM",
    "model": "VT250 TK",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT250 TK",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "VT250-1",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "VEAM",
    "model": "VT250-1",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "VT252",
    "seats": "1.91 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.91",
    "maxTonnage": "1.91"
  },
  {
    "brand": "VEAM",
    "model": "VT252",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT252",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "VEAM",
    "model": "VT252",
    "seats": "2.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.36",
    "maxTonnage": "2.36"
  },
  {
    "brand": "VEAM",
    "model": "VT252",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "VEAM",
    "model": "VT252",
    "seats": "2.41 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.41",
    "maxTonnage": "2.41"
  },
  {
    "brand": "VEAM",
    "model": "VT252",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "VEAM",
    "model": "VT255",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "VEAM",
    "model": "VT255",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT255",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "VEAM",
    "model": "VT255",
    "seats": "2.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.49",
    "maxTonnage": "2.49"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.55",
    "maxTonnage": "1.55"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "VEAM",
    "model": "VT260",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VT340",
    "seats": "2.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.45",
    "maxTonnage": "2.45"
  },
  {
    "brand": "VEAM",
    "model": "VT340",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VT350",
    "seats": "3.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.2",
    "maxTonnage": "3.2"
  },
  {
    "brand": "VEAM",
    "model": "VT350",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VT490 MB",
    "seats": "3.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.95",
    "maxTonnage": "3.95"
  },
  {
    "brand": "VEAM",
    "model": "VT490 MB",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "VEAM",
    "model": "VT490A",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "VEAM",
    "model": "VT490TK",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "VEAM",
    "model": "VT498 MB",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "VEAM",
    "model": "VT498 TK",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "VEAM",
    "model": "VT500 MB",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "VEAM",
    "model": "VT500 TK",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "VEAM",
    "model": "VT650",
    "seats": "4.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.85",
    "maxTonnage": "4.85"
  },
  {
    "brand": "VEAM",
    "model": "VT650",
    "seats": "6.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.4",
    "maxTonnage": "6.4"
  },
  {
    "brand": "VEAM",
    "model": "VT650",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "VEAM",
    "model": "VT650",
    "seats": "6.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.49",
    "maxTonnage": "6.49"
  },
  {
    "brand": "VEAM",
    "model": "VT650",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "3.73 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.73",
    "maxTonnage": "3.73"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "4.05 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.05",
    "maxTonnage": "4.05"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "4.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.15",
    "maxTonnage": "4.15"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "4.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.3",
    "maxTonnage": "4.3"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "4.86 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.86",
    "maxTonnage": "4.86"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "5.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.55",
    "maxTonnage": "5.55"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "5.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.8",
    "maxTonnage": "5.8"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "5.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.9",
    "maxTonnage": "5.9"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "5.97 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.97",
    "maxTonnage": "5.97"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "6.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.2",
    "maxTonnage": "6.2"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "6.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.35",
    "maxTonnage": "6.35"
  },
  {
    "brand": "VEAM",
    "model": "VT651",
    "seats": "6.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.49",
    "maxTonnage": "6.49"
  },
  {
    "brand": "VEAM",
    "model": "VT652",
    "seats": "3.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.3",
    "maxTonnage": "3.3"
  },
  {
    "brand": "VEAM",
    "model": "VT652",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VEAM",
    "model": "VT652",
    "seats": "6.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.49",
    "maxTonnage": "6.49"
  },
  {
    "brand": "VEAM",
    "model": "VT735 MB",
    "seats": "7.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.47",
    "maxTonnage": "7.47"
  },
  {
    "brand": "VEAM",
    "model": "VT735 TK",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "1.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.4",
    "maxTonnage": "1.4"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "4.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.55",
    "maxTonnage": "4.55"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "4.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.99",
    "maxTonnage": "4.99"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "6.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.65",
    "maxTonnage": "6.65"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "7.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.3",
    "maxTonnage": "7.3"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "7.36 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.36",
    "maxTonnage": "7.36"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "7.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.45",
    "maxTonnage": "7.45"
  },
  {
    "brand": "VEAM",
    "model": "VT750",
    "seats": "7.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.85",
    "maxTonnage": "7.85"
  },
  {
    "brand": "VEAM",
    "model": "VT751",
    "seats": "6.29 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.29",
    "maxTonnage": "6.29"
  },
  {
    "brand": "VEAM",
    "model": "VT751",
    "seats": "7.07 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.07",
    "maxTonnage": "7.07"
  },
  {
    "brand": "VEAM",
    "model": "VT751",
    "seats": "7.16 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.16",
    "maxTonnage": "7.16"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA 6110",
    "seats": "30 chỗ",
    "minSeat": "30",
    "maxSeat": "30",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "TRANSINCO",
    "model": "CA 6110",
    "seats": "50 chỗ",
    "minSeat": "50",
    "maxSeat": "50",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINAMOTOR",
    "model": "CABSTAR",
    "seats": "3.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.8",
    "maxTonnage": "3.8"
  },
  {
    "brand": "VINAMOTOR",
    "model": "CABSTAR",
    "seats": "3.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.9",
    "maxTonnage": "3.9"
  },
  {
    "brand": "VINAMOTOR",
    "model": "CABSTAR NS200",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VINAMOTOR",
    "model": "CABSTAR NS350",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VINAMOTOR",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "VINAMOTOR",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "VINAMOTOR",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "VINAMOTOR",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VINAMOTOR",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "VINAMOTOR",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "VINAXUKI",
    "model": "1200B",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "VINAXUKI",
    "model": "1240T",
    "seats": "0.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.98",
    "maxTonnage": "0.98"
  },
  {
    "brand": "VINAXUKI",
    "model": "1240T",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "VINAXUKI",
    "model": "1240T",
    "seats": "1.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.24",
    "maxTonnage": "1.24"
  },
  {
    "brand": "VINAXUKI",
    "model": "1240T\/MB1",
    "seats": "1.15 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.15",
    "maxTonnage": "1.15"
  },
  {
    "brand": "VINAXUKI",
    "model": "1240T\/TK",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "VINAXUKI",
    "model": "1240T\/TK1",
    "seats": "1.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1",
    "maxTonnage": "1"
  },
  {
    "brand": "VINAXUKI",
    "model": "1250BA",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VINAXUKI",
    "model": "1250TA",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VINAXUKI",
    "model": "1490T",
    "seats": "1.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.1",
    "maxTonnage": "1.1"
  },
  {
    "brand": "VINAXUKI",
    "model": "1490T",
    "seats": "1.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.3",
    "maxTonnage": "1.3"
  },
  {
    "brand": "VINAXUKI",
    "model": "1490T",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VINAXUKI",
    "model": "1490T\/MB1",
    "seats": "1.39 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.39",
    "maxTonnage": "1.39"
  },
  {
    "brand": "VINAXUKI",
    "model": "1490T\/TK1",
    "seats": "1.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.25",
    "maxTonnage": "1.25"
  },
  {
    "brand": "VINAXUKI",
    "model": "1900TA",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "VINAXUKI",
    "model": "1980.PD",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "VINAXUKI",
    "model": "1980T",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "VINAXUKI",
    "model": "1980T",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "VINAXUKI",
    "model": "1980T",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "VINAXUKI",
    "model": "1980T\/MB1",
    "seats": "1.83 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.83",
    "maxTonnage": "1.83"
  },
  {
    "brand": "VINAXUKI",
    "model": "1980T\/TK1",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "VINAXUKI",
    "model": "1990BA",
    "seats": "1.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.99",
    "maxTonnage": "1.99"
  },
  {
    "brand": "VINAXUKI",
    "model": "2500BA",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "2500BA.4x4",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "2500BK.4x4",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "2500TL",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "2500TL.4x4",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "2500TL\/TK",
    "seats": "2.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.25",
    "maxTonnage": "2.25"
  },
  {
    "brand": "VINAXUKI",
    "model": "2700BA",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "VINAXUKI",
    "model": "3000BA",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "VINAXUKI",
    "model": "3250BA",
    "seats": "3.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.25",
    "maxTonnage": "3.25"
  },
  {
    "brand": "VINAXUKI",
    "model": "3450T",
    "seats": "3.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.45",
    "maxTonnage": "3.45"
  },
  {
    "brand": "VINAXUKI",
    "model": "3490BA",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VINAXUKI",
    "model": "3490BA.4x4",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VINAXUKI",
    "model": "3490BA\/HIệP HOà",
    "seats": "2.79 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.79",
    "maxTonnage": "2.79"
  },
  {
    "brand": "VINAXUKI",
    "model": "3490TL",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "VINAXUKI",
    "model": "3500TL",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "3500TL1",
    "seats": "3.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "3600AT",
    "seats": "3.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.6",
    "maxTonnage": "3.6"
  },
  {
    "brand": "VINAXUKI",
    "model": "3600AT\/MB1",
    "seats": "3.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.35",
    "maxTonnage": "3.35"
  },
  {
    "brand": "VINAXUKI",
    "model": "4000TL",
    "seats": "4.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4",
    "maxTonnage": "4"
  },
  {
    "brand": "VINAXUKI",
    "model": "4500BA",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "4500BA.TIDY",
    "seats": "2.46 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.46",
    "maxTonnage": "2.46"
  },
  {
    "brand": "VINAXUKI",
    "model": "4500BA\/BD",
    "seats": "4.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.5",
    "maxTonnage": "4.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "470TL",
    "seats": "0.47 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.47",
    "maxTonnage": "0.47"
  },
  {
    "brand": "VINAXUKI",
    "model": "5000BA",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "VINAXUKI",
    "model": "5000BA.4X4",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "VINAXUKI",
    "model": "5500TL",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "5500TL\/KM",
    "seats": "5.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.1",
    "maxTonnage": "5.1"
  },
  {
    "brand": "VINAXUKI",
    "model": "6000",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "VINAXUKI",
    "model": "6000BA.4x4",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "VINAXUKI",
    "model": "6000TL",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "VINAXUKI",
    "model": "6500BA",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "6500TL",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "6800BA",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "VINAXUKI",
    "model": "7000BA",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "VINAXUKI",
    "model": "7000TL",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "VINAXUKI",
    "model": "7000TL.4x4",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "VINAXUKI",
    "model": "7000TL1",
    "seats": "7.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7",
    "maxTonnage": "7"
  },
  {
    "brand": "VINAXUKI",
    "model": "7500TL",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "8500BA",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "VINAXUKI",
    "model": "8500TL",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "960B",
    "seats": "0.96 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.96",
    "maxTonnage": "0.96"
  },
  {
    "brand": "VINAXUKI",
    "model": "990T",
    "seats": "0.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.7",
    "maxTonnage": "0.7"
  },
  {
    "brand": "VINAXUKI",
    "model": "990T",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "VINAXUKI",
    "model": "990T",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VINAXUKI",
    "model": "990T\/KPI",
    "seats": "0.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.6",
    "maxTonnage": "0.6"
  },
  {
    "brand": "VINAXUKI",
    "model": "990T\/MB1",
    "seats": "0.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.9",
    "maxTonnage": "0.9"
  },
  {
    "brand": "VINAXUKI",
    "model": "990T\/TK1",
    "seats": "0.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.75",
    "maxTonnage": "0.75"
  },
  {
    "brand": "VINAXUKI",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "VINAXUKI",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "VINAXUKI",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "VINAXUKI",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "VINAXUKI",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "VINAXUKI",
    "model": "V29K",
    "seats": "29 chỗ",
    "minSeat": "29",
    "maxSeat": "29",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK1044TL1",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK1044TL1\/TB",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK1240TL",
    "seats": "1.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.24",
    "maxTonnage": "1.24"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK12BA",
    "seats": "10.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.5",
    "maxTonnage": "10.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK13BA",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK13BA210",
    "seats": "9.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.7",
    "maxTonnage": "9.7"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK13BA280",
    "seats": "9.60 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "9.6",
    "maxTonnage": "9.6"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK1490TL",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK17BA",
    "seats": "14.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14",
    "maxTonnage": "14"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK17BA375",
    "seats": "16.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16",
    "maxTonnage": "16"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK1980TL1",
    "seats": "1.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.98",
    "maxTonnage": "1.98"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK1980TL1\/SGCD",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK2700BA.4x4",
    "seats": "2.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.7",
    "maxTonnage": "2.7"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK4950BA",
    "seats": "4.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.95",
    "maxTonnage": "4.95"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK5500TL",
    "seats": "5.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.5",
    "maxTonnage": "5.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK6000BA.4x4",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "VINAXUKI",
    "model": "VXK990TL",
    "seats": "0.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0.99",
    "maxTonnage": "0.99"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK1044TL",
    "seats": "2.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2",
    "maxTonnage": "2"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK1044TL\/TB",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK1044TL\/TB",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK1044TL\/VL",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK1047TL",
    "seats": "2.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.5",
    "maxTonnage": "2.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK1047TL\/Z751.TCR",
    "seats": "1.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.22",
    "maxTonnage": "1.22"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK1047TL\/ĐL",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK10TL1",
    "seats": "11.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.1",
    "maxTonnage": "11.1"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK13TL1",
    "seats": "13.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "13.5",
    "maxTonnage": "13.5"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK14TL1",
    "seats": "15.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15.75",
    "maxTonnage": "15.75"
  },
  {
    "brand": "VINAXUKI",
    "model": "XK7TL1",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "VINAXUKI",
    "model": "Xe khách",
    "seats": "từ 09 đến 11 chỗ",
    "minSeat": "9",
    "maxSeat": "11",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINAXUKI",
    "model": "Xe khách",
    "seats": "từ 12 đến 16 chỗ",
    "minSeat": "12",
    "maxSeat": "16",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINAXUKI",
    "model": "Xe khách",
    "seats": "từ 17 đến 24 chỗ",
    "minSeat": "17",
    "maxSeat": "24",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINAXUKI",
    "model": "Xe khách",
    "seats": "từ 25 đến 28 chỗ",
    "minSeat": "25",
    "maxSeat": "28",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINAXUKI",
    "model": "Xe khách",
    "seats": "từ 29 đến 42 chỗ",
    "minSeat": "29",
    "maxSeat": "42",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINAXUKI",
    "model": "Xe khách",
    "seats": "từ 43 chỗ trở lên",
    "minSeat": "43",
    "maxSeat": "99",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FN129",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "ISUZU",
    "model": "FN129",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "ISUZU",
    "model": "FN129",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/CDMB",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/CDMB.TKBN",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/DONGLINH",
    "seats": "6.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.75",
    "maxTonnage": "6.75"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/DONGLINH",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/HONGDUC",
    "seats": "7.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.8",
    "maxTonnage": "7.8"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/HT",
    "seats": "6.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.8",
    "maxTonnage": "6.8"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/HT",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/MB",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/MB",
    "seats": "7.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.35",
    "maxTonnage": "7.35"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/MB",
    "seats": "7.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.7",
    "maxTonnage": "7.7"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/MB",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/MK",
    "seats": "6.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.9",
    "maxTonnage": "6.9"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/MK",
    "seats": "7.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.5",
    "maxTonnage": "7.5"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/TK",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/TLC",
    "seats": "6.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.85",
    "maxTonnage": "6.85"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/TTCM",
    "seats": "5.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.7",
    "maxTonnage": "5.7"
  },
  {
    "brand": "ISUZU",
    "model": "FN129\/VL",
    "seats": "6.55 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.55",
    "maxTonnage": "6.55"
  },
  {
    "brand": "ISUZU",
    "model": "FN129AL4\/MB",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FN129L4",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FN129L4\/VPM",
    "seats": "6.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.95",
    "maxTonnage": "6.95"
  },
  {
    "brand": "ISUZU",
    "model": "FN129L4\/VPM",
    "seats": "8.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.2",
    "maxTonnage": "8.2"
  },
  {
    "brand": "ISUZU",
    "model": "FN129L4\/VUL",
    "seats": "5.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.4",
    "maxTonnage": "5.4"
  },
  {
    "brand": "ISUZU",
    "model": "FN129M4",
    "seats": "8.32 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.32",
    "maxTonnage": "8.32"
  },
  {
    "brand": "ISUZU",
    "model": "FN129M4",
    "seats": "8.42 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.42",
    "maxTonnage": "8.42"
  },
  {
    "brand": "ISUZU",
    "model": "FN129M4\/VPM",
    "seats": "8.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.4",
    "maxTonnage": "8.4"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S",
    "seats": "7.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.65",
    "maxTonnage": "7.65"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S",
    "seats": "8.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.1",
    "maxTonnage": "8.1"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S\/CTĐ",
    "seats": "5.43 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5.43",
    "maxTonnage": "5.43"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S\/TUP",
    "seats": "7.77 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.77",
    "maxTonnage": "7.77"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S\/VL",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S\/VPM",
    "seats": "8.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S4\/HIEPHOA",
    "seats": "6.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6",
    "maxTonnage": "6"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S4\/MIKA",
    "seats": "7.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "7.4",
    "maxTonnage": "7.4"
  },
  {
    "brand": "ISUZU",
    "model": "FN129S4\/MIKA",
    "seats": "8.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8.5",
    "maxTonnage": "8.5"
  },
  {
    "brand": "ISUZU",
    "model": "FTR160L4\/QTH",
    "seats": "6.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.3",
    "maxTonnage": "6.3"
  },
  {
    "brand": "ISUZU",
    "model": "FTR160L4\/VPM",
    "seats": "6.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.45",
    "maxTonnage": "6.45"
  },
  {
    "brand": "ISUZU",
    "model": "FTR160L4\/VPM",
    "seats": "6.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "6.5",
    "maxTonnage": "6.5"
  },
  {
    "brand": "ISUZU",
    "model": "FV330",
    "seats": "16.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.2",
    "maxTonnage": "16.2"
  },
  {
    "brand": "ISUZU",
    "model": "FV330\/CDV",
    "seats": "11.51 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "11.51",
    "maxTonnage": "11.51"
  },
  {
    "brand": "ISUZU",
    "model": "FV330\/DPT",
    "seats": "16.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.65",
    "maxTonnage": "16.65"
  },
  {
    "brand": "ISUZU",
    "model": "FV330\/MB",
    "seats": "16.10 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "16.1",
    "maxTonnage": "16.1"
  },
  {
    "brand": "ISUZU",
    "model": "FV330\/MB",
    "seats": "17.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "17.99",
    "maxTonnage": "17.99"
  },
  {
    "brand": "ISUZU",
    "model": "NK490\/MB",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "ISUZU",
    "model": "NK490\/TK",
    "seats": "2.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.4",
    "maxTonnage": "2.4"
  },
  {
    "brand": "ISUZU",
    "model": "NK490L4",
    "seats": "2.30 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.3",
    "maxTonnage": "2.3"
  },
  {
    "brand": "ISUZU",
    "model": "NK490L4\/MK",
    "seats": "1.85 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.85",
    "maxTonnage": "1.85"
  },
  {
    "brand": "ISUZU",
    "model": "NK490L4\/TK",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "ISUZU",
    "model": "NK490S4\/VPM",
    "seats": "1.95 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.95",
    "maxTonnage": "1.95"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4\/ADT",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4\/QTH",
    "seats": "1.50 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.5",
    "maxTonnage": "1.5"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4\/QTH",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4\/QTH",
    "seats": "1.75 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.75",
    "maxTonnage": "1.75"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4\/TL",
    "seats": "1.80 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.8",
    "maxTonnage": "1.8"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4\/VPM",
    "seats": "1.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.49",
    "maxTonnage": "1.49"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL4\/VPM",
    "seats": "1.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.9",
    "maxTonnage": "1.9"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL9\/VPM",
    "seats": "1.45 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.45",
    "maxTonnage": "1.45"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL9\/VPM",
    "seats": "1.65 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.65",
    "maxTonnage": "1.65"
  },
  {
    "brand": "ISUZU",
    "model": "NK490SL9\/VPM",
    "seats": "1.70 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.7",
    "maxTonnage": "1.7"
  },
  {
    "brand": "ISUZU",
    "model": "NK550SL4\/MK",
    "seats": "1.82 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.82",
    "maxTonnage": "1.82"
  },
  {
    "brand": "ISUZU",
    "model": "NK650L4",
    "seats": "3.48 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.48",
    "maxTonnage": "3.48"
  },
  {
    "brand": "ISUZU",
    "model": "NK650L4\/TK",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "NK650L4\/VPM",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "QHR650\/CTĐ",
    "seats": "2.13 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.13",
    "maxTonnage": "2.13"
  },
  {
    "brand": "ISUZU",
    "model": "QHR650\/MB",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "QHR650\/TK",
    "seats": "3.49 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.49",
    "maxTonnage": "3.49"
  },
  {
    "brand": "ISUZU",
    "model": "QHR650\/TTĐ",
    "seats": "3.22 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.22",
    "maxTonnage": "3.22"
  },
  {
    "brand": "ISUZU",
    "model": "QHR650L4",
    "seats": "3.40 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.4",
    "maxTonnage": "3.4"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "ISUZU",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "VOLVO",
    "model": "FM12",
    "seats": "10.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10.2",
    "maxTonnage": "10.2"
  },
  {
    "brand": "VOLVO",
    "model": "FM400",
    "seats": "10.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "10"
  },
  {
    "brand": "VOLVO",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "VOLVO",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "VOLVO",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "VOLVO",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "VOLVO",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "VOLVO",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "VINFAST",
    "model": "Fadil",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Fadil",
    "seats": "1.4-AT-PLUS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Fadil",
    "seats": "1.4-AT-PREMIUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Fadil",
    "seats": "1.4AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Fadil",
    "seats": "1.4L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Lux A 2.0",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Lux A 2.0",
    "seats": "PLUS-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Lux A 2.0",
    "seats": "PREMIUM-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Lux SA 2.0",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Lux SA 2.0",
    "seats": "PLUS-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Lux SA 2.0",
    "seats": "PREMIUM-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "Lux SA 2.0",
    "seats": "SUV",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VINFAST",
    "model": "VF E34",
    "seats": "R5GH01",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FN series",
    "seats": "FN129AL4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FN series",
    "seats": "FN129L4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FN series",
    "seats": "FN129M4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FN series",
    "seats": "FN129S4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FTR series",
    "seats": "FTR160L4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FTR series",
    "seats": "FTR160S4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FTR series",
    "seats": "FTR160SL4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "FV series",
    "seats": "FV330",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Ginga series",
    "seats": "Ginga370",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "Ginga series",
    "seats": "Ginga460",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "KR series",
    "seats": "KR750L4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "KR series",
    "seats": "KR750SL4",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "NK series",
    "seats": "NK 470L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "NK series",
    "seats": "NK490L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "NK series",
    "seats": "NK490S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "NK series",
    "seats": "NK490SL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "NK series",
    "seats": "NK550L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "NK series",
    "seats": "NK550SL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "NK series",
    "seats": "NK650L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ISUZU",
    "model": "NK series",
    "seats": "NK650SL",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Beetle",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Beetle",
    "seats": "CONVERTIBLE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Beetle",
    "seats": "DUNE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Beetle",
    "seats": "DUNE-LIMITED",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Jetta",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "New Beetle",
    "seats": "1.4-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "New Beetle",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "New Beetle",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "New Beetle",
    "seats": "2.5-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "1.8-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "1.8-BLUEMOTION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "1.8-BLUEMOTION-COMFORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "1.8-BLUEMOTION-HIGH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "1.8TSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "BLUEMOTION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "BLUEMOTION-COMFORT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "BLUEMOTION-HIGH",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "CC-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Passat",
    "seats": "GP",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Polo",
    "seats": "1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Polo",
    "seats": "GP-1.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Polo",
    "seats": "SEDAN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Scirocco",
    "seats": "1.4-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Scirocco",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Scirocco",
    "seats": "GTS",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Tiguan",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Tiguan",
    "seats": "ALLSPACE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Tiguan",
    "seats": "ALLSPACE-HIGHLINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Tiguan",
    "seats": "ALLSPACE-LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Tiguan",
    "seats": "ALLSPACE-LUXURY-S",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Tiguan",
    "seats": "LUXURY",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Tiguan",
    "seats": "LUXURY-TOPLINE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Touareg",
    "seats": "2.5-R5-TDI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Touareg",
    "seats": "3.6-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Touareg",
    "seats": "ELEGANCE-2.0-TSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLKSWAGEN",
    "model": "Touareg",
    "seats": "PREMIUM-2.0-TSI",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "S90",
    "seats": "T5-INSCRIPTION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "S90",
    "seats": "T5-MOMENTUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "V90",
    "seats": "CROSS-COUNTY-T6-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC40",
    "seats": "T5-AWD-R-DESIGN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC60",
    "seats": "INSCRIPTION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC60",
    "seats": "R-DESIGN",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC60",
    "seats": "T6-AWD-INSCRIPTION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC90",
    "seats": "2.5-AT-AWD",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC90",
    "seats": "T6-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC90",
    "seats": "T6-INSCRIPTION",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC90",
    "seats": "T6-MOMENTUM",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "VOLVO",
    "model": "XC90",
    "seats": "T8-EXCELLENCE",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "YUEJIN",
    "model": "GMC",
    "seats": "4.25 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.25",
    "maxTonnage": "4.25"
  },
  {
    "brand": "YUEJIN",
    "model": "NJ1031BEDJ1",
    "seats": "1.24 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.24",
    "maxTonnage": "1.24"
  },
  {
    "brand": "YUEJIN",
    "model": "NJ1042DAVN",
    "seats": "2.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.2",
    "maxTonnage": "2.2"
  },
  {
    "brand": "YUEJIN",
    "model": "NJ1043DAVN",
    "seats": "3.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3"
  },
  {
    "brand": "YUEJIN",
    "model": "NJ1063DAVN",
    "seats": "5.00 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "5",
    "maxTonnage": "5"
  },
  {
    "brand": "YUEJIN",
    "model": "NJ1310DAWL.GMC",
    "seats": "14.99 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "14.99",
    "maxTonnage": "14.99"
  },
  {
    "brand": "YUEJIN",
    "model": "NJ3038BEDJ1",
    "seats": "1.20 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "1.2",
    "maxTonnage": "1.2"
  },
  {
    "brand": "YUEJIN",
    "model": "NJT1810",
    "seats": "2.98 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.98",
    "maxTonnage": "2.98"
  },
  {
    "brand": "YUEJIN",
    "model": "TM2",
    "seats": "2.35 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "2.35",
    "maxTonnage": "2.35"
  },
  {
    "brand": "YUEJIN",
    "model": "TM4",
    "seats": "4.90 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "4.9",
    "maxTonnage": "4.9"
  },
  {
    "brand": "YUEJIN",
    "model": "Tải",
    "seats": "dưới 3.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "3"
  },
  {
    "brand": "YUEJIN",
    "model": "Tải",
    "seats": "trên 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "15",
    "maxTonnage": "1000"
  },
  {
    "brand": "YUEJIN",
    "model": "Tải",
    "seats": "từ 10.0 đến dưới 15.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "10",
    "maxTonnage": "15"
  },
  {
    "brand": "YUEJIN",
    "model": "Tải",
    "seats": "từ 3.0 đến dưới 3.5 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3",
    "maxTonnage": "3.5"
  },
  {
    "brand": "YUEJIN",
    "model": "Tải",
    "seats": "từ 3.5 đến dưới 8.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "3.5",
    "maxTonnage": "8"
  },
  {
    "brand": "YUEJIN",
    "model": "Tải",
    "seats": "từ 8.0 đến dưới 10.0 tấn",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "8",
    "maxTonnage": "10"
  },
  {
    "brand": "ZOTYE",
    "model": "Hunter",
    "seats": "1.3-MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ZOTYE",
    "model": "T600",
    "seats": "S-2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ZOTYE",
    "model": "Z3",
    "seats": "1.5L Turrbo",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ZOTYE",
    "model": "Z300",
    "seats": "1.5L 5MT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ZOTYE",
    "model": "Z300",
    "seats": "1.6 4AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ZOTYE",
    "model": "Z500",
    "seats": "1.5L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ZOTYE",
    "model": "Z700",
    "seats": "2.0L",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ZOTYE",
    "model": "Z8",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  },
  {
    "brand": "ZOTYE",
    "model": "Z8L",
    "seats": "2.0-AT",
    "minSeat": "2",
    "maxSeat": "2",
    "minTonnage": "0",
    "maxTonnage": "0"
  }];
  