import { types } from "./agreementpay.type";

const initState = {
  creatingAgreementpay: false,
  agreementpayResponse: null,
  createAgreementpayErrMsg: "",
  unBindingAgreementpay: false,
  unBindingAgreementpaySuccess: false,
  unBindingAgreementpayErrMsg: "",
};

export const agreementpayReducer = (state = initState, action) => {
  switch (action.type) {
    case types.CREATING_AGREEMENTPAY:
      return {
        creatingAgreementpay: true,
        agreementpayResponse: null,
        createAgreementpayErrMsg: "",
        unBindingAgreementpay: false,
        unBindingAgreementpaySuccess: false,
        unBindingAgreementpayErrMsg: "",
      };
    case types.CREATE_AGREEMENTPAY_SUCCESS:
      return {
        creatingAgreementpay: false,
        agreementpayResponse: action.agreementpayResponse,
        createAgreementpayErrMsg: "",
        unBindingAgreementpay: false,
        unBindingAgreementpaySuccess: false,
        unBindingAgreementpayErrMsg: "",
      };
    case types.CREATE_AGREEMENTPAY_FAIL:
      return {
        creatingAgreementpay: false,
        agreementpayResponse: null,
        createAgreementpayErrMsg: action.errMsg,
        unBindingAgreementpay: false,
        unBindingAgreementpaySuccess: false,
        unBindingAgreementpayErrMsg: "",
      };
    case types.UNBINDING_AGREEMENTPAY:
      return {
        creatingAgreementpay: false,
        agreementpayResponse: null,
        createAgreementpayErrMsg: "",
        unBindingAgreementpay: true,
        unBindingAgreementpaySuccess: false,
        unBindingAgreementpayErrMsg: "",
      };
    case types.UNBINDING_AGREEMENTPAY_SUCCESS:
      return {
        creatingAgreementpay: false,
        agreementpayResponse: null,
        createAgreementpayErrMsg: "",
        unBindingAgreementpay: false,
        unBindingAgreementpaySuccess: true,
        unBindingAgreementpayErrMsg: "",
      };
    case types.UNBINDING_AGREEMENTPAY_FAIL:
      return {
        creatingAgreementpay: false,
        agreementpayResponse: null,
        createAgreementpayErrMsg: "",
        unBindingAgreementpay: false,
        unBindingAgreementpaySuccess: false,
        unBindingAgreementpayErrMsg: action.errMsg,
      };
    case types.CLEAR_AGREEMENTPAY:
      return initState;

    default:
      return state;
  }
};
